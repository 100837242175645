import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { TrashIcon } from "../../../components/SvgIcons";
import ThemeModal from "../../../components/ThemeModal";
import S3BrowserNew from "../../../components/S3Browser";


function VideoCommon({
  bodyMultiTypeFileVideo,
  setBodyMultiTypeFileVideo,
  setBodyMultiTypeVideos,
  bodyMultiTypeVideos,
  currentPlayingIndex,
  setCurrentPlayingIndex,
}) {
  // console.log("currentPlayingIndex", currentPlayingIndex);
  const videoRefs = useRef([]);
  const [videoUrls, setVideoUrls] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});
  const [source, setSource] = React.useState();

//   const selectedMedia = (fileName, file) => {
//     setFormData({ ...formData, ...{ [fileName]: file } });
//     setSource(file);
//     setVideoUrls(file);
//     setModalShow(false);
//   };

  const selectedMedia = (fileName, file) => {
    // If a single file is selected, push it to the array
    if (Array.isArray(file)) {
      const newFiles = file.slice(0, Math.max(0, 12 - bodyMultiTypeFileVideo.length)); // Restrict to remaining slots
      const newUrls = newFiles.map((file) => file);
  
      setBodyMultiTypeFileVideo((prev) => [...prev, ...newFiles]);
      setVideoUrls((prev) => [...prev, ...newUrls]);
    } else {
      if (bodyMultiTypeFileVideo.length < 12) {
        setBodyMultiTypeFileVideo((prev) => [...prev, file]);
        setVideoUrls((prev) => [...prev, file]);
      }
    }
  
    setModalShow(false);
  };
  

  console.log("videoUrls=======>",videoUrls);
//   useEffect(() => {
//     // Create URLs for each file and update state
//     const urls = bodyMultiTypeFileVideo.map(file => URL.createObjectURL(file));
//     setVideoUrls(urls);

//     // Cleanup: Revoke URLs when component unmounts or files change
//     return () => {
//       urls.forEach(URL.revokeObjectURL);
//     };
//   }, [bodyMultiTypeFileVideo]);
  //Multiple BodyType Video Upload  Function

  const handleVideoClick = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.play();
    }
  };

  //Multiple Delete BodyType Video Upload  Function
  const deleteMultipleVideo = (index) => {
    const updatedVideos = [...bodyMultiTypeFileVideo];
    updatedVideos.splice(index, 1);
    setBodyMultiTypeFileVideo(updatedVideos);
  };


  return (
    <Row>
      <Col md={12}>
        <span>Body Type Video (up to 12)</span>
        <Form.Group
          className="custom-file-upload mt-0"
          controlId="bodyMultiTypeVideos"
        //   onChange={handleBodyTypeVideo}
          onClick={() => {
            setFileName("image");
            setModalShow(true);
          }}
        >
          <Form.Label className={"common-btn"}>Upload Video</Form.Label>
          {/* <Form.Control hidden type="file" accept="video/*" multiple /> */}
        </Form.Group>
        <div className="upload-video">
          {videoUrls && videoUrls.length > 0 ? (
            videoUrls.map((url, index) => (
              <div className="image-item" key={index}>
                <video
                  className={"img-table img-thumbnail"}
                  controls
                  title={`Video ${index + 1}`}
                  autoPlay={currentPlayingIndex === index}
                  src={url}
                  ref={el => videoRefs.current[index] = el}
                  onClick={() => handleVideoClick()}
                ></video>

                <div className="image-item__btn-wrapper">
                  <Button
                    type="Button"
                    className="common-btn"
                    onClick={() => deleteMultipleVideo(index)}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <span>No videos uploaded</span>
          )}
        </div>

        <ThemeModal
        title={"S3 Browser"}
        content={
          <S3BrowserNew
            fileName={fileName}
            selectedMedia={selectedMedia}
            fileType={"videos"}
          />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
      </Col>
    </Row>
  );
}

export default VideoCommon;
