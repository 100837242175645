import React, { useRef, useState, useParams, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { ArrowIcon, TrashIcon } from "../../components/SvgIcons";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import { GetEquipmentCategories } from "../../services/equipment-categories.service";
import {
  GetEquipmentById,
  UpdateEquipment,
  GetEquipmentOrder,
} from "../../services/equipments.service";
import { FadeLoader } from "react-spinners";
import { constants, cross_origin, GENDERS } from "../../utils/constants";
import { UploadImageToS3, upload, uploadToS3 } from "../../utils/helpers";
import Select from "react-select";
import FullPageLoader from "../../components/FullPageLoader";

function EditEquipment(props) {
  let history = useHistory();
  const equipment_id = props.match.params.id;
  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [row, setRow] = useState(null);
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);
  const [refererDomain, setRefererDomain] = useState("");
  const [referalLinkImage, setReferalLinkImage] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [image, setImage] = useState("");
  const [correctUrl, setcorrectUrl] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [latestOrder, setLatestOrder] = useState(null);

  const [isUploadEquipmentImage, setUploadEquipmentImage] = useState(false);
  const [isUploadImage, setUploadImage] = useState(false);

  const [equipmentImageFile, setEquipmentImageFile] = useState(null);
  const [equipmentImage, setEquipmentImage] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [rows, setRows] = useState([
    { id: 1, inputValue: "", logo: "", imageFile: "", selectValue: "" },
  ]);

  const [brands, setBrands] = useState([
    { name: "Amazon", id: 1 },
    { name: "Ebay", id: 2 },
    { name: "Walmart", id: 3 },
  ]);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const selectedMedia = (fileName, file) => {
    // clearErrors(fileName);
    // setFormData({ ...formData, ...{ [fileName]: file } });
    // setModalShow(false);

    if (fileName.includes("image")) {
        const index = parseInt(fileName.split("-")[1], 10);
        const newEduFormValues = [...rows];
        newEduFormValues[index].logo = file;
        newEduFormValues[index].imageFile = file;
        setRows(newEduFormValues);
      } else {
        setEquipmentImage(file);
        setEquipmentImageFile(file);
        setFormData({ ...formData, ...{ [fileName]: file } });
      }
    clearErrors(fileName);
   
    setModalShow(false);
  };

  const handleEquipmentImage = async (event) => {
    setEquipmentImageFile(URL.createObjectURL(event.target.files[0]));
    setEquipmentImage(event.target.files[0]);
    setUploadEquipmentImage(true);
  };

  const deleteEquipmentImage = (type) => {
    setEquipmentImageFile(null);
    setEquipmentImage(null);
  };

  useEffect(() => {
    setLoader(true);
    GetEquipmentById(equipment_id)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            if (
              result?.data?.referral_link2 != null &&
              result?.data?.referral_link2 !== ""
            ) {
              try {
                const parsedData = JSON.parse(result?.data?.referral_link2);
                if (Array.isArray(parsedData)) {
                  setRows(parsedData);
                }
              } catch (error) {
                console.error("Error parsing referral_link2 JSON:", error);
              }
            }
            setFormData(result.data);
            setValue("title", result?.data?.title);
            setValue("weight_kg", result?.data?.weight_kg);
            setValue("weight_lb", result?.data?.weight_lb);
            setValue("description", result?.data?.description);
            setValue("note", result?.data?.note);
            setValue("status", result?.data?.status);
            setSelectedCategories({
              value: result?.data?.equipmentCategory?.id,
              label: result?.data?.equipmentCategory?.name,
            });
            setImage(result.data.referral_link_image);
            if (result.data.referral_link_image) {
              setIsUpload(true);
            }
            setWeightKg(result.data.weight_kg);
            setWeightLbs(result.data.weight_lb);
            setEquipmentImage(result.data.image);
            setEquipmentImageFile(result.data.image);
          }
        } else {
          toast.error(result.message);
        }
        setLoader(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
        setLoader(false);
      });
  }, [equipment_id]);

  const addRow = () => {
    // if (rows.length < 3) {
    const newRow = {
      id: rows.length + 1,
      inputValue: "",
      logo: "",
      imageFile: "",
      selectValue: "",
    };
    setRows([...rows, newRow]);
    // } else {
    //   toast.error("You may include up to 3 referral links");
    // }
  };

  const handleInputChange = (id, value) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, inputValue: value } : row
    );
    setRows(updatedRows);
  };

  const handleSelectChange = (id, event) => {
    console.log("id->", id);
    console.log("event->", event.value);

    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, selectValue: event.value } : row
    );
    setRows(updatedRows);
  };

  const deleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };


  const deleteRowImage = (selectedId) => {
    // setUploadImage(true);
    const updatedRows = rows.map((row) =>
      row.id === selectedId ? { ...row, logo: "", imageFile: "" } : row
    );
    setRows(updatedRows);
  };

  console.log("row=====>",rows)
  const onSubmit = async (data) => {
    setLoader(true);

    if (isUploadEquipmentImage) {
     
      data.image = equipmentImage;
    }

    let tempImagePath = [];

    for (let i = 0; i < rows.length; i++) {
      let logoImg = "";
      let tempObj = {};
      // console.log("rows[i]?.logo", rows[i]?.logo);

      // tempObj.id = rows[i].id;
      // tempObj.logo = rows[i].logo;
      // tempObj.inputValue = rows[i].inputValue;
      // tempObj.selectValue = rows[i].selectValue;

      // console.log("tempObj-->", tempObj);

      // tempImagePath.push(tempObj);

      if (rows[i].logo) {
        console.log("in 0");

        if (rows[i]?.logo.includes("amazonaws.com")) {
            console.log("in 1")

          tempObj.id = rows[i].id;
          tempObj.logo = rows[i].logo;
          tempObj.inputValue = rows[i].inputValue;
          tempObj.selectValue = rows[i].selectValue;

          tempImagePath.push(tempObj);
        } else {
          if (rows[i].imageFile || isUploadImage) {
            console.log("in 2")
            logoImg = rows[i].logo;
          }
          tempObj.id = rows[i].id;
          tempObj.logo = logoImg;
          tempObj.inputValue = rows[i].inputValue;
          tempObj.selectValue = rows[i].selectValue;
          tempImagePath.push(tempObj);
        }
      } else {
        if (rows[i].imageFile) {
            console.log("in 3")

          logoImg = rows[i].imageFile;
        }
        tempObj.id = rows[i].id;
        tempObj.logo = logoImg;
        tempObj.inputValue = rows[i].inputValue;
        tempObj.selectValue = rows[i].selectValue;
        tempImagePath.push(tempObj);
      }
    }
    data.status = data.status;
    data.weight_kg = weightKg;
    data.weight_lb = weightLbs;
    data.equipment_category_id = selectedCategories.value;
    data.referral_link2 = JSON.stringify(tempImagePath);
    if (row?.equipment_category_id == 0) {
      data.order_no = latestOrder;
    } else {
      data.order_no = row?.order_no;
    }

    await UpdateEquipment(equipment_id, data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/equipments");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const getCategories = async () => {
    // setLoader(true);
    await GetEquipmentCategories(keyword, page, perPage, 0, "", "", "")
      .then((result) => {
        if (result.status) {
          if (result.data) {
            setCategories(result.data);
          }
        } else {
          //   setLoader(false);
          toast.error(result.message);
        }
      })
      .catch((error) => {
        // setLoader(false);
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const getLatestOrder = async (id) => {
    await GetEquipmentOrder(id)
      .then((result) => {
        if (result.data) {
          setLatestOrder(result?.data);
        }
      })
      .catch((error) => {
        // setLoader(false);
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const selectedCategoryOpt = (selectedValue) => {
    setSelectedCategories(selectedValue);
    if (row.equipment_category_id == 0) {
      getLatestOrder(selectedValue.value);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getBrand = (url) => {
    const pattern =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const correctUrl = pattern.test(url);
    if (correctUrl) {
      setcorrectUrl(true);
    }

    let domain = new URL(url);
    let host = domain.hostname.replace("www.", "");
    if (host.includes("amazon")) {
      setRefererDomain("amazon");
    } else if (host.includes("ebay")) {
      setRefererDomain("ebay");
    }
  };

  const handleWeightKg = (event) => {
    const kg = event.target.value;
    const lbs = kg * 2.20462262185;
    setWeightKg(kg);
    setWeightLbs(lbs.toFixed(2));
  };

  const handleWeightLbs = (event) => {
    const lbs = event.target.value;
    const kg = lbs / 2.20462262185;

    setWeightLbs(lbs);
    setWeightKg(kg.toFixed(2));
  };

  const handleChangeVideo = (e, index) => {
    const newEduFormValues = [...rows];
    newEduFormValues[index].logo = URL.createObjectURL(e.target.files[0]);
    newEduFormValues[index].imageFile = e.target.files[0];
    setRows(newEduFormValues);
  };



  const uploadFile = async (fileInput) => {
    let fileUrl = URL.createObjectURL(fileInput[0]);
    setReferalLinkImage(fileInput[0]);
    // setImage(fileUrl)
    setIsUpload(true);
    // let uploadImage = await UploadImageToS3(fileInput[0]);
    // if (uploadImage) {
    //     if (!uploadImage.success) {
    //         toast.error(uploadImage.message);
    //         return false;
    //     }
    //     setReferalLinkImage(uploadImage.data)
    // } else {
    //     toast.error('Can\'t Upload Image');
    //     return false;
    // }
  };

  const BackEquipment = () => {
    history.push("/equipments");
  };

  return (
    <>
      {loader && <FullPageLoader loading={loader} />}

      <div className="equipment-index">
        <div className="page-title">
          <h1>Edit Equipment</h1>
          <Button onClick={BackEquipment} className="btn common-btn">
            Back
          </Button>
        </div>
        <div className="common-form">
          <Form
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group
              controlId="formFile"
            //   onChange={(e) => handleEquipmentImage(e)}
              onClick={() => {
                setUploadEquipmentImage(true);
                setFileName("equipmentImage");
                setModalShow(true);
              }}
              className="custom-file-upload mb-3"
            >
              <Form.Label className={"btn common-btn"}>
                Upload Equipment Image
              </Form.Label>
              {/* <Form.Control
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/gif"
                {...register("image")}
              /> */}
            </Form.Group>
            {equipmentImageFile && (
              <div className="image-item mb-3">
                <img
                  crossOrigin={cross_origin ? "anonymous" : null}
                  src={equipmentImageFile}
                  alt="Image Cover"
                  className={"img-fluid"}
                />
                <div className="image-item__btn-wrapper">
                  <button
                    type="button"
                    className="btn common-btn"
                    onClick={() => deleteEquipmentImage("image")}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            )}

            <Row>
              <Col sm={12}>
                <Form.Group className="mb-4" controlId="categories">
                  <Form.Label className="d-block">
                    Equipment Categories
                  </Form.Label>
                  <Select
                    options={
                      categories &&
                      categories.map((e) => ({ label: e.name, value: e.id }))
                    }
                    value={selectedCategories}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedCategoryOpt}
                  />
                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group className="mb-4" controlId="euipment_title">
                  <Form.Label>Equipment Name*</Form.Label>
                  <Form.Control
                    placeholder="Enter Equipment Name"
                    id={"formSignUpvideo"}
                    {...register("title", {
                      required: {
                        value: "required",
                        message: "Equipment Name is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                    })}
                    type="text"
                  />
                  {errors.title && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.title.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="weightKgF">
                  <Form.Label className="d-block">Weight (kg)</Form.Label>
                  <Form.Control
                    placeholder="Weight (kg)"
                    value={weightKg}
                    {...register("weight_kg", {
                      onChange: (e) => handleWeightKg(e),
                      required: {
                        value: "required",
                        message: "Weight is required",
                      },
                      minLength: {
                        value: 1,
                        message: "Min length is 1",
                      },
                      maxLength: {
                        value: 25,
                        message: "Max length is 25",
                      },
                    })}
                    type="number"
                  />
                  {errors.weight_kg && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.weight_kg.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="weightLbsF">
                  <Form.Label className="d-block">Weight (lbs)</Form.Label>
                  <Form.Control
                    placeholder="Weight (lbs)"
                    value={weightLbs}
                    {...register("weight_lb", {
                      onChange: (e) => handleWeightLbs(e),
                      required: {
                        value: "required",
                        message: "Weight is required",
                      },
                      minLength: {
                        value: 1,
                        message: "Min length is 1",
                      },
                      maxLength: {
                        value: 25,
                        message: "Max length is 25",
                      },
                    })}
                    type="number"
                  />
                  {errors.weight_lb && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.weight_lb.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className={"mb-4"}>
              <Col sm={1}>
                <Form.Group className="mt-2 float-right" controlId="categories">
                  <Button
                    type={"button"}
                    className={"btn common-btn px-0 w-100 mt-3"}
                    onClick={addRow}
                  >
                    Add
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            {rows.map((row, index) => (
              <Row key={row.id}>
                <Col xl={5} xxl={3}>
                  <Form.Group
                    className="mb-3"
                    controlId={`referralLink_${row.id}`}
                  >
                    <Form.Label className="d-block">Referral Link</Form.Label>
                    <Form.Control
                      placeholder="Enter Referral Link"
                      {...register(`referral_link_${row.id}`)}
                      defaultValue={row.inputValue}
                      type="text"
                      onChange={(e) =>
                        handleInputChange(row.id, e.target.value)
                      }
                    />
                    {errors[`referral_link_${row.id}`] && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors[`referral_link_${row.id}`].message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col xl={5} xxl={2}>
                  <Form.Group className="mb-3" controlId={`brands_${row.id}`}>
                    <Form.Label className="d-block">
                      Brand Categories
                    </Form.Label>
                    <Form.Select
                      placeholder="Select Brand"
                      id={"brand"}
                      className="formselect program-dropdown"
                      aria-label="brand"
                      onChange={(e) => handleSelectChange(row.id, e.target)}
                    >
                      <option
                        value={""}
                        selected={row.selectValue != "" ? false : true}
                      >
                        Select Brand
                      </option>
                      <option
                        selected={row.selectValue == "1" ? true : false}
                        value="1"
                      >
                        Amazon
                      </option>
                      <option
                        selected={row.selectValue == "2" ? true : false}
                        value="2"
                      >
                        Walmart
                      </option>
                      <option
                        selected={row.selectValue == "3" ? true : false}
                        value="3"
                      >
                        Target
                      </option>
                      <option
                        selected={row.selectValue == "4" ? true : false}
                        value="4"
                      >
                        Rogue
                      </option>
                      <option
                        selected={row.selectValue == "5" ? true : false}
                        value="5"
                      >
                        eBay
                      </option>
                      <option
                        selected={row.selectValue == "6" ? true : false}
                        value="6"
                      >
                        IKEA
                      </option>
                      <option
                        selected={row.selectValue == "7" ? true : false}
                        value="7"
                      >
                        AliExpress
                      </option>
                      <option
                        selected={row.selectValue == "8" ? true : false}
                        value="8"
                      >
                        BestBuy
                      </option>
                      <option
                        selected={row.selectValue == "9" ? true : false}
                        value="9"
                      >
                        Joom
                      </option>
                      <option
                        selected={row.selectValue == "10" ? true : false}
                        value="10"
                      >
                        Rakuten
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col xl={1} xxl={1} style={{ marginRight: "20px" }}>
                  <div className="mt-4">
                    <Form.Group
                      controlId={`formFile_${index}`}
                    //   onChange={(e) => handleChangeVideo(e, index)}
                      onClick={() => {
                        setUploadImage(true);
                        setFileName(`image-${index}`);
                        setModalShow(true);
                      }}
                      className="custom-file-upload mb-3"
                    >
                      <Form.Label className={"btn common-btn"}>
                        Upload Image
                      </Form.Label>
                      {/* <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        // {...register(`image${index}`)}
                      /> */}
                    </Form.Group>
                  </div>
                </Col>

                {row?.logo ? (
                  <>
                    <div className="image-item-logo">
                      <img
                        crossOrigin={cross_origin ? "anonymous" : null}
                        className={"image-thumbnail-logo"}
                        src={row?.logo}
                        alt={"Image"}
                      />
                    </div>
                    <div className="delete-logo-img">
                      <button
                        type="button"
                        className="common-delete-btn"
                        onClick={() => deleteRowImage(row?.id)}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <Col xl={2} xxl={1}>
                  <Form.Group
                    className="mt-4"
                    controlId={`categories_${row.id}`}
                  >
                    <button
                      className={"btn common-btn px-0 w-100"}
                      onClick={() => deleteRow(row.id)}
                    >
                      Delete
                    </button>
                  </Form.Group>
                </Col>
              </Row>
            ))}

            <Row>
              {/* <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="referalLink">
                  <Form.Label className="d-block">
                    Referral Link (Amazon)
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Referal Link"
                    {...register("referral_link", {
                      onChange: (e) => {
                        getBrand(e.target.value);
                      },
                    })}
                    type="text"
                  />
                  {errors.referral_link && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.referral_link.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="referalLink">
                  <Form.Label className="d-block">
                    Referral Link (Ebay)
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Referral Link"
                    {...register("referral_link2", {
                      onChange: (e) => {
                      },
                    })}
                    type="text"
                  />
                </Form.Group>
              </Col> */}

              {/* <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Referral Link Image</Form.Label>
                  <input
                    {...register("referral_link_image")}
                    onChange={(e) => uploadFile(e.target.files)}
                    type="file"
                    id="referral_link_image"
                  />
                  {errors.referral_link_image && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.referral_link_image.message}
                    </Form.Text>
                  )}
                  {isUpload ? (
                    <img
                      src={image}
                      className={"img-table img-thumbnail"}
                      crossOrigin={cross_origin ? "anonymous" : null}
                    />
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col> */}

              <Col className="mt-3" sm={12}>
                <Form.Group controlId="equipmentdescription">
                  <Form.Label>Equipment Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    {...register("description")}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-3" sm={12}>
                <Form.Group controlId="comments">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control as="textarea" rows={3} {...register("note")} />
                </Form.Group>
              </Col>
              <Form.Group className="mt-3" controlId="status">
                <Form.Label className="d-block">Select Status</Form.Label>
                <Form.Select
                  className="formselect"
                  aria-label="status"
                  {...register("status", {
                    required: {
                      value: "required",
                      message: "Status is required",
                    },
                  })}
                >
                  <option value="10">Active</option>
                  <option value="20">Inactive</option>
                </Form.Select>
                {errors.status && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.weight.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Row>

            <Row>
              <Col className="mt-3 ms-auto" xl={3} xxl={2}>
                {loader ? (
                  <FullPageLoader loading={loader} />
                ) : (
                  <Button
                    type={"submit"}
                    className={"btn common-btn px-0 w-100 mt-3"}
                    value={"Update Equipment"}
                  >
                    Update Equipment
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>

        <ThemeModal
          title={"S3 Browser"}
          content={
            <S3Browser fileName={fileName} selectedMedia={selectedMedia} />
          }
          size={"xl"}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      </div>
    </>
  );
}

export default EditEquipment;
