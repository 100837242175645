import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { confirmMessage, genders } from "../../utils/constants";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { TrashIcon } from "../../components/SvgIcons";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { GetBodyTypes } from "../../services/body_types.service";
import { GetCategories } from "../../services/categories.service";
import { GetInjuries } from "../../services/injurys.service";
import { GetProgramById, UpdateProgram } from "../../services/programs.service";
import { GetTests } from "../../services/tests.service";
import { upload } from "../../utils/helpers";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import FullPageLoader from "../../components/FullPageLoader";
import ThemeModal from "../../components/ThemeModal";
import S3BrowserNew from "../../components/S3Browser";

function EditProgramForm(props) {
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [data, setData] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [injuryPrograms, setInjuryPrograms] = useState([]);

  //Body Types Video
  const [isBodyTypeVideo, setIsBodyTypeVideo] = useState(false);
  const [bodyTypeVideo, setBodyTypeVideo] = useState(null);
  const [bodyTypeFileVideo, setbodyTypeFileVideo] = useState(null);

  const [isBodyAboutTypeVideo, setIsBodyAboutTypeVideo] = useState(false);
  const [bodyTypeVideos, setBodyTypeVideos] = useState([]);
  const [responseBodyTypeVideos, setResponseBodyTypeVideos] = useState([]);
  const [bodyTypeFileVideos, setbodyTypeFileVideos] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(-1);

  //Welcome Video
  const [isWelcomeVideo, setIsWelcomeVideo] = useState(false);
  const [welcomeVideo, setWelcomeVideo] = useState(null);
  const [welcomeFileVideo, setWelcomeFileVideo] = useState(null);

  const [editSelectedTests, setEditSelectedTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [tests, setTests] = useState([]);
  const [removeBodyTypes, setRemoveBodytypes] = useState([]);

  const [buttonType, setButtonType] = useState("");
  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});
  const [modalShow, setModalShow] = useState(false);

  const videoRefs = useRef([]);
  const handleVideoClick = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.play();
    }
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onBlur",
  });

  const BackEditProgram = () => {
    history.push("/program-library");
  };

  const [injuryProgram, setInjuryProgram] = useState("");
  const [genderProgram, setGenderProgram] = useState("");
  const [completeStatus, setCompleteStatus] = useState();
  const [daysProgram, setDaysProgram] = useState("");

  const [subCategory, setSubCategory] = useState("");
  const [bodyType, setBodyType] = useState("");

  //Upload Welcome BodyType Fun
  const handleWelcomeVideo = (event) => {
    setWelcomeFileVideo(URL.createObjectURL(event.target.files[0]));
    setWelcomeVideo(event.target.files[0]);
    setIsWelcomeVideo(true);
    URL.revokeObjectURL(event.target.files[0]);
  };

  //Upload AboutBodyType Fun
  const handleBodyAboutTypeVideo = (event) => {
    setbodyTypeFileVideo(URL.createObjectURL(event.target.files[0]));
    setBodyTypeVideo(event.target.files[0]);
    setIsBodyAboutTypeVideo(true);
    URL.revokeObjectURL(event.target.files[0]);
  };

  const selectedMedia = (fileName, file) => {
    console.log("fffffffff========>", fileName);
    setFormData({ ...formData, ...{ [fileName]: file } });

    if (Array.isArray(file) && fileName === "videoType") {
      console.log("in if00");
      // Restrict to remaining slots
      const newFiles = file.slice(
        0,
        Math.max(0, 12 - responseBodyTypeVideos.length)
      );

      setResponseBodyTypeVideos((prev) => [...prev, ...newFiles]);
      setBodyTypeVideos((prev) => [...prev, ...newFiles]);
    } else if (fileName === "videoType") {
      console.log("in if00ssd");

      if (responseBodyTypeVideos.length < 12) {
        setResponseBodyTypeVideos((prev) => [...prev, file]);
        setBodyTypeVideos((prev) => [...prev, file]);
      }
    } else if (fileName == "about-video") {
      setbodyTypeFileVideo(file);
      setBodyTypeVideo(file);
    } else if (fileName == "video") {
      setWelcomeFileVideo(file);
      setWelcomeVideo(file);
    }
    setModalShow(false);
  };

  console.log(
    "await Promise.all====>",
    responseBodyTypeVideos,
    isBodyTypeVideo,
    isWelcomeVideo,
    isBodyAboutTypeVideo
  );

  console.log("await Promise.all====>", responseBodyTypeVideos);

  const handleBodyTypeVideo = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const videoURLs = selectedFiles.map((file) => URL.createObjectURL(file));

    // Initialize bodyTypeFileVideos and bodyTypeVideos as empty arrays if they are null
    const updatedBodyTypeFileVideos = bodyTypeFileVideos
      ? [...bodyTypeFileVideos]
      : [];
    const updatedBodyTypeVideos = bodyTypeVideos ? [...bodyTypeVideos] : [];

    // Calculate the index for inserting the new video
    const newIndex = updatedBodyTypeFileVideos.length;

    // Inserting the new video URL at the next index
    updatedBodyTypeFileVideos.splice(newIndex, 0, ...videoURLs);
    // Inserting the new video file at the next index
    updatedBodyTypeVideos.splice(newIndex, 0, ...selectedFiles);

    setbodyTypeFileVideos(updatedBodyTypeFileVideos);
    setBodyTypeVideos(updatedBodyTypeVideos);
    setCurrentPlayingIndex(-1);
    setIsBodyTypeVideo(true);
  };

  //Welcome Delete Function
  const deleteWelcomeVideo = () => {
    setWelcomeVideo(null);
    setWelcomeFileVideo(null);
    URL.revokeObjectURL(null);
  };

  const deleteBodyTypeVideo = (id, index) => {
    console.log("test", id, index);
    // Check if bodyTypeVideos is not null before filtering
    if (bodyTypeVideos !== null) {
      // Remove the video from bodyTypeVideos
      setBodyTypeVideos((prevVideos) =>
        prevVideos.filter((video, idx) => idx !== index)
      );
    }

    // Check if bodyTypeFileVideos is not null before filtering
    if (bodyTypeFileVideos !== null) {
      // Remove the video from bodyTypeFileVideos
      setbodyTypeFileVideos((prevFiles) =>
        prevFiles.filter((file, idx) => idx !== index)
      );
    }

    // Revoke the object URL associated with the video
    const videoURL = bodyTypeVideos.find((video) => video.id === id)?.url;
    if (videoURL) {
      URL.revokeObjectURL(videoURL);
    }

    // Update the list of removed video ids
    setRemoveBodytypes((prevIds) => [...prevIds, id]);

    // Optionally, update any other state that depends on responseBodyTypeVideos
    const filteredVideos = responseBodyTypeVideos.filter(
      (obj) => obj.id !== id
    );
    setResponseBodyTypeVideos(filteredVideos);
  };

  //AboutBodyType Delete Function
  const deleteAboutBodyTypeVideo = () => {
    setBodyTypeVideo(null);
    setbodyTypeFileVideo(null);
    URL.revokeObjectURL(null);
  };

  const [isPageDirty, setPageDirty] = useState(false);

  const handleInputChange = (event) => {
    setPageDirty(true);
  };

  useEffect(() => {
    let id = props.match.params.id ? props.match.params.id : null;

    const getSubCategories = async () => {
      await GetCategories("", "", "", 0, "", "", 0, "asc", "name").then(
        (result) => {
          if (result.status && result.data) {
            setSubCategories(result.data);
          }
        }
      );
    };

    const getBodyTypes = async () => {
      await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setBodyTypes(result.data);
        }
      });
    };

    const getInjuryPrograms = async () => {
      await GetInjuries("", "", "", 0, 10, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setInjuryPrograms(result.data);
        }
      });
    };

    const getProgramById = async (id) => {
      try {
        setLoader(true);

        const result = await GetProgramById(id);

        if (result.status && result.data) {
          setData(result.data);
          setName(result.data?.name);
          setValue("body_type_id", result.data?.body_type_id);
          setValue("category_id", result.data?.category_id);
          setValue("days_per_week", result.data?.days_per_week);
          setValue("gender", result.data?.gender);
          setValue("is_draft", result.data?.is_draft);
          setValue("injury_id", result.data?.injury_id);

          // Welcome Video Setlink Code
          setWelcomeVideo(result.data?.welcome_video);
          setWelcomeFileVideo(result.data?.welcome_video);

          // BodyTypeVideo Setlink Code
          setBodyTypeVideo(result.data?.body_type_video);
          setbodyTypeFileVideo(result.data?.body_type_video);

          // AboutVideo Setlink Code
          const modifiedBodyTypeVideos = (
            result.data?.bodyTypeVideos || []
          ).map((item) => ({ id: item.id, video: item.video }));
          setResponseBodyTypeVideos(modifiedBodyTypeVideos);

          const selectedTests = result.data.programTests
            ? result.data.programTests.map((item) => ({
                value: item.id,
                label: item.title,
              }))
            : [];
          setEditSelectedTests(selectedTests);
        }
      } catch (error) {
        console.error("Error fetching program by ID:", error);
      } finally {
        setLoader(false);
      }
    };

    const getTests = async () => {
      await GetTests("", "", "", "", 0, "asc", "title").then((result) => {
        if (result.status && result.data) {
          setTests(result.data);
        }
      });
    };

    getBodyTypes();
    getSubCategories();
    getInjuryPrograms();
    getTests();
    getProgramById(id);
  }, []);

  useEffect(() => {
    if (data.body_type_id != undefined) {
      let editBodyType = bodyTypes.filter(
        (item, index) => item.id == data.body_type_id
      );
      setBodyType({
        value: editBodyType[0] ? editBodyType[0].id : null,
        label: editBodyType[0] ? editBodyType[0].name : "",
      });
    }

    if (data.injury_id != undefined) {
      let editInjuryProgram = injuryPrograms.filter(
        (item, index) => item.id == data.injury_id
      );
      setInjuryProgram({
        value: editInjuryProgram[0] ? editInjuryProgram[0].id : null,
        label: editInjuryProgram[0] ? editInjuryProgram[0].name : "",
      });
    }

    if (data.gender != undefined) {
      setGenderProgram({
        value: data.gender,
        label: data.gender === 10 ? "Male" : "Female",
      });
    }

    // if (data.is_draft !== "" && data.is_draft !== null) {
    setCompleteStatus({
      value: data.is_draft,
      label: data.is_draft === 1 ? "Complete" : "Incomplete",
    });
    // }

    if (data.days_per_week != undefined) {
      setDaysProgram({
        value: data.days_per_week,
        label: data.days_per_week,
      });
    }
    if (data.category_id != null) {
      let editSubCategory = subCategories.filter(
        (item, index) => item.id == data.category_id
      );
      setSubCategory({
        value: editSubCategory[0] ? editSubCategory[0].id : null,
        label: editSubCategory[0] ? editSubCategory[0].name : "",
      });
    }
  }, [data, bodyTypes, injuryPrograms, subCategories]);

  const navigationPrompt = async (location) => {
    let id = props.match.params.id ? props.match.params.id : null;
    if (isPageDirty) {
      const userConfirmed = window.confirm(confirmMessage.message);
      if (userConfirmed) {
        console.log('User clicked "Yes" to leave with unsaved changes.');
        setDisableButton(true);
        setLoader(true);
        await UpdateProgram(id, {
          name: name,
          body_type_id: getValues("body_type_id"),
          category_id: getValues("category_id"),
          days_per_week: getValues("days_per_week"),
          gender: getValues("gender"),
          injury_id: getValues("injury_id"),
          welcome_video: data.welcome_video ? data.welcome_video : "",
          body_type_video: data.bodyTypeVideo ? data.bodyTypeVideo : "",
          body_type_videos: data.bodyTypeVideos ? data.bodyTypeVideos : "",
          program_tests: selectedTests,
        })
          .then(async (data) => {
            if (data.status) {
              return userConfirmed;
            } else {
              setDisableButton(false);
              setLoader(false);
            }
          })
          .catch((error) => {
            setDisableButton(false);
            setLoader(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              setDisableButton(false);
              setLoader(false);
              return toast.error(
                error.response.data.message.replace(/_/g, " ")
              );
            }
          });
      }
    }
    return true;
  };

  const onSubmit = async (data) => {
    console.log("genderProgram", genderProgram, data);

    setPageDirty(false);
    let id = props.match.params.id ? props.match.params.id : null;
    setDisableButton(true);
    setLoader(true);
    //Welcome Video Submit
    if (isWelcomeVideo) {
      data.welcome_video = welcomeVideo;
    }

    //About Video Submit
    if (isBodyAboutTypeVideo) {
      data.bodyTypeVideo = bodyTypeVideo;
    }
    //BodyType Video Submit
    if (isBodyTypeVideo) {
      try {
        const uploadPromises = bodyTypeVideos.map(async (videoBody) => {
          return { id: null, video: videoBody };
        });
        const locations = await Promise.all(uploadPromises);
        if (responseBodyTypeVideos.length > 0) {
          responseBodyTypeVideos.map((item, key) =>
            locations.push({ id: item.id, video: item.video })
          );
        }

        data.bodyTypeVideos = locations;
      } catch (error) {
        console.error("Error uploading videos:", error);
      }
    } else {
      data.bodyTypeVideos = bodyTypeVideos;
    }

    // let params = {
    //     name: name,
    //     body_type_id: data.body_type_id,
    //     category_id: data.category_id,
    //     days_per_week: daysProgram?.value,
    //     gender: genderProgram?.value,
    //     is_draft: completeStatus?.value,
    //     injury_id: data.injury_id,
    //     welcome_video: data.welcome_video ? data.welcome_video : "",
    //     body_type_video: data.bodyTypeVideo ? data.bodyTypeVideo : null,
    //     body_type_videos: data.bodyTypeVideos ? data.bodyTypeVideos : "",
    //     program_tests: selectedTests,
    //     remove_body_types: removeBodyTypes,
    // };

    let params = {
      name: name,
      body_type_id: data.body_type_id,
      category_id: data.category_id,
      days_per_week: daysProgram?.value,
      gender: genderProgram?.value,
      is_draft: completeStatus?.value,
      injury_id: data.injury_id,
      body_type_videos: data.bodyTypeVideos ? data.bodyTypeVideos : "",
      program_tests: selectedTests,
      remove_body_types: removeBodyTypes,
    };

    // Conditionally add keys only if they exist in the `data` object
    if ("welcome_video" in data) {
      params.welcome_video = data.welcome_video || "";
    }
    if ("bodyTypeVideo" in data) {
      params.body_type_video = data.bodyTypeVideo || null;
    }
    console.log("params=====>", params);

    // return false;

    await UpdateProgram(id, params)
      .then(async (data) => {
        if (data.status) {
          if (buttonType == "save") {
            toast.success(data.message);
            history.push("/program-library");
          } else {
            history.push({
              pathname: "/manage-program/",
              state: {
                program: data.data,
              },
            });
          }
        } else {
          setDisableButton(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          setDisableButton(false);
          setLoader(false);
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const setWelcomeVideoFunc = (data) => {
    setWelcomeVideo(data);
  };

  const setBodyTypeVideoFunc = (data) => {
    setBodyTypeVideo(data);
  };

  const genderOptions = [
    { value: "", label: "Select Gender", isDisabled: true },
    { value: genders.FEMALE, label: "Female" },
    { value: genders.MALE, label: "Male" },
  ];

  const statusOptions = [
    { value: "", label: "Select Status", isDisabled: true },
    { value: 1, label: "Complete" },
    { value: 0, label: "Incomplete" },
  ];

  const daysPerWeekOptions = [
    { value: "", label: "Select Days Per Week", isDisabled: true },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
  ];

  const selectedTestsOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedTests(data);
    setEditSelectedTests(selectedValue);
    setPageDirty(true);
  };

  const setBodyTypeFunc = (selectedValue) => {
    setValue("body_type_id", selectedValue ? selectedValue.value : null);
    setBodyType(selectedValue ? selectedValue : null);
    setPageDirty(true);
  };

  const setDaysTypeFunc = (selectedValue) => {
    setDaysProgram(selectedValue ? selectedValue : null);
    setPageDirty(true);
  };

  const setGenderTypeFunc = (selectedValue) => {
    setGenderProgram(selectedValue ? selectedValue : "");
    setPageDirty(true);
  };
  const setCompleteStatusTypeFunc = (selectedValue) => {
    setCompleteStatus(selectedValue);
    //setValue("is_draft", selectedValue);
    setPageDirty(true);
  };

  const setInjuryProgramFunc = (selectedValue) => {
    setValue("injury_id", selectedValue ? selectedValue.value : null);
    setInjuryProgram(selectedValue ? selectedValue : null);
    setPageDirty(true);
  };

  const setSubCategoryFunc = (selectedValue) => {
    setValue("category_id", selectedValue ? selectedValue.value : null);
    setSubCategory(selectedValue ? selectedValue : null);
    setPageDirty(true);
  };

  const resetWelcomeVideoFunc = (data) => {
    setWelcomeVideo(null);
  };

  const resetBodyTypeVideoFunc = (data) => {
    setBodyTypeVideo(null);
  };
  const [name, setName] = useState("");
  const handleNameChange = (event) => {
    setName(event.target.value);
    setPageDirty(true);
  };

  return (
    <>
      <FullPageLoader loading={loader} />
      <div className="EditProgramForm program-index">
        <Prompt when={isPageDirty} message={navigationPrompt} />
        <div className="page-title">
          <h1>Edit Program - {data?.name}</h1>
          <Button onClick={BackEditProgram} className="common-btn">
            Back
          </Button>
        </div>

        <div className="common-form">
          <Form
            className="form-program"
            id="formEditProgram"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label htmlFor={"name"}>Program Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Program Name"
                    value={name}
                    onChange={handleNameChange}
                  />
                  {errors.name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Body Type</Form.Label>
                  <Select
                    id={"body_type_id"}
                    isClearable
                    value={bodyType}
                    options={
                      bodyTypes &&
                      bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Body Type"
                    onChange={setBodyTypeFunc}
                  />
                  {errors.body_type_id && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.body_type_id.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-4">
                  <Form.Label>Sub Category</Form.Label>
                  <Select
                    isClearable
                    id={"category_id"}
                    value={subCategory}
                    options={
                      subCategories &&
                      subCategories.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Sub Category"
                    onChange={setSubCategoryFunc}
                  />
                  {errors.category_id && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.category_id.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Gender*</Form.Label>
                  <Select
                    isClearable
                    id="gender"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Gender"
                    {...register("gender", {
                      // required: {
                      //   value: "required",
                      //   message: "gender is required"
                      // }
                    })}
                    value={genderOptions.find(
                      (option) => option.value === genderProgram?.value
                    )}
                    options={genderOptions}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setGenderTypeFunc(selectedOption);
                      } else {
                        setGenderTypeFunc("");
                      }
                    }}
                  />
                  {errors.gender && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.gender.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Injury Program</Form.Label>
                  <Select
                    isClearable
                    id={"injury_id"}
                    value={injuryProgram}
                    options={
                      injuryPrograms &&
                      injuryPrograms.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={setInjuryProgramFunc}
                  />
                  {errors.injury_id && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.injury_id.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Days Per Week</Form.Label>
                  <Select
                    isClearable
                    id="days_per_week"
                    {...register("days_per_week", {})}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Days Per Week"
                    value={daysProgram}
                    onChange={(selectedOption) =>
                      setDaysTypeFunc(selectedOption)
                    }
                    options={daysPerWeekOptions}
                  />

                  {errors.days_per_week && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.days_per_week.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="bodyType">
                  <Form.Label className="d-block">Tests</Form.Label>
                  <Select
                    isClearable
                    value={editSelectedTests}
                    isMulti
                    options={
                      tests &&
                      tests.map((e) => ({
                        label: `${e.title} (${
                          e.gender == 20 ? "Female" : "Male"
                        })`,
                        value: e.id,
                      }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedTestsOpt}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  className="custom-file-upload"
                  controlId="videoFile"
                  //   onChange={(e) => handleWelcomeVideo(e)}
                  onClick={() => {
                    setIsWelcomeVideo(true);
                    setFileName("video");
                    setModalShow(true);
                  }}
                >
                  <Form.Label className={"common-btn"}>
                    Welcome Video
                  </Form.Label>
                  {/* <Form.Control type="file" accept="video/*" /> */}
                </Form.Group>
                {welcomeFileVideo ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={welcomeFileVideo}
                        title="Video"
                      ></video>
                      <div className="image-item__btn-wrapper">
                        <Button
                          type="Button"
                          className="common-btn"
                          onClick={() => deleteWelcomeVideo()}
                        >
                          <TrashIcon />
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Col>
              <Col md={4}>
                <Form.Group
                  controlId="aboutVideoFile"
                  // onChange={(e) => handleBodyAboutTypeVideo(e)}
                  onClick={() => {
                    setIsBodyAboutTypeVideo(true);
                    setFileName("about-video");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"common-btn"}>About Video</Form.Label>
                  {/* <Form.Control type="file" accept="video/*" /> */}
                </Form.Group>

                {bodyTypeFileVideo ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={bodyTypeFileVideo}
                        title="Video"
                      ></video>
                      <div className="image-item__btn-wrapper">
                        <Button
                          type="Button"
                          className="common-btn"
                          onClick={() => deleteAboutBodyTypeVideo()}
                        >
                          <TrashIcon />
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <span>Body Type Video (up to 12)</span>
                <Form.Group
                  className="custom-file-upload mt-2"
                  controlId="bodyTypeVideos"
                  // onChange={(e) => handleBodyTypeVideo(e)}
                  onClick={() => {
                    setIsBodyTypeVideo(true);
                    setFileName("videoType");
                    setModalShow(true);
                  }}
                >
                  <Form.Label className={"common-btn"}>Upload Video</Form.Label>
                  {/* <Form.Control hidden type="file" accept="video/*" multiple /> */}
                </Form.Group>
                <div className="upload-video mb-3">
                  <>
                    {responseBodyTypeVideos && responseBodyTypeVideos.length > 0
                      ? responseBodyTypeVideos.map((resVideo, resIndex) => (
                          <div className="image-item" key={resIndex}>
                            {/* <video
                  className={"img-table img-thumbnail"}
                  src={resVideo?.video}
                  title={`Video ${resIndex + 1}`}
                ></video> */}
                            <video
                              className={"img-table img-thumbnail"}
                              controls
                              title={`Video ${resIndex + 1}`}
                              autoPlay={currentPlayingIndex === resIndex}
                              src={resVideo?.video}
                              ref={(el) => (videoRefs.current[resIndex] = el)}
                              onClick={() => handleVideoClick()}
                            ></video>
                            <div className="image-item__btn-wrapper">
                              <Button
                                type="Button"
                                className="common-btn"
                                onClick={() =>
                                  deleteBodyTypeVideo(resVideo?.id, resIndex)
                                }
                              >
                                <TrashIcon />
                              </Button>
                            </div>
                          </div>
                        ))
                      : ""}

                    {bodyTypeFileVideos && bodyTypeFileVideos.length > 0
                      ? bodyTypeFileVideos.map((video, index) => (
                          <div className="image-item" key={index}>
                            {/* <video
                  className={"img-table img-thumbnail"}
                  src={video}
                  title={`Video ${index + 1}`}
                  autoPlay={false}
                ></video> */}
                            <video
                              className={"img-table img-thumbnail"}
                              controls
                              src={video}
                              autoPlay={currentPlayingIndex === index}
                              title={`video ${index + 1}`}
                              ref={(el) => (videoRefs.current[index] = el)}
                              onClick={() => handleVideoClick()}
                            ></video>
                            <div className="image-item__btn-wrapper">
                              <Button
                                type="Button"
                                className="common-btn"
                                onClick={() =>
                                  deleteBodyTypeVideo(video?.id, index)
                                }
                              >
                                <TrashIcon />
                              </Button>
                            </div>
                          </div>
                        ))
                      : ""}
                  </>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={2} className="ms-auto d-flex">
                {loader ? (
                  <div className="spin-loader">
                    <FadeLoader color={"#EC1246"} height={10} />
                  </div>
                ) : (
                  <>
                    <Button
                      type={"submit"}
                      className={"btn common-btn w-100 mt-3 ms-3"}
                      value={"Next"}
                      disabled={disableButton}
                      onClick={() => setButtonType("next")}
                    >
                      Next
                    </Button>
                    <Button
                      type={"submit"}
                      className={"btn common-btn w-100 mt-3 ms-3"}
                      value={"Next"}
                      disabled={disableButton}
                      onClick={() => setButtonType("save")}
                    >
                      Save
                    </Button>
                  </>
                )}
              </Col>
            </Row>

            <ThemeModal
              title={"S3 Browser"}
              content={
                <S3BrowserNew
                  fileName={fileName}
                  selectedMedia={selectedMedia}
                  fileType={"videos"}
                />
              }
              size={"xl"}
              show={modalShow}
              onHide={() => {
                setModalShow(false);
              }}
            />
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditProgramForm;
