/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Button, Col, Row, InputGroup, FormControl } from "react-bootstrap";
import {
  AddIconOutline,
  SearchIcon,
  TrashIcon,
} from "../../components/SvgIcons";
import { constants, cross_origin, STATUS } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  DeleteMusclesAnatomy,
  GetMusclesAnatomy,
  DeleteMultipleMusclesAnatomy,
} from "../../services/muscles_anatomy_library.service";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { MdEdit, MdDelete } from "react-icons/md";
import FullPageLoader from "../../components/FullPageLoader";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import { MenuItem, Pagination, Select, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function MusclesAnatomy(props) {
  let history = useHistory();
  //Multiple Ids Delete
  const apiRef = useGridApiRef();
  const [countPage, setPageCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const handleRedirect = () => {
    history.push("/add-new-muscles-anatomy");
  };

  useEffect(() => {
    const fetchData = async () => {
      await getMusclesAnatomyFunction(true);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (keyword) {
      getMusclesAnatomyFunction(false);
    } else {
      getMusclesAnatomyFunction(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("muscles_library", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  function htmlToPlainText(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  const getMusclesAnatomyFunction = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }

    await GetMusclesAnatomy(keyword, page, perPage, 1)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            const rowData = [];
            setPage(result?.data?.meta?.current_page);
            setPageCount(result?.data?.meta?.last_page);
            result?.data?.data?.map((dt) => {
              rowData.push({
                id: dt?.id,
                name: dt?.name,
                title: dt?.title,
                title2: dt?.title2 ? dt?.title2 : "-",
                image: dt?.image,
                description: dt?.description
                  ? htmlToPlainText(dt?.description)
                  : "-",
                internal_notes: dt?.internal_notes
                  ? htmlToPlainText(dt?.internal_notes)
                  : "-",
                search_keyword: dt?.search_keyword,
                status: dt?.is_complete
                  ? dt?.is_complete == 1
                    ? "Complete"
                    : "Incomplete"
                  : "Incomplete",
                category: dt?.musclesCategory ? dt?.musclesCategory?.name : "-",
              });
            });
            setRows(rowData);
          }
        }
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
      })
      .catch((error) => {
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "muscles_library",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("muscles_library", "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => {
                  const onDelete = () => {
                    // Logic to handle delete action
                    const id = `${params.id}`;
                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to delete?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willShip) => {
                      //Delete Product API
                      if (willShip) {
                        await DeleteMusclesAnatomy(id)
                          .then((result) => {
                            if (result.status) {
                              swal(result.message, {
                                icon: "success",
                              });
                              getMusclesAnatomyFunction();
                            } else {
                              toast.error(result.message);
                            }
                          })
                          .catch((error) => {
                            toast.error(
                              error.response.data.message.replace(/_/g, " ")
                            );
                          });
                      }
                    });
                  };

                  const onEdit = (id) => {
                    history.push("/edit-muscles-anatomy/" + id);
                  };

                  return (
                    <div>
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onEdit(params.id);
                        }}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className={"btn btn-danger btn-sm mx-2"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(params.id);
                        }}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  );
                },
              };
            } else if (item.field === "image") {
              return {
                field: "image",
                headerName: "Image",
                width: 180,
                renderCell: (params) => (
                  <a
                    href={params.value}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={params.value}
                      alt="image"
                      style={{ width: "auto", height: 50 }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = constants.IMAGENOTFOUND;
                      }}
                      className={"img-table img-thumbnail"}
                      crossOrigin={cross_origin ? "anonymous" : null}
                    />
                  </a>
                ),
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "muscles_library",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false); // Stop loading if there was an error
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false); // Stop loading if there was an error
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false); // Ensure loading stops even if an unexpected error occurs
    } finally {
      setLoading(false); // Safely stop loading after the operation completes or fails
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "muscles_library",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("muscles_library", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("muscles_library", columns);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
  }, []);

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "ID", width: 80 },
      { field: "title", headerName: "Title 1", width: 170 },
      { field: "title2", headerName: "Title 2", width: 150 },
      {
        field: "image",
        headerName: "Image",
        width: 180,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            <img
              src={params.value}
              alt="image"
              style={{ width: "auto", height: 50 }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = constants.IMAGENOTFOUND;
              }}
              className={"img-table img-thumbnail"}
              crossOrigin={cross_origin ? "anonymous" : null}
            />
          </a>
        ),
      },

      { field: "category", headerName: "Category", width: 150 },
      {
        field: "description",
        headerName: "Description",
        width: 0,
      },
      {
        field: "internal_notes",
        headerName: "Internal Notes",
        width: 250,
      },
      {
        field: "search_keyword",
        headerName: "Keywords",
        width: 250,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 120,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            // Logic to handle delete action
            const id = `${params.id}`;
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              //Delete Product API
              if (willShip) {
                await DeleteMusclesAnatomy(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getMusclesAnatomyFunction();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          const onEdit = (id) => {
            history.push("/edit-muscles-anatomy/" + id);
          };

          return (
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params.id);
                }}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm mx-2"}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.id);
                }}
              >
                <MdDelete />
              </button>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const onMultipleDelete = async () => {
    const rows = await apiRef.current.getSelectedRows();

    const dataArray = [...rows.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeMuscles: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleMusclesAnatomy(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getMusclesAnatomyFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <div className="muscles-index">
        <h1 className="mb-3">Muscles Library</h1>
        <Row>
          <Col md={2}></Col>
          <Col md={6} className="ms-auto">
            <div className="all-user">
              <div className="search-bar">
                <div className="common-search-bar">
                  <InputGroup className="search-group">
                    <InputGroup.Text id="basic-addon1">
                      <SearchIcon />
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="Search"
                      maxLength={"255"}
                      onChange={(e) => {
                        e.target.value.length > 0
                          ? setKeyword(e.target.value.trim())
                          : setKeyword(null);
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="add-new-muscles">
              <Button
                className={"btn common-btn w-100"}
                onClick={handleRedirect}
              >
                <AddIconOutline /> Add New
              </Button>
            </div>
          </Col>
        </Row>
        <div className="common-table mt-2">
          <div className="delete-icon">
            <Button className={"btn common-btn"} onClick={onMultipleDelete}>
              <TrashIcon />
            </Button>
          </div>
          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>
          <div className="user-pagination">
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <Stack spacing={2}>
                  <Pagination
                    count={countPage}
                    page={page}
                    onChange={handlePaginationChange}
                    size="large"
                  />
                </Stack>
              </Col>
              <Col md={4}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <label>Rows per page:</label>
                  <Select value={perPage} onChange={handlePageSizeChange}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </Stack>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default MusclesAnatomy;
