import React, { useCallback, useMemo, useState, useRef } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CreatePage } from "../../services/pages.service";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { ArrowIcon } from "../../components/SvgIcons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { uploadToS3 } from "../../utils/helpers";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import {
//   EditorState,
//   Entity,
//   convertToRaw,
//   CompositeDecorator,
// } from "draft-js";
// import draftToHtml from "draftjs-to-html";
import * as QuillNamespace from "quill";

import MyEditor from "./MyEditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill's styling
import ThemeModal from "../../components/ThemeModal";
import S3Browser from "../../components/S3Browser";
window.jQuery = require("jquery");

// Register the image resize module
// Quill.register("modules/resize", QuillResizeImage);

function PageAdd(props) {
  let history = useHistory();

  // const [editorState, setEditorState] = useState(
  //   EditorState.createEmpty(ImageDecorator)
  // );
  const [content, setContent] = useState("");
  const quillRef = useRef(null); // Create a ref for Quill editor
  const [fileName, setFileName] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [fileType, setFileType] = useState("");

  const [folderName, setfolderName] = useState("/");
  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
    ],
  };
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [loader, setLoader] = useState(false);

  const [editor, setEditor] = useState(null);

  const onModalSubmit = async (data) => {
    setLoader(true);
    await CreatePage({
      name: data.name,
      content: content,
    })
      .then((data) => {
        if (data.status) {
          setLoader(false);
          toast.success(data.message);
          history.push("/page-list");
        } else {
          setLoader(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  // const onEditorStateChange = (newEditorState) => {
  // setEditorState(newEditorState);
  // const url = apiUrl[props['moduleName']]
  // const contentState = newEditorState.getCurrentContent();
  // const plainText = contentState.getPlainText();

  // setValue(url == "faqs" ? "answer" : 'description', plainText );
  // };

  const selectedMedia = (fileName, fileUrl) => {
    const editor = quillRef.current?.getEditor();
    const range = editor.getSelection();
    if (range) {
      if (fileName === "video") {
        editor.insertEmbed(range.index, "video", fileUrl);
      } else if (fileName === "image") {
        editor.insertEmbed(range.index, "image", fileUrl);
      }
      editor.setSelection(range.index + 1);
    }

    setModalShow(false);
  };

  const handleImageClick = useCallback(() => {
    setFileType("images");
    setFileName("image");
    setModalShow(true);
  }, [setFileName, setModalShow]);

  const handleVideoClick = useCallback(() => {
    setFileType("videos");
    setFileName("video");
    setModalShow(true);
  }, [setFileName, setModalShow]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike"],
          ["link", "image", "video"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          ["clean"],
        ],
        handlers: {
          image: handleImageClick,
          video: handleVideoClick,
        },
      },
      // resize: {
      //   locale: {},
      // },
    }),
    [handleImageClick, handleVideoClick]
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "link",
    "image",
    "video",
    "list",
    "bullet",
  ];

  return (
    <div className={"PageAdd"}>
      <div className="common-title-block-new">
        <h1 className="page-heading">Add New Page</h1>
        <Link to={"/page-list"}>
          <Button className={"btn common-btn   w-20"}>
            {/* <ArrowIcon /> */}
            Back
          </Button>
        </Link>
      </div>

      {/* <hr /> */}
      <Form onSubmit={handleSubmit(onModalSubmit)}>
        <div className={"PageAddForm mt-3"}>
          <div className="">
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Page Title*</Form.Label>
                  <Form.Control
                    placeholder="Page Title"
                    {...register("name", {
                      required: {
                        value: "required",
                        message: "Page Title is required",
                      },
                      minLength: {
                        value: 5,
                        message: "max length is 5",
                      },
                      maxLength: {
                        value: 255,
                        message: "max length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className={""}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="content">
                  <Form.Label>Description*</Form.Label>
                  <>
                    {/* <ReactQuill
                      theme="snow"
                      value={content}
                      onChange={setContent}
                      modules={modules}
                    /> */}
                    <ReactQuill
                      theme="snow"
                      value={content}
                      onChange={setContent}
                      modules={modules}
                      formats={formats}
                      placeholder="Write something..."
                      ref={quillRef}
                    />
                  </>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                {loader ? (
                  <div className="spin-loader">
                    <FadeLoader color={"#EC1246"} height={10} />
                  </div>
                ) : (
                  <button
                    type={"submit"}
                    className={"btn common-btn w-100 mt-3"}
                  >
                    Save Changes
                  </button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Form>
      <ThemeModal
        title={"S3 Browser"}
        content={
          <S3Browser
            fileName={fileName}
            selectedMedia={selectedMedia}
            fileType={fileType}
            folderName={folderName}
          />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}
export default PageAdd;
