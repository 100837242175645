import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import { constants, customStyles, STATUS } from "../../utils/constants";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import imgTemp from "../../assets/images/step01.png";
import { useForm } from "react-hook-form";
import S3Browser from "../../components/S3Browser";
import { CreateEquipment } from "../../services/equipments.service";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {
  CreateFaqs,
  DeleteFaqs,
  GetFaqs,
  UpdateFaqs,
  DeleteMultipleFaqs,
} from "../../services/faqs.service";

function HomePageSettings() {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});

  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const {
    register,
    watch,
    reset,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row);
    if (isSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow !== row)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setSelectedRows(rows.map((li) => li.id));
    if (isCheckAll) {
      setSelectedRows([]);
    }
  };

  const onMultipleDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeFaqs: selectedRows,
      };
      if (willShip) {
        await DeleteMultipleFaqs(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getFaqs();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const onDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Faq entry?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      if (willShip) {
        await DeleteFaqs(rowData?.id)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getFaqs();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const contactActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item
          as="button"
          onClick={() => {
            setShowViewModalFunc(true);
            setSelectedData(data);
          }}
        >
          View
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={() => {
            setShowEditModalFunc(true);
            setSelectedData(data);
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => onDelete(data)}>
          Delete
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      name: "#",
      width: "60px",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row.id)}
          onChange={() => handleCheckboxChange(row.id)}
        />
      ),
    },
    {
      id: "question",
      sortable: true,
      name: "question",
      maxWidth: "70%",
      selector: (rowData) => rowData?.question,
    },
    {
      maxWidth: "30%",
      field: "action",
      name: "Action",
      cell: (rowData) => contactActions(rowData),
    },
  ];

  const ViewModal = (props) => {
    const {
      register,
      setValue,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
    });

    useEffect(() => {
      console.log("selectedData", selectedData);
      setValue("question", selectedData?.question);
      setValue("answer", selectedData?.answer);
      setValue("search_keyword", selectedData?.search_keyword);
    }, []);

    return (
      <div className={"ViewModalContact"}>
        <Form>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Question*</Form.Label>
            <Form.Control
              placeholder="Question"
              {...register("question", {
                required: {
                  value: "required",
                  message: "Question is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 80,
                  message: "Max length is 80",
                },
              })}
              type="text"
              disabled
            />
            {errors.question && (
              <Form.Text className="text-muted validationText hasError">
                {errors.question.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="answer">
            <Form.Label>Answer</Form.Label>
            <Form.Control
              placeholder={"Answer"}
              as="textarea"
              rows={3}
              disabled
              {...register("answer", {
                required: {
                  value: "required",
                  message: "Answer is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 450,
                  message: "Max length is 450",
                },
              })}
            />
            {errors.answer && (
              <Form.Text className="text-muted validationText hasError">
                {errors.answer.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="search_keyword">
            <Form.Label>Keyword</Form.Label>
            <Form.Control
              placeholder={"Keyword"}
              as="textarea"
              rows={3}
              disabled
              {...register("search_keyword", {
                required: {
                  value: "required",
                  message: "Keyword is required",
                },
              })}
            />
            {errors.answer && (
              <Form.Text className="text-muted validationText hasError">
                {errors.search_keyword.message}
              </Form.Text>
            )}
          </Form.Group>
        </Form>
      </div>
    );
  };

  const onSubmit = async (data) => {
    setLoader(true);
    await CreateFaqs(data)
      .then(async (data) => {
        setShowAddModalFunc(false);
        setLoader(false);
        reset({
          question: "",
          answer: "",
          search_keyword: "",
        });
        getFaqs();
        if (data.status) {
          toast.success(data.message);
          history.push("/faqs");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        reset({
          question: "",
          answer: "",
        });
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const EditModal = (props) => {
    const {
      register,
      reset,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
    });

    const onSubmit = async (data) => {
      setLoader(true);
      await UpdateFaqs(selectedData?.id, data)
        .then(async (data) => {
          setShowEditModalFunc(false);
          setLoader(false);
          if (data.status) {
            reset({
              question: "",
              answer: "",
              search_keyword: "",
            });
            toast.success(data.message);
            getFaqs();
          } else {
            setLoader(false);
            reset({
              question: "",
              answer: "",
              search_keyword: "",
            });
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          reset({
            question: "",
            answer: "",
            search_keyword: "",
          });
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            setLoader(false);
            reset({
              question: "",
              answer: "",
              search_keyword: "",
            });
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    };

    useEffect(() => {
      setValue("question", selectedData?.question);
      setValue("answer", selectedData?.answer);
      setValue("search_keyword", selectedData?.search_keyword);
    }, []);

    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Question*</Form.Label>
            <Form.Control
              placeholder="Question"
              {...register("question", {
                required: {
                  value: "required",
                  message: "Question is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 80,
                  message: "Max length is 80",
                },
              })}
              type="text"
            />
            {errors.question && (
              <Form.Text className="text-muted validationText hasError">
                {errors.question.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="answer">
            <Form.Label>Answer</Form.Label>
            <Form.Control
              placeholder={"Answer"}
              as="textarea"
              rows={3}
              {...register("answer", {
                required: {
                  value: "required",
                  message: "Answer is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 450,
                  message: "Max length is 450",
                },
              })}
            />
            {errors.answer && (
              <Form.Text className="text-muted validationText hasError">
                {errors.answer.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="search_keyword">
            <Form.Label>Keyword</Form.Label>
            <Form.Control
              placeholder={"Keyword"}
              as="textarea"
              rows={3}
              {...register("search_keyword", {
                required: {
                  value: "required",
                  message: "Keyword is required",
                },
              })}
            />
            {errors.answer && (
              <Form.Text className="text-muted validationText hasError">
                {errors.search_keyword.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <input
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Update Faq"}
            />
          )}
        </Form>
      </div>
    );
  };

  const AddModal = () => {
    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Question*</Form.Label>
            <Form.Control
              placeholder="Question"
              {...register("question", {
                required: {
                  value: "required",
                  message: "Question is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 80,
                  message: "Max length is 80",
                },
              })}
              type="text"
            />
            {errors.question && (
              <Form.Text className="text-muted validationText hasError">
                {errors.question.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="answer">
            <Form.Label>Answer</Form.Label>
            <Form.Control
              placeholder={"Answer"}
              as="textarea"
              rows={3}
              {...register("answer", {
                required: {
                  value: "required",
                  message: "Answer is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 450,
                  message: "Max length is 450",
                },
              })}
            />
            {errors.answer && (
              <Form.Text className="text-muted validationText hasError">
                {errors.answer.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="search_keyword">
            <Form.Label>Keyword</Form.Label>
            <Form.Control
              placeholder={"Keyword"}
              as="textarea"
              rows={3}
              {...register("search_keyword", {
                required: {
                  value: "required",
                  message: "Keyword is required",
                },
              })}
            />
            {errors.answer && (
              <Form.Text className="text-muted validationText hasError">
                {errors.search_keyword.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <input
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Add New Faq"}
            />
          )}
        </Form>
      </div>
    );
  };

  const getFaqs = async () => {
    try {
      let params = {
        page,
        perPage,
        keyword,
        order: "asc",
        ["order-column"]: "question",
        is_paginate: 0,
      };

      setLoading(true);
      const res = await GetFaqs(params);
      const data = res?.data?.map((val) => ({
        id: val?.id,
        question: val?.question,
        answer: val?.answer,
        search_keyword: val?.search_keyword,
      }));
      setRows(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.response.data.message);
    }
  };

  useEffect(() => {
    reset({
      question: "",
      answer: "",
    });
    setRows([]);
    getFaqs();
  }, [page, perPage, keyword]);

  const setShowAddModalFunc = async (data) => {
    reset({
      question: "",
      answer: "",
    });
    setShowAddModal(data);
  };

  const setShowViewModalFunc = async (data) => {
    reset({
      question: "",
      answer: "",
    });
    setShowViewModal(data);
  };

  const setShowEditModalFunc = async (data) => {
    reset({
      question: "",
      answer: "",
    });
    setShowEditModal(data);
  };

  return (
    <div className={"orderPage"}>
      <h3 className="page-heading">Home Settings</h3>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => {
                  e.target.value.length > 1
                    ? setKeyword(e.target.value)
                    : setKeyword(null);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <Button
                  className={`btn common-btn   w-100 d-block`}
                  onClick={() => setShowAddModalFunc(true)}
                >
                  <AddIconOutline /> Add New
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Col xs={12} sm={12} md={8} lg={8} xl={8}>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div>
              <input
                className="mb-3"
                type="checkbox"
                checked={isCheckAll}
                onClick={handleSelectAll}
              />{" "}
              Select all
              <Button
                className={"btn common-btn   w-100 mb-3"}
                onClick={onMultipleDelete}
              >
                Delete Selected
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      <div className="theme-dataGrid products-dataGrid">
        <DataTable
          columns={columns}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          striped
          highlightOnHover
          pagination
          paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          progressComponent={
            <BarLoader
              color={"#EC1246"}
              loading={loading}
              css={"marginTop: 10px"}
              height={"4"}
              width={"100%"}
            />
          }
        />

        <ThemeModal
          title={"Add Faqs"}
          show={showAddModal}
          onHide={() => setShowAddModalFunc(false)}
          size={"md"}
          content={<AddModal />}
        />
        <ThemeModal
          title={"Faqs"}
          show={showViewModal}
          onHide={() => setShowViewModalFunc(false)}
          size={"md"}
          content={<ViewModal />}
        />
        <ThemeModal
          title={"Edit Faqs"}
          show={showEditModal}
          onHide={() => setShowEditModalFunc(false)}
          size={"md"}
          content={<EditModal />}
        />
      </div>
    </div>
  );
}

export default HomePageSettings;
