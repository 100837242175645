import {apiUrl} from '../utils/constants';
import {DELETE, GET, POST, PUT} from './api.service.wrapper';


// export const GetSportsList = async () => {
//     let response = await GET(apiUrl.sports_list);
//     return response;
// }

export const UpdateSkipReasons = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.skip_question_reasons, id, data, config);
    return response;
}


export const GetSkipReasons = async (keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.skip_question_reasons, params);
    return response;
}

export const GetSkipReasonById = async (id) => {
    let response = '';
    response = await GET(apiUrl.skip_question_reasons + '/' + id);
    return response;
}

export const CreateSkipReasons = async (data) => {
    const response = await POST(apiUrl.skip_question_reasons, data);
    return response;
}

export const UpdateSkipReason = async (id, data) => {
    const response = await PUT(apiUrl.skip_question_reasons, id, data);
    return response;
}

export const DeleteSkipReason = async (id) => {
    const response = await DELETE(apiUrl.skip_question_reasons, id);
    return response;
}

export const DeleteMultipleSkipReason = async (data) => {
    const response = await POST(apiUrl.deleteSkipQuestions, data);
    return response;
}

