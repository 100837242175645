import React, { useState, useRef, useEffect } from 'react';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {cross_origin} from "../../utils/constants"; // https://fontawesome.com/v5/docs/web/use-with/react

function VideoPlayer({ videoData, OrientationVideoFlip }) {
    const [playing, setPlaying] = useState(false);
    const [flip, setFlip] = useState("");
    const videoRef = useRef(null);

    useEffect(() => {
        videoFlipHandle()
    }, [OrientationVideoFlip, videoData.exerciseLevel?.video_flip])
    const togglePlay = () => {
        const video = videoRef.current;
        if (playing) {
            video.pause();
        } else {
            video.play();
        }
        setPlaying(!playing);
    };

    const videoFlipHandle = () => {
        if (videoData.exerciseLevel?.video_flip) {
            setFlip('flip-video')
        } else if (OrientationVideoFlip == 2) {
            setFlip('flip-video')
        } else {
            setFlip('')
        }
    }

    return (
        <div className="video-player">
            <video
                id={`video-${videoData.id}`}
                poster={videoData.exerciseLevel?.video_cover || ''}
                crossOrigin={cross_origin ? "anonymous" : null}
                className={`video ${flip}`}
                ref={videoRef}
                onClick={togglePlay}
            >
                <source src={videoData.exerciseLevel?.video || ''} type="video/mp4" />
            </video>
            <button className={"video-control"} onClick={togglePlay}>
                {playing ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
            </button>
        </div>
    );
}
export default VideoPlayer;