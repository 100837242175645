import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { constants, customStyles, STATUS } from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";


function ContactRequests() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    const [showViewModal, setShowViewModal] = useState(false);

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this Contact enquiry?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            // if (willShip) {
            //     await DeleteProduct(id).then((result) => {
            //         if (result.status) {
            //             swal(result.message, {
            //                 icon: "success",
            //             });
            //             getProductFunction(keyword, sub-category, subCategory, page);
            //         } else {
            //             toast.error(result.message);
            //         }
            //     }).catch((error) => {
            //         toast.error(error.response.data.message.replace(/_/g, ' '));
            //     })
            // }
        });
    }

    const contactActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => setShowViewModal(true)}>View</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            minWidth: '50px',
            selector: rowData => rowData.id,
        },
        {
            id: 'name',
            name: 'Name',
            maxWidth: '100px',
            sortable: true,
            selector: rowData => rowData.name,
        },
        {
            id: 'email',
            name: 'Email',
            minWidth: '200px',
            sortable: true,
            style: {
                'div:first-child': {
                    whiteSpace: 'normal',
                }
            },
            selector: rowData => rowData.email,
        },
        {
            id: 'subject',
            name: 'Subject',
            minWidth: '200px',
            sortable: true,
            style: {
                'div:first-child': {
                    whiteSpace: 'normal',
                }
            },
            selector: rowData => rowData.subject,
        },
        {
            id: 'message',
            name: 'Message',
            minWidth: '300px',
            style: {
                'div:first-child': {
                    whiteSpace: 'normal',
                }
            },
            selector: rowData => rowData.message.slice(0, 100) + "...",
        },
        {
            field: 'action',
            name: 'Action',
            cell: rowData => contactActions(rowData.id),
        },
    ];

    const tempRows = [{
        id: "BS0001",
        name: "Bilal",
        email: "bilalkhan@tekrevol.com",
        subject: "enquiry",
        message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum.",
        status: "Active"
    }, {
        id: "BS0002",
        name: "Azhan",
        email: "azhan@tekrevol.com",
        subject: "enquiry",
        message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum.",
        status: "Active"
    }, {
        id: "BS0003",
        name: "Nabeel",
        email: "nabeel@tekrevol.com",
        subject: "enquiry",
        message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum.",
        status: "Active"
    }, {
        id: "BS0004",
        name: "Joseph Kahn",
        email: "joseph.khan@tekrevol.com",
        subject: "enquiry",
        message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum.",
        status: "Active"
    },];

    const ViewModal = (props) => {
        return (
            <div className={"ViewModalContact"}>
                <Form.Group controlId="id" className={"mb-2"}>
                    <Form.Label>Id</Form.Label>
                    <Form.Control placeholder={"ID"} type="text" disabled />
                </Form.Group>
                <Form.Group controlId="name" className={"mb-2"}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control placeholder={"Name"} type="text" disabled />
                </Form.Group>
                <Form.Group controlId="name" className={"mb-2"}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control placeholder={"email"} type="text" disabled />
                </Form.Group>
                <Form.Group controlId="subject" className={"mb-2"}>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control placeholder={"subject"} type="text" disabled />
                </Form.Group>
                <Form.Group controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control placeholder={"Message"} as="textarea" rows={3} disabled />
                </Form.Group>
            </div>
        )
    }

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Contact Requests</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>

                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    // data={rows}
                    data={tempRows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />

                <ThemeModal title={"Contact Details"} show={showViewModal} onHide={() => setShowViewModal(false)} size={"md"} content={<ViewModal />} />
            </div>
        </div>
    )
}

export default ContactRequests
