import { apiUrl } from '../utils/constants';
import { GET, POST, PUT, DELETE, PUTNEW } from './api.service.wrapper';

export const GetMusclesAnatomy = async (keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    params['is_paginate'] = is_paginate;

    const response = await GET(apiUrl.muscles_anatomy_list, params);
    return response;
}

export const CreateMusclesAnatomy = async (data) => {
    const response = await POST(apiUrl.muscles_anatomy_library, data);
    return response;
}

export const DeleteMusclesAnatomy = async (id) => {
    const response = await DELETE(apiUrl.muscles_anatomy_library, id);
    return response;
}

export const DeleteMultipleMusclesAnatomy = async (data) => {
    const response = await POST(apiUrl.deleteMusclesAnatomy, data);
    return response;
}

export const GetMusclesAnatomyById = async (id) => {
    const response = await GET(apiUrl.muscles_anatomy_library + '/' + id);
    return response;
}

export const UpdateMusclesAnatomy = async (id, data) => {
    const response = await PUT(apiUrl.muscles_anatomy_library, id, data);
    return response;
}

export const DeleteFile = async (id, type) => {
    const response = await PUTNEW(apiUrl.delete_muscles_file, id, type);
    return response;
}