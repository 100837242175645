import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { ArrowIcon, TrashIcon } from "../../components/SvgIcons";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import { GetBodyPartById, UpdateBodyPart } from "../../services/body_parts";
import { constants, cross_origin } from "../../utils/constants";
import { upload } from "../../utils/helpers";

function EditFeelItHere(props) {
  let history = useHistory();
  const body_part_id = props.match.params.id;
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [row, setRow] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});

  const [isMaleUpload, setIsMaleUpload] = useState(false);
  const [male_image, setMaleImage] = useState(null);
  const [fileMaleImage, setFileMaleImage] = useState(null);
  const [folderName, setfolderName] = useState(
    "2. Exercise Management/Feel It Here Images/"
  );
  const [isFemaleUpload, setIsFemaleUpload] = useState(false);
  // const [female_image, setfemale_image] = useState(null);
  const [fileFemaleImage, setFileFemaleImage] = useState(null);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const selectedMedia = (fileName, file) => {
    setMaleImage(file);
    setFileMaleImage(file);
    clearErrors(fileName);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const handleMaleImageChange = async (event) => {
    setFileMaleImage(URL.createObjectURL(event.target.files[0]));
    setMaleImage(event.target.files[0]);
    setIsMaleUpload(true);
  };
  const deleteMaleImage = (fileName) => {
    setFormData({ ...formData, ...{ [fileName]: "" } });
    setMaleImage("");
    setFileMaleImage(null);
  };

  // const handleFemaleImageChange = async (event) => {
  //     setFileFemaleImage(URL.createObjectURL(event.target.files[0]));
  //     // setfemale_image(event.target.files[0]);
  //     setIsFemaleUpload(true)
  // };
  // const deleteFemaleImage = (fileName) => {
  //     setFormData({ ...formData, ...{ [fileName]: "" } });
  //     setfemale_image("")
  //     setFileFemaleImage(null)
  // };

  useEffect(() => {
    GetBodyPartById(body_part_id)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            console.log("data", result.data);

            setRow(result.data);
            setFormData(result.data);
            setValue("name", result.data.name);
            setValue("reference_number", result.data.reference_number);
            // setValue("male_image", result.data.male_image)
            // setValue("female_image", result.data.female_image)
            setValue("status", result.data.status);

            //setMaleImage(result.data.male_image);
            setFileMaleImage(result.data.male_image);

            // setfemale_image(result.data.female_image);
            // setFileFemaleImage(result.data.female_image);
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  }, []);

  const onSubmit = async (data) => {
    console.log("fileMaleImage", fileMaleImage);

    if (!fileMaleImage) {
      setError("male_image", { message: "Male image is required" });
      return false;
    }
    data.male_image = fileMaleImage;
    // if (!female_image) {
    //     setError('female_image', { message: 'Female image is required' });
    //     return false;
    // }

    // if (isMaleUpload) {
    //   data.male_image = fileMaleImage;
    // } else {
    //   data.male_image = fileMaleImage;
    // }

    // if (isFemaleUpload) {
    //     let s3Data = await upload(female_image, "feel-it-here/images", female_image.extension || "", female_image.type || "");
    //     if (!s3Data) {
    //         toast.error("Uploading failed");
    //         return false;
    //     }
    //     data.female_image = s3Data?.Location;
    // } else {
    //     data.female_image = male_image;
    // }
    setDisableButton(true);
    await UpdateBodyPart(body_part_id, data)
      .then(async (data) => {
        if (data.status) {
          toast.success(data.message);
          history.push("/feel-it-here");
        } else {
          setDisableButton(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const BackFeelItHere = () => {
    history.push("/feel-it-here");
  };

  return (
    <div className="feet-here-index">
      <div className="page-title">
        <h1>Edit New Feel It Here</h1>
        <Button onClick={BackFeelItHere} className="common-btn">
          Back
        </Button>
      </div>

      <div className="common-form">
        <Form
          className="formAddUser"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="reference_number">
                <Form.Label>Reference No.</Form.Label>
                <Form.Control
                  placeholder="Enter Reference No."
                  id={"formSignUpvideo"}
                  {...register("reference_number", {
                    required: {
                      value: "required",
                      message: "Reference No is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Min length is 3",
                    },
                    // maxLength: {
                    //   value: 25,
                    //   message: "Max length is 25",
                    // },
                  })}
                  type="text"
                />
                {errors.reference_number && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.reference_number.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name of Muscle*</Form.Label>
                <Form.Control
                  placeholder="Enter Name of Muscle"
                  id={"formSignUpvideo"}
                  {...register("name", {
                    required: {
                      value: "required",
                      message: "Muscle Name is required",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_ ]*$/,
                      message: "This field must contain only letters",
                    },
                    minLength: {
                      value: 3,
                      message: "Min length is 3",
                    },
                    // maxLength: {
                    //   value: 25,
                    //   message: "Max length is 25",
                    // },
                  })}
                  type="text"
                />
                {errors.name && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Row>
              <Col sm={3}>
                <Form.Group
                  controlId="formFile"
                  //   onChange={(e) => handleMaleImageChange(e)}
                  onClick={() => {
                    setIsMaleUpload(true);
                    setFileName("male_image");
                    setModalShow(true);
                  }}
                  className="custom-file-upload mb-0"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Image
                  </Form.Label>

                  {errors.male_image && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.male_image.message}
                    </Form.Text>
                  )}
                </Form.Group>
                {fileMaleImage && (
                  <div className="image-item mb-3">
                    <img
                      crossOrigin={cross_origin ? "anonymous" : null}
                      src={fileMaleImage}
                      alt="Video Cover"
                      className={"img-fluid"}
                    />
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteMaleImage("male_image")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                )}
              </Col>
              <ThemeModal
                title={"S3 Browser"}
                content={
                  <S3Browser
                    fileName={fileName}
                    selectedMedia={selectedMedia}
                    fileType={"images"}
                    folderName={folderName}
                  />
                }
                size={"xl"}
                show={modalShow}
                onHide={() => {
                  setModalShow(false);
                }}
              />
            </Row>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Label className="d-block">Select Status</Form.Label>
              <Form.Select
                className="formselect"
                aria-label="status"
                {...register("status", {
                  required: {
                    value: "required",
                    message: "Status is required",
                  },
                })}
              >
                <option value="10">Active</option>
                <option value="20">Inactive</option>
              </Form.Select>
              {errors.status && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.weight.message}
                </Form.Text>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={2} className="ms-auto">
              <input
                type={"submit"}
                className={"btn common-btn w-100 mt-3"}
                disabled={disableButton}
                value={"Submit"}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default EditFeelItHere;
