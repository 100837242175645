import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { GENDERS } from "../../utils/constants";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  DeleteTests,
  GetTests,
  DeleteMultipleTests,
  DuplicateTest,
} from "../../services/tests.service";
import { genders } from "../../utils/constants";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import { MdEdit, MdDelete, MdSummarize } from "react-icons/md";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function Tests(props) {
  let history = useHistory();
  const apiRef = useGridApiRef();
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState({ keyword: "", gender: "" });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const AddTest = () => {
    history.push("/add-new-tests");
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getTestsFunction(true);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (keyword) {
      getTestsFunction(false);
    } else {
      getTestsFunction(true);
    }
  }, [keyword.keyword, keyword.gender, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("tests", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("tests", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getTestsFunction = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }

    try {
      const result = await GetTests(
        keyword.keyword,
        keyword.gender,
        page,
        perPage,
        1
      );

      if (result.status) {
        const rowData = [];
        setPage(result?.data?.meta?.current_page);
        setPageCount(result?.data?.meta?.last_page);
        result?.data?.data?.map((dt) => {
          rowData.push({
            id: dt?.id,
            title: dt?.title,
            gender: GENDERS[dt?.gender],
            note: dt?.note,
          });
        });
        setRows(rowData);
      }

      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
    } catch (error) {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.replace(/_/g, " ")) ||
          "An error occurred"
      );
    }
  };

  const onMultipleDelete = async () => {
    let rowsDeleted = await apiRef.current.getSelectedRows();

    const dataArray = [...rowsDeleted.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeTests: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleTests(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getTestsFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const onDuplicate = (test) => {
    console.log("test", test);
    swal({
      title: "Are you sure?",
      text: "You want to duplicate this test?",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter test name",
          className: "form-control",
          required: true,
        },
      },
      buttons: {
        close: {
          text: "Cancel",
          value: { button: "cancel" },
        },
        confirm: {
          text: "Confirm",
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(async (willShip) => {
      if (willShip?.button !== "cancel") {
        if (willShip) {
          const data = {
            title: willShip,
          };
          await DuplicateTest(test, data)
            .then((result) => {
              if (result.status) {
                swal(result.message, {
                  icon: "success",
                });
                getTestsFunction();
              } else {
                toast.error(result.message);
              }
            })
            .catch((error) => {
              toast.error(error.response.data.message.replace(/_/g, " "));
            });
        } else {
          swal("Warning", "Test name is required!", "warning");
        }
      }
    });
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "ID", width: 100 },
      { field: "title", headerName: "Title", width: 320 },
      { field: "gender", headerName: "Gender", width: 160 },
      { field: "note", headerName: "Note", width: 330 },
      {
        field: "actions",
        headerName: "Actions",
        width: 120,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            // Logic to handle delete action
            const id = `${params.id}`;
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              //Delete Product API
              if (willShip) {
                await DeleteTests(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getTestsFunction();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          const onEdit = (id) => {
            history.push("/edit-tests/" + id);
          };
          const onView = (id) => {
            history.push("/view-test/" + id);
          };

          return (
            <div>
              <Tooltip title="Edit" arrow>
                <button
                  className={"btn btn-primary btn-sm"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEdit(params.id);
                  }}
                >
                  <MdEdit />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  className={"btn btn-danger btn-sm mx-2"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(params.id);
                  }}
                >
                  <MdDelete />
                </button>
              </Tooltip>
              <Tooltip title="View" arrow>
                <button
                  className={"btn btn-info  btn-sm"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onView(params.id);
                  }}
                >
                  <MdSummarize />
                </button>
              </Tooltip>
              <Tooltip title="Duplicate" arrow>
                <button
                  className={"btn btn-warning  btn-sm mx-2"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDuplicate(params?.row?.id);
                  }}
                >
                  <HiOutlineDocumentDuplicate />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "tests",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "tests",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("tests", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("tests", "column_order_change").then((result) => {
      if (result.status && result.data) {
        const parsedArray = JSON.parse(result.data.table_data);
        const dataArray = Object.values(parsedArray);
        const completeColumns = dataArray?.map((item) => {
          if (item.field === "actions") {
            return {
              field: "actions",
              headerName: "Actions",
              sortable: false,
              filterable: false,
              width: item.width,
              renderCell: (params) => {
                const onDelete = () => {
                  // Logic to handle delete action
                  const id = `${params.id}`;
                  swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to delete?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (willShip) => {
                    //Delete Product API
                    if (willShip) {
                      await DeleteTests(id)
                        .then((result) => {
                          if (result.status) {
                            swal(result.message, {
                              icon: "success",
                            });
                            getTestsFunction();
                          } else {
                            toast.error(result.message);
                          }
                        })
                        .catch((error) => {
                          toast.error(
                            error.response.data.message.replace(/_/g, " ")
                          );
                        });
                    }
                  });
                };

                const onEdit = (id) => {
                  history.push("/edit-tests/" + id);
                };
                const onView = (id) => {
                  history.push("/view-test/" + id);
                };

                return (
                  <div>
                    <Tooltip title="Edit" arrow>
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onEdit(params.id);
                        }}
                      >
                        <MdEdit />
                      </button>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <button
                        className={"btn btn-danger btn-sm mx-2"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(params.id);
                        }}
                      >
                        <MdDelete />
                      </button>
                    </Tooltip>
                    <Tooltip title="View" arrow>
                      <button
                        className={"btn btn-info  btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onView(params.id);
                        }}
                      >
                        <MdSummarize />
                      </button>
                    </Tooltip>
                  </div>
                );
              },
            };
          } else {
            return item;
          }
        });
        setColumns(completeColumns);
      }
    });
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };
  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "tests",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}

      <div className={"orderPage"}>
        <h1 className="page-heading">Tests</h1>
        <div className={"customOrderFilters"}>
          <Row>
            <Col md={5}>
              <Form.Group className="mb-3" controlId="gender">
                <Form.Select
                  placeholder="Select Gender"
                  className={"search-select"}
                  onChange={(e) => {
                    setKeyword({ ...keyword, gender: e.target.value });
                  }}
                >
                  <option key="blankChoice" hidden value>
                    Gender
                  </option>
                  <option value={0}>All</option>
                  <option value={genders.MALE}>Male</option>
                  <option value={genders.FEMALE}>Female</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={5}>
              <div className="all-user">
                <div className="search-bar">
                  <div className="common-search-bar">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <SearchIcon />
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="Search"
                        onChange={(e) => {
                          if (e.target.value.length > 1) {
                            setKeyword({
                              ...keyword,
                              keyword: e.target.value,
                            });
                          } else {
                            setKeyword({ ...keyword, keyword: "" });
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <Button onClick={AddTest} className={"btn common-btn   w-100"}>
                <AddIconOutline /> Add New
              </Button>
            </Col>
          </Row>
        </div>
        <section className="common-table mt-2 ">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>
          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>

          <div className="user-pagination">
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                {" "}
                <Stack spacing={2}>
                  <Pagination
                    count={countPage}
                    page={page}
                    onChange={handlePaginationChange}
                    size="large"
                  />
                </Stack>
              </Col>
              <Col md={4}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <label>Rows per page:</label>
                  <Select value={perPage} onChange={handlePageSizeChange}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </Stack>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
}

export default Tests;
