import React, { useEffect, useState } from "react";
import { Button, Col, Row, InputGroup, FormControl } from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { constants, cross_origin, STATUS } from "../../utils/constants";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  DeletePainPoints,
  GetPainPoints,
  DeleteMultiplePainPoints,
} from "../../services/pain_point_library.service";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { MdEdit, MdDelete } from "react-icons/md";
import { Pagination, Select, MenuItem, Stack } from "@mui/material";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function PainPoints(props) {
  let history = useHistory();
  //Multiple Ids Delete
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [countPage, setPageCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const apiRef = useGridApiRef();

  useEffect(() => {
    const fetchData = async () => {
      await getPainPointsFunction(true);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (keyword) {
      getPainPointsFunction(false);
    } else {
      getPainPointsFunction(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("trigger_point_library", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };



  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "trigger_point_library",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow(
      "trigger_point_library",
      "column_order_change"
    ).then((result) => {
      if (result.status && result.data) {
        const parsedArray = JSON.parse(result.data.table_data);
        const dataArray = Object.values(parsedArray);
        const completeColumns = dataArray.map((item) => {
          if (item.field === "actions") {
            return {
              field: "actions",
              headerName: "Actions",
              sortable: false,
              filterable: false,
              width: item.width,
              renderCell: (params) => {
                const onDelete = () => {
                  // Logic to handle delete action
                  const id = `${params.id}`;
                  swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to delete?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (willShip) => {
                    //Delete Product API
                    if (willShip) {
                      await DeletePainPoints(id)
                        .then((result) => {
                          if (result.status) {
                            swal(result.message, {
                              icon: "success",
                            });
                            GetPainPoints();
                          } else {
                            toast.error(result.message);
                          }
                        })
                        .catch((error) => {
                          toast.error(
                            error.response.data.message.replace(/_/g, " ")
                          );
                        });
                    }
                  });
                };

                const onEdit = (id) => {
                  history.push("/edit-pain-point/" + id);
                };

                return (
                  <div>
                    <button
                      className={"btn btn-primary btn-sm"}
                      onClick={(event) => {
                        event.stopPropagation();
                        onEdit(params.id);
                      }}
                    >
                      <MdEdit />
                    </button>
                    <button
                      className={"btn btn-danger btn-sm mx-2"}
                      onClick={(event) => {
                        event.stopPropagation();
                        onDelete(params.id);
                      }}
                    >
                      <MdDelete />
                    </button>
                  </div>
                );
              },
            };
          } else if (item.field === "image") {
            return {
              field: "image",
              headerName: "Image",
              width: 180,
              renderCell: (params) => (
                <a
                  href={params.value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={params.value}
                    alt="image"
                    style={{ width: "auto", height: 50 }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = constants.IMAGENOTFOUND;
                    }}
                    className={"img-table img-thumbnail"}
                    crossOrigin={cross_origin ? "anonymous" : null}
                  />
                </a>
              ),
            };
          } else {
            return item;
          }
        });
        setColumns(completeColumns);
      }
    });
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "trigger_point_library",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "trigger_point_library",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("trigger_point_library", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("trigger_point_library", columns);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
  }, []);

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  function htmlToPlainText(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  const programManagementRedirect = (data) => {
    history.push("/program-trash-data/");
  };

  const getPainPointsFunction = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }

    try {
      const result = await GetPainPoints(keyword, page, perPage, 1);

      if (result.status) {
        const rowData = [];
        setPage(result?.data?.meta?.current_page);
        setPageCount(result?.data?.meta?.last_page);
        result?.data?.data?.forEach((dt) => {
          const painCategory = dt?.painPointCategories.map((li) => li.name);
          let painCategoryData = painCategory.join(", ");

          rowData.push({
            id: dt?.id,
            name: dt?.name,
            title: dt?.title,
            title2: dt?.title2 ? dt?.title2 : "-",
            image: dt?.image,
            description: dt?.description
              ? htmlToPlainText(dt?.description)
              : "-",
            internal_notes: dt?.internal_notes
              ? htmlToPlainText(dt?.internal_notes)
              : "-",
            search_keyword: dt?.search_keyword,
            status: dt?.is_complete
              ? dt?.is_complete == 1
                ? "Complete"
                : "Incomplete"
              : "Incomplete",
            category:
              dt?.painPointCategories.length > 0 ? painCategoryData : "-",
            // category: dt?.painPointCategory ? dt?.painPointCategory?.name : "-",
          });
        });
        setRows(rowData);
      }

      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
    } catch (error) {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.replace(/_/g, " ")) ||
          "An error occurred"
      );
    }
  };

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "ID", width: 210 },
      { field: "category", headerName: "Category", width: 150 },
      {
        field: "image",
        headerName: "Image",
        width: 180,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            <img
              src={params.value}
              alt="image"
              style={{ width: "auto", height: 50 }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = constants.IMAGENOTFOUND;
              }}
              className={"img-table img-thumbnail"}
              crossOrigin={cross_origin ? "anonymous" : null}
            />
          </a>
        ),
      },
      { field: "title", headerName: "Title 1", width: 250 },
      { field: "title2", headerName: "Title 2", width: 250 },
      {
        field: "description",
        headerName: "Description",
        width: 250,
      },
      {
        field: "internal_notes",
        headerName: "Internal Notes",
        width: 250,
      },
      {
        field: "search_keyword",
        headerName: "Keywords",
        width: 250,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 180,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            // Logic to handle delete action
            const id = `${params.id}`;
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              //Delete Product API
              if (willShip) {
                await DeletePainPoints(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getPainPointsFunction();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          const onEdit = (id) => {
            history.push("/edit-pain-point/" + id);
          };

          return (
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params.id);
                }}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm mx-2"}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.id);
                }}
              >
                <MdDelete />
              </button>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const onMultipleDelete = async () => {
    const rows = await apiRef.current.getSelectedRows();

    const dataArray = [...rows.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removePaintpoint: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultiplePainPoints(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getPainPointsFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const AddTriggerr = () => {
    history.push("/add-new-pain-point");
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <div className={"orderPage"}>
        <h1>Trigger Point Library</h1>
        <div className={"customOrderFilters"}>
          <Row>
            <Col md={1}></Col>
            <Col md={5} className="ms-auto">
              <div className="all-user">
                <div className="search-bar">
                  <div className="common-search-bar">
                    <InputGroup className="mb-3 search-group">
                      <InputGroup.Text id="basic-addon1">
                        <SearchIcon />
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="Search"
                        maxLength={"255"}
                        onChange={(e) => {
                          e.target.value.length > 0
                            ? setKeyword(e.target.value.trim())
                            : setKeyword(null);
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <Button
                onClick={AddTriggerr}
                className={"btn common-btn   w-100"}
              >
                <AddIconOutline /> Add New
              </Button>
            </Col>
          </Row>
        </div>

        <div className="common-table mt-2">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>
          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>
          <div className="user-pagination">
            <div className="theme-dataGrid products-dataGrid">
              <Row>
                <Col md={4}>
                  {" "}
                  <Button
                    className={"btn common-btn   w-35"}
                    onClick={() => programManagementRedirect()}
                  >
                    Trashed Exercises
                  </Button>
                </Col>
                <Col md={4}>
                  {" "}
                  <Stack spacing={2}>
                    <Pagination
                      count={countPage}
                      page={page}
                      onChange={handlePaginationChange}
                      size="large"
                    />
                  </Stack>
                </Col>
                <Col md={4}>
                  {" "}
                  <Stack direction="row" spacing={2} alignItems="center">
                    <label>Rows per page:</label>
                    <Select value={perPage} onChange={handlePageSizeChange}>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={200}>200</MenuItem>
                    </Select>
                  </Stack>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PainPoints;
