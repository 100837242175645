import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { useForm } from "react-hook-form";
import { CreateUser } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { constants } from "../../utils/constants";
import { createUser } from "../../services/firebase.service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { VideoImg } from "./VideoImg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";

function AddNewVideo(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const selectedMedia = (file) => {
    console.log("fileData:", file);
  };
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = async (data) => {
    setDisableButton(true);
    await CreateUser({
      full_name: data.full_name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      password: data.password,
      password_confirmation: data.password_confirmation,
      dob: data.dob,
      company_name: " ",
      business_type: " ",
      role_id: constants.ROLES.ROLE_USER.toString(),
      device_type: "web",
      device_token: "user",
    })
      .then(async (data) => {
        if (data.status) {
          await createUser(data.data.user).then(() => {
            toast.success(data.message);
            history.push("/manage-users");
          });
        } else {
          setDisableButton(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">Add New Video</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                {" "}
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Video Cover</Form.Label>
                  <VideoImg />
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group controlId="email">
                  <Form.Label className="d-block">Upload Video</Form.Label>
                  <label className="upload-vid" for="video">
                    <input
                      id="video"
                      className="d-none"
                      type="file"
                      accept="video/mp4,video/x-m4v,video/*"
                    />
                    Upload Video{" "}
                    <FontAwesomeIcon
                      icon={faUpload}
                      className="upload"
                    ></FontAwesomeIcon>
                  </label>
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="video_title">
                  <Form.Label>Video Title</Form.Label>
                  <Form.Control
                    placeholder="Enter Video Title"
                    id={"formSignUpvideo"}
                    {...register("video_title", {
                      required: {
                        value: "required",
                        message: "Video Title is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Max length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.video_title && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.video_title.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Label className="d-block">Select Gender</Form.Label>
                <Form.Select className="formselect" aria-label="Gender">
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Form.Select>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                {" "}
                <Form.Label className="d-block">Select Category</Form.Label>
                <Form.Select className="formselect" aria-label="Gender">
                  <option value="1">Yoga</option>
                  <option value="2">Cardio</option>
                  <option value="2">Meditation</option>
                  <option value="2">Weight Lifting</option>
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <Col className="mt-3" xs={12} sm={12} md={8} lg={6} xl={6}>
                <input
                  type={"submit"}
                  className={"btn common-btn w-100 mt-3"}
                  disabled={disableButton}
                  value={"Create User"}
                />
              </Col>
            </Row>

            {/* <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="dob">
                                    
                                </Form.Group>
                            </Col>
                        </Row> */}
            {/*<Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="company_name">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Company Name"
                                        {...register('company_name', {
                                            required: {
                                                value: "required",
                                                message: "Company name is required"
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "Min length is 6"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "Max length is 255"
                                            },
                                        })} type="text"/>
                                    {errors.company_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.company_name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="business_type">
                                    <Form.Label>Business Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Business Type"
                                        {...register("business_type", {
                                                required: {
                                                    value: "required",
                                                    message: "Business type is required"
                                                },
                                                minLength: {
                                                    value: 6,
                                                    message: "Min length is 6"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.business_type && <Form.Text
                                        className="text-muted validationText hasError">{errors.business_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>*/}

            {/* Social Links */}
          </Form>
        </Col>
      </Row>
      <button
        onClick={() => {
          setModalShow(true);
        }}
      >
        Open Modal
      </button>
      <ThemeModal
        title={"S3 Browser"}
        content={<S3Browser selectedMedia={selectedMedia} />}
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default AddNewVideo;
