import React, { useEffect } from "react";
import { useState } from "react";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Summary from "../exercise/Summary";
import { TrashIcon } from "../../components/SvgIcons";
import ThemeModal from "../../components/ThemeModal";
import S3Browser from "../../components/S3Browser";
import { cross_origin } from "../../utils/constants";

export function VideoImg({ imageUrl, videoCover, resetCoverViewer, levelKey }) {
  // const [theArray, setTheArray] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [source, setSource] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  // useEffect(() => {
  //   onChange()
  //   // console.log('levelKey', levelKey)
  // }, [levelKey])

  const onChange = (imageList, addUpdateIndex) => {
    setSource(null);
    imageUrl("");
  };

  const selectedMedia = (fileName, file, imageList) => {
    setSource(file);
    imageUrl(file);
    setModalShow(false);
  };

  useEffect(() => {
    console.log("useEffect", videoCover);
    console.log("resetCoverViewer---", resetCoverViewer);
    if (resetCoverViewer === true) {
      setSource(null);
      imageUrl("");
    }
    if (videoCover !== undefined || videoCover !== "" || videoCover !== null) {
      console.log("videoCover", videoCover);
      setSource(videoCover);
      imageUrl(videoCover);
    }
    // else {
    //   setSource(null);
    //   imageUrl("");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("resetCoverViewer", resetCoverViewer);
    if (resetCoverViewer === true) {
      setSource(null);
      imageUrl("");
    }
    if (videoCover !== undefined || videoCover !== "" || videoCover !== null) {
      setSource(videoCover);
      imageUrl(videoCover);
    }
    // else {
    //   setSource(null);
    //   imageUrl("");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetCoverViewer, videoCover, levelKey]);

  return (
    <div className="App">
      <ImageUploading
        className="mb-2"
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              type="button"
              className="btn common-btn  mb-3"
              onClick={() => {
                setFileName("image");
                setModalShow(true);
              }}
            >
              Upload Video Cover
            </button>
            {/*<button type='button'*/}
            {/*className='btn common-btn  mb-3'*/}
            {/*  style={isDragging ? { color: 'red' } : undefined}*/}
            {/*  onClick={onImageUpload}*/}
            {/*  {...dragProps}*/}
            {/*>*/}
            {/*  Upload Video Cover <FontAwesomeIcon icon={faUpload} className='upload'></FontAwesomeIcon>*/}
            {/*</button>*/}
            &nbsp;
            {source && (
              <div className="image-item mb-3">
                <img
                  crossOrigin={cross_origin ? "anonymous" : null}
                  src={source}
                  alt="Video Cover"
                  className={"img-fluid"}
                />
                <div className="image-item__btn-wrapper">
                  <button
                    type="button"
                    className="btn common-btn"
                    onClick={() => onChange()}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </ImageUploading>
      <ThemeModal
        title={"S3 Browser"}
        content={
          <S3Browser
            fileName={fileName}
            selectedMedia={selectedMedia}
            fileType={"images"}
          />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}
