import firebase from 'firebase';
import firestore from 'firebase/firestore';

// Initialize Cloud Firestore through Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCx-9_SvtehzjsNEl_79ggVq_fAf6ZoWmE",
  authDomain: "imprint-f67d2.firebaseapp.com",
  databaseURL: "https://imprint-f67d2-default-rtdb.firebaseio.com",
  projectId: "imprint-f67d2",
  storageBucket: "imprint-f67d2.appspot.com",
  messagingSenderId: "987056204894",
  appId: "1:987056204894:web:0f1fe338e0e034e2be18cf",
  measurementId: "G-SPLV2SWMQP"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();

/*
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCx-9_SvtehzjsNEl_79ggVq_fAf6ZoWmE",
  authDomain: "imprint-f67d2.firebaseapp.com",
  databaseURL: "https://imprint-f67d2-default-rtdb.firebaseio.com",
  projectId: "imprint-f67d2",
  storageBucket: "imprint-f67d2.appspot.com",
  messagingSenderId: "987056204894",
  appId: "1:987056204894:web:0f1fe338e0e034e2be18cf",
  measurementId: "G-SPLV2SWMQP"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
*/
