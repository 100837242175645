import { apiUrl } from '../utils/constants';
import { GET, POST, PUT, DELETE } from './api.service.wrapper';

export const GetEquipmentCategories = async (keyword = '', page = '', perPage = '', is_paginate = 1, is_parent = '', status = '', is_child = '', orderByValue, orderByColumn) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (is_parent) {
        params['is_parent'] = is_parent;
    }
    if (status) {
        params['status'] = status;
    }

    if (is_child) {
        params['is_child'] = is_child;
    }

    if (orderByValue) {
        params['order'] = orderByValue;
    }

    if (orderByColumn) {
        params['order-column'] = orderByColumn;
    }

    params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.equipment_categories, params);
    return response;
}

export const GetEquipmentCategoryById = async (id) => {
    let response = '';
    response = await GET(apiUrl.equipment_categories + '/' + id);
    return response;
}

export const CreateEquipmentCategory = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await POST(apiUrl.equipment_categories, data, config);
    return response;
}

export const UpdateEquipmentCategory = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.equipment_categories, id, data, config);
    return response;
}

export const DeleteEquipmentCategory = async (id) => {
    const response = await DELETE(apiUrl.equipment_categories, id);
    return response;
}

