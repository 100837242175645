/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GetUserById } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import Select from "react-select";
import { GetCountries } from "../../services/countries.service";
import { GetSports } from "../../services/sports.service";
import { GetOccupations } from "../../services/occupations.service";
import { GetPrograms } from "../../services/programs.service";
import {
  constants,
  cross_origin,
  genders,
  weight_unit,
} from "../../utils/constants";
import { UploadIcon } from "../../components/SvgIcons";
import { FadeLoader } from "react-spinners";
import PhoneInput from "react-phone-number-input";

function ViewUser(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [countries, setCountyList] = useState([]);
  const [sports, setSport] = useState([]);
  const [occupations, setOccupation] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [age, setAge] = useState({ format: "mm/dd/yyyy" });
  const [formData, setFormData] = useState({});
  const [editSelectedSport, setEditSelectedSport] = useState([]);
  const [editSelectedOccupation, setEditSelectedOccupation] = useState([]);
  const [editSelectedProgram, setEditSelectedProgram] = useState([]);
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);

  const [loader, setLoader] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    getOccupations();
    getCountries();
    getSports();
    getPrograms();
    setTimeout(() => {
      const elements = document.querySelectorAll(".PhoneInputCountryIconImg");
      elements.forEach((element) => {
        element.setAttribute("crossOrigin", "anonymous");
      });
    }, 1500);
  }, []);

  const getCountries = async () => {
    await GetCountries(null, null, null, null, 0).then((result) => {
      if (result.status && result.data) {
        const reversedCountries = result.data.reverse();
        setCountyList(reversedCountries);
      }
    });
  };

  const getSports = async () => {
    await GetSports(null, null, null, 0, 10).then((result) => {
      if (result.status && result.data) {
        setSport(result.data);
      }
    });
  };
  const getOccupations = async () => {
    await GetOccupations(null, null, null, 0, 10).then((result) => {
      if (result.status && result.data) {
        setOccupation(result.data);
      }
    });
  };

  const getPrograms = async () => {
    await GetPrograms(null, null, null, null, 0).then((result) => {
      if (result.status && result.data) {
        setPrograms(result.data);
      }
    });
  };

  useEffect(async () => {
    let id = props.match.params.id;
    await getUserByid(id);
    if (data) {
      reset({
        full_name: data.full_name,
        // 'username': data.username,
        email: data.email,
        phone: data.phone,
        dob: data.dob,
        //'company_name': data.company_name,
        //'business_type': data.business_type,
      });
    }
  }, []);

  const getUserByid = async (id) => {
    await GetUserById(id)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            setData(result.data);
            setFormData(result.data);
            setValue("full_name", result.data?.name);
            setValue("email", result.data?.email);
            setValue("phone", result.data?.phone);
            setValue("gender", result.data?.userDetail?.gender);
            setValue("age", result.data?.userDetail?.dob);
            setValue("country", result.data?.userDetail?.country);
            setValue("weight_unit", result.data?.userDetail?.weight_unit);
            setPhoneValue(result.data?.phone);
            let selectedSportsData = [];
            if (result.data.userSports) {
              result.data.userSports.map((item) => {
                selectedSportsData.push({ value: item.id, label: item.name });
              });
              setEditSelectedSport(selectedSportsData);
            }
            let selectedOccupationData = [];
            if (result.data.occupation) {
              selectedOccupationData.push({
                value: result.data.occupation?.id,
                label: result.data.occupation?.name,
              });
              setEditSelectedOccupation(selectedOccupationData);
            }

            let selectedProgramsData = [];
            if (result.data.userActivePrograms) {
              result.data.userActivePrograms.map((item) => {
                selectedProgramsData.push({
                  label: item.program?.name,
                  value: item?.program_id,
                });
              });
              setEditSelectedProgram(selectedProgramsData);
            }
            setDefaultImage(result.data?.userDetail?.image || null);

            if (result.data.userSocialLinks) {
              result.data.userSocialLinks.map((item) => {
                setValue(item.platform, item.url);
              });
            }
          }
        } else {
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const onSubmit = async () => {
    setLoader(true);
    history.push("/manage-users");
  };

  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">View User - {data?.name}</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form
            encType="multipart/form-data"
            className="formAddUser"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row
                  className={"mb-3 justify-content-center align-items-center"}
                >
                  {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Group controlId="formFile"
                                            className="custom-file-upload mb-0">
                                            <Form.Label className={"toggleFileUpload mb-0"}>
                                                <UploadIcon />
                                                Upload Image
                                            </Form.Label>
                                            <Form.Control type="file" accept="image/png, image/jpeg, image/jpg, image/gif" {...register('image')} disabled={true} />

                                        </Form.Group>
                                    </Col> */}
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ul className={"list-inline list-variations mb-0"}>
                      <li
                        className={
                          "list-inline-item d-flex justify-content-between mb-0"
                        }
                      >
                        {defaultImage ? (
                          <img
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={defaultImage}
                            width="50%"
                            height="50%"
                            crossOrigin={cross_origin ? "anonymous" : null}
                            alt="Not Found"
                          />
                        ) : null}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Full Name*</Form.Label>
                  <Form.Control
                    disabled
                    placeholder="Enter Full Name"
                    id={"formSignUpName"}
                    autoFill={"off"}
                    defaultValue={data.name}
                    maxLength={25}
                    minLength={3}
                    {...register("full_name")}
                    type="text"
                  />
                  {errors.full_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.full_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email Address*</Form.Label>
                  <Form.Control
                    placeholder="Enter Email Address"
                    defaultValue={data.email}
                    maxLength={255}
                    {...register("email")}
                    type="email"
                    disabled
                  />
                  {errors.email && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>DOB*</Form.Label>
                  <Form.Control
                    placeholder="Enter Age"
                    id={"formSignUpAge"}
                    value={age.date}
                    maxLength={3}
                    {...register("age")}
                    type="date"
                    disabled
                  />
                  {errors.age && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.age.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="gender">
                  <Form.Label>Gender*</Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="Select Gender"
                    {...register("gender")}
                    disabled
                  >
                    <option selected disabled value="">
                      Select Gender
                    </option>
                    <option value={genders.FEMALE}>Female</option>
                    <option value={genders.MALE}>Male</option>
                  </Form.Select>
                  {errors.gender && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.gender.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="weight">
                  <Form.Label>Weight Unit</Form.Label>
                  <Form.Select
                    placeholder="Select Weight Unit"
                    {...register("weight_unit")}
                    disabled
                  >
                    <option value="" selected={true} disabled={true}>
                      Select Weight Unit
                    </option>
                    <option value={weight_unit.KG}>Kg</option>
                    <option value={weight_unit.LBS}>Lbs</option>
                  </Form.Select>
                  {errors.weight_unit && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.weight_unit.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone Number*</Form.Label>
                  <PhoneInput
                    name="phone"
                    limitMaxLength={20}
                    className="phone-input"
                    placeholder="Enter phone number"
                    international
                    value={phoneValue}
                    defaultCountry="US"
                    disabled
                  />
                  {errors.phone && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone.message}
                    </Form.Text>
                  )}
                </Form.Group>
                {/*<p>{value}</p>*/}
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Select {...register("country")} disabled>
                    <option value="" selected={true} disabled={true}>
                      Select Country
                    </option>
                    {countries &&
                      countries.map((country) => (
                        <option
                          value={country.id}
                          selected={
                            country.id == data?.userDetail?.country
                              ? true
                              : false
                          }
                        >
                          {country.nicename}
                        </option>
                      ))}
                  </Form.Select>
                  {errors.country && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.country.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="occupations">
                  <Form.Label className="d-block">Occupation</Form.Label>
                  <Select
                    value={editSelectedOccupation}
                    // isMulti
                    options={
                      occupations &&
                      occupations.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled
                  />
                  {errors.occupations && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.occupations.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="sports">
                  <Form.Label className="d-block">Sports</Form.Label>
                  <Select
                    value={editSelectedSport}
                    isMulti
                    options={
                      sports &&
                      sports.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled
                  />
                  {errors.sports && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.sports.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="programs">
                  <Form.Label className="d-block">Programs</Form.Label>
                  <Select
                    value={editSelectedProgram}
                    // isMulti
                    options={
                      programs &&
                      programs.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled
                  />
                  {errors.programs && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.programs.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="programs">
                  <Form.Label className="d-block">Programs</Form.Label>
                </Form.Group>
              </Col>
            </Row>

            {/* Social Links */}
            <Row>
              <Col className="mt-2" xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="basic-url">
                    Facebook URL(optional)
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://facebook.com/
                    </InputGroup.Text>
                    <Form.Control
                      id="fb-basic-url"
                      aria-describedby="basic-addon3"
                      type={"text"}
                      maxLength={255}
                      {...register("facebook")}
                      disabled
                    />
                  </InputGroup>
                  {errors.fb && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.fb.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="basic-url">
                    Instagram URL(optional)
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://instagram.com/
                    </InputGroup.Text>
                    <Form.Control
                      id="insta-basic-url"
                      aria-describedby="basic-addon3"
                      type={"text"}
                      maxLength={255}
                      {...register("instagram")}
                      disabled
                    />
                  </InputGroup>
                  {errors.insta && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.insta.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="basic-url">
                    Linkedin URL(optional)
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://linkedin.com/
                    </InputGroup.Text>
                    <Form.Control
                      id="linkedin-basic-url"
                      aria-describedby="basic-addon3"
                      type={"text"}
                      maxLength={255}
                      {...register("linkedin")}
                      disabled
                    />
                  </InputGroup>
                  {errors.linkedin && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.linkedin.message}
                    </Form.Text>
                  )}
                </div>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                {loader ? (
                  <div className="spin-loader">
                    <FadeLoader color={"#EC1246"} height={10} />
                  </div>
                ) : (
                  <input
                    type={"submit"}
                    className={"btn common-btn w-100 mt-3"}
                    value={"Back"}
                  />
                )}
                {/*<input type={"submit"} className={"btn common-btn w-100 mt-3"}*/}
                {/*       disabled={disableButton}*/}
                {/*       value={"Update User"}/>*/}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default ViewUser;
