import { apiUrl } from "../utils/constants";
import { DELETE, GET, POST, PUT } from "./api.service.wrapper";

export const UpdateExercises = async (id, data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await PUT(apiUrl.exercises, id, data, config);
  return response;
};

export const GetExercises = async (
  keyword = "",
  page = "",
  perPage = "",
  is_paginate = 1,
  orderByValue,
  orderByColumn,
  exerciseType
) => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }

  params["exercise_filter"] = exerciseType;
  params["is_paginate"] = is_paginate;
  const response = await GET(apiUrl.exercises, params);
  return response;
};

export const GetCompliedAllExercises = async (
  keyword = "",
  page = "",
  perPage = "",
  is_paginate = 1,
  orderByValue,
  orderByColumn,
  exerciseType
) => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }

  params["exercise_filter"] = exerciseType;
  params["is_paginate"] = is_paginate;
  const response = await GET(apiUrl.get_complied_exercise, params);
  return response;
};

export const GetAllExercises = async (
  keyword = "",
  page = "",
  perPage = "",
  is_paginate = 1,
  orderByValue,
  orderByColumn,
  filterModel
) => {
  console.log("filterModel service", filterModel);
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }

  if (filterModel) {
    params["filterModel"] = filterModel;
  }

  params["is_paginate"] = is_paginate;
  const response = await GET(apiUrl.exercises, params);
  return response;
};

export const GetIProgramExercises = async (
  keyword = "",
  bodyType = "",
  exerciseType = "",
  equipment = "",
  muscle = "",
  gender = "",
  level = "",
  exerciseFilter = "",
  statusFilter = "",
  selectedSubCategories = "",
  orderByValue,
  orderByColumn
) => {
  let params = {};
  if (bodyType) {
    params["body_type"] = bodyType;
  }

  if (exerciseType) {
    params["exercise_type"] = exerciseType;
  }

  if (equipment) {
    params["equipment"] = equipment;
  }

  if (muscle) {
    params["muscle"] = muscle;
  }

  if (gender) {
    params["gender"] = gender;
  }
  if (level) {
    params["level_id"] = level;
  }

  if (exerciseFilter) {
    params["exercise_filter"] = exerciseFilter;
  }

  if (statusFilter) {
    params["is_complete"] = statusFilter;
  }

  // if (selectedSubCategories) {
  //   params["subcategories"] = statusFilter;
  // }

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }

  const response = await GET(apiUrl.individual_exercises, params);
  return response;
};

export const GetProgramExercises = async (
  keyword = "",
  bodyType = "",
  exerciseType = "",
  equipment = "",
  muscle = "",
  gender = "",
  level = "",
  exerciseFilter = "",
  statusFilter = "",
  selectedSubCategories = "",
  orderByValue,
  orderByColumn
) => {
  let params = {};

  if (bodyType) {
    params["body_type"] = bodyType;
  }

  if (exerciseType) {
    params["exercise_type"] = exerciseType;
  }

  if (equipment) {
    params["equipment"] = equipment;
  }

  if (muscle) {
    params["muscle"] = muscle;
  }

  if (gender) {
    params["gender"] = gender;
  }
  if (level) {
    params["level_id"] = level;
  }

  if (exerciseFilter) {
    params["exercise_filter"] = exerciseFilter;
  }

  if (statusFilter) {
    params["is_complete"] = statusFilter;
  }
  if (selectedSubCategories) {
    params["exercise_categories"] = selectedSubCategories;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }

  const response = await GET(apiUrl.program_exercises, params);
  return response;
};

export const GetAllProgramExercises = async (orderByValue, orderByColumn) => {
  let params = {};

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }

  const response = await GET(apiUrl.program_exercises, params);
  return response;
};

export const GetExerciseById = async (id) => {
  let response = "";
  response = await GET(apiUrl.exercises + "/" + id);
  return response;
};

export const GetExerciseByPulseId = async (id) => {
  let response = "";
  response = await GET(apiUrl.exercise_pulse_details + "/" + id);
  return response;
};

export const GetCompliedExercises = async (id) => {
  let response = "";
  response = await GET(apiUrl.exercises_compiled + "/" + id);
  return response;
};

export const GetProgramTrashedExercises = async (
  key = 1,
  keyword = "",
  page = "",
  perPage = "",
  isPaginate,
  orderByValue,
  orderByColumn
) => {
  let params = {};
  if (key) {
    params["key"] = key;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }
  params["is_paginate"] = isPaginate;

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }

  const response = await GET(apiUrl.get_program_management_trashed, params);
  return response;
};

export const GetTrashedExercises = async (
  key,
  keyword = "",
  page = "",
  perPage = "",
  isPaginate,
  orderByValue,
  orderByColumn
) => {
  let params = {};
  if (key) {
    params["exercise_filter"] = key;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }
  params["is_paginate"] = isPaginate;

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }

  let response = "";
  response = await GET(apiUrl.trashed_exercises, params);
  return response;
};

export const GetExerciseSummary = async (id, level) => {
  let params = {};
  if (level) {
    params["level_id"] = level;
  }
  let response = "";
  response = await GET(apiUrl.exerciseSummary + "/" + id, params);
  return response;
};

export const CreateExercise = async (data) => {
  const response = await POST(apiUrl.exercises, data);
  return response;
};

export const CreateExerciseCompilation = async (data) => {
  const response = await POST(apiUrl.exerciseCompilation, data);
  return response;
};

export const UpdateExerciseCompilation = async (id, data) => {
  const response = await PUT(apiUrl.updateCompilation, id, data);
  return response;
};

export const CreateExerciseSteps = async (data) => {
  const response = await POST(apiUrl.exercise_steps, data);
  return response;
};

export const UpdateExerciseSteps = async (id, data) => {
  const response = await PUT(apiUrl.exerciseLevelUpdate, id, data);
  return response;
};

export const UpdateExercise = async (id, data) => {
  const response = await PUT(apiUrl.exercises, id, data);
  return response;
};

export const UpdateWeightExercise = async (id, data) => {
  const response = await PUT(apiUrl.exercises_weight, id, data);
  return response;
};

export const DeleteExercise = async (id) => {
  const response = await DELETE(apiUrl.exercises, id);
  return response;
};

export const DeleteForeverExercise = async (id) => {
  const response = await DELETE(apiUrl.delete_forever_exercises, id);
  return response;
};

export const DeleteForeverProgramExercise = async (id) => {
  const response = await DELETE(
    apiUrl.delete_program_management_trashed_forever_exercises,
    id
  );
  return response;
};

export const RestoreProgramExercise = async (id) => {
  const response = await PUT(
    apiUrl.program_management_trashed_restore_exercise,
    id
  );
  return response;
};

export const RestoreExercise = async (id) => {
  const response = await PUT(apiUrl.restore_exercise, id);
  return response;
};

export const UpdateExercisePulseId = async (id, data) => {
  const response = await PUT(apiUrl.updateExercisePulse, id, data);
  return response;
};

export const UpdateExerciseLevelPulseId = async (id, data) => {
  const response = await PUT(apiUrl.updateExerciseLevelPulse, id, data);
  return response;
};

export const DeleteMultipleExercise = async (data) => {
  const response = await POST(apiUrl.deleteExercise, data);
  return response;
};

export const DuplicateExercise = async (id, data) => {
  const response = await POST(apiUrl.duplicateExercise + "/" + id, data);
  return response;
};

export const GetExerciseLevelByIds = async (
  level_id,
  exercise_id,
  equipment_type
) => {
  let response = "";
  response = await GET(
    apiUrl.exerciseLevelDetail +
      "/" +
      level_id +
      "/" +
      exercise_id +
      "/" +
      equipment_type
  );
  return response;
};
