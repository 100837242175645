import { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { TiDelete, TiCogOutline } from "react-icons/ti";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import ThemeModal from "../../components/ThemeModal";
import russianTwist from "../../assets/images/russian-twist-1.jpg";
import obliques from "../../assets/images/obliques-m.PNG";
import React from "react";
import PosterVideoPlayer from "../../components/PosterVideoPlayer";

function VideoThumbnail(props) {
  const [show, setShow] = useState(false);
  const [feelImageShow, setFeelImageShow] = useState(false);
  const [position, setPosition] = useState(null);
  const [overlayTextShow, setOverlayTextShow] = useState(false);
  const [overlayPos, setOverlayPos] = useState(null);
  const history = useHistory();

  const CheckPosImage = (feelPos) => {
    if (feelPos === "TR") {
      return "top-right";
    } else if (feelPos === "TL") {
      return "top-left";
    } else if (feelPos === "BL") {
      return "bottom-left";
    } else if (feelPos === "BR") {
      return "bottom-right";
    } else {
      return "";
    }
  };

  const CheckPosText = (overlayPos) => {
    if (overlayPos === "RO") {
      return "top-right";
    } else if (overlayPos === "LO") {
      return "top-left";
    } else if (overlayPos === "CE") {
      return "center";
    } else {
      return "";
    }
  };
  const handleClick = (event) => {
    event.preventDefault();

    let url;
    if (props.exercise.is_compilation === 1) {
      url = `/edit-compilation-steps/${props.compilation_check_video_id}`;
    } else if (props.exercise.is_compilation === 0) {
      url = `/edit-exercise-steps/${props.id}`;
      const state = { exercise: props.exercise, level: 1 };
    }
    // Open link in a new tab
    window.open(url, "_blank");

    // Note: The history.push method is not suitable for opening a new tab.
    // It is used for navigating within the same tab/window.
    // history.push(url, state);
  };

  const ImgPlacement = (props) => {
    return (
      <div className={"modalFeelItHere"}>
        <div className={"image-view-container"}>
          <img
            className={"img-fluid parentImg"}
            src={russianTwist}
            alt={""}
            title={""}
          />
          {feelImageShow && (
            <img
              className={`img-fluid feel-it-here ${CheckPosImage(position)}`}
              src={obliques}
              alt={"Feel IT"}
              title={"feel it here image"}
            />
          )}
          {overlayTextShow && overlayPos === "BO" ? (
            <>
              <p className={"text-placement top-left"}>right side only</p>{" "}
              <p className={"text-placement top-right"}>right side only</p>
            </>
          ) : (
            ""
          )}
          {overlayTextShow && overlayPos !== "BO" ? (
            <p className={`text-placement ${CheckPosText(overlayPos)}`}>
              right side only
            </p>
          ) : (
            ""
          )}

          {/*<p className={"text-placement top-right"}>right side only</p>*/}
        </div>
        <div className={"mt-3"}>
          <Row>
            <Col xs={12} sm={12} md={3} lg={6} xl={6}>
              {/* <Form.Check
                                label="Enable Feel It here Image"
                                name="feelItHere"
                                checked={feelImageShow}
                                type={"checkbox"}
                                id={`feelItHere`}
                                onChange={() => setFeelImageShow(!feelImageShow)}
                            /> */}
              <Form.Group
                className={`mb-3 ${feelImageShow ? "d-block" : "d-none"}`}
              >
                <Form.Label className="d-block mt-3">
                  Feel it here image Position:
                </Form.Label>
                <Form.Select
                  className="formselect exercise-dropdown"
                  id="position"
                  aria-label="Feel it here position"
                  onChange={(e) => {
                    setPosition(e.target.value);
                  }}
                >
                  <option disabled>Select Position</option>
                  <option value="TL">Top Left</option>
                  <option value="TR">Top Right </option>
                  <option value="BR">Bottom Right</option>
                  <option value="BL">Bottom Left</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Form.Check
                label="Enable Text Overlay"
                name="overlayText"
                checked={overlayTextShow}
                type={"checkbox"}
                id={`overlayText`}
                onChange={() => setOverlayTextShow(!overlayTextShow)}
              />
              <Form.Group
                className={`mb-3 ${overlayTextShow ? "d-block" : "d-none"}`}
                controlId="overlayTextPosition"
              >
                <Form.Label className="d-block mt-3">
                  Overlay Text Position:
                </Form.Label>
                <Form.Select
                  defaultValue={overlayPos}
                  className="formselect exercise-dropdown"
                  aria-label="Feel it here position"
                  onChange={(e) => {
                    setOverlayPos(e.target.value);
                  }}
                >
                  <option disabled>Select Position</option>
                  <option value="RO">Right Side Only</option>
                  <option value="LO">Left Side Only</option>
                  <option value="BO">Both Sides</option>
                  <option value="CE">Center</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const onVideoDelete = (e) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  };

  const onDragStartFunc = (e) => {
    console.log("e", e);
    props.dragged(e.target);
  };
  return (
    <div
      className="video-detail"
      draggable="true"
      onDragStart={(e) => onDragStartFunc(e)}
      data-video_id={props.id}
      data-level_id={props.level}
      data-exercise_type={props?.exercise?.is_compilation}
      data-compilation_video_id={props?.compilation_check_video_id}
      data-gender={props?.gender}
    >
      <div className="vid-thumbnail card-thumbnail">
        <PosterVideoPlayer
          videoUrl={props.videoUrl}
          id={"video-" + props.id + "-" + props.level}
          flip={props.flipVideo}
          posterUrl={props.videoPoster}
          isCompilation={props?.exercise?.is_compilation}
          className={`${props.flipVideo ? "video flip-video" : "video"}`}
        />
        {/*<video id={"video-" + props.id + "-" + props.level} poster={props.videoPoster} controls crossOrigin='anonymous'*/}
        {/*    className={`${props.flipVideo ? 'video flip-video' : 'video'}`}*/}
        {/*>*/}
        {/*    <source src={props.videoUrl} type="video/mp4" />*/}
        {/*</video>*/}

        <h6 className="vid-title">
          <Link
            className={`${
              props.exercise.is_compilation == 1 ? "custom-red" : "custom-blue"
            }`}
            onClick={handleClick}
          >
            {props.videoTitle}
          </Link>
        </h6>
        <p className="vid-author">{props.addedBy}</p>
        <span className={"deleteIcon"} onClick={(e) => onVideoDelete(e)}>
          <TiDelete />
        </span>
        <span
          title={"view Details"}
          className={"ViewIcon"}
          onClick={() => setShow(true)}
        >
          <TiCogOutline />
        </span>
      </div>
      <ThemeModal
        size={"md"}
        show={show}
        onHide={() => setShow(false)}
        title={props.videoTitle}
        content={<ImgPlacement />}
      />
    </div>
  );
}

export default VideoThumbnail;
