import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';
import { GetAuthUser, GetUserRole } from "../services/auth/auth.service"

const user = GetAuthUser();
let role = GetUserRole();

export const GetProfile = async () => {
    let response = await GET(apiUrl.profile);
    return response;
}

export const UpdateProfile = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.update_profile, id, data, config);
    return response;
}

export const GetUsers = async (keyword = '', role_id = '', page = '', perPage = '', verified = '', sport = '', program = '', is_paginate = 1) => {
    let params = {};
    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (role_id) {
        params['role_id'] = role_id;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (verified) {
        params['verified'] = verified;
    }

    if (program) {
        params['program'] = program;
    }

    if (sport) {
        params['sport'] = sport;
    }

    params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.users, params);
    return response;
}

export const GetUserById = async (id) => {
    let response = '';
    response = await GET(apiUrl.users + '/' + id);
    return response;
}

export const CreateUser = async (data) => {
    const response = await POST(apiUrl.register, data);
    return response;
}

export const UpdateUser = async (id, data) => {
    const response = await PUT(apiUrl.users, id, data);
    return response;
}

export const DeleteUser = async (id) => {
    const response = await DELETE(apiUrl.users, id);
    return response;
}

export const ChatNotification = async (data) => {
    const response = await POST(apiUrl.chat_notification, data);
    return response;
}


export const getPresignedPostUrl = async (fileType, fileName) => {
    let response = '';
    response = await GET(apiUrl.get_s3_token + '?image_type=' + fileType + "&file_name=" + fileName);
    return response.data;
}

export const getPresignedPostUrlUpdated = async (folder_path, fileType, fileName) => {
    let response = '';
    response = await GET(apiUrl.get_s3_token_updated + '?file_type=' + fileType + "&file_name=" + fileName+ "&folder_path=" + folder_path);
    return response.data;
}

export const DeleteMultipleUsers = async (data) => {
    const response = await POST(apiUrl.deleteUsers, data);
    return response;
}

export const ColumnHideShow = async (data) => {
    const response = await POST(apiUrl.table_columns, data);
    return response;
}

export const GetColumnHideShow = async (name, type = "column_order_change") => {
    let params = {};
    if(name){
        params['module_name'] = name;
    }
    if(type){
        params['type'] = type;
    }
    const response = await GET(apiUrl.get_table_columns, params);
    return response;
}

