import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  FilterIcon,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import { GetNotifications } from "../../services/notifications.service";
import { toast } from "react-toastify";
import moment from "moment";
import { constants, customStyles } from "../../utils/constants";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { useHistory } from "react-router-dom";

function AllNotifications() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState();

  useEffect(async () => {
    await getNotifications();
  }, [page, perPage]);

  const getNotifications = async () => {
    setLoading(true);
    await GetNotifications(page, perPage)
      .then((result) => {
        if (result.status) {
          if (result.data.data) {
            const rowData = [];
            setPage(result.data.meta.current_page);
            setTotalRows(result.data.meta.total);
            result.data.data.map((dt, index) => {
              rowData.push({
                id: ++index,
                notifiable_id: dt.notifiable_id,
                title: dt.title,
                message: dt.message,
                ref_id: dt.ref_id,
                type: dt.type,
                read_at: dt.read_at,
                extra: dt.extra,
                created_at: moment.utc(dt.created_at).fromNow(),
                updated_at: dt.updated_at,
                deleted_at: dt.deleted_at,
                image: dt.image,
                created_ago: dt.created_ago,
              });
            });
            setRows(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const userActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button">Mark as Read</Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      id: "title",
      name: "Title",
      selector: (rowData) => rowData.title,
    },
    {
      id: "message",
      name: "Message",
      selector: (rowData) => rowData.message,
    },
    {
      id: "created_at",
      name: "Created At",
      selector: (rowData) => rowData.created_at,
    },
  ];

  const handlePageChange = async (page) => {
    await setPage(page);
  };

  return (
    <div className="dashboard">
      <h1>Notifications</h1>
      <div className="notification-index">
        <DataTable
          columns={columns}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          striped
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          progressComponent={
            <BarLoader
              color={"#12726C"}
              loading={loading}
              css={"marginTop: 10px"}
              height={"4"}
              width={"100%"}
            />
          }
        />
        <div className="notification-list">
          <i>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 46.5C36.4264 46.5 46.5 36.4264 46.5 24C46.5 11.5736 36.4264 1.5 24 1.5C11.5736 1.5 1.5 11.5736 1.5 24C1.5 36.4264 11.5736 46.5 24 46.5Z"
                stroke="#C00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24 10.5002V24.0002L33 28.5002"
                stroke="#C00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          <div className="notification-detail">
            <div className="notification-title">
              <h6>Account Has Been Verified</h6>
              <div className="notification-date">
                <span>Date :- 01 / 13 / 2024</span>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
        </div>
        <div className="notification-list">
          <i>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 46.5C36.4264 46.5 46.5 36.4264 46.5 24C46.5 11.5736 36.4264 1.5 24 1.5C11.5736 1.5 1.5 11.5736 1.5 24C1.5 36.4264 11.5736 46.5 24 46.5Z"
                stroke="#C00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24 10.5002V24.0002L33 28.5002"
                stroke="#C00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          <div className="notification-detail">
            <div className="notification-title">
              <h6>Account Has Been Verified</h6>
              <div className="notification-date">
                <span>Date :- 01 / 13 / 2024</span>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
        </div>
        <div className="notification-list">
          <i>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 46.5C36.4264 46.5 46.5 36.4264 46.5 24C46.5 11.5736 36.4264 1.5 24 1.5C11.5736 1.5 1.5 11.5736 1.5 24C1.5 36.4264 11.5736 46.5 24 46.5Z"
                stroke="#C00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24 10.5002V24.0002L33 28.5002"
                stroke="#C00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          <div className="notification-detail">
            <div className="notification-title">
              <h6>Account Has Been Verified</h6>
              <div className="notification-date">
                <span>Date :- 01 / 13 / 2024</span>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllNotifications;
