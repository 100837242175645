import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GetProfile, UpdateProfile } from "../../services/users.service";
import { UploadIcon } from "../../components/SvgIcons";
import { toast } from "react-toastify";
import {
  EmptyLocalStorage,
  GetAccessToken,
  GetUserRole,
  SetAuthUser,
} from "../../services/auth/auth.service";
import { constants } from "../../utils/constants";
import { updateUser } from "../../services/firebase.service";
import UserContext from "../../hooks/UserContext";
import { FadeLoader } from "react-spinners";

function EditProfile(props) {
  let history = useHistory();
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(async () => {
    getProfile();
  }, []);

  const getProfile = async () => {
    await GetProfile()
      .then(async (result) => {
        if (result.status) {
          if (result.data?.profile) {
            let dt = result.data?.profile;
            if (dt) {
              setValue("id", dt.id);
              setValue("full_name", dt.name);
              setValue("email", dt.email);
              setValue("phone", dt.phone);
              await setDefaultImage(
                dt.profile_picture?.mediumImage || constants.USERIMAGENOTFOUND
              );
            }
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const onSubmit = async (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("name", data.full_name.trim());
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("dob", "2001-02-02");
    formData.append("company_name", "Administrator");
    formData.append("business_type", "Admin");
    if (data.media[0]) {
      formData.append("media", data.media[0]);
    }
    setDisableButton(true);
    await UpdateProfile(data.id, formData)
      .then(async (data) => {
        if (data.status) {
          toast.success(data.message);
          setLoader(false);
          history.push("/profile");
          if (
            GetUserRole() == constants.ROLES.ROLE_CSA ||
            GetUserRole() == constants.ROLES.ROLE_VENDOR
          ) {
            //update data on firestore
            let newData = {
              user: data.data,
              access_token: GetAccessToken(),
            };
            await updateUser(data.data);
            await SetAuthUser(JSON.stringify(newData));
          }

          setDisableButton(false);
        } else {
          toast.error(data.message.replace(/_/g, " "));
          setDisableButton(false);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <div className="dashboard">
      <h1>Edit Profile</h1>
      <div className="edit-profile">
        <Row>
          <Col md={12}>
            <div className="common-form">
              <Form
                encType="multipart/form-data"
                className="formAddUser"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Form.Group className="mb-3" controlId="formFullName">
                  <Form.Label>User Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    name="full_name"
                    {...register("full_name", {
                      onChange: (e) => {
                        setValue("full_name", e.target.value);
                      },
                      required: {
                        value: "required",
                        message: "Full name is required",
                      },
                    })}
                    maxLength={"255"}
                  />
                  <Form.Text className="text-muted validationText hasError">
                    {errors.full_name && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.full_name.message}
                      </Form.Text>
                    )}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    disabled
                    type="email"
                    placeholder="Enter Email Address"
                    readOnly
                    {...register("email")}
                    maxLength={"255"}
                  />
                  <Form.Text className="text-muted validationText hasError">
                    {errors.email && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formContact">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone", {
                      onChange: (e) => {
                        setValue("phone", e.target.value);
                      },
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                    })}
                    maxLength={"16"}
                  />
                  <Form.Text className="text-muted validationText hasError">
                    {errors.phone && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.phone.message}
                      </Form.Text>
                    )}
                  </Form.Text>
                </Form.Group>
                <Form.Group
                  controlId="formFile"
                  onChange={(e) => handleChange(e)}
                  className="custom-file-upload mb-0"
                >
                  <Form.Label className={"toggleFileUpload mb-0"}>
                    <UploadIcon />
                    Upload Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                    {...register("media")}
                  />
                </Form.Group>
                {loader ? (
                  <div className="spin-loader">
                    <FadeLoader color={"#EC1246"} height={10} />
                  </div>
                ) : (
                  <Button
                    type={"submit"}
                    disabled={disableButton}
                    className={"btn common-btn w-100 mt-3"}
                    value={"Edit User"}
                  >
                    Edit User
                  </Button>
                )}

                {/* <Col md={12}>
                <ul className={"list-inline list-variations mb-0"}>
                  <li
                    className={
                      "list-inline-item d-flex justify-content-between mb-0"
                    }
                  >
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = constants.IMAGENOTFOUND;
                      }}
                      src={file ? file : defaultImage}
                      width="50%"
                      height="50%"
                    />
                  </li>
                </ul>
              </Col> */}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EditProfile;
