import {constants} from '../utils/constants';
import {db} from "../utils/firebase-config";
import moment from "moment";
import ProfileIcon from "../assets/images/profile.jpg";
import {GetAuthUser} from "./auth/auth.service";

const current_user = GetAuthUser();
const chatroom = constants.COLLECTION.CHATROOM;
const user = constants.COLLECTION.USERS;
const chat_list = constants.COLLECTION.CHATLIST;
const general_chat_users_live = constants.COLLECTION.GENERALCHATLIST;
const general_chatRoom_live = constants.COLLECTION.GENERALCHATROOM;

//Using this function for search in thread
const searchThread = (nameKey, myArray) => {
    for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].chat_id.includes(nameKey)) {
            return myArray[i];
        }
    }
}

//Get real time thread list
export const getThreadList = async (search = null, is_vendor = false, callback) => {
    let user_id = current_user.id;
    await db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)
        //.where('isVendor', '==', is_vendor)
        .orderBy('timestamp', 'desc')
        .onSnapshot((snapShot) => {
            let data = []
            snapShot.forEach((doc) => {
                let dt = doc.data();
                data.push({
                    chat_id: doc.id,
                    //last_message: dt.lastChat,
                    created_ago: moment(dt.timestamp).fromNow(),
                    online: false,
                    customer_id: dt.chatWith
                })
            });
            if (search) {
                let search_data = searchThread(search, data);
                if (search_data) {
                    callback([search_data]);
                } else {
                    callback(data);
                }
            } else {
                callback(data);
            }
        });
}

//Get Last Thread Message
export const getLastThreadMessage = async (chatroom, chat_id, callback) => {
    let resultArray = [];
    let docRef = db.collection(chatroom)
        .doc(chat_id.toString())
        .collection(chat_id.toString())
        .orderBy("timestamp", 'desc')
        .limit(1)
    docRef = await docRef.get()
    docRef.forEach((doc) => {
        let dt = doc.data();
        resultArray.push(dt)
    });
    callback(resultArray[0])
}

//get user image for sender and receiver on chat
export const getUserImage = async (user_id, callback) => {
    if (user_id) {
        const docRef = db.collection(user).where('id', '==', user_id.toString());
        await docRef.onSnapshot((snapShot) => {
            let image = ''
            snapShot.forEach((doc) => {
                let dt = doc.data();
                image = dt?.image || '';
            });
            callback(image);
        });
    }
}

//get all thread messages by active thread and active customer
export const getThreadMessages = async (activeThread, activeCustomer, callback) => {
    const docRef = db.collection(chatroom)
        .doc(activeThread)
        .collection(activeThread);
    await docRef.where("deleteAgent", "==", constants.CHATMESSAGE.CONTENT).onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach(async (doc) => {
            let dt = doc.data();
            if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                let sender_image = ProfileIcon;
                let receiver_image = ProfileIcon;

                //get chat messages
                data.push({
                    type: dt.idTo == current_user.id ? 'sent' : 'received',
                    text: dt.content ? dt.content : '',
                    profileImg: dt.idTo == current_user.id ? sender_image : receiver_image,
                    receivedAt: dt.timestamp ? moment(dt.timestamp).fromNow() : '',
                    vendorIds: dt.vendorIds ? dt.vendorIds : [],
                    mediaType: dt.contentType ? dt.contentType : '',
                    idFrom: dt.idFrom ? dt.idFrom : '',
                    order_id: dt.order_id ? dt.order_id : '',
                    media: dt.url && (dt.contentType == 'image' || dt.contentType == 'audio' || dt.contentType == 'video' || dt.contentType == 'doc') ? dt.url : '',
                    timestamp: dt.timestamp ? dt.timestamp : '',
                    isRead: dt.isread
                })


            }
        });
        callback(data);
    });
}

//get all vendor thread messages by active thread and active customer
export const getVendorThreadMessages = async (activeThread, activeCustomer, is_vendor = true, callback) => {
    let whereColumn = is_vendor ? 'delete' : 'deleteAgent';
    const docRef = db.collection(chatroom)
        .doc(activeThread.toString())
        .collection(activeThread.toString());
    await docRef.where(whereColumn.toString(), "==", constants.CHATMESSAGE.CONTENT).onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach(async (doc) => {
            let dt = doc.data();
            if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                let sender_image = ProfileIcon;
                let receiver_image = ProfileIcon;

                //get chat messages
                data.push({
                    type: dt.idTo == current_user.id ? 'sent' : 'received',
                    text: dt.content ? dt.content : '',
                    profileImg: dt.idTo == current_user.id ? sender_image : receiver_image,
                    receivedAt: dt.timestamp ? moment(dt.timestamp).fromNow() : '',
                    vendorIds: dt.vendorIds ? dt.vendorIds : [],
                    mediaType: dt.contentType ? dt.contentType : '',
                    idFrom: dt.idFrom ? dt.idFrom : '',
                    order_id: dt.order_id ? dt.order_id : '',
                    media: dt.url && (dt.contentType == 'image' || dt.contentType == 'audio' || dt.contentType == 'video' || dt.contentType == 'doc') ? dt.url : '',
                    timestamp: dt.timestamp ? dt.timestamp : '',
                    isRead: dt.isread
                })


            }
        });
        callback(data);
    });
}

//getting active thread media files
export const getActiveThreadMedia = async (activeThread, activeCustomer, callback) => {
    const docRef = db.collection(chatroom).doc(activeThread.toString()).collection(activeThread.toString());
    await docRef.orderBy('timestamp', 'asc').onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach((doc) => {
            let dt = doc.data();
            if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                //get chat messages
                data.push({
                    type: dt.idTo == current_user.id ? 'sent' : 'received',
                    mediaType: dt.contentType ? dt.contentType : '',
                    media: dt.url && (dt.contentType == 'image' || dt.contentType == 'audio' || dt.contentType == 'video' || dt.contentType == 'doc') ? dt.url : '',
                    timestamp: dt.timestamp ? dt.timestamp : ''
                })
            }
        });
        callback(data);
    });
}

//Submit Messsage
export const submitMessage = async (data) => {
    //new Date().getTime().toString()
    await db.collection(chatroom)
        .doc(data.chatId.toString())
        .collection(data.chatId.toString())
        .doc(data.timestamp.toString())
        .set(data);
    return true;
}

//Update last message on threads
export const updateLastMessage = async (userId, activeThread, message) => {
    await db.collection(user)
        .doc(userId.toString())
        .collection(chat_list)
        .doc(activeThread.toString())
        .update({
            lastChat: message,
            timestamp: new Date().getTime()
        });
}

export const updateVendorLastMessage = async (userId, activeThread, message) => {
    await db.collection(user)
        .doc(userId.toString())
        .collection(chat_list)
        .doc(activeThread.toString())
        .update({
            lastChatVendor: message,
            timestamp: new Date().getTime()
        });
}

//create new user doc
export const createUser = async (data) => {
    return await db.collection(user)
        .doc(data.id.toString())
        .set({
            createdAt: moment.now(), //data.created_at,
            email: data.email,
            fcmToken: 'NoToken',
            id: data.id.toString(),
            image: (data && data.profile_picture) ? data.profile_picture.mediumImage : constants.USERIMAGENOTFOUND,
            name: data.full_name,
            online: false
        });
}

//update existing user doc
export const updateUser = async (data) => {
    let docRef = db.collection(user)
        .doc(data.id.toString());
    await docRef.update({
        email: data.email,
        fcmToken: 'NoToken',
        id: data.id.toString(),
        image: data.profile_picture?.mediaUrl || constants.USERIMAGENOTFOUND,
        name: data.full_name,
        online: false,
        updatedAt: moment.now()
    });
}

//delete message
export const deleteMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            deleteAgent: data.deleted_at
        })
}

//delete vendor message
export const deleteVendorMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            delete: data.deleted_at
        })
}

//delete message from everyone
export const deleteEveryOneMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            delete: data.deleted_at,
            deleteAgent: data.deleted_at
        })
}

//delete vendor message
export const deleteEveryOneVendorMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            delete: data.deleted_at,
            deleteAgent: data.deleted_at
        })
}

//read all messages
export const readMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        //.doc(data.message_id.toString())
        .where('idFrom', '==', data.customer_id.toString())
        .get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                doc.ref.update({
                    isread: true
                });
            });
        });
}

//Get real time thread list for orders
export const getUsersThreadList = async (search = null, callback) => {
    let user_id = current_user.id;
    await db.collection(general_chat_users_live)
        .doc(user_id.toString())
        .collection(chat_list)
        .orderBy('timestamp', 'desc')
        .onSnapshot((snapShot) => {
            let data = []
            snapShot.forEach(async (doc) => {
                let dt = doc.data();

                data.push({
                    chat_id: doc.id,
                    //last_message: dt.lastChat,
                    created_ago: moment(dt.timestamp).fromNow(),
                    online: false,
                    customer_id: dt.chatWith,
                    customer_name: dt.userName || dt.chatWith
                })
            });

            if (search) {
                let search_data = searchThread(search, data);
                if (search_data) {
                    callback([search_data]);
                } else {
                    callback(data);
                }
            } else {
                callback(data);
            }
        });
}

//Get real time thread list for users
export const getUserThreadMessages = async (activeThread, activeCustomer, callback) => {
    const docRef = db.collection(general_chatRoom_live)
        .doc(activeThread)
        .collection(activeThread);
    await docRef.where("deleteAgent", "==", constants.CHATMESSAGE.CONTENT).onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach(async (doc) => {
            let dt = doc.data();
            if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                let sender_image = ProfileIcon;
                let receiver_image = ProfileIcon;

                //get chat messages
                data.push({
                    type: dt.idTo == current_user.id ? 'sent' : 'received',
                    text: dt.content ? dt.content : '',
                    profileImg: dt.idTo == current_user.id ? sender_image : receiver_image,
                    receivedAt: dt.timestamp ? moment(dt.timestamp).fromNow() : '',
                    mediaType: dt.contentType ? dt.contentType : '',
                    idFrom: dt.idFrom ? dt.idFrom : '',
                    order_id: dt.order_id ? dt.order_id : '',
                    media: dt.url && (dt.contentType == 'image' || dt.contentType == 'audio' || dt.contentType == 'video' || dt.contentType == 'doc') ? dt.url : '',
                    timestamp: dt.timestamp ? dt.timestamp : '',
                    isRead: dt.isread,
                    product: dt.product ? dt.product : '',
                })
            }
        });
        callback(data);
    });
}

//Submit Messsage User Chat
export const submitUserChatMessage = async (data) => {
    //new Date().getTime().toString()
    await db.collection(general_chatRoom_live)
        .doc(data.chatId.toString())
        .collection(data.chatId.toString())
        .doc(data.timestamp.toString()).set(data);
    return true;
}

//delete message user chat
export const deleteUserChatMessage = async (data) => {
    let docRef = await db.collection(general_chatRoom_live)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            deleteAgent: data.deleted_at
        })
}

//delete for everyone in user chat
export const deleteEveryOneUserChatMessage = async (data) => {
    let docRef = await db.collection(general_chatRoom_live)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            delete: data.deleted_at,
            deleteAgent: data.deleted_at
        })
}

//read all users messages
export const readUserChatMessage = async (data) => {
    let docRef = await db.collection(general_chatRoom_live)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        //.doc(data.message_id.toString())
        .where('idFrom', '==', data.customer_id.toString())
        .get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                doc.ref.update({
                    isread: true
                });
            });
        });
}

//Update last message on user threads
export const updateUserChatLastMessage = async (userId, activeThread, message) => {
    await db.collection(general_chat_users_live)
        .doc(userId.toString())
        .collection(chat_list)
        .doc(activeThread.toString())
        .update({
            lastChat: message,
            timestamp: new Date().getTime()
        });
    return true;
}

//Check User Online
export const checkUserOnline = async (data, callback) => {
    let userdb = data.orderChat ? user : general_chat_users_live
    await db.collection(userdb).doc(data.user_id).collection(chat_list)
        .where("chatID", "==", data.active_thread.toString()).get().then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let dt = await doc.data();
                callback(dt);
            });
        });
}
