import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ArrowIcon } from "../../components/SvgIcons";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { GetExerciseTypes } from "../../services/exercise_types.service";
import { GetMuscles } from "../../services/muscles.service";
import { GetCategories } from "../../services/categories.service";
import { GetBodyTypes } from "../../services/body_types.service";

import {
  GetExerciseById,
  UpdateExercise,
} from "../../services/exercises.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import FullPageLoader from "../../components/FullPageLoader";

function UpdateExcerciseStep1(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [muscles, setMuscles] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [exerciseType, setExerciseType] = useState([]);
  const [selectedBodyTypes, setSelectedBodyTypes] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedExerciseTypes, setSelectedExerciseTypes] = useState([]);
  const [editSelectedBodyTypes, setEditSelectedBodyTypes] = useState([]);
  const [editSelectedCategories, setEditSelectedCategories] = useState([]);
  const [editSelectedExerciseTypes, setEditSelectedExerciseTypes] = useState(
    []
  );
  const [selectedMuscles, setSelectedMuscles] = useState([]);
  const [editSelectedMuscles, setEditSelectedMuscles] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState();
  const [buttonType, setButtonType] = useState("");

  const [levels, setLevels] = useState([
    { name: "Level 0", id: 4 },
    { name: "Level 1", id: 1 },
    { name: "Level 2", id: 2 },
    { name: "Level 3", id: 3 },
  ]);

  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const handleClick = () => {
    history.push("/exercises");
  };

  useEffect(async () => {
    let id = props.match.params.id;
    await getExerciseById(id);

    await bodyTypesList();
    await getSubCategories();
    await getExerciseTypes();
    await getMuscles();
  }, []);

  const selectedLevelOpt = (selectedValue) => {
    setSelectedLevels(selectedValue);
  };

  const getExerciseById = async (id) => {
    await GetExerciseById(id).then((result) => {
      if (result.status) {
        if (result.data) {
          setData(result.data);
          setValue("name", result.data.name);
          if (result.data.is_compilation == 0) {
            var filteredLevel = levels.filter(
              (level) => level?.id == result.data?.level_id
            );
            setSelectedLevels({
              value: filteredLevel[0]?.id,
              label: filteredLevel[0]?.name,
            });
          }

          let selectedBodyTypes = [];
          if (result.data.exerciseBodyTypes) {
            result.data.exerciseBodyTypes.map((item) => {
              selectedBodyTypes.push({ value: item.id, label: item.name });
            });
            setEditSelectedBodyTypes(selectedBodyTypes);
          }

          let selectedCategories = [];
          if (result.data.exerciseCategories) {
            let arr = [];
            result.data.exerciseCategories.map((item) => {
              selectedCategories.push({ value: item.id, label: item.name });
              arr.push(item.id);
            });
            setEditSelectedCategories(selectedCategories);
            setSelectedSubCategories(arr);
          }

          let selectedExerciseTypes = [];
          if (result.data.exerciseTypes) {
            result.data.exerciseTypes.map((item) => {
              selectedExerciseTypes.push({ value: item.id, label: item.name });
            });
            setEditSelectedExerciseTypes(selectedExerciseTypes);
          }

          let selectedMuscles = [];
          if (result.data.exerciseMuscles) {
            result.data.exerciseMuscles.map((item) => {
              selectedMuscles.push({ value: item.id, label: item.name });
            });
            setEditSelectedMuscles(selectedMuscles);
          }
        }
      }
    });
  };

  const getExerciseTypes = async () => {
    setLoader(true);
    await GetExerciseTypes("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setExerciseType(result.data);
      }
    });
  };

  const getMuscles = async () => {
    setLoader(true);
    await GetMuscles("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setMuscles(result.data);
        setLoader(false);
      }
    });
  };

  const getSubCategories = async () => {
    setLoader(true);
    await GetCategories("", "", "", 0, "", "", 0, "asc", "name").then(
      (result) => {
        if (result.status && result.data) {
          setSubCategories(result.data);
        }
      }
    );
  };

  const bodyTypesList = async () => {
    setLoader(true);
    await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setBodyTypes(result.data);
      }
    });
  };

  const selectedBodyTypesOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedBodyTypes(data);
    setEditSelectedBodyTypes(selectedValue);
  };

  const selectedSubCategoryOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedSubCategories(data);
    setEditSelectedCategories(selectedValue);
  };

  const selectedExerciseTypeOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedExerciseTypes(data);
    setEditSelectedExerciseTypes(selectedValue);
  };

  const selectedMusclesOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedMuscles(data);
    setEditSelectedMuscles(selectedValue);
  };

  const onSubmit = async (data) => {
    let id = props.match.params.id;
    setDisableButton(true);
    setLoader(true);

    let dt = {
      name: data.name,
      body_types: selectedBodyTypes,
      exercise_categories: selectedSubCategories,
      exercise_types: selectedExerciseTypes,
      exercise_muscles: selectedMuscles,
      level_id: selectedLevels?.value,
    };
    await UpdateExercise(id, dt)
      .then(async (data) => {
        if (data.status) {
          if (data.data.is_compilation == 0) {
            if (buttonType == "save") {
              history.push("/exercises");
            } else {
              history.push({
                pathname: "/add-exercise-2",
                state: {
                  exercise: data.data,
                },
              });
            }
          } else {
            if (buttonType == "save") {
              history.push("/exercises");
            } else {
              history.push({
                pathname: "/add-exercise-steps",
                state: {
                  exercise: data.data,
                },
              });
            }
          }
        } else {
          setDisableButton(false);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          setDisableButton(false);
          setLoader(false);
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const redirectToSummary = () => {
    let id = props.match.params.id;
    history.push({
      pathname: "/edit-exercise-steps/" + id,
      state: {
        exercise: data,
        redirectToSummary: true,
      },
    });
  };
  return (
    <div className="AddNewExerciseForm">
      <div className="page-title">
        <h1>Add Exercise</h1>
        <Button onClick={handleClick} className="common-btn">
          Back
        </Button>
      </div>
      <div className="common-form">
        {loader ? (
          <FullPageLoader loading={loader} />
        ) : (
          <Form
            className="formAddUser"
            id="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="exercise_name">
                  <Form.Label id={"formExerciseName"}>Name*</Form.Label>
                  <Form.Control
                    placeholder="Name"
                    id={"formExerciseName"}
                    // value={data.name}
                    {...register("name", {
                      required: {
                        value: "required",
                        message: "name is required",
                      },
                      minLength: {
                        value: 2,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 80,
                        message: "Max length is 80",
                      },
                    })}
                    type="text"
                  />
                  {errors.name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className="mb-3" controlId="bodyType">
                  <Form.Label className="d-block">Body Types</Form.Label>
                  <Select
                    value={editSelectedBodyTypes}
                    isMulti
                    options={
                      bodyTypes &&
                      bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedBodyTypesOpt}
                    // required={true}
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className="mb-3" controlId="subcategory">
                  <Form.Label>Sub Category</Form.Label>
                  <Select
                    value={editSelectedCategories}
                    isMulti
                    options={
                      subCategories &&
                      subCategories.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedSubCategoryOpt}
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className="mb-3" controlId="exerciseType">
                  <Form.Label>Exercise Type</Form.Label>
                  <Select
                    value={editSelectedExerciseTypes}
                    isMulti
                    options={
                      exerciseType &&
                      exerciseType.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedExerciseTypeOpt}
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className="mb-3" controlId="muscle">
                  <Form.Label>Muscles</Form.Label>
                  <Select
                    value={editSelectedMuscles}
                    isMulti
                    options={
                      muscles &&
                      muscles.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedMusclesOpt}
                  />
                </Form.Group>
              </Col>
              {data.is_compilation == 0 ? (
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="levels">
                    <Form.Label id={"formLevel"}>Levels</Form.Label>
                    <Select
                      id={"formLevel"}
                      value={selectedLevels}
                      options={
                        levels &&
                        levels.map((e) => ({ label: e.name, value: e.id }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={selectedLevelOpt}
                    />
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
            </Row>
            {loader ? (
              <FullPageLoader loading={loader} />
            ) : (
              <Row>
                <Col sm={1} className="ms-auto">
                  <>
                    <Button
                      className={"btn common-btn w-100 mt-3 px-0"}
                      type={"submit"}
                      name={"next"}
                      onClick={() => setButtonType("next")}
                    >
                      Next
                    </Button>
                  </>
                </Col>
                <Col md={1}>
                  <Button
                    className={"btn common-btn w-100 mt-3 px-0"}
                    type={"submit"}
                    name={"next"}
                    onClick={() => setButtonType("save")}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </div>
    </div>
  );
}

export default UpdateExcerciseStep1;
