import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";

import {
  UpdateWeightSettings,
  GetWeightSettings,
} from "../../services/admin/dashboard.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function Settings() {
  const history = useHistory();
  const [weight, setWeight] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [disableButton, setDisableButton] = useState(false);

  const onSubmit = async (data) => {
    setDisableButton(true);
    await UpdateWeightSettings(1, weight)
      .then((data) => {
        if (data.status) {
          toast.success(data.message);
          reset();
          setDisableButton(false);
        } else {
          setDisableButton(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  useEffect(async () => {
    await getWeightSettings();
  }, []);

  const getWeightSettings = async () => {
    await GetWeightSettings(1).then((result) => {
      console.log("result", result);
      if (result.status && result.data) {
        setWeight(result.data.weight);
      }
    });
  };

  return (
    <div className="dashboard">
      <div className="change-password">
        {/*<BreadcrumbsComponent />*/}
        <h1>Settings</h1>
        <div className="common-form">
          <Form className={"form-add-user"} onSubmit={handleSubmit(onSubmit)}>
            <div className={"pass-field mb-3"}>
              <Form.Label>Weight</Form.Label>
              <InputGroup controlId="Weight">
                <FormControl
                  placeholder="Enter Weight"
                  autoComplete={"off"}
                  {...register("weight", {
                    required: {
                      value: "required",
                      message: "Weight is required",
                    },
                  })}
                  type={"text"}
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </InputGroup>
              {errors.weight && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.password.message}
                </Form.Text>
              )}
            </div>

            <Button
              type={"submit"}
              disabled={disableButton}
              className={"btn common-btn mt-3"}
              value={"Update"}
            >
              Update
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Settings;
