import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import ProgramDetails from "../program/ProgramDetails";
import { Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import StepButton from "@mui/material/StepButton";
import ProgramSummary from "./ProgramSummary";
import ThemeModal from "../../components/ThemeModal";
const steps = [
  "Day 1",
  "Day 2",
  "Day 3",
  "Day 4",
  "Day 5",
  "Day 6",
  "Day 7",
  "Summary",
];

export default function AddProgram() {
  const location = useLocation();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [program, setProgram] = useState();
  const [changed, setChanged] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [handleNextStep, setHandleNextStep] = useState(null);
  const [nextStep, setNextStep] = useState(null);

  if (location.state !== undefined && program === undefined) {
    setProgram(location.state.program);
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    //console.log("prevActiveStep", prevActiveStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    if (changed == true) {
      setNextStep(step);
      modalShowFunc(step);
    } else {
      setActiveStep(step);
    }
  };

  const modalShowFunc = (step) => {
    setHandleNextStep(step);
    setModalShow(true);
  };

  const modalHideFunc = () => {
    setHandleNextStep(null);
    setModalShow(false);
  };

  const discardChanges = () => {
    setModalShow(false);
    setChanged(false);
    setActiveStep(handleNextStep);
  };

  const saveChanges = () => {
    setModalShow(false);
    setChanged(false);
    document.getElementById("formProgramExerciseSubmitBtn").click();
  };

  // useEffect(() => {
  //   const body = document.querySelector('#root');
  //   body.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //   }, 2000)
  // }, [activeStep])

  useEffect(() => {
    setTimeout(() => {
      const topElement = document.querySelector(".create-program-heading"); // Replace 'top-element' with the actual ID of your top element
      if (topElement) {
        topElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
        });
      }
    }, 2000);
  }, []);

  const SaveChanges = ({ content }) => {
    return (
      <div className={"modalFeelItHere"}>
        <div className={"mt-3"}>
          <Row>
            <Col md={6} className={"text-start"}>
              <Button
                className="back-btn"
                color="inherit"
                type={"button"}
                sx={{ mr: 1 }}
                onClick={() => discardChanges()}
              >
                No
              </Button>
            </Col>
            <Col md={{ span: 2, offset: 4 }} className={"text-end"}>
              <Button
                className="next-btn"
                type={"submit"}
                onClick={() => saveChanges()}
              >
                Yes
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (changed) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    const unblock = history.block((location) => {
      if (changed) {
        setModalShow(true); // Show the custom modal
        return false; // Prevent the location change
      }
      return true; // Allow the location change
    });

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      unblock(); // Release the block when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history, changed]);

  return (
    <>
      <Box className="program-index">
        <div className="page-title mb-1">
          <div className="d-flex align-items-center">
            <h1>Manage Program</h1>
            <div className="angela-text">
              ({program ? program.name + "'s Page" : ""})
            </div>
          </div>
          {activeStep === 0 ? (
            <Button
              href={`/edit-program/${program?.id}`}
              className="common-btn"
            >
              Back
            </Button>
          ) : (
            <Button
              className="common-btn"
              color="inherit"
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          )}
        </div>

        {/*<Col md={2}>*/}
        {/*  <input*/}
        {/*    type={"submit"}*/}
        {/*    className={"btn new-btn common-btn w-100 mt-3"}*/}
        {/*    value={"Clear Template"}*/}
        {/*  />*/}
        {/*</Col>*/}
        {/*<Col md={2}>*/}
        {/*  <input*/}
        {/*    type={"submit"}*/}
        {/*    className={"btn new-btn common-btn w-100 mt-3"}*/}
        {/*    value={"Paste Template"}*/}
        {/*  />*/}
        {/*</Col>*/}
        {/*<Col md={2}>*/}
        {/*  <input*/}
        {/*    type={"submit"}*/}
        {/*    className={"btn new-btn common-btn w-100 mt-3"}*/}
        {/*    value={"Copy Template"}*/}
        {/*  />*/}
        {/*</Col>*/}
        <Row>
          <Col md={12}>
            <Stepper activeStep={activeStep} nonLinear>
              {steps.map((label, key) => (
                <Step key={key}>
                  <StepButton color="inherit" onClick={handleStep(key)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Col>
        </Row>

        {activeStep === 0 ? (
          <ProgramDetails
            handleNext={handleNext}
            handleBack={handleBack}
            handleStep={setActiveStep}
            nextStep={nextStep}
            setNextStepFunc={setNextStep}
            activeStep={activeStep}
            day="Mon"
            dayId={1}
            changed={setChanged}
            isChanging={changed}
          />
        ) : activeStep === 1 ? (
          <ProgramDetails
            handleNext={handleNext}
            handleBack={handleBack}
            handleStep={setActiveStep}
            nextStep={nextStep}
            setNextStepFunc={setNextStep}
            activeStep={activeStep}
            day="Tue"
            dayId={2}
            changed={setChanged}
            isChanging={changed}
          />
        ) : activeStep === 2 ? (
          <ProgramDetails
            handleNext={handleNext}
            handleBack={handleBack}
            handleStep={setActiveStep}
            nextStep={nextStep}
            setNextStepFunc={setNextStep}
            activeStep={activeStep}
            day="Wed"
            dayId={3}
            changed={setChanged}
            isChanging={changed}
          />
        ) : activeStep === 3 ? (
          <ProgramDetails
            handleNext={handleNext}
            handleBack={handleBack}
            handleStep={setActiveStep}
            nextStep={nextStep}
            setNextStepFunc={setNextStep}
            activeStep={activeStep}
            day="Thu"
            dayId={4}
            changed={setChanged}
            isChanging={changed}
          />
        ) : activeStep === 4 ? (
          <ProgramDetails
            handleNext={handleNext}
            handleBack={handleBack}
            handleStep={setActiveStep}
            nextStep={nextStep}
            setNextStepFunc={setNextStep}
            activeStep={activeStep}
            day="Fri"
            dayId={5}
            changed={setChanged}
            isChanging={changed}
          />
        ) : activeStep === 5 ? (
          <ProgramDetails
            handleNext={handleNext}
            handleBack={handleBack}
            handleStep={setActiveStep}
            nextStep={nextStep}
            setNextStepFunc={setNextStep}
            activeStep={activeStep}
            day="Sat"
            dayId={6}
            changed={setChanged}
            isChanging={changed}
          />
        ) : activeStep === 6 ? (
          <ProgramDetails
            handleNext={handleNext}
            handleBack={handleBack}
            handleStep={setActiveStep}
            nextStep={nextStep}
            setNextStepFunc={setNextStep}
            activeStep={activeStep}
            day="Sun"
            dayId={7}
            changed={setChanged}
            isChanging={changed}
          />
        ) : (
          <ProgramSummary changed={setChanged} />
        )}

        {/* Buttons of next and finish and back */}

        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&acos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              className="back-btn"
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Button
              className="back-btn draft-btn"
              color="inherit"
              sx={{ mr: 1 }}
            >
              Save as draft
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button
                className="d-none"
                color="inherit"
                onClick={handleSkip}
                sx={{ mr: 1 }}
              >
                Skip
              </Button>
            )}

            <Button className="next-btn" type={"Submit"} onClick={validateForm}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box> */}
          </React.Fragment>
        )}
      </Box>
      <ThemeModal
        title={"Save Changes?"}
        content={<SaveChanges />}
        size={"md"}
        show={modalShow}
        onHide={() => {
          modalHideFunc();
        }}
      />
    </>
  );
}
