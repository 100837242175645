import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ArrowIcon } from "../../components/SvgIcons";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { GetExerciseTypes } from "../../services/exercise_types.service";
import { GetMuscles } from "../../services/muscles.service";
import { GetCategories } from "../../services/categories.service";
import { GetBodyTypes } from "../../services/body_types.service";
import { REACT_APP_MONDAY_ACCESS_TOKEN } from "../../utils/constants";
import {
  GetExerciseById,
  UpdateExercise,
  GetExerciseByPulseId,
} from "../../services/exercises.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import FullPageLoader from "../../components/FullPageLoader";
import { Switch } from "@mui/material";

function EditExercise(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [muscles, setMuscles] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [exerciseType, setExerciseType] = useState([]);
  const [selectedBodyTypes, setSelectedBodyTypes] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedExerciseTypes, setSelectedExerciseTypes] = useState([]);
  const [editSelectedBodyTypes, setEditSelectedBodyTypes] = useState([]);
  const [editSelectedCategories, setEditSelectedCategories] = useState([]);
  const [editSelectedExerciseTypes, setEditSelectedExerciseTypes] = useState(
    []
  );
  const [selectedMuscles, setSelectedMuscles] = useState([]);
  const [editSelectedMuscles, setEditSelectedMuscles] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState();
  const [buttonType, setButtonType] = useState("");

  const [selectedWeights, setSelectedWeights] = useState(0);

  const [isCompilation, setIsCompilation] = useState(false);

  const [levels, setLevels] = useState([
    { name: "Level 0", id: 4 },
    { name: "Level 1", id: 1 },
    { name: "Level 2", id: 2 },
    { name: "Level 3", id: 3 },
  ]);

  const [weightScale, setWeightScale] = useState([
    { name: "Precision", id: 1, prefix: "P" },
    { name: "Heavy", id: 2, prefix: "H" },
    { name: "Body Weight", id: 3, prefix: "B" },
  ]);

  const [selectedIntensity, setSelectedIntensity] = useState(3);

  const [filteredBodyTypes, setFilteredBodyTypes] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [filteredExerciseTypes, setFilteredExerciseTypes] = useState([]);
  const [filteredMuscles, setFilteredMuscles] = useState([]);
  const [filteredLevel, setFilteredLevel] = useState([]);

  const [isActive, setIsActive] = useState(0);

  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(async () => {
    let id = props.match.params.id;
    await getExerciseById(id);

    await bodyTypesList();
    await getSubCategories();
    await getExerciseTypes();
    await getMuscles();
  }, []);

  const selectedWeightOpt = (selectedValue) => {
    setSelectedWeights(selectedValue);
  };

  const selectedLevelOpt = (selectedValue) => {
    setFilteredLevel(selectedValue.value);

    setSelectedLevels(selectedValue);
  };

  const handleToggle = async () => {
    setIsActive(!isActive);
  };

  const getExerciseById = async (id) => {
    await GetExerciseById(id).then((result) => {
      if (result.status) {
        if (result.data) {
          setData(result.data);
          setIsCompilation(result.data.is_compilation);

          setValue("name", result.data.name);
          if (result.data.is_compilation == 0) {
            var filteredLevel = levels.filter(
              (level) => level?.id == result.data?.level_id
            );

            setSelectedLevels({
              value: filteredLevel[0]?.id,
              label: filteredLevel[0]?.name,
            });

            setSelectedIntensity(result.data?.weight_scale);
          }

          let selectedBodyTypes = [];
          if (result.data.exerciseBodyTypes) {
            let arr = [];
            result.data.exerciseBodyTypes.map((item) => {
              selectedBodyTypes.push({ value: item.id, label: item.name });
              arr.push(item.id);
            });
            setEditSelectedBodyTypes(selectedBodyTypes);
            setSelectedBodyTypes(arr);
          }

          let selectedCategories = [];
          if (result.data.exerciseCategories) {
            let arr = [];
            result.data.exerciseCategories.map((item) => {
              selectedCategories.push({ value: item.id, label: item.name });
              arr.push(item.id);
            });
            setEditSelectedCategories(selectedCategories);
            setSelectedSubCategories(arr);
          }

          let selectedExerciseTypes = [];
          if (result.data.exerciseTypes) {
            let arr = [];
            result.data.exerciseTypes.map((item) => {
              selectedExerciseTypes.push({ value: item.id, label: item.name });
              arr.push(item.id);
            });
            setEditSelectedExerciseTypes(selectedExerciseTypes);
            setSelectedExerciseTypes(arr);
          }

          let selectedMuscles = [];
          if (result.data.exerciseMuscles) {
            let arr = [];
            result.data.exerciseMuscles.map((item) => {
              selectedMuscles.push({ value: item.id, label: item.name });
              arr.push(item.id);
            });
            setEditSelectedMuscles(selectedMuscles);
            setSelectedMuscles(arr);
          }
        }
      }
    });
  };

  const getExerciseTypes = async () => {
    setLoader(true);
    await GetExerciseTypes("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setExerciseType(result.data);
      }
    });
  };

  const getMuscles = async () => {
    setLoader(true);
    await GetMuscles("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setMuscles(result.data);
        setLoader(false);
      }
    });
  };

  const getSubCategories = async () => {
    setLoader(true);
    await GetCategories("", "", "", 0, "", "", 0, "asc", "name").then(
      (result) => {
        if (result.status && result.data) {
          setSubCategories(result.data);
        }
      }
    );
  };

  const bodyTypesList = async () => {
    setLoader(true);
    await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setBodyTypes(result.data);
      }
    });
  };

  const selectedBodyTypesOpt = (selectedValue) => {
    setFilteredBodyTypes(selectedValue);
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedBodyTypes(data);
    setEditSelectedBodyTypes(selectedValue);
  };

  const selectedSubCategoryOpt = (selectedValue) => {
    setFilteredSubCategories(selectedValue);
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedSubCategories(data);
    setEditSelectedCategories(selectedValue);
  };

  const selectedExerciseTypeOpt = (selectedValue) => {
    setFilteredExerciseTypes(selectedValue);
    let data = [];
    // selectedValue.map((item) => {
    //   data.push(item.value);
    // });
    data[0] = selectedValue.value;
    setSelectedExerciseTypes(data);
    setEditSelectedExerciseTypes(selectedValue);
  };

  const selectedMusclesOpt = (selectedValue) => {
    setFilteredMuscles(selectedValue);
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedMuscles(data);
    setEditSelectedMuscles(selectedValue);
  };

  const getDropdownOptions = async (boardId, columnId) => {
    let query = `{
      boards(ids: ${boardId}) {
        columns {
          id
          title
          settings_str
        }
      }
    }`;

    let res = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: REACT_APP_MONDAY_ACCESS_TOKEN,
      },
      body: JSON.stringify({
        query: query,
      }),
    });

    let responseBody = await res.json();
    let columns = responseBody?.data?.boards[0]?.columns;
    let dropdownOptions = {};

    columns.forEach((column) => {
      if (column.id === columnId) {
        let settings = JSON.parse(column.settings_str);
        dropdownOptions = settings.labels;
      }
    });

    return dropdownOptions;
  };

  const newExerciseLevel = async (level) => {
    let levelText;
    switch (level) {
      case 1:
        levelText = "0";
        break;

      case 2:
        levelText = "1";
        break;

      case 3:
        levelText = "2";
        break;

      default:
        levelText = "0";
        break;
    }
    return levelText;
  };
  const onSubmit = async (data) => {
    let id = props.match.params.id;

    setDisableButton(true);
    setLoader(true);
    let name = data.name;
    let dt = {
      name: data.name,
      body_types: selectedBodyTypes,
      exercise_categories: selectedSubCategories,
      exercise_types: selectedExerciseTypes,
      exercise_muscles: selectedMuscles,
      level_id: selectedLevels?.value,
      weight_scale: selectedIntensity,
    };
    await UpdateExercise(id, dt)
      .then(async (data) => {
        if (data.status) {
          setLoader(false);
          let maleObj = {};

          maleObj.name = name;

          if (filteredLevel) {
            let levelLatest = await newExerciseLevel(filteredLevel);
            maleObj.label__1 = levelLatest;
          }

          if (filteredBodyTypes.length) {
            // Body type
            let bodyTypeApiValue = await getDropdownOptions(
              7278133312,
              "dropdown4__1"
            );

            let joinedBodyType = filteredBodyTypes.map(
              (item) => `${item.label}-${item.value}`
            );

            const matchesBodyType = bodyTypeApiValue.filter((item2) =>
              joinedBodyType.some((item1) => item1 == item2.name)
            );

            const bodyTypeIds = matchesBodyType.map((item) => item.id);

            maleObj.dropdown4__1 = { ids: bodyTypeIds };
          }

          if (filteredSubCategories.length) {
            // Sub category
            let subCategoryApiValue = await getDropdownOptions(
              7278133312,
              "dup__of_dropdown__1"
            );

            let joinedSubCategory = filteredSubCategories.map(
              (item) => `${item.label}-${item.value}`
            );

            const matchesSubCategory = subCategoryApiValue.filter((item2) =>
              joinedSubCategory.some((item1) => item1 == item2.name)
            );

            const subCategoryIds = matchesSubCategory.map((item) => item.id);
            maleObj.dup__of_dropdown__1 = { ids: subCategoryIds };
          }
          if (filteredMuscles.length) {
            // Muscles
            let subMusclesApiValue = await getDropdownOptions(
              7278133312,
              "dup__of_sub_category__1"
            );

            let joinedMuscle = filteredMuscles.map(
              (item) => `${item.label}-${item.value}`
            );

            const matchesMuscle = subMusclesApiValue.filter((item2) =>
              joinedMuscle.some((item1) => item1 == item2.name)
            );

            const muscleIds = matchesMuscle.map((item) => item.id);
            maleObj.dup__of_sub_category__1 = { ids: muscleIds };
          }

          // if (editSelectedExerciseTypes.length > 0) {
          //     // Exercise Type
          //     let joinedExerciseType = `${editSelectedExerciseTypes[0].label}-${editSelectedExerciseTypes[0].value}`;

          //     maleObj.label5__1 = joinedExerciseType;
          // }

          if (
            (Array.isArray(editSelectedExerciseTypes) &&
              editSelectedExerciseTypes.length > 0) ||
            (typeof editSelectedExerciseTypes === "object" &&
              editSelectedExerciseTypes !== null)
          ) {
            let joinedExerciseType = "";

            // If it's an array, join the first item's label and value
            if (Array.isArray(editSelectedExerciseTypes)) {
              joinedExerciseType = `${editSelectedExerciseTypes[0].label}-${editSelectedExerciseTypes[0].value}`;
            }
            // If it's a single object, directly join its label and value
            else if (
              typeof editSelectedExerciseTypes === "object" &&
              editSelectedExerciseTypes !== null
            ) {
              joinedExerciseType = `${editSelectedExerciseTypes.label}-${editSelectedExerciseTypes.value}`;
            }

            // Assign to maleObj
            maleObj.label5__1 = joinedExerciseType;

            console.log("Updated maleObj:", maleObj);
          }

          let isCompilationType = isCompilation === 0 ? 2 : 1;
          maleObj.dropdown2__1 = { ids: [isCompilationType] };

          // let weightScaleText = "";
          // if (selectedIntensity == 1) {
          //   weightScaleText = "Precision";
          // } else if (selectedIntensity == 2) {
          //   weightScaleText = "Heavy";
          // } else if (selectedIntensity == 3) {
          //   weightScaleText = "Body Weight";
          // }
          // maleObj.text66__1 = weightScaleText;
          maleObj.dropdown0__1 = { ids: [selectedIntensity] };
          let columnValues = JSON.stringify(maleObj)
            .replace(/\\/g, "\\\\")
            .replace(/"/g, '\\"');

          var queryExercise = `mutation {
              change_multiple_column_values (
                item_id: ${data.data.pulse_id},
                board_id: 7278133312,
                 column_values: "${columnValues}"
              ) {
                id
              }
            }`;

          let res = await fetch("https://api.monday.com/v2", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: REACT_APP_MONDAY_ACCESS_TOKEN,
            },
            body: JSON.stringify({
              query: queryExercise,
            }),
          });

          if (data.data.is_compilation == 0) {
            if (buttonType == "save") {
              history.push("/exercises");
            } else {
              history.push({
                pathname: "/edit-exercise-steps/" + id,
                state: {
                  exercise: data.data,
                },
              });
            }
          } else {
            if (buttonType == "save") {
              history.push("/exercises");
            } else {
              history.push({
                pathname: "/edit-compilation-steps/" + id,
                state: {
                  exercise: data.data,
                },
              });
            }
          }
        } else {
          setDisableButton(false);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          setDisableButton(false);
          setLoader(false);
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  // Function to handle selection
  const handleSelectIntensity = (event) => {
    setSelectedIntensity(event);
  };

  const redirectToSummary = () => {
    let id = props.match.params.id;
    history.push({
      pathname: "/edit-exercise-steps/" + id,
      state: {
        exercise: data,
        redirectToSummary: true,
      },
    });
  };

  const BackToExercise = () => {
    history.push("/exercises");
  };

  return loader ? (
    <FullPageLoader loading={loader} />
  ) : (
    <div className="add-new-exercise">
      <div className="page-title">
        <h1>Edit Exercise - {data?.name}</h1>
        <Button onClick={BackToExercise} className="common-btn">
          Back
        </Button>
      </div>
      <div className="common-form">
        <Form
          className="formAddUser"
          id="formAddUser"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="exercise_name">
                <Form.Label id={"formExerciseName"}>Name*</Form.Label>
                <Form.Control
                  placeholder="Name"
                  id={"formExerciseName"}
                  // value={data.name}
                  {...register("name", {
                    required: {
                      value: "required",
                      message: "name is required",
                    },
                    minLength: {
                      value: 2,
                      message: "Min length is 3",
                    },
                    maxLength: {
                      value: 80,
                      message: "Max length is 80",
                    },
                  })}
                  type="text"
                />
                {errors.name && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="bodyType">
                <Form.Label>Body Types</Form.Label>
                <Select
                  value={editSelectedBodyTypes}
                  isMulti
                  options={
                    bodyTypes &&
                    bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selectedBodyTypesOpt}
                  // required={true}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="subcategory">
                <Form.Label>Sub Category</Form.Label>
                <Select
                  value={editSelectedCategories}
                  isMulti
                  options={
                    subCategories &&
                    subCategories.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selectedSubCategoryOpt}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="exerciseType">
                <Form.Label>Exercise Type</Form.Label>
                <Select
                  value={editSelectedExerciseTypes}
                  // isMulti
                  options={
                    exerciseType &&
                    exerciseType.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selectedExerciseTypeOpt}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="muscle">
                <Form.Label>Muscles</Form.Label>
                <Select
                  value={editSelectedMuscles}
                  isMulti
                  options={
                    muscles &&
                    muscles.map((e) => ({ label: e.name, value: e.id }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selectedMusclesOpt}
                />
              </Form.Group>
            </Col>
            {data.is_compilation == 0 ? (
              <Col md={4}>
                <Form.Group className="mb-3" controlId="levels">
                  <Form.Label id={"formLevel"}>Levels</Form.Label>
                  <Select
                    id={"formLevel"}
                    value={selectedLevels}
                    options={
                      levels &&
                      levels.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedLevelOpt}
                  />
                </Form.Group>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="common-select" controlId="weight_scale">
                <Form.Label id={"formWeight"}>Weight Scale</Form.Label>
                <div className="d-flex flex-wrap">
                  {weightScale.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="d-flex flex-column align-items-center mb-3"
                      >
                        <Button
                          variant={
                            selectedIntensity === item.id
                              ? "primary"
                              : "outline-dark"
                          }
                          // className={`btn ${
                          //   selectedIntensity === item.id
                          //     ? "btn-primary"
                          //     : "btn-success"
                          // }`}
                          onClick={() => handleSelectIntensity(item.id)}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            // color: "white",
                          }}
                        >
                          {item.prefix}
                        </Button>
                        <p
                          className="mt-2 text-center"
                          style={{
                            fontSize: "12px",
                            width: "90px",
                            wordWrap: "break-word",
                          }}
                        >
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xl={2} className="ms-auto d-flex">
              {!loader ? (
                <>
                  <Button
                    className={"common-btn w-100 mt-3 px-0"}
                    type={"submit"}
                    name={"next"}
                    onClick={() => setButtonType("next")}
                  >
                    Next
                  </Button>
                  {/* </>
              ) : null}
            </Col>
            <Col xl={2} xxl={1}>
              {!loader ? (
                <> */}
                  <Button
                    className={"common-btn w-100 mt-3 px-0 ms-2"}
                    type={"submit"}
                    name={"next"}
                    onClick={() => setButtonType("save")}
                  >
                    Save
                  </Button>
                </>
              ) : null}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default EditExercise;
