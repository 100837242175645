import React, { useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import Button from "react-bootstrap/Button";

export const Submenu = ({ items, name, icon }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isInnerSubMenuOpen, setIsInnerSubMenuOpen] = useState(false);

    const match = useRouteMatch({
        path: items.map((item) => item.path),
        exact: true,
    });

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };
    const toggleSubMenu1 = () => {
        setIsInnerSubMenuOpen(!isInnerSubMenuOpen);
    };

    return (
        <li className={`nav-item dropdown ${isSubMenuOpen ? 'show' : ''}`}>
            <span

                className={`nav-link custom-toggle dropdown-toggle ${match ? 'active' : ''}`}
                onClick={toggleSubMenu}
                role="button"
            >
                {icon}
                {name}
            </span>
            <ul className={`dropdown-menu custom-dropdown ${isSubMenuOpen ? 'show' : ''}`}>
                {items.map((item) => (
                    <li key={item.path} className={`nav-item dropdown ${isInnerSubMenuOpen ? 'show' : ''}`}>
                        {
                            item?.subMenuItems.length > 0 ?
                                <span
                                    className={`nav-link custom-toggle dropdown-toggle ${match ? 'active' : ''}`}
                                    onClick={toggleSubMenu1}
                                    role="button"
                                >
                                    {item.icon}
                                    {item.label}
                                </span> : <NavLink
                                    to={item.path}
                                    className="dropdown-item"
                                    onClick={toggleSubMenu1}
                                >
                                    {item.icon}
                                    {item.label}
                                </NavLink>
                        }

                        {
                            item?.subMenuItems.length > 0 ?
                                <>
                                    <ul className={`dropdown-menu custom-dropdown ${isInnerSubMenuOpen ? 'show' : ''}`}>
                                        {
                                            item?.subMenuItems?.map((innerItem) => (
                                                <li key={innerItem.path}>
                                                    <NavLink
                                                        to={innerItem.path}
                                                        className="dropdown-item"
                                                    >
                                                        {innerItem.icon}
                                                        {innerItem.label}
                                                    </NavLink>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </> : ''
                        }

                    </li>
                ))}
            </ul>
        </li>
    );
};