import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';

export const GetBodyParts = async (keyword = '', page = '', perPage = '', is_paginate = 1, orderByValue, orderByColumn) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (orderByValue) {
        params['order'] = orderByValue;
    }

    if (orderByColumn) {
        params['order-column'] = orderByColumn;
    }

    params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.body_parts, params);
    return response;
}

export const CreateBodyPart = async (data) => {
    const response = await POST(apiUrl.body_parts, data);
    return response;
}

export const DeleteBodyPart = async (id) => {
    const response = await DELETE(apiUrl.body_parts, id);
    return response;
}

export const DeleteMultipleBodyParts = async (data) => {
    const response = await POST(apiUrl.deleteBodyParts, data);
    return response;
}

export const GetBodyPartById = async (id) => {
    const response = await GET(apiUrl.body_parts + '/' + id);
    return response;
}

export const UpdateBodyPart = async (id, data) => {
    const response = await PUT(apiUrl.body_parts, id, data);
    return response;
}