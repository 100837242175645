import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';


export const UpdatePrograms = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.programs, id, data, config);
    return response;
}

export const GetPrograms = async (keyword = '', roleId = '', page = '', perPage = '', isPaginate = 1, orderByValue, orderByColumn, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (roleId) {
        params['role_id'] = roleId;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    if (orderByValue) {
        params['order'] = orderByValue;
    }

    if (orderByColumn) {
        params['order-column'] = orderByColumn;
    }

    params['is_paginate'] = isPaginate;

    const response = await GET(apiUrl.programs, params);
    return response;
}

export const GetProgramById = async (id) => {
    let response = '';
    response = await GET(apiUrl.programs + '/' + id);
    return response;
}

export const CreateProgram = async (data) => {
    const response = await POST(apiUrl.programs, data);
    return response;
}

export const UpdateProgram = async (id, data) => {
    const response = await PUT(apiUrl.programs, id, data);
    return response;
}

export const DeleteProgram = async (id) => {
    const response = await DELETE(apiUrl.programs, id);
    return response;
}

export const DeleteMultipleProgram = async (data) => {
    const response = await POST(apiUrl.deleteProgram, data);
    return response;
}

export const DuplicateProgram = async (id, data) => {
    const response = await POST(apiUrl.duplicateProgram + '/' + id, data);
    return response;
}

export const UpdateProgramDays = async (id, data) => {
    const response = await PUT(apiUrl.programDaysUpdate, id, data);
    return response;
}



export const GetProgramDayDetails = async (program_id, day_id) => {
    let response = '';
    response = await GET(apiUrl.programDayDetails + '/' + program_id + '/' + day_id);
    return response;
}

export const GetProgramSummary = async (id, day) => {
    let params = {};
    if (day) {
        params['day'] = day;
    }
    let response = '';
    response = await GET(apiUrl.programSummary + '/' + id, params);
    return response;
}
