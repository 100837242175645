import React, { useEffect, useState } from "react";
import { Link, useHistory, Prompt } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { upload } from "../../utils/helpers";
import { Col, Form, Row, Button } from "react-bootstrap";
import { CreatePainPoints } from "../../services/pain_point_library.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { cross_origin, constants, confirmMessage } from "../../utils/constants";
import ThemeModal from "../../components/ThemeModal";
import S3Browser from "../../components/S3Browser";
import { TrashIcon } from "../../components/SvgIcons";
import Select from "react-select";
import { GetPainPointCategories } from "../../services/pain_point-categories.service";
import FullPageLoader from "../../components/FullPageLoader";
function AddNewPainPoint(props) {
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);

  const [fileName, setFileName] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState("");
  const [title2, setTitle2] = useState("");

  const [videoTitle, setVideoTitle] = useState("");
  const [videoTitle2, setVideoTitle2] = useState("");
  const [videoTitle3, setVideoTitle3] = useState("");
  const [videoTitle4, setVideoTitle4] = useState("");

  const [file, setFileData] = useState(null);
  const [file2, setFileData2] = useState(null);
  const [file3, setFileData3] = useState(null);

  const [img, setImg] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");

  const [fileVideo, setFileVideo] = useState(null);
  const [fileType, setFileType] = useState("");

  const [fileVideo2, setFileVideo2] = useState(false);
  const [fileVideo3, setFileVideo3] = useState(false);
  const [fileVideo4, setFileVideo4] = useState(false);
  const [video, setVideo] = useState("");
  const [video2, setVideo2] = useState("");
  const [video3, setVideo3] = useState("");
  const [video4, setVideo4] = useState("");
  const [defaultImage, setDefaultImage] = useState(false);
  const [isPageDirty, setPageDirty] = useState(false);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [editSelectedCategories, setEditSelectedCategories] = useState([]);
  const [isComplete, setIsComplete] = useState(0);
  const [completeStatus, setCompleteStatus] = useState();
  const [images, setImages] = React.useState([]);
  const [folderName, setfolderName] = useState("6. Trigger Point Library/");
  const maxNumber = 1;

  const statusOptions = [
    { value: "", label: "Select Status", isDisabled: true },
    { value: 1, label: "Complete" },
    { value: 0, label: "Incomplete" },
  ];

  const setCompleteStatusTypeFunc = (selectedValue) => {
    setCompleteStatus(selectedValue);
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    getCategories();
  }, []);

  const selectedMedia = (fileName, file) => {
    console.log("2222200=====>", fileName, file);
    if (fileName == "image") {
      setFileData(file);
      setImg(file);
    } else if (fileName == "image2") {
      setFileData2(file);
      setImg2(file);
    } else if (fileName == "image3") {
      setFileData3(file);
      setImg3(file);
    } else if (fileName == "video") {
      setFileVideo(file);
      setVideo(file);
    } else if (fileName == "video2") {
      setFileVideo2(file);
      setVideo2(file);
    } else if (fileName == "video3") {
      setFileVideo3(file);
      setVideo3(file);
    } else if (fileName == "video4") {
      setFileVideo4(file);
      setVideo4(file);
    }
    clearErrors(fileName);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const onChange = (imageList, addUpdateIndex) => {
    setFileData(null);
    setImg(null);
  };

  const deleteImage = (fileName) => {
    if (fileName == "video") {
      setFileVideo(null);
      setVideo(null);
    } else if (fileName == "video2") {
      setFileVideo2(null);
      setVideo2(null);
    } else if (fileName == "video3") {
      setFileVideo3(null);
      setVideo3(null);
    } else {
      setFileVideo4(null);
      setVideo4(null);
    }
  };

  const resetImage = (type) => {
    if (type == "image") {
      setFileData(null);
      setImg(null);
    } else if (type == "image2") {
      setFileData2(null);
      setImg2(null);
    } else {
      setFileData3(null);
      setImg3(null);
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setPageDirty(true);
  };

  const handleTitle2Change = (event) => {
    setTitle2(event.target.value);
    setPageDirty(true);
  };

  const handleVideoTitleChange = (event) => {
    setVideoTitle(event.target.value);
    setPageDirty(true);
  };

  const handleVideoTitle2Change = (event) => {
    setVideoTitle2(event.target.value);
    setPageDirty(true);
  };

  const handleVideoTitle3Change = (event) => {
    setVideoTitle3(event.target.value);
    setPageDirty(true);
  };

  const handleVideoTitle4Change = (event) => {
    setVideoTitle4(event.target.value);
    setPageDirty(true);
  };

  const navigationPrompt = async (location) => {
    if (isPageDirty) {
      const userConfirmed = window.confirm(confirmMessage.message);
      if (userConfirmed) {
        let imageUrl = null;
        if (img) {
          let s3Data = await upload(
            img,
            "pain-point-library/images",
            img.extension || "",
            img.type || ""
          );

          imageUrl = s3Data.Location;
        }
        setLoader(true);
        await CreatePainPoints({
          title: title,
          image: imageUrl,
        })
          .then(async (data) => {
            setLoader(false);
            if (data.status) {
              toast.success(data.message);
            } else {
              setLoader(false);
              toast.error(data.message.replace(/_/g, " "));
            }
          })
          .catch((error) => {
            setLoader(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(
                error.response.data.message.replace(/_/g, " ")
              );
            }
          });
      }
      return userConfirmed;
    }
    return true;
  };

  const getCategories = async () => {
    await GetPainPointCategories(keyword, page, perPage, 0, "", "", "")
      .then((result) => {
        if (result.status) {
          if (result.data) {
            setCategories(result.data);
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const onSubmit = async (data) => {
    setPageDirty(false);
    setLoader(true);

    console.log("img", img);

    if (!img) {
      toast.error("Image 1 is required");
      // setError("image", { message: "Image is required" });
      setLoader(false); // Stop loader
      return false;
    }

    if (title === "") {
      toast.error("Title is required");
      // setError("title", { message: "Title is required" });
      setLoader(false); // Stop loader
      return false;
    }

    data.video_title = videoTitle;
    data.video_title2 = videoTitle2;
    data.video_title3 = videoTitle3;
    data.video_title4 = videoTitle4;
    data.title = title;
    data.title2 = title2;
    data.description = description;
    // data.pain_point_category_id = selectedCategories;
    data.pain_point_categories = selectedCategories;
    data.internal_notes = data.internal_notes;
    data.is_complete = isComplete;

    if (img) {
      data.image = img;
    }

    if (img2) {
      data.image2 = img2;
    }

    if (img3) {
      data.image3 = img3;
    }

    if (video) {
      data.video = video;
    }

    if (video2) {
      data.video2 = video2;
    }

    if (video3) {
      data.video3 = video3;
    }
    if (video4) {
      data.video4 = video4;
    }
    console.log("data-->", data);

    await CreatePainPoints(data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/pain-point-library");
        } else {
          setLoader(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
    setImg(event.target.files[0]);
    setPageDirty(true);
  };

  const handleChangeImage2 = (event) => {
    setFileData2(URL.createObjectURL(event.target.files[0]));
    setImg2(event.target.files[0]);
  };

  const handleChangeImage3 = (event) => {
    setFileData3(URL.createObjectURL(event.target.files[0]));
    setImg3(event.target.files[0]);
  };

  const handleChangeVideo = (event) => {
    setFileVideo(URL.createObjectURL(event.target.files[0]));
    setVideo(event.target.files[0]);
    setPageDirty(true);
  };

  const handleChangeVideo2 = (event) => {
    console.log("event->", event.target);
    setFileVideo2(URL.createObjectURL(event.target.files[0]));
    setVideo2(event.target.files[0]);
    setPageDirty(true);
  };
  const selectedCategoryOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedCategories(data);
    setEditSelectedCategories(selectedValue);
  };
  const handleChangeVideo3 = (event) => {
    setFileVideo3(URL.createObjectURL(event.target.files[0]));
    setVideo3(event.target.files[0]);
    setPageDirty(true);
  };
  const handleChangeVideo4 = (event) => {
    setFileVideo4(URL.createObjectURL(event.target.files[0]));
    setVideo4(event.target.files[0]);
    setPageDirty(true);
  };

  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
    ],
  };

  const BackTriggerr = () => {
    history.push("/pain-point-library");
  };

  return (
    <div className="trigger-Point-index">
      <section className="add-trigger-Point">
        <Prompt when={isPageDirty} message={navigationPrompt} />
        <div className="page-title">
          <h1>Add New Trigger Point</h1>
          <Button onClick={BackTriggerr} className={"btn common-btn"}>
            Back
          </Button>
        </div>
        <div className="common-form">
          <Form
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md={3}>
                <Form.Group className="common-input" controlId="categories">
                  <Form.Label>Category</Form.Label>
                  <Select
                    value={editSelectedCategories}
                    isMulti
                    options={
                      categories &&
                      categories.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedCategoryOpt}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="common-input" controlId="title">
                  <Form.Label>Title 1*</Form.Label>
                  <Form.Control
                    // placeholder="Enter Title"
                    // {...register("title", {})}
                    // type="text"

                    placeholder="Enter Title"
                    onChange={handleTitleChange}
                    value={title}
                    type="text"
                  />
                  {errors.title && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.title.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="common-input" controlId="title">
                  <Form.Label>Title 2</Form.Label>
                  <Form.Control
                    // placeholder="Enter Title"
                    // {...register("title2", {})}
                    // type="text"
                    placeholder="Enter Title"
                    onChange={handleTitle2Change}
                    value={title2}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="common-input" controlId="title">
                  <Form.Label>Keywords</Form.Label>
                  <Form.Control
                    placeholder="Enter Keyword"
                    type="text"
                    {...register("search_keyword", {
                      // required: {
                      //     value: "required",
                      //     message: "Keyword is required"
                      // }
                    })}
                    aria-label="With textarea"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <ImageUploading
                      className="mb-2"
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            type="button"
                            className="btn common-btn  mb-3"
                            onClick={() => {
                              setFileType("images");
                              setFileName("image");
                              setModalShow(true);
                            }}
                          >
                            Upload Image 1
                          </button>
                          &nbsp;
                          {file ? (
                            <>
                              <div className="image-item">
                                <img
                                  src={file ? file : defaultImage}
                                  className={"img-table img-thumbnail"}
                                  crossOrigin={
                                    cross_origin ? "anonymous" : null
                                  }
                                />
                                <Button
                                  type="button"
                                  className="btn common-btn"
                                  onClick={() => resetImage("image")}
                                >
                                  <TrashIcon />
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div className="third-img"></div>
                          )}
                        </div>
                      )}
                    </ImageUploading>
                  </Col>

                  <Col md={4}>
                    <ImageUploading
                      className="mb-2"
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            type="button"
                            className="btn common-btn  mb-3"
                            onClick={() => {
                              setFileType("images");
                              setFileName("image2");
                              setModalShow(true);
                            }}
                          >
                            Upload Image 2
                          </button>
                          &nbsp;
                          {file2 ? (
                            <>
                              <div className="image-item">
                                <img
                                  src={file2 ? file2 : defaultImage}
                                  className={"img-table img-thumbnail"}
                                  crossOrigin={
                                    cross_origin ? "anonymous" : null
                                  }
                                />
                                <Button
                                  type="button"
                                  className="btn common-btn"
                                  onClick={() => resetImage("image2")}
                                >
                                  <TrashIcon />
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div className="third-img"></div>
                          )}
                        </div>
                      )}
                    </ImageUploading>
                  </Col>
                  <Col md={4}>
                    <ImageUploading
                      className="mb-2"
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            type="button"
                            className="btn common-btn  mb-3"
                            onClick={() => {
                              setFileType("images");
                              setFileName("image3");
                              setModalShow(true);
                            }}
                          >
                            Upload Image 3
                          </button>
                          &nbsp;
                          {file3 ? (
                            <>
                              <div className="image-item">
                                <img
                                  src={file3 ? file3 : defaultImage}
                                  className={"img-table img-thumbnail"}
                                  crossOrigin={
                                    cross_origin ? "anonymous" : null
                                  }
                                />
                                <Button
                                  type="button"
                                  className="btn common-btn"
                                  onClick={() => resetImage("image3")}
                                >
                                  <TrashIcon />
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div className="third-img"></div>
                          )}
                        </div>
                      )}
                    </ImageUploading>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="common-input" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"description"}
                      // {...register("description")}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onChange={(event, editor) => {
                        try {
                          const data = editor.getData();
                          console.log("data", data);
                          setDescription(data);
                          // if (!data) {
                          //     setError('description', { message: 'Description is required' });
                          // } else if (data.length < 10) {
                          //     setError('description', { message: 'Min length is 3' });
                          // } else if (data.length > 1008) {
                          //     setError('description', { message: 'Max length is 1000' });
                          // } else {
                          //     clearErrors('description')
                          // }
                        } catch (error) {
                          console.error("Error in CKEditor onChange:", error);
                        }
                      }}
                    />
                  </div>
                  {errors.description && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.description.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={3}>
                <Form.Group
                  controlId="videoFile"
                  //   onChange={(e) => handleChangeVideo(e)}
                  onClick={() => {
                    setFileType("videos");

                    setFileName("video");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 1
                  </Form.Label>
                </Form.Group>
                {fileVideo ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo}
                        title="Video"
                      ></video>
                      <Button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteImage("video")}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="video-box"></div>
                )}
                <Form.Group className="mt-3" controlId="title">
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitleChange}
                    value={videoTitle}
                  />
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group
                  controlId="videoFile2"
                  //   onChange={(e) => handleChangeVideo2(e)}
                  onClick={() => {
                    setFileType("videos");

                    setFileName("video2");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 2
                  </Form.Label>
                </Form.Group>
                {fileVideo2 ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo2}
                        title="Video"
                      ></video>
                      <Button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteImage("video2")}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="video-box"></div>
                )}
                <Form.Group className="mt-3" controlId="title">
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle2Change}
                    value={videoTitle2}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile3"
                  //   onChange={(e) => handleChangeVideo3(e)}
                  onClick={() => {
                    setFileType("videos");

                    setFileName("video3");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 3
                  </Form.Label>
                </Form.Group>
                {fileVideo3 ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo3}
                        title="Video"
                      ></video>
                      <Button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteImage("video3")}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="video-box"></div>
                )}
                <Form.Group className="mt-3" controlId="title">
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle3Change}
                    value={videoTitle3}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile4"
                  //   onChange={(e) => handleChangeVideo4(e)}
                  onClick={() => {
                    setFileType("videos");

                    setFileName("video4");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 4
                  </Form.Label>
                </Form.Group>
                {fileVideo4 ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo4}
                        title="Video"
                      ></video>
                      <Button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteImage("video4")}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="video-box"></div>
                )}
                <Form.Group className="mt-3" controlId="title">
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle4Change}
                    value={videoTitle4}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Select
                    isClearable
                    id="is_draft"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Status"
                    {...register("is_complete", {
                      onChange: (e) => {
                        setIsComplete(e.target.value);
                      },
                    })}
                    value={statusOptions.find(
                      (option) => option.value === completeStatus?.value
                    )}
                    options={statusOptions}
                    onChange={(selectedOption) =>
                      setCompleteStatusTypeFunc(selectedOption)
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={7}>
                <Form.Group>
                  <Form.Label>Internal Notes</Form.Label>
                  <Form.Control
                    className="exercise-input"
                    placeholder="Internal Notes"
                    as="textarea"
                    id={"internal_notes"}
                    {...register("internal_notes", {
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 500,
                        message: "Max length is 500",
                      },
                    })}
                    type="text"
                  />
                  {errors.internal_notes && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.internal_notes.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={2} className="mt-2">
                {loader ? (
                  <FullPageLoader loading={loader} />
                ) : (
                  <Button
                    type={"submit"}
                    className={"btn common-btn mt-4 w-100"}
                    value={"Create Muscles Anatomy"}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </section>
      <ThemeModal
        title={"S3 Browser"}
        content={
          <S3Browser
            fileName={fileName}
            selectedMedia={selectedMedia}
            fileType={fileType}
            folderName={folderName}
          />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default AddNewPainPoint;
