import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import logoIcon from "../../assets/images/logo-full.svg";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage, Login } from "../../services/auth/auth.service";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FadeLoader } from "react-spinners";

function Signin() {
  const history = useHistory();
  const [pass, TogglePass] = useState(true);
  const [loader, setLoader] = useState(false);
  const errorToastRef = useRef(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setLoader(true);
    await Login(data.email, data.password, "1", "web", "xyz")
      .then((data) => {
        if (data.status) {
          setLoader(false);
          toast.success(data.message);
          history.push("/dashboard");
        } else {
          setLoader(false);
          if (!toast.isActive(errorToastRef.current)) {
            errorToastRef.current = toast.error(
              data.message.replace(/_/g, " ")
            );
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          if (!toast.isActive(errorToastRef.current)) {
            errorToastRef.current = toast.error(
              error.response.data.message.replace(/_/g, " ")
            );
          }
          return;
        }
      });
  };

  return (
    <div className={"text-center authLayout"}>
      <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
        <a href="/">
          <img
            className="img-fluid mb-4"
            src={logoIcon}
            alt="Imprint Logo"
            title="Imprint Logo"
            style={{ padding: "10px 25px" }}
          />
        </a>
        <h1 className="h5 mb-3 font-weight-normal">Sign in to your account</h1>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Email address"
            id={"formSignInEmail"}
            {...register("email", {
              required: {
                value: "required",
                message: "Email is required",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
            type="email"
          />
          {errors.email && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.email.message}
            </span>
          )}

          {/*password*/}
          <div className={"pass-field"}>
            <InputGroup controlId="passowrd">
              <FormControl
                placeholder="Enter Password"
                autoComplete={"off"}
                {...register("password", {
                  required: {
                    value: "required",
                    message: "Password is required",
                  },
                  // minLength: {
                  //     value: 6,
                  //     message: "Min length is 6"
                  // },
                  // maxLength: {
                  //     value: 255,
                  //     message: "Max length is 255"
                  // }
                })}
                type={pass ? "password" : "text"}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => TogglePass(!pass)}
              >
                {pass ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </InputGroup>
            {errors.password && (
              <span className={"validation-error pr-5"} role="alert">
                {errors.password.message}
              </span>
            )}
          </div>
          {/*password*/}
        </Form.Group>

        <ul className={"list-unstyled mt-3 d-block"}>
          <li className={"d-block text-end"}>
            <NavLink to={"/forgot-password"}>Forgot Password</NavLink>
          </li>
        </ul>
        <div className="d-grid gap-2 loader_custom">
          {loader ? (
            <FadeLoader color={"#FFF"} height={10} />
          ) : (
            <Button
              variant="primary"
              size="lg"
              type="submit"
              className={"common-btn text-uppercase"}
            >
              Sign In
            </Button>
          )}
        </div>
        <p className="mt-5 mb-3 text-muted">
          &copy; All rights reserved by Tekrevol 2022
        </p>
      </Form>
    </div>
  );
}

export default Signin;
