import React, { useState, useEffect } from "react";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  DeleteForeverExercise,
  GetTrashedExercises,
  RestoreExercise,
} from "../../services/exercises.service";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { MdDelete } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import { FaTrashRestoreAlt } from "react-icons/fa";
import { Button, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import { SearchIcon } from "../SvgIcons";
import { MenuItem, Pagination, Select, Stack } from "@mui/material";
import FullPageLoader from "../FullPageLoader";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

const CompiledExercise = () => {
  let history = useHistory();
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const [rowsTrashed, setRowsTrashed] = useState([]);
  const [key, setKey] = useState("1");
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [columns, setColumns] = useState([]);
  const [countPage, setPageCount] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [orderByValue, setOrderByValue] = useState("asc");
  const [orderByColumn, setOrderByColumn] = useState("name");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const backRedirect = (data) => {
    history.push("/exercises/");
  };

  useEffect(() => {
    const fetchData = async () => {
      await getTrashedExercises(true);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      if (keyword) {
        await getTrashedExercises(false);
      } else {
        await getTrashedExercises(true);
      }
    };

    fetchData();
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("trashed_compiled", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("trashed_compiled", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getColumns = async () => {
    const obj = [
      {
        field: "name",
        headerName: "Exercise Name",
        width: 200,
        editable: true,
      },
      {
        field: "exerciseType",
        headerName: "Exercise Type",
        width: 200,
        editable: true,
      },

      {
        field: "bodytype",
        headerName: "Body Type",
        width: 200,
        editable: true,
      },
      {
        field: "category",
        headerName: "Sub Category",
        width: 200,
        editable: true,
      },
      {
        field: "equipments",
        headerName: "Equipment",
        width: 200,
        editable: true,
      },
      {
        field: "muscle",
        headerName: "Muscle",
        width: 200,
        editable: true,
      },

      {
        field: "isComplete",
        headerName: "Complete",
        width: 120,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        filterable: false,
        width: 150,
        renderCell: (params) => (
          <>
            <div>
              <button className={"btn btn-primary btn-sm mx-2"}>
                <Tooltip title="Restore" arrow>
                  <span onClick={() => onRestore(params.row)}>
                    <FaTrashRestoreAlt />
                  </span>
                </Tooltip>
              </button>
              <button className={"btn btn-danger btn-sm mx-2"}>
                <Tooltip title="Delete Forever" arrow>
                  <span onClick={() => onDeleteForever(params.row)}>
                    <MdDelete />
                  </span>
                </Tooltip>
              </button>
            </div>
          </>
        ),
      },
    ];
    return obj;
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "trashed_compiled",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("trashed_compiled", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "trashed_compiled",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };

      const result = await ColumnHideShow(requestObj);

      if (result.status && result.data) {
        if (columns?.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        setLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("trashed_compiled", "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => (
                  <>
                    <div>
                      <button className={"btn btn-primary btn-sm mx-2"}>
                        <Tooltip title="Restore" arrow>
                          <span onClick={() => onRestore(params.row)}>
                            <FaTrashRestoreAlt />
                          </span>
                        </Tooltip>
                      </button>
                      <button className={"btn btn-danger btn-sm mx-2"}>
                        <Tooltip title="Delete Forever" arrow>
                          <span onClick={() => onDeleteForever(params.row)}>
                            <MdDelete />
                          </span>
                        </Tooltip>
                      </button>
                    </div>
                  </>
                ),
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "trashed_compiled",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getTrashedExercises = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }

    try {
      const result = await GetTrashedExercises(
        key,
        keyword,
        page,
        perPage,
        1,
        orderByValue,
        orderByColumn
      );

      if (result.data) {
        const rowDataTrashed = [];
        setPage(result?.data?.meta?.current_page);
        setPageCount(result?.data?.meta?.last_page);
        result?.data?.data?.map((dt) => {
          let level = dt.level_id == 4 ? "0" : dt.level_id;
          rowDataTrashed.push({
            id: dt.id,
            name: dt.name + " - L" + level,
            exerciseType: dt.exerciseTypesCSV ? dt.exerciseTypesCSV : "-",
            bodytype: dt.exerciseBodyTypesCSV ? dt.exerciseBodyTypesCSV : "-",
            muscle: dt.exerciseMusclesCSV ? dt.exerciseMusclesCSV : "-",
            category: dt.exerciseCategoriesCSV ? dt.exerciseCategoriesCSV : "-",
            equipments: dt.exerciseEquipmentUsed
              ? dt.exerciseEquipmentUsed
              : "-",
            isComplete: dt.is_complete
              ? dt.is_complete == 1
                ? "Complete"
                : "Incomplete"
              : "Incomplete",
            isCompilation: dt.is_compilation == 1 ? "Compiled" : "Individual",
          });
        });

        setRowsTrashed(rowDataTrashed);
        setLoading(false);
      } else {
        toast.error("Failed to fetch trashed exercises");
      }
    } catch (error) {
      toast.error(
        "An error occurred while fetching trashed exercises: " +
          (error.response?.data?.message || error.message)
      );
    } finally {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
    }
  };

  const onDeleteForever = async (data) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to permanent delete this exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete API
      if (willShip) {
        await DeleteForeverExercise(data.id)
          .then(async (result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });

              getTrashedExercises("1");
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const onRestore = async (data) => {
    await RestoreExercise(data.id)
      .then(async (result) => {
        if (result.status) {
          swal(result.message, {
            icon: "success",
          });

          getTrashedExercises("1");
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <Row>
        <Col md={3}>
          <h1>Trashed Compiled Exercises</h1>
        </Col>
        <Col md={2} className="ms-auto">
          <Button
            className={"btn common-btn w-35"}
            onClick={() => backRedirect()}
          >
            Back Exercise Management
          </Button>
        </Col>
      </Row>
      <div className={"customOrderFilters"}>
        <Row>
          <Col md={1}></Col>
          <Col md={5} className="ms-auto">
            <div className="all-user">
              <div className="search-bar">
                <div className="common-search-bar">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <SearchIcon />
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="Search"
                      onChange={(e) => {
                        e.target.value.length > 0
                          ? setKeyword(e.target.value.trim())
                          : setKeyword(null);
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <section className="common-table mt-2 ">
        <div style={{ height: "100%", width: "100%" }}>
          <StyledDataGrid
            rows={rowsTrashed}
            loading={searchLoading}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={toggleCustomFunc}
            isColumnResizable
            onColumnWidthChange={handleColumnWidthChange}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            unstable_headerFilters
            apiRef={apiRef}
            density="compact"
            pageSize={perPage}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => {
              setPerPage(newPageSize);
              setPage(1);
            }}
            paginationMode="server"
            slots={{
              toolbar: GridToolbar,
            }}
            initialState={{ pinnedColumns: { right: ["actions"] } }}
            onColumnOrderChange={handleColumnOrderChange}
          />
        </div>
        <div className="user-pagination">
          <Row>
            <Col md={4}> </Col>
            <Col md={4}>
              {" "}
              <Stack spacing={2}>
                <Pagination
                  count={countPage}
                  page={page}
                  onChange={handlePaginationChange}
                  size="large"
                />
              </Stack>
            </Col>
            <Col md={4}>
              {" "}
              <Stack direction="row" spacing={2} alignItems="center">
                <label>Rows per page:</label>
                <Select value={perPage} onChange={handlePageSizeChange}>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                </Select>
              </Stack>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default CompiledExercise;
