import { React, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

function ThemeModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`common-modal ${props?.className}`}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
    </Modal>
  );
}

export default ThemeModal;
