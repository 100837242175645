import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Row,
  Form,
} from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { toast } from "react-toastify";
import {
  constants,
  GENDERS,
  IS_VERIFIED,
  subscription_type,
  SUBSCRIPTION_TYPE,
} from "../../utils/constants";
import {
  DeleteUser,
  GetUsers,
  DeleteMultipleUsers,
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { ageCalculate } from "../../utils/helpers";
import { GetSports } from "../../services/sports.service";
import { GetPrograms } from "../../services/programs.service";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import Pagination from "@material-ui/lab/Pagination";
import { MdEdit, MdDelete } from "react-icons/md";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function Users() {
  let history = useHistory();
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [exportRows, setExportRows] = useState();
  const [keyword, setKeyword] = useState("");
  const [verified, setVerified] = useState("");
  const [sports, setSports] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [sport, setSport] = useState("");
  const [program, setProgram] = useState("");
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("users", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getUsers(true);
      await getColumns();
      await getSports();
      await getPrograms();
    };

    fetchData();
  }, [verified, sport, program, page, perPage]);

  useEffect(() => {
    if (keyword) {
      getUsers(false);
    } else {
      getUsers(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("users", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  const AddUser = () => {
    history.push("/manage-users/add-user");
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(exportRows[0]);
    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  }

  const getSports = async () => {
    await GetSports(null, null, null, 0, 10, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setSports(result.data);
      }
    });
  };

  const getPrograms = async () => {
    await GetPrograms(null, null, null, null, 0, "asc", "name").then(
      (result) => {
        if (result.status && result.data) {
          setPrograms(result.data);
        }
      }
    );
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "users",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns?.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder?.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "users",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const getUsers = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }
    try {
      const result = await GetUsers(
        keyword,
        constants.ROLES.ROLE_USER,
        page,
        perPage,
        verified,
        sport,
        program,
        1
      );
      if (result.status) {
        if (result.data) {
          const rowData = [];
          const exportData = [];
          setPage(result?.data?.meta?.current_page);
          setPageCount(result?.data?.meta?.last_page);
          result?.data?.data?.map((dt, index) => {
            let indexNum = ++index;
            rowData.push({
              id: dt.id,
              full_name: dt.name || "-",
              email: dt.email || "-",
              gender: GENDERS[dt?.userDetail?.gender] || "-",
              age: dt?.userDetail?.dob
                ? ageCalculate(dt?.userDetail?.dob)
                : "-",
              sports: dt.userSportsCSV ? dt.userSportsCSV : "-",
              programs: dt.userProgramCSV ? dt.userProgramCSV : "-",
              injuries: dt.userInjuriesCSV ? dt.userInjuriesCSV : "-",
              body_types: dt.userBodyTypesCSV ? dt.userBodyTypesCSV : "-",
              is_verified: IS_VERIFIED[dt?.is_verified],
              subscription_type: SUBSCRIPTION_TYPE[dt?.subscription_type],
              created_at: dt.created_at,
            });
            exportData.push({
              name: dt.name || "-",
              email: dt.email || "-",
              gender: GENDERS[dt?.userDetail?.gender] || "-",
              age: dt?.userDetail?.dob
                ? ageCalculate(dt?.userDetail?.dob)
                : "-",
              sports: dt.userSportsCSV ? dt.userSportsCSV : "-",
              programs: dt.userProgramCSV ? dt.userProgramCSV : "-",
              is_verified: IS_VERIFIED[dt?.is_verified],
              start_date: dt.start_date,
            });
          });
          setRows(rowData);
          setExportRows(exportData);
          if (isInitialLoad) {
            setLoading(false);
          } else {
            setSearchLoading(false);
          }
        }
      }
    } catch (error) {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.replace(/_/g, " ")) ||
          "An error occurred"
      );
    }
  };

  const getColumns = async () => {
    const obj = [
      {
        field: "full_name",
        headerName: "Full Name ",
        width: 160,
      },
      { field: "email", headerName: "Email", width: 200 },
      { field: "gender", headerName: "Gender", width: 120 },
      { field: "age", headerName: "Age", width: 120 },
      { field: "sports", headerName: "Sports", width: 140 },
      { field: "injuries", headerName: "Injuries", width: 140 },
      { field: "body_types", headerName: "Body Types", width: 140 },
      { field: "programs", headerName: "Programs", width: 200 },
      {
        field: "subscription_type",
        headerName: "Subscription Type",
        width: 200,
      },
      {
        field: "is_verified",
        headerName: "Is Verified",
        width: 163,
      },
      {
        field: "created_at",
        headerName: "Start Date",
        width: 163,

        renderCell: (params) => {
          const date = new Date(params.value);
          const formattedDate = date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
          return <span>{formattedDate}</span>;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        filterable: false,
        width: 150,
        renderCell: (params) => (
          <>
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={() => onEdit(params.row)}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm"}
                onClick={() => onUserDelete(params.row.id)}
              >
                <MdDelete />
              </button>
            </div>
          </>
        ),
      },
    ];

    return obj;
  };

  const getColumnsHideShow = async () => {
    await GetColumnHideShow("users", "column_hide_show").then((result) => {
      if (result.status && result.data) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("users", "column_order_change").then((result) => {
      if (result.status && result.data) {
        const parsedArray = JSON.parse(result.data.table_data);
        const dataArray = Object.values(parsedArray);
        const completeColumns = dataArray.map((item) => {
          console.log("item", item.field);
          if (item.field == "actions") {
            return {
              field: item.field,
              headerName: item.headerName,
              width: item.width || 150,
              sortable: false,
              filterable: false,
              renderCell: (params) => (
                <div>
                  <>
                    <div>
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={() => onEdit(params.row)}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className={"btn btn-danger btn-sm mx-2"}
                        onClick={() => onUserDelete(params.row.id)}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </>
                </div>
              ),
            };
          } else {
            return item;
          }
        });
        setColumns(completeColumns);
      }
    });
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "users",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("users", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const onUserDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      if (willShip) {
        await DeleteUser(id)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getUsers();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(
                error.response.data.message.replace(/_/g, " ")
              );
            }
          });
      }
    });
  };

  const onEdit = (data) => {
    const id = data.id;
    history.push("/manage-users/edit-user/" + id, {
      data: data,
    });
  };

  const onView = (data) => {
    const id = data.id;
    history.push("/manage-users/view-user/" + id, {
      data: data,
    });
  };
  const onReportView = (data) => {
    const id = data.id;
    history.push("/survey-report/" + id, {
      data: data,
    });
  };
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
    const filename = "users-export.csv";
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const onMultipleDelete = async () => {
    const rows = await apiRef.current.getSelectedRows();

    const dataArray = [...rows.values()];

    const dataArrayIds = dataArray.map((li) => li.id);

    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeUsers: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleUsers(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getUsers();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <div className="users-index">
        <div className="custom-order-filters">
          <div className="common-header-block">
            <section className="add-users-section">
              <Row className="align-items-center">
                <h1>Manages Users</h1>
                <Row>
                  <Col md={2}>
                    <Form.Group controlId="verified">
                      <Form.Select
                        className="formselect filter"
                        aria-label="verified"
                        onChange={(e) => setVerified(e.target.value)}
                      >
                        <option key="blankChoice" hidden value>
                          Is Verified
                        </option>
                        <option value="">All</option>
                        <option value="0">Not Verified</option>
                        <option value="1">Verified</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="sport">
                      <Form.Select
                        className="formselect filter"
                        aria-label="sport"
                        onChange={(e) => setSport(e.target.value)}
                      >
                        <option key="blankChoice" hidden value>
                          Sports
                        </option>
                        <option value="">All</option>
                        {sports &&
                          sports.map((sport, index) => (
                            <option value={sport.id}>{sport.name}</option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="program">
                      <Form.Select
                        className="formselect filter"
                        aria-label="program"
                        onChange={(e) => setProgram(e.target.value)}
                      >
                        <option key="blankChoice" hidden value>
                          Programs
                        </option>
                        <option value="">All</option>
                        {programs &&
                          programs.map((program, index) => (
                            <option value={program.id}>{program.name}</option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <div className="all-user">
                      <div className="search-bar">
                        <div className="common-search-bar">
                          <InputGroup>
                            <InputGroup.Text id="basic-addon1">
                              <SearchIcon />
                            </InputGroup.Text>
                            <FormControl
                              placeholder="Search"
                              aria-label="Search"
                              aria-describedby="Search"
                              onChange={(e) => {
                                e.target.value.length > 0
                                  ? setKeyword(e.target.value.trim())
                                  : setKeyword(null);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={2}>
                    <Button
                      onClick={AddUser}
                      className={"btn common-btn   w-100"}
                    >
                      <AddIconOutline /> Add New
                    </Button>
                  </Col>
                </Row>
              </Row>
            </section>
          </div>

          <section className="common-table mt-2">
            <div class="delete-icon">
              <Button className={"common-btn"} onClick={onMultipleDelete}>
                <DeleteUpdated />
              </Button>
            </div>
            <div style={{ height: "99vh", width: "100%" }}>
              <StyledDataGrid
                rows={rows}
                loading={searchLoading}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={toggleCustomFunc}
                isColumnResizable
                onColumnWidthChange={handleColumnWidthChange}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                unstable_headerFilters
                apiRef={apiRef}
                density="compact"
                pageSize={perPage}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => {
                  setPerPage(newPageSize);
                  setPage(1);
                }}
                paginationMode="server"
                slots={{
                  toolbar: GridToolbar,
                }}
                headerFilters
                initialState={{ pinnedColumns: { right: ["actions"] } }}
                onColumnOrderChange={handleColumnOrderChange}
              />
            </div>
            <div className="theme-dataGrid products-dataGrid">
              <div className="user-pagination">
                <Row>
                  <Col md={4}></Col>
                  <Col md={4}>
                    {" "}
                    <Stack spacing={2} alignItems="center">
                      <Pagination
                        count={countPage}
                        page={page}
                        onChange={handlePaginationChange}
                        size="large"
                      />
                    </Stack>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Stack direction="row" spacing={2} alignItems="center">
                      <label>Rows per page:</label>
                      <Select value={perPage} onChange={handlePageSizeChange}>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                      </Select>
                    </Stack>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Users;
