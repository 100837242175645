/* eslint-disable react-hooks/exhaustive-deps */
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Select from "react-select";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { genders } from "../../utils/constants";
import { TbFilterOff } from "react-icons/tb";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import VideoThumbnail from "./VideoThumbnail";
import { SearchIcon } from "../../components/SvgIcons";
import { FadeLoader } from "react-spinners";
import { GetBodyTypes } from "../../services/body_types.service";
import {
  GetProgramDayDetails,
  UpdateProgramDays,
} from "../../services/programs.service";
import { GetExerciseTypes } from "../../services/exercise_types.service";
import { GetMuscles } from "../../services/muscles.service";
import { GetEquipments } from "../../services/equipments.service";
import {
  GetProgramExercises,
  GetAllProgramExercises,
} from "../../services/exercises.service";
import DayWiseExercise from "./DayWiseExercise";
import { HashLoader } from "react-spinners";
import { GetCategories } from "../../services/categories.service";
import FullPageLoader from "../../components/FullPageLoader";

function ProgramDetails(props) {
  let history = useHistory();
  let location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const [isSaving, setIsSaving] = useState(props.isChanging);
  const [programPreData, setProgramPreData] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [programNewData, setProgramNewData] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [program, setProgram] = useState();
  const [keyword, setKeyword] = useState("");
  const [gender, setGender] = useState("");
  const [exerciseFilter, setExcerciseFilter] = useState("");
  const [statusFilter, setStatusWiseFilter] = useState([]);
  const [bodyType, setBodyType] = useState("");
  const [bodyTypePre, setBodyTypePre] = useState([]);
  const [loading, setLoading] = useState(false);
  const [equipment, setEquipment] = useState([]);
  const [exerciseType, setExerciseType] = useState("");
  const [muscle, setMuscle] = useState("");
  const [level, setLevel] = useState([]);
  const [loader, setLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [exerciseTypes, setExerciseTypes] = useState([]);
  const [muscles, setMuscles] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [dragged, setDragged] = useState(null);
  const [swapDraggedArray, setSwapDraggedArray] = useState(null);
  const [selectRef, setSelectRef] = useState([]);
  const videoContainerRef = useRef();
  const videoComponentRef = useRef();
  const [removeExercises, setRemoveExercises] = useState([]);
  const [isRestComponent, setIsRestComponent] = useState(false);
  const [isComplete, setIsComplete] = useState(0);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [completeStatus, setCompleteStatus] = useState();
  const [buttonType, setButtonType] = useState("");

  const [dayId, setDayId] = useState("");
  const typingTimeoutRef = useRef(null);

  const statusOptions = [
    { value: "", label: "Select Status", isDisabled: true },
    { value: 1, label: "Complete" },
    { value: 0, label: "Incomplete" },
  ];

  const handleKeyUp = (e) => {
    clearTimeout(typingTimeoutRef.current);
    const value = e.target.value;
    const sanitizedInput = value.replace(/[^A-Za-z\s]+/g, "");
    if (sanitizedInput.length > 0) {
      typingTimeoutRef.current = setTimeout(() => {
        setKeyword(sanitizedInput);
      }, 500);
    } else {
      setKeyword(null);
    }
  };

  if (location.state !== undefined && program === undefined) {
    setProgram(location.state.program);
    if (location.state.redirectToSummary) {
      location.state.redirectToSummary = false;
      props.handleStep(12);
    }
  }

  if (location.state === undefined && program === undefined) {
    history.push("/add-new-program");
  }

  const resetCurrentComponent = async () => {
    setIsRestComponent(true);
    setDayId("");
    setDragged(null);
    setExercises([]);
    await props.changed(false);
    await props.setNextStepFunc(null);
    setProgramPreData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setProgramNewData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);

    document.getElementById("formFilterProgram")?.reset();
    document.getElementById("formProgramExercise")?.reset();
  };
  useEffect(() => {
    const getProgramDayDetails = async (program_id, day_id) => {
      setDisableButton(true);
      setLoader(true);
      await GetProgramDayDetails(program_id, day_id).then((result) => {
        if (result.status && result.data) {
          setDayId(result.data.id);
          setValue("internal_notes", result.data.internal_notes);
          if (result.data.programDayExercises.length > 0) {
            handlePreDataArray(result.data.programDayExercises);
          }
          if (
            result.data.program.is_draft !== "" &&
            result.data.program.is_draft !== null
          ) {
            setCompleteStatus({
              value: result.data.program.is_draft,
              label:
                result.data.program.is_draft === 1 ? "Complete" : "Incomplete",
            });
          }
        } else {
          setProgramPreData([
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ]);
        }
        setDisableButton(false);
        setLoader(false);
      });
    };

    resetCurrentComponent();
    getProgramDayDetails(program?.id, props.dayId);
  }, [program, props.activeStep, props.dayId, props.day]);

  useEffect(() => {
    setProgramNewData(programPreData);
  }, [programPreData]);

  useEffect(() => {
    const getBodyTypes = async () => {
      await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setBodyTypes(result.data);
          if (bodyType !== "" && bodyType !== null) {
            let editBodyTypePre = result.data.filter(
              (item, index) => item.id == bodyType
            );
            setBodyTypePre({
              value: editBodyTypePre[0] ? editBodyTypePre[0].id : "",
              label: editBodyTypePre[0] ? editBodyTypePre[0].name : "",
            });
          } else {
            setBodyTypePre(null);
          }
        }
      });
    };

    const getExerciseTypes = async () => {
      await GetExerciseTypes("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setExerciseTypes(result.data);
        }
      });
    };

    const getMuscles = async () => {
      await GetMuscles("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setMuscles(result.data);
        }
      });
    };

    const getEquipmentList = async () => {
      await GetEquipments("", "", "", 0, "asc", "title").then((result) => {
        if (result.status && result.data) {
          setEquipments(result.data);
        }
      });
    };

    getBodyTypes();
    getExerciseTypes();
    getMuscles();
    getEquipmentList();
  }, []);

  const removeExerciseVideo = (targetId) => {
    const newPreData = [...programPreData];
    let removeExercisesArray = removeExercises;
    if (
      newPreData[targetId] !== undefined &&
      newPreData[targetId] !== null &&
      newPreData[targetId].id !== undefined &&
      newPreData[targetId].id !== null
    ) {
      removeExercisesArray.push(newPreData[targetId].id);
      setRemoveExercises(removeExercisesArray);
    }
    newPreData[targetId] = null;
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    newNewData[targetId] = null;
    setProgramNewData(newNewData);
  };

  const selectedSubCategoryOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedSubCategories(data);
  };

  const onSubmit = async (data) => {
    if (programNewData.length !== 0 && programNewData !== undefined) {
      setDisableButton(true);
      setLoader(true);
      await UpdateProgramDays(props.dayId, {
        internal_notes: data.internal_notes,
        is_draft: completeStatus?.value,
        program_id: program.id,
        week: 1,
        day: props.dayId,
        circuit: 1,
        name: props.day,
        day_id: dayId ? dayId : "",
        programDayExercises: programNewData,
        removeExercises: removeExercises,
        exercise_categories: selectedSubCategories,
      })
        .then(async (data) => {
          setIsRestComponent(true);
          await resetCurrentComponent();
          setDayId("");
          if (data.status) {
            setDisableButton(false);
            setLoader(false);
            toast.success(data.message);
            props.changed(false);
            if (buttonType == "save") {
              history.push("/program-library");
            } else {
              if (props.nextStep != null) {
                await props.handleStep(props.nextStep);
              } else {
                await props.handleNext();
              }
            }
          } else {
            toast.error(data.message.replace(/_/g, " "));
            setDisableButton(false);
            setLoader(false);
          }
        })
        .catch((error) => {
          setDisableButton(false);
          setLoader(false);
          toast.error("Something went wrong. Please try again.");
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          }
        });
    } else {
      toast.error("Please select exercise videos");
    }
  };

  const getExercises = async () => {
    setLoading(true);
    setExercises([]);
    await GetProgramExercises(
      keyword,
      bodyType,
      exerciseType,
      equipment,
      muscle,
      gender,
      level,
      exerciseFilter,
      statusFilter,
      selectedSubCategories,
      "asc",
      "name"
    )
      .then((result) => {
        if (result.status) {
          const exerciseData = result.data;
          setExercises(exerciseData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const getAllProgramExercises = async () => {
    setLoading(true);
    setExercises([]);
    await GetAllProgramExercises("asc", "name")
      .then((result) => {
        if (result.status) {
          const exerciseData = result.data;
          setExercises(exerciseData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(async () => {
    getExercises();
    await getSubCategories();
  }, [
    keyword,
    bodyType,
    exerciseType,
    equipment,
    muscle,
    gender,
    program,
    exerciseFilter,
    level,
    statusFilter,
    selectedSubCategories,
    props.activeStep,
    props.dayId,
    props.day,
  ]);

  const clearFilter = () => {
    setKeyword("");
    setBodyType("");
    setExerciseType("");
    setEquipment([]);
    setMuscle("");
    setGender("");
    setLevel([]);
    setBodyTypePre([]);
    resetSelectInstances();
    setExcerciseFilter("");
    setStatusWiseFilter("");
    document.getElementById("formFilterProgram").reset();
  };

  const setDraggedFunc = (data) => {
    setDragged(data);
    setSwapDraggedArray(null);
  };

  const onDragOverFunc = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDragEnterFunc = (e) => {
    e.stopPropagation();
  };

  const onDropFunc = (e) => {
    e.preventDefault();
    props.changed(true);
    const targetId = e.target.id.split("-")[1];
    if (targetId && swapDraggedArray && !dragged) {
      let oldIndex = parseInt(swapDraggedArray.exercise_no) - 1;
      let newIndex = parseInt(targetId) - 1;
      let tempData = swapDraggedArray;
      tempData.exercise_no = targetId;
      const newPreData = [...programPreData];
      if (
        oldIndex != null &&
        oldIndex != -1 &&
        newIndex != null &&
        newIndex != -1
      ) {
        newPreData[oldIndex] = null;
        newPreData[newIndex] = tempData;
      }
      setProgramPreData(newPreData);

      const newNewData = [...programNewData];
      if (
        oldIndex != null &&
        oldIndex != -1 &&
        newIndex != null &&
        newIndex != -1
      ) {
        newNewData[oldIndex] = null;
        newNewData[newIndex] = tempData;
      }
      setProgramNewData(newNewData);
      setSwapDraggedArray(null);
      setDragged(null);
    } else if (e.target.className === "dropzone" && dragged) {
      const videoId = dragged.dataset.video_id;
      const levelId = dragged.dataset.level_id;
      const exerciseType = dragged.dataset.exercise_type;
      const compilationVideoId = dragged.dataset.compilation_video_id;
      const gender = dragged.dataset.gender;
      setProgramDayNewDataFunc(
        videoId,
        targetId,
        levelId,
        exerciseType,
        gender,
        compilationVideoId
      );
      setDragged(null);
      setSwapDraggedArray(null);
    }
  };

  const setSwapDraggedFunc = (data) => {
    props.changed(true);
    setDragged(null);
    setSwapDraggedArray(data);
  };

  const setCompleteStatusTypeFunc = (selectedValue) => {
    setCompleteStatus(selectedValue);
  };

  const setProgramDayNewDataFunc = (
    exerciseId,
    targetId,
    levelId,
    exerciseType,
    gender,
    compilationVideoId
  ) => {
    if (exerciseId !== null && targetId !== null) {
      console.log("level---", level);
      console.log("exercises", exercises);
      let result = level.includes("4");

      let exerciseData;
      let maleLevel;
      let femaleLevel;
      if (result) {
        exerciseData = exercises.filter(
          (elem) => elem.exercise.id == exerciseId
        );
        maleLevel = exerciseData[0].exercise.exerciseLevels[0];
        femaleLevel = exerciseData[0].exercise.exerciseLevels[1];
      } else {
        exerciseData = exercises.filter((elem) => elem.id == exerciseId);
        maleLevel = exerciseData[0].exerciseLevels[0];
        femaleLevel = exerciseData[0].exerciseLevels[1];
      }
      let preData = {
        is_compilation: exerciseType,
        compilation_video_id: compilationVideoId,
        exercise_id: exerciseId,
        exercise_no: targetId,
        position: 0,
        exercise_level_id: levelId,
        exerciseLevel: maleLevel
          ? gender == 10
            ? maleLevel
            : femaleLevel
          : null,
        feel_it_position: "TL",
        feel_it_show: true,
        text_position: "TR",
        text_show: true,
        exerciseLevels: maleLevel ? maleLevel : null,
      };

      let newData = {
        is_compilation: exerciseType,
        compilation_video_id: compilationVideoId,
        exercise_id: exerciseId,
        exercise_no: targetId,
        position: 0,
        exercise_level_id: levelId,
        exerciseLevel: maleLevel ? maleLevel : null,
        feel_it_position: "TL",
        feel_it_show: true,
        text_position: "TR",
        text_show: true,
        exerciseLevels: maleLevel ? maleLevel : null,
      };

      handleNewDataArray(preData, newData, targetId);
      return true;
    }
    return false;
  };

  const setWeightFunc = (e) => {
    props.changed(true);
    const targetId = e.target.id.split("-")[1];
    const position = e.target.value;
    if (position !== null && targetId !== null) {
      const newNewData = [...programNewData];
      if (targetId - 1 !== -1) {
        newNewData[targetId - 1].position = position;
      } else {
        newNewData[targetId].position = position;
      }
      setProgramNewData(newNewData);
    }
  };

  const setFeelItOverlayFunc = (data) => {
    props.changed(true);
    const targetId = data.index;
    const position = data.position;

    if (position !== null && targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].feel_it_position = position;
        tempArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempArray[targetId].feel_it_position = position;
        tempArray[targetId].feel_it_show = data.show;
      }
      setProgramNewData((programNewData) => tempArray);
    }
  };

  const setFeelImageShowFunc = (data) => {
    props.changed(true);
    const targetId = data.index;
    if (targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempArray[targetId].feel_it_show = data.show;
      }
      setProgramNewData(tempArray);

      let tempPreArray = programPreData;
      if (targetId - 1 !== -1) {
        tempPreArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempPreArray[targetId].feel_it_show = data.show;
      }
      setProgramPreData(tempPreArray);
    }
  };

  const setTextOverlayFunc = (data) => {
    props.changed(true);
    const targetId = data.index;
    const position = data.position;
    if (position !== null && targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].text_position = position;
        tempArray[targetId - 1].text_show = data.show;
      } else {
        tempArray[targetId].text_position = position;
        tempArray[targetId].text_show = data.show;
      }
      setProgramNewData((programNewData) => tempArray);
    }
  };

  const setTextOverlayShowFunc = (data) => {
    props.changed(true);
    const targetId = data.index;
    if (targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].text_show = data.show;
      } else {
        tempArray[targetId].text_show = data.show;
      }
      setProgramNewData(tempArray);

      let tempPreArray = programPreData;
      if (targetId - 1 !== -1) {
        tempPreArray[targetId - 1].text_show = data.show;
      } else {
        tempPreArray[targetId].text_show = data.show;
      }
      setProgramPreData(tempPreArray);
    }
  };

  const handlePreDataArray = (array) => {
    const newPreData = [];
    array.map((item) => {
      newPreData[item.exercise_no - 1] = item;
    });
    setProgramPreData(newPreData);
  };

  const handleNewDataArray = (preData, newData, targetId) => {
    const newPreData = [...programPreData];
    if (targetId - 1 !== -1) {
      newPreData[targetId - 1] = preData;
    } else {
      newPreData[targetId] = preData;
    }
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    if (targetId - 1 !== -1) {
      newNewData[targetId - 1] = newData;
    } else {
      newNewData[targetId] = newData;
    }
    setProgramNewData(newNewData);
  };

  const swapArrayFunc = (data) => {
    const newPreData = [...programPreData];
    if (
      data.indexSource != null &&
      data.indexSource != -1 &&
      data.indexDestination != null &&
      data.indexDestination != -1
    ) {
      newPreData[data.indexDestination] = data.tempSource;
      newPreData[data.indexSource] = data.tempDestination;
    }
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    if (
      data.indexSource != null &&
      data.indexSource != -1 &&
      data.indexDestination != null &&
      data.indexDestination != -1
    ) {
      newNewData[data.indexDestination] = data.tempSource;
      newNewData[data.indexSource] = data.tempDestination;
    }
    setProgramNewData(newNewData);
  };

  const setBodyTypeFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setBodyType(data);
  };

  const setEquipmentFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });

    setEquipment(data);
  };

  const setExerciseTypeFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });

    setExerciseType(data);
  };

  const setMuscleFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });

    setMuscle(data);
  };
  const getSubCategories = async () => {
    setLoader(true);
    await GetCategories("", "", "", 0, "", "", 0, "asc", "name").then(
      (result) => {
        if (result.status && result.data) {
          setSubCategories(result.data);
        }
      }
    );
  };

  const setLevelFilter = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setLevel(data);
  };

  const resetSelectInstances = () => {
    selectRef.map((item) => {
      if (item !== null) {
        item.clearValue();
      }
    });
    setSelectRef([null]);
  };

  const setIsRestComponentFunc = (data) => {
    setIsRestComponent(false);
  };

  const setExerciseFunc = (selectedValue) => {
    setExcerciseFilter(selectedValue?.value);
  };

  const setGenderFunc = (selectedValue) => {
    setGender(selectedValue?.value);
  };

  return (
    <>
      {/* {loading && <FullPageLoader loading={loading} />} */}
      {/* form starts */}
      <Form id="formFilterProgram" autoComplete="off">
        <div className="exercise-detail">
          <div className="exercise-select">
            <Select
              placeholder="Select Exercise"
              id={"exercise"}
              className="basic-multi-select"
              classNamePrefix="select"
              aria-label="exercise"
              onChange={(selectedOptions) => setExerciseFunc(selectedOptions)}
              ref={(ref) => {
                let selectRefs = selectRef;
                selectRefs.push(ref);
                setSelectRef(selectRefs);
              }}
              isClearable={true}
              options={[
                { value: "0", label: "Individual Exercise" },
                { value: "1", label: "Linked/Complied Exercise" },
              ]}
            />
          </div>
          <div className="exercise-select">
            <Select
              id={"gender"}
              {...register("gender", {})}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Gender"
              onChange={(selectedOptions) => setGenderFunc(selectedOptions)}
              ref={(ref) => {
                let selectRefs = selectRef;
                selectRefs.push(ref);
                setSelectRef(selectRefs);
              }}
              isClearable={true}
              options={[
                { value: genders.FEMALE, label: "Female" },
                { value: genders.MALE, label: "Male" },
              ]}
            />

            {errors.gender && (
              <Form.Text className="text-muted validationText hasError">
                {errors.gender.message}
              </Form.Text>
            )}
          </div>
          <div className="exercise-select">
            <Select
              id={"body_type_id"}
              placeholder="Select Body Type"
              options={
                bodyTypes &&
                bodyTypes.map((e) => ({ label: e.name, value: e.id }))
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) => setBodyTypeFunc(selectedOptions)}
              isClearable={true}
              isMulti={true}
              ref={(ref) => {
                let selectRefs = selectRef;
                selectRefs.push(ref);
                setSelectRef(selectRefs);
              }}
            />
          </div>
          <div className="exercise-select">
            <Select
              id={"exercise_type"}
              placeholder="Select Exercise Type"
              options={
                exerciseTypes &&
                exerciseTypes.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setExerciseTypeFunc(selectedOptions)
              }
              isClearable={true}
              isMulti={true}
              ref={(ref) => {
                let selectRefs = selectRef;
                selectRefs.push(ref);
                setSelectRef(selectRefs);
              }}
            />
          </div>
          <div className="exercise-select">
            <Select
              id={"muscle"}
              placeholder="Select Muscle"
              options={
                muscles && muscles.map((e) => ({ label: e.name, value: e.id }))
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) => setMuscleFunc(selectedOptions)}
              isClearable={true}
              isMulti={true}
              ref={(ref) => {
                let selectRefs = selectRef;
                selectRefs.push(ref);
                setSelectRef(selectRefs);
              }}
            />
          </div>
          <div className="exercise-select">
            <Select
              id="equipment"
              placeholder="Select Equipment"
              options={
                equipments &&
                equipments.map((e) => ({ label: e.title, value: e.id }))
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) => setEquipmentFunc(selectedOptions)}
              isClearable={true}
              isMulti={true}
              ref={(ref) => {
                let selectRefs = selectRef;
                selectRefs.push(ref);
                setSelectRef(selectRefs);
              }}
            />
          </div>
          <div className="exercise-select">
            <Select
              id="category"
              placeholder="Select Subcategory"
              options={
                subCategories &&
                subCategories.map((e) => ({ label: e.name, value: e.id }))
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                selectedSubCategoryOpt(selectedOptions)
              }
              isClearable={true}
              isMulti={true}
              ref={(ref) => {
                let selectRefs = selectRef;
                selectRefs.push(ref);
                setSelectRef(selectRefs);
              }}
            />
          </div>
          <div className="exercise-select">
            <Select
              placeholder="Select Level"
              id={"exercise"}
              className="basic-multi-select"
              classNamePrefix="select"
              aria-label="exercise"
              onChange={(selectedOptions) => setLevelFilter(selectedOptions)}
              ref={(ref) => {
                let selectRefs = selectRef;
                selectRefs.push(ref);
                setSelectRef(selectRefs);
              }}
              isClearable={true}
              isMulti={true}
              options={[
                { value: "4", label: "Level 0" },
                { value: "1", label: "Level 1" },
                { value: "2", label: "Level 2" },
                { value: "3", label: "Level 3" },
              ]}
            />
          </div>
          <div className="common-search-bar">
            <InputGroup className="search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => handleKeyUp(e)}
              />
            </InputGroup>
          </div>
          <div className="exercise-select">
            <Button
              onClick={() => clearFilter()}
              className="common-btn"
              title={"Clear All Filters"}
            >
              <TbFilterOff /> Clear All Filters
            </Button>
          </div>
        </div>
      </Form>
      <Row>
        {/* Thumbnail gallery starts*/}
        <Col md={6}>
          <div className="common-video">
            <div className="common-title">
              <h6>All Exercises</h6>
            </div>
            {loading ? (
              <div className="spin-loader mt-auto mb-auto">
                <HashLoader color={"#EC1246"} height={10} />
              </div>
            ) : (
              <div className="thumbnail-gallery">
                <div
                  className="exercise-video exercise-video-flex-custom"
                  ref={videoContainerRef}
                  id={"thumbnail-gallery-row"}
                >
                  {exercises.length > 0 ? (
                    exercises.map((exercise, index) => {
                      const item =
                        "compilation_video_id" in exercise
                          ? exercise.exercise
                          : exercise;

                      const compilation_check_video_id =
                        "compilation_video_id" in exercise
                          ? exercise.compilation_video_id
                          : null;

                      const renderVideoThumbnail = (exerciseLevel, idx) => (
                        <VideoThumbnail
                          compilation_check_video_id={
                            compilation_check_video_id
                          }
                          exercise={item}
                          ref={videoComponentRef}
                          index={index}
                          videoUrl={exerciseLevel.video || ""}
                          videoPoster={exerciseLevel.video_cover || ""}
                          videoTitle={item.name || ""}
                          gender={exerciseLevel?.gender || ""}
                          addedBy={""}
                          link={exerciseLevel.video || ""}
                          key={`${item.id}-${idx}`}
                          id={item.id}
                          level={exerciseLevel.id || ""}
                          exerciseFilter={exerciseLevel.id || ""}
                          statusFilter={exerciseLevel.id || ""}
                          selectedSubCategories={exerciseLevel.id || ""}
                          dragged={setDraggedFunc}
                          flipVideo={exerciseLevel.video_flip || null}
                        />
                      );

                      return (
                        <>
                          {item.exerciseLevels.map((exerciseLevel, idx) => {
                            if (
                              exerciseLevel.gender == 10 ||
                              exerciseLevel.gender == 20
                            ) {
                              return renderVideoThumbnail(exerciseLevel, idx);
                            }
                            return null;
                          })}
                        </>
                      );
                    })
                  ) : (
                    <div className="no-data-found">
                      <strong>No data found</strong>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Col>
        {/* Thumbnail gallery ends*/}
        <Col md={6}>
          <div className="common-video">
            <div className="common-title">
              <h6>No. of Exercises</h6>
            </div>
            <div className="thumbnail-gallery exercise-video common-thumbnail-gallery">
              <DayWiseExercise
                dragged={setDraggedFunc}
                onDragEnterFunc={onDragEnterFunc}
                onDragOverFunc={onDragOverFunc}
                onDropFunc={onDropFunc}
                setWeightFunc={setWeightFunc}
                removeExerciseVideo={removeExerciseVideo}
                dayExerciseData={programPreData}
                setFeelItOverlayFunc={setFeelItOverlayFunc}
                setFeelImageShowFunc={setFeelImageShowFunc}
                setTextOverlayPosFunc={setTextOverlayFunc}
                setTextOverlayShowFunc={setTextOverlayShowFunc}
                swapArray={swapArrayFunc}
                swapDragged={setSwapDraggedFunc}
                isRestComponent={isRestComponent}
                setIsRestComponent={setIsRestComponentFunc}
              />
            </div>
          </div>
        </Col>
      </Row>

      <div className="note">
        <Form
          id="formProgramExercise"
          className="formProgramExercise"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row className="align-items-center">
            <Col md={2}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Select
                  isClearable
                  id="is_draft"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Status"
                  {...register("is_draft", {})}
                  value={statusOptions.find(
                    (option) => option.value === completeStatus?.value
                  )}
                  options={statusOptions}
                  onChange={(selectedOption) =>
                    setCompleteStatusTypeFunc(selectedOption)
                  }
                />
              </Form.Group>
            </Col>
            <Col md={7}>
              <Form.Group>
                <Form.Label>Internal Notes</Form.Label>
                <Form.Control
                  className="exercise-input"
                  placeholder="Internal Notes"
                  as="textarea"
                  id={"internal_notes"}
                  {...register("internal_notes", {
                    minLength: {
                      value: 3,
                      message: "Min length is 3",
                    },
                    maxLength: {
                      value: 500,
                      message: "Max length is 500",
                    },
                    onChange: (e) => props.changed(true),
                  })}
                  type="text"
                />
                {errors.internal_notes && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.internal_notes.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={1}>
              <Button
                className="common-btn"
                color="inherit"
                type={"button"}
                onClick={() => props.handleBack()}
              >
                Back
              </Button>
            </Col>
            <Col md={1}>
              <Button
                className="common-btn"
                color="inherit"
                type={"submit"}
                disabled={disableButton}
                onClick={() => setButtonType("save")}
              >
                Save
              </Button>
            </Col>
            <Col md={1}>
              <Button
                id={"formProgramExerciseSubmitBtn"}
                className="common-btn"
                type={"submit"}
                disabled={disableButton}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default ProgramDetails;
