import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { ArrowIcon, TrashIcon } from "../../components/SvgIcons";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { CreateBodyPart } from "../../services/body_parts";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import { constants, cross_origin } from "../../utils/constants";
import { upload } from "../../utils/helpers";

const AddFeel = (props) => {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [fileName, setFileName] = useState(null);

  const [male_image, setMaleImage] = useState(null);
  const [fileMaleImage, setFileMaleImage] = useState(null);

  // const [female_image, setfemale_image] = useState(null);
  // const [fileFemaleImage, setFileFemaleImage] = useState(null);
  const [folderName, setfolderName] = useState(
    "2. Exercise Management/Feel It Here Images/"
  );
  const [formData, setFormData] = useState({});

  const {
    register,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const selectedMedia = (fileName, file) => {
    console.log("file====>", file);
    clearErrors(fileName);
    setMaleImage(file);
    setFileMaleImage(file);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const handleMaleImageChange = async (event) => {
    setFileMaleImage(URL.createObjectURL(event.target.files[0]));
    setMaleImage(event.target.files[0]);
  };
  const deleteMaleImage = (fileName) => {
    setFormData({ ...formData, ...{ [fileName]: "" } });
    setMaleImage("");
    setFileMaleImage(null);
  };

  // const handleFemaleImageChange = async (event) => {
  //   setFileFemaleImage(URL.createObjectURL(event.target.files[0]));
  //   setfemale_image(event.target.files[0]);
  // };
  // const deleteFemaleImage = (fileName) => {
  //   setFormData({ ...formData, ...{ [fileName]: "" } });
  //   setMaleImage("");
  //   setFileMaleImage(null);
  // };

  const onSubmit = async (data) => {
    if (!male_image) {
      setError("male_image", { message: "Male image is required" });
      return false;
    }

    if (male_image) {
      data.male_image = male_image;
    }

    setDisableButton(true);
    await CreateBodyPart(data)
      .then(async (data) => {
        if (data.status) {
          toast.success(data.message);
          history.push("/feel-it-here");
        } else {
          setDisableButton(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const BackFeelItHere = () => {
    history.push("/feel-it-here");
  };

  return (
    <div className="feet-here-index">
      <div className="page-title">
        <h1>Add New Feel It Here</h1>
        <Button onClick={BackFeelItHere} className="common-btn">
          Back
        </Button>
      </div>
      <div className="common-form">
        <Form
          className="formAddUser"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="reference_number">
                <Form.Label>Reference No.</Form.Label>
                <Form.Control
                  readOnly={true}
                  defaultValue={"FH" + Math.floor(Math.random() * 10000)}
                  placeholder="Enter Reference No."
                  id={"formSignUpvideo"}
                  {...register("reference_number", {
                    required: {
                      value: "required",
                      message: "Reference No is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Min length is 3",
                    },
                    // maxLength: {
                    //   value: 25,
                    //   message: "Max length is 25",
                    // },
                  })}
                  type="text"
                />
                {errors.reference_number && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.reference_number.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name of Muscle*</Form.Label>
                <Form.Control
                  placeholder="Enter Name of Muscle"
                  id={"formSignUpvideo"}
                  {...register("name", {
                    required: {
                      value: "required",
                      message: "Muscle Name is required",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_ ]*$/,
                      message: "This field must contain only letters",
                    },
                    minLength: {
                      value: 3,
                      message: "Min length is 3",
                    },
                    // maxLength: {
                    //   value: 25,
                    //   message: "Max length is 25",
                    // },
                  })}
                  type="text"
                />
                {errors.name && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={3}>
              <Form.Group
                controlId="formFile"
                // onChange={(e) => handleMaleImageChange(e)}
                onClick={() => {
                  setFileName("male_image");
                  setModalShow(true);
                }}
                className="custom-file-upload mb-0"
              >
                <Form.Label className={"common-btn btn"}>Image</Form.Label>
                {/* <Form.Control
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg, image/gif"
                                    {...register("male_image")}
                                /> */}
                {errors.male_image && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.male_image.message}
                  </Form.Text>
                )}
              </Form.Group>
              {fileMaleImage && (
                <div className="image-item mb-3">
                  <img
                    crossOrigin={cross_origin ? "anonymous" : null}
                    src={fileMaleImage}
                    alt="Video Cover"
                    className={"img-fluid"}
                  />
                  <div className="image-item__btn-wrapper">
                    <button
                      type="button"
                      className="btn common-btn"
                      onClick={() => deleteMaleImage("male_image")}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </div>
              )}
            </Col>
            <ThemeModal
              title={"S3 Browser"}
              content={
                <S3Browser
                  fileName={fileName}
                  selectedMedia={selectedMedia}
                  fileType={"images"}
                  folderName={folderName}
                />
              }
              size={"xl"}
              show={modalShow}
              onHide={() => {
                setModalShow(false);
              }}
            />
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Label className="d-block">Select Status</Form.Label>
              <Form.Select
                className="formselect"
                aria-label="status"
                {...register("status", {
                  required: {
                    value: "required",
                    message: "Status is required",
                  },
                })}
              >
                <option value="10">Active</option>
                <option value="20">Inactive</option>
              </Form.Select>
              {errors.status && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.weight.message}
                </Form.Text>
              )}
            </Col>
          </Row>

          <Row>
            <Col xl={2} xxl={1} className="ms-auto">
              <Button
                type={"submit"}
                className={"common-btn w-100 px-0"}
                disabled={disableButton}
                value={""}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AddFeel;
