/* eslint-disable func-names */
import React from "react";
import { useState, useEffect } from "react";
import { FileDrop } from "react-file-drop"; // https://github.com/sarink/react-file-drop
import "../editor.css";
import Editor from "./Editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // https://fontawesome.com/v5/docs/web/use-with/react
import { faLightbulb, faMoon } from "@fortawesome/free-solid-svg-icons"; // https://fontawesome.com/v5/docs/web/use-with/react
import { AiOutlineClose } from "react-icons/ai";
import ThemeModal from "../../../../components/ThemeModal";
import S3Browser from "../../../../components/S3Browser";

function VideoEditorFemale({
  s3VideoUrl,
  trimmedTimings,
  trimmedDuration,
  videoFileName,
  resetEditor,
  levelVideoUrl,
  levelVideoDuration,
  levelKey,
  videoImageUrl,
  videoFlip,
  videoFlipped,
  isLoaded,
}) {
  //Boolean state handling whether upload has occured or not
  const [isUpload, setIsUpload] = useState(true);

  //State handling storing of the video
  const [videoUrl, setVideoUrl] = useState("");
  const [videoBlob, setVideoBlob] = useState("");
  const [fileNameVid, setFileNameVid] = useState("");

  //Boolean state handling whether light or dark mode has been chosen
  const [isDarkMode, setIsDarkMode] = useState(false);

  //Stateful array handling storage of the start and end times of videos
  const [timings, setTimings] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);

  //Lifecycle handling light and dark themes
  useEffect(() => {
    toggleThemes();
    document.addEventListener("drop", function (e) {
      e.preventDefault();
      e.stopPropagation();
    });
    setVideoBlob("");
    setVideoUrl("");
    s3VideoUrl("");
    setIsUpload(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsUpload(true);
  }, [levelKey]);

  useEffect(() => {
    if (resetEditor === true) {
      setVideoBlob("");
      setVideoUrl("");
      s3VideoUrl("");
      closeVideo();
      setIsUpload(true);
    }
    if (
      levelVideoUrl !== undefined &&
      levelVideoUrl !== "" &&
      levelVideoUrl !== null
    ) {
      setVideoUrl(levelVideoUrl);
      s3VideoUrl(levelVideoUrl);
      setIsUpload(false);
      if (
        levelVideoDuration !== undefined &&
        levelVideoDuration !== "" &&
        levelVideoDuration !== null
      ) {
        console.log("Female", levelVideoDuration);
      }
    } else {
      setVideoBlob("");
      setVideoUrl("");
      s3VideoUrl("");
      closeVideo();
      setIsUpload(true);
    }
  }, [resetEditor, levelVideoUrl, levelVideoDuration]);

  const setTrimmedTimings = (data) => {
    trimmedTimings({
      startTime: data.trimStartMin + ":" + data.trimStartSec,
      endTime: data.trimEndMin + ":" + data.trimEndSec,
    });
    s3VideoUrl(videoUrl);
  };

  const setTrimmedDuration = (data) => {
    trimmedDuration(data.Minutes + ":" + data.Seconds);
    s3VideoUrl(videoUrl);
  };

  const setVideoFileNameFunc = (data) => {
    videoFileName(data);
  };

  const videoImageUrlFunc = (data) => {
    videoImageUrl(data);
  };

  const videoFlipFunc = (data) => {
    videoFlip(data);
  };

  const closeVideo = () => {
    setVideoUrl("");
    s3VideoUrl("");
    setIsUpload(true);
    setTimings([]);
  };

  //Function handling file input as well as file drop library and rendering of those elements
  const renderUploader = () => {
    return (
      <div className={"wrapper"}>
        {/*<input*/}

        {/*	onChange={(e) => uploadFile(e.target.files)}*/}
        {/*	type='file'*/}
        {/*	className='hidden'*/}
        {/*	id='up_file'*/}
        {/*/>*/}
        {/*<FileDrop*/}

        {/*	onDrop={(e) => uploadFile(e)}*/}
        {/*	onTargetClick={() => document.getElementById('up_file').click()}*/}
        {/*>*/}
        {/*    Click or drop your video here to edit!*/}
        {/*</FileDrop>*/}
        <div className="file-drop">
          <button
            type="button"
            className="btn-video-upload"
            onClick={() => {
              setModalShow(true);
            }}
          >
            Upload Video
          </button>
        </div>
      </div>
    );
  };

  //Function handling rendering the Editor component and passing props to that child component
  const renderEditor = () => {
    return (
      // videoUrl --> URL of uploaded video
      <Editor
        videoUrl={videoUrl}
        videoBlob={videoBlob}
        setVideoUrl={setVideoUrl}
        timings={timings}
        setTimings={setTimings}
        fileName={fileNameVid}
        trimmedTimings={setTrimmedTimings}
        trimmedDuration={setTrimmedDuration}
        setIsUpload={setIsUpload}
        setVideoCover={videoImageUrlFunc}
        setVideoFlip={videoFlipFunc}
        videoFlipped={videoFlipped}
        onClose={closeVideo}
        isLoaded={isLoaded}
        // videoFileName={setVideoFileNameFunc}
      />
    );
  };

  //Function handling the light and dark themes logic
  const toggleThemes = () => {
    if (isDarkMode) {
      document.body.style.backgroundColor = "#1f242a";
      document.body.style.color = "#fff";
    }
    if (!isDarkMode) {
      document.body.style.backgroundColor = "#fff";
      document.body.style.color = "#1f242a";
    }
    setIsDarkMode(!isDarkMode);
  };

  //Function handling the file upload file logic
  // const uploadFile = async (fileInput) => {
  // 	console.log("uploaded file: ", fileInput[0].name)
  // 	let fileUrl = URL.createObjectURL(fileInput[0])
  // 	setIsUpload(false)
  // 	setVideoUrl(fileUrl)
  // 	setVideoBlob(fileInput[0])
  // 	setFileNameVid(fileInput[0].name);
  //
  // }

  const selectedMedia = (fileName, file) => {
    var uploadedVideoName = file.substring(file.lastIndexOf("/") + 1);
    setFileNameVid(uploadedVideoName);
    setVideoFileNameFunc(
      uploadedVideoName &&
        uploadedVideoName
          .replace(/_+/g, " ")
          .replace(/-+/g, " ")
          .split(".")
          .slice(0, -1)
          .join(".")
    );
    setVideoUrl(file);
    setIsUpload(false);
    setModalShow(false);
  };

  return (
    <div>
      {/* Boolean to handle whether to render the file uploader or the video editor */}
      {isUpload ? renderUploader() : renderEditor()}
      <div className={"theme_toggler"} onClick={toggleThemes}>
        {isDarkMode ? (
          <i className="toggle" aria-hidden="true">
            <FontAwesomeIcon icon={faLightbulb} />
          </i>
        ) : (
          <i className="toggle">
            <FontAwesomeIcon icon={faMoon} />
          </i>
        )}
      </div>
      <ThemeModal
        title={"S3 Browser"}
        content={
          <S3Browser
            fileName={fileName}
            selectedMedia={selectedMedia}
            fileType={"videos"}
          />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default VideoEditorFemale;
