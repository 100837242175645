import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { CreateUser } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { constants } from "../../utils/constants";
import { createUser } from "../../services/firebase.service";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import VideoEditorFemale from "./video/VideoEditor/VideoEditorFemale";
import { VideoImg } from "../video/VideoImg";

export default function VideoTemp() {
  let history = useHistory();
  const [disableButton, setDisableButton] = useState(false);

  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
    ],
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = async (data) => {
    setDisableButton(true);
    await CreateUser({
      full_name: data.full_name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      password: data.password,
      password_confirmation: data.password_confirmation,
      dob: data.dob,
      company_name: " ",
      business_type: " ",
      role_id: constants.ROLES.ROLE_USER.toString(),
      device_type: "web",
      device_token: "user",
    })
      .then(async (data) => {
        if (data.status) {
          await createUser(data.data.user).then(() => {
            toast.success(data.message);
            history.push("/manage-users");
          });
        } else {
          setDisableButton(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const MaleExcerciseComponent = () => {
    return (
      <>
        <Col md={6}>
          {/* Male Video Section Starts */}
          <div className="male mb-5">
            <h2 className="male-female mt-5">For Male:</h2>
            <VideoEditorFemale />
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
              <Form.Label></Form.Label>
              <VideoImg />
            </Col>
          </div>
          {/* Male Video Section ends */}
        </Col>
      </>
    );
  };

  return (
    <div className={"box"}>
      <MaleExcerciseComponent />
    </div>
  );
}
