import React, { useRef } from "react";
import { Form, Label, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logoIcon from "../../assets/images/logo-full.svg";
import { useForm } from "react-hook-form";

function SignUp() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = async (data) => {
    // await login(data.email, data.password);
  };

  return (
    <div className={"text-center authLayout"}>
      <Form
        className={"form-signup"}
        autoComplete={"off"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <a href="/">
          <img
            className="img-fluid mb-4"
            src={logoIcon}
            alt="Imprint Logo"
            title="Imprint Logo"
            style={{ padding: "10px 25px" }}
          />
        </a>
        <h1 className="h5 mb-3 font-weight-normal">Sign Up</h1>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Enter Name"
            id={"formSignUpName"}
            {...register("name", {
              required: {
                value: "required",
                message: "Name is required",
              },
              minLength: {
                value: 3,
                message: "Min length is 3",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
            })}
            type="text"
          />
          {errors.name && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.name.message}
            </span>
          )}
          <Form.Control
            placeholder="Enter email"
            id={"formSignUpEmail"}
            {...register("email", {
              required: {
                value: "required",
                message: "Email is required",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
            })}
            type="email"
          />
          {errors.email && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.email.message}
            </span>
          )}
          <Form.Control
            placeholder="Password"
            id={"formSignUpPassword"}
            {...register("password", {
              required: {
                value: "required",
                message: "Password is required",
              },
              minLength: {
                value: 6,
                message: "Min length is 6",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
            })}
            type="password"
          />
          {errors.password && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.password.message}
            </span>
          )}
          <Form.Control
            placeholder="Confirm Password"
            id={"formSignUpConfirmPassword"}
            {...register("Cpassword", {
              required: {
                value: "required",
                message: "Confirm password is required",
              },
              validate: (value) =>
                value === password.current || "The passwords do not match",
              minLength: {
                value: 6,
                message: "Min length is 6",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
            })}
            type="password"
          />
          {errors.Cpassword && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.Cpassword.message}
            </span>
          )}
        </Form.Group>
        <div className="d-grid gap-2">
          <Button
            variant="primary"
            size="lg"
            type="submit"
            className={"common-btn"}
          >
            Submit
          </Button>
          <p className={"mt-3"}>
            Already have an account <NavLink to={"/"}>Login</NavLink>
          </p>
        </div>

        <p className="mt-5 mb-3 text-muted">
          &copy; All rights reserved by Tekrevol 2022
        </p>
      </Form>
    </div>
  );
}

export default SignUp;
