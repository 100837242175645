import React, { useState, useEffect } from "react";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { FaRegUserCircle } from "react-icons/fa";
import { useHistory } from "react-router";
import {
  Logout,
  EmptyLocalStorage,
  GetUserRole,
} from "../services/auth/auth.service";
import { toast } from "react-toastify";
import { GetNotifications } from "../services/notifications.service";
import { constants } from "../utils/constants";
import { AiFillNotification } from "react-icons/ai";


import { Notification } from "./SvgIcons";


function Header({ handleGetDataFromParent, isOpen, setIsOpen }) {
  const [notificationStatus, setNotificationStatus] = useState(false);
  const [chatnotificationStatus, setchatNotificationStatus] = useState(false);
  const [data, setData] = useState();
  const [showChat, setShowChat] = useState(false);
  const history = useHistory();
  //const {user} = useContext(UserContext);
  const [user, setUser] = useState("");

  useEffect(() => {
    if (GetUserRole() !== constants.ROLES.ROLE_ADMIN) {
      setShowChat(true);
    }
    getNotifications();
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleProfileClick = () => {
    history.push("/profile");
  };
  const handleChangePasswordClick = () => {
    history.push("/change-password");
  };

  /*useLayoutEffect(() => {
        setUser(GetAuthUser()?.full_name)
    }, [])*/

  const getNotifications = async () => {
    await GetNotifications()
      .then((result) => {
        if (result.status) {
          if (result.data.data) {
            const rowData = [];
            result.data.data.map((dt) => {
              rowData.push({
                id: dt.id,
                notifiable_id: dt.notifiable_id,
                title: dt.title,
                message: dt.message,
                ref_id: dt.ref_id,
                type: dt.type,
                read_at: dt.read_at,
                extra: dt.extra,
                created_at: dt.created_at,
                updated_at: dt.updated_at,
                deleted_at: dt.deleted_at,
                image: dt.image,
                created_ago: dt.created_ago,
              });
            });

            setData(rowData);
          }
        } else {
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  async function handleLogout() {
    await Logout()
      .then(async (data) => {
        if (data.status) {
          toast.success(data.message);
          await EmptyLocalStorage();
          history.push("/");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  }

  const checkNotifications = () => {
    const notificationCount = 0;
    /*
     *
     *  checking the notification count and set to true
     *
     */
    if (notificationCount > 1) {
      setNotificationStatus(!notificationStatus);
    }
  };
  const checkChatNotifications = () => {
    const chatNotificationCount = 0;
    /*
     *
     *  checking the notification count and set to true
     *
     */
    if (chatNotificationCount > 1) {
      setchatNotificationStatus(!chatnotificationStatus);
    }
  };

  const navDropdownTitle = (
    <>
      <FaRegUserCircle /> {user}
    </>
  );

  return (
    <>
      <div className="header-index">
        <Navbar>
          <Navbar.Toggle
            className="toggles"
            aria-controls={`offcanvasNavbar-expand`}
            expanded={isOpen}
            onClick={handleGetDataFromParent}
          />

          <Nav className="ms-auto d-none d-lg-flex">
            <NavDropdown
              align="end"
              title={<Notification />}
              id="basic-nav-dropdown"
              className={`notificationsDropdown ${
                notificationStatus ? "hasNotifications" : ""
              }`}
            >
              {data
                ? data.map((notification) => {
                    let url = "";

                    if (
                      notification.type == constants.NOTIFICATIONTYPES.REVIEW
                    ) {
                      url = "/reviews";
                    }

                    if (
                      notification.type ==
                        constants.NOTIFICATIONTYPES.ORDERED ||
                      notification.type ==
                        constants.NOTIFICATIONTYPES.CONFIRMED_BY_CSR ||
                      notification.type ==
                        constants.NOTIFICATIONTYPES.CANCELLED ||
                      notification.type == constants.NOTIFICATIONTYPES.PAID ||
                      notification.type ==
                        constants.NOTIFICATIONTYPES.SHIPPED ||
                      notification.type ==
                        constants.NOTIFICATIONTYPES.COMPLETED ||
                      notification.type ==
                        constants.NOTIFICATIONTYPES.REVIEWED ||
                      notification.type == constants.NOTIFICATIONTYPES.RETURNED
                    ) {
                      url =
                        notification && notification.ref_id
                          ? "/orders/order-details/" + notification.ref_id
                          : "/orders";
                    }

                    return (
                      <>
                        <NavDropdown.Item href={url}>
                          <div className={"notificationTile"}>
                            <AiFillNotification
                              style={{ marginRight: "10px", fontSize: "20px" }}
                            />
                            {/*<img
                                                            src={NotificationIcon}
                                                            alt={"Notification"}/>*/}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                width: "100%",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignContent: "center",
                              }}
                            >
                              <div>
                                <h3>{notification.title}</h3>
                                <p>{notification.message}</p>
                              </div>
                              <span style={{ fontSize: "10px" }}>
                                {notification?.created_ago || ""}
                              </span>
                            </div>
                          </div>
                        </NavDropdown.Item>
                      </>
                    );
                  })
                : ""}
              <NavDropdown.Item
                className={"viewAllNotifications"}
                href="/notifications"
              >
                Show all notifications
              </NavDropdown.Item>
            </NavDropdown>
            <div className="profile-name">
              <i className="profile-icon">{navDropdownTitle}</i>
              {/* <p className="mb-0 mx-2">User Name</p> */}
              <NavDropdown
                align="end"
                id="basic-nav-dropdown"
                className={"profileDropdown"}
              >
                <NavDropdown.Item onClick={handleProfileClick}>
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleChangePasswordClick}>
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout} href="javascript:;">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Nav>
        </Navbar>
      </div>
      {/* Responsive Navbar Starts Here
      <div className="responsive-navbar">
        <Navbar
          expand="false"
          bg="dark"
          className="navbar-dark imprint-header shadow"
        >
          <Navbar.Brand href="/dashboard">
              <img
                src={Logo}
                alt="BodySolution"
                title={"BodySolution"}
                className={"img-fluid"}
              />
            </Navbar.Brand>

          <Navbar.Offcanvas
            className="offcanvas-bg"
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${expand}`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="res-head justify-content-end flex-grow-1 pe-3">
                <ul className="nav nav-pills sidebarNav flex-column mb-auto">
                  <li className="nav-item">
                    <NavLink
                      to="/dashboard"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <HomeIcon />
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/manage-users"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <ManageUsersIcon />
                      User Management
                    </NavLink>
                  </li>
                  <li className="nav-item">
                      <NavLink to="/videos" className="nav-link" activeClassName="active">
                          <ManagePaymentsIcon/>
                          Video Management
                      </NavLink>
                 </li>
                  <li className="nav-item">
                    <NavLink
                      to="/program-library"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <ManagePaymentsIcon />
                      Program Management
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/exercises"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GiWeightLiftingUp />
                      Exercise Management
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/equipments"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GiWeight />
                      Equipment Management
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/page-list"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <AiFillContainer />
                      Content Management
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/feel-it-here"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GiMuscularTorso />
                      Feel It Here
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/sports"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GiMuscleUp />
                      Sports
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/exercise-types"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GrYoga />
                      Exercise Type
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/body-types"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GiBodyHeight />
                      Body Types
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/muscles"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GiMuscleUp />
                      Muscles
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/category"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GiMuscleUp />
                      Category
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/occupation"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <FaSuitcase />
                      Occupation
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/payment"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <BsCashCoin />
                      Payment
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/testimonials"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <FaSlideshare />
                      Testimonials
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/app-instructions"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <IoMdHelpCircle />
                      How To Use
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/pain-point-library"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <BiRadioCircleMarked />
                      <GiTriggerHurt/>
                      Trigger Point Library
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/muscles-anatomy-library"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <GiAnatomy />
                      <FaBookMedical />
                      Muscles Library
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/contact-us"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <AiFillMail />
                      Contact Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link" activeClassName="active">
                      <ManageUsersIcon />
                      <span onClick={handleLogout} className="ms-3 ">
                        Logout
                      </span>
                    </button>
                  </li>
                </ul>
                <NavDropdown
                    className="dropdown-bg"
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>

                  </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </div>
      Responsive Navbar Ends Here */}
    </>
  );
}

export default Header;
