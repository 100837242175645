import React, { useEffect, useState } from "react";
import { constants } from "../utils/constants";
import { GetUserRole } from "../services/auth/auth.service";
import { Admin } from "./SideMenu/index";

function SideBar() {
  return (
    <div className="sidebar">
      <Admin />
    </div>
  );
}

export default SideBar;
