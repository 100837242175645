import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { constants, cross_origin, STATUS } from "../../utils/constants";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  GetBodyParts,
  DeleteBodyPart,
  DeleteMultipleBodyParts,
} from "../../services/body_parts";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { MdEdit, MdDelete } from "react-icons/md";
import { AiOutlineFolderView } from "react-icons/ai";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import { MenuItem, Select } from "@mui/material";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function FeelitHereManagement() {
  let history = useHistory();
  const apiRef = useGridApiRef();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState([]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const handleComPaginationChange = (event, value) => {
    setPage(value);
  };

  const handleComPageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getListingFunction(true);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (keyword) {
      getListingFunction(false);
    } else {
      getListingFunction(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("feel_it_here", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("feel_it_here", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumns = async () => {
    const obj = [
      {
        field: "reference_number",
        headerName: "Ref No",
        width: 310,
        sortable: true,
      },
      {
        field: "name",
        headerName: "Muscle",
        width: 380,
        sortable: true,
      },
      {
        field: "image",
        headerName: "Images",
        width: 280,
        sortable: false,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            <img
              src={params.value}
              alt="Product Image" // Example of meaningful alt text
              style={{ width: "auto", height: 50 }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = constants.IMAGENOTFOUND;
              }}
              className={"img-table img-thumbnail"}
              crossOrigin={cross_origin ? "anonymous" : null}
            />
          </a>
        ),
      },

      {
        field: "status",
        headerName: "Status",
        width: 350,
        sortable: true,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 180,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            // Logic to handle delete action
            const id = `${params.id}`;
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete this video?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              // Delete BodyPart API
              if (willShip) {
                await DeleteBodyPart(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getListingFunction();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          const onEdit = () => {
            const id = `${params.id}`;
            history.push("/edit-feel-it-here/" + id);
          };

          const onView = () => {
            const id = `${params.id}`;
            history.push("/add-feel-it-here/" + id);
          };

          return (
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params.id);
                }}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm mx-2"}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.id);
                }}
              >
                <MdDelete />
              </button>
              {/* <button
                className={"btn btn-dark btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onView(params.id);
                }}
              >
                <AiOutlineFolderView />
              </button> */}
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "feel_it_here",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "feel_it_here",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("feel_it_here", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const getColumnsOrderChange = async () => {
    try {
      const result = await GetColumnHideShow(
        "feel_it_here",
        "column_order_change"
      );

      if (result.status && result.data) {
        const parsedArray = JSON.parse(result.data.table_data);
        const dataArray = Object.values(parsedArray);
        const completeColumns = dataArray?.map((item) => {
          if (item.field === "actions") {
            return {
              field: "actions",
              headerName: "Actions",
              sortable: false,
              filterable: false,
              width: item.width,
              renderCell: (params) => {
                const onDelete = async () => {
                  const id = `${params.id}`;
                  const willShip = await swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to delete this video?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  });

                  if (willShip) {
                    try {
                      const deleteResult = await DeleteBodyPart(id);
                      if (deleteResult.status) {
                        swal(deleteResult.message, {
                          icon: "success",
                        });
                        getListingFunction();
                      } else {
                        toast.error(deleteResult.message);
                      }
                    } catch (error) {
                      toast.error(
                        error.response?.data?.message.replace(/_/g, " ") ||
                          "An error occurred"
                      );
                    }
                  }
                };

                const onEdit = (id) => {
                  history.push("/edit-feel-it-here/" + id);
                };

                const onView = (id) => {
                  history.push("/add-feel-it-here/" + id);
                };

                return (
                  <div>
                    <button
                      className={"btn btn-primary btn-sm"}
                      onClick={(event) => {
                        event.stopPropagation();
                        onEdit(params.id);
                      }}
                    >
                      <MdEdit />
                    </button>
                    <button
                      className={"btn btn-danger btn-sm mx-2"}
                      onClick={(event) => {
                        event.stopPropagation();
                        onDelete(params.id);
                      }}
                    >
                      <MdDelete />
                    </button>
                    <button
                      className={"btn btn-dark btn-sm"}
                      onClick={(event) => {
                        event.stopPropagation();
                        onView(params.id);
                      }}
                    >
                      <AiOutlineFolderView />
                    </button>
                  </div>
                );
              },
            };
          } else if (item.field === "image") {
            return {
              field: "image",
              headerName: "Images",
              width: 280,
              sortable: false,
              renderCell: (params) => (
                <a
                  href={params.value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={params.value}
                    alt="Product Image" // Example of meaningful alt text
                    style={{ width: "auto", height: 50 }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = constants.IMAGENOTFOUND;
                    }}
                    className={"img-table img-thumbnail"}
                    crossOrigin={cross_origin ? "anonymous" : null}
                  />
                </a>
              ),
            };
          } else {
            return item;
          }
        });
        setColumns(completeColumns);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };
  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "feel_it_here",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getListingFunction = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }

    try {
      const result = await GetBodyParts(keyword, page, perPage, 1);

      if (result.status) {
        const rowData = [];
        setPage(result?.data?.meta?.current_page);
        setPageCount(result?.data?.meta?.last_page);
        result?.data?.data?.map((dt) => {
          rowData.push({
            id: dt?.id,
            name: dt?.name,
            reference_number: dt?.reference_number,
            image: dt.male_image,
            status: STATUS[dt?.status],
            gender: "male",
          });
        });
        setRowData(rowData);
      }

      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
    } catch (error) {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.replace(/_/g, " ")) ||
          "An error occurred"
      );
    }
  };

  const onMultipleDelete = async () => {
    let rowsDeleted = await apiRef.current.getSelectedRows();

    const dataArray = [...rowsDeleted.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeBodyParts: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleBodyParts(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getListingFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const AddFeelItHere = () => {
    history.push("/add-feel-it-here");
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <div className={"orderPage feet-here-index"}>
        <Row>
          <div className={"orderPage"}>
            <h1>Feel It Here</h1>
            <div className={"customOrderFilters"}>
              <Row>
                <Col md={1}></Col>
                <Col md={5} className="ms-auto">
                  <div className="all-user">
                    <div className="search-bar">
                      <div className="common-search-bar">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            <SearchIcon />
                          </InputGroup.Text>
                          <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="Search"
                            onChange={(e) => {
                              e.target.value.length > 0
                                ? setKeyword(e.target.value)
                                : setKeyword(null);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={2}>
                  <Button
                    className={"btn common-btn   w-100"}
                    onClick={AddFeelItHere}
                  >
                    <AddIconOutline /> Add New
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
        <section className="common-table  =mt-5">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>
          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rowData}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>
          <div className="user-pagination">
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                {" "}
                <Stack spacing={2}>
                  <Pagination
                    count={countPage}
                    page={page}
                    onChange={handleComPaginationChange}
                    size="large"
                  />
                </Stack>
              </Col>
              <Col md={4} className="ms-auto">
                {" "}
                <Stack direction="row" spacing={2} alignItems="center">
                  <label>Rows per page:</label>
                  <Select value={perPage} onChange={handleComPageSizeChange}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </Stack>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
}

export default FeelitHereManagement;
