import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { upload } from "../../utils/helpers";
import {
  GetPainPointsById,
  UpdatePainPoints,
} from "../../services/pain_point_library.service";
import ImageUploading from "react-images-uploading";

import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { cross_origin, constants } from "../../utils/constants";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import { TrashIcon } from "../../components/SvgIcons";
import { GetPainPointCategories } from "../../services/pain_point-categories.service";
import Select from "react-select";
import FullPageLoader from "../../components/FullPageLoader";

function EditPainPoint(props) {
  const id = props.match.params.id;
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [row, setRow] = useState(null);
  const [description, setDescription] = useState(null);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoTitle2, setVideoTitle2] = useState("");
  const [videoTitle3, setVideoTitle3] = useState("");
  const [videoTitle4, setVideoTitle4] = useState("");

  const [isUpload, setIsUpload] = useState(false);
  const [isUpload2, setIsUpload2] = useState(false);
  const [isUpload3, setIsUpload3] = useState(false);

  const [images, setImages] = React.useState([]);

  const [isUploadVideo, setIsUploadVideo] = useState(false);
  const [isUploadVideo2, setIsUploadVideo2] = useState(false);
  const [isUploadVideo3, setIsUploadVideo3] = useState(false);
  const [isUploadVideo4, setIsUploadVideo4] = useState(false);

  const [fileName, setFileName] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const [file, setFileData] = useState(null);
  const [file2, setFileData2] = useState(null);
  const [file3, setFileData3] = useState(null);

  const [img, setImg] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");

  const [fileVideo, setFileVideo] = useState(null);
  const [fileVideo2, setFileVideo2] = useState(null);
  const [fileVideo3, setFileVideo3] = useState(null);
  const [fileVideo4, setFileVideo4] = useState(null);
  const [video, setVideo] = useState(null);
  const [video2, setVideo2] = useState(null);
  const [video3, setVideo3] = useState(null);
  const [video4, setVideo4] = useState(null);
  const [defaultImage, setDefaultImage] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [editSelectedCategories, setEditSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isComplete, setIsComplete] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [fileType, setFileType] = useState("");
  const [folderName, setfolderName] = useState("6. Trigger Point Library/");

  const maxNumber = 1;

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const selectedMedia = (fileName, file) => {
    console.log("2222200=====>", fileName, file);
    if (fileName == "image") {
      setFileData(file);
      setImg(file);
      setIsUpload(true);
    } else if (fileName == "image2") {
      setFileData2(file);
      setImg2(file);
      setIsUpload2(true);
    } else if (fileName == "image3") {
      setFileData3(file);
      setImg3(file);
      setIsUpload3(true);
    } else if (fileName == "video") {
      setFileVideo(file);
      setVideo(file);
      setIsUploadVideo(true);
    } else if (fileName == "video2") {
      setFileVideo2(file);
      setVideo2(file);
      setIsUploadVideo2(true);
    } else if (fileName == "video3") {
      setFileVideo3(file);
      setVideo3(file);
      setIsUploadVideo3(true);
    } else if (fileName == "video4") {
      setFileVideo4(file);
      setVideo4(file);
      setIsUploadVideo4(true);
    }
    clearErrors(fileName);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const resetImage = (type) => {
    if (type == "image") {
      setFileData(null);
      setImg(null);
    } else if (type == "image2") {
      setFileData2(null);
      setImg2(null);
    } else {
      setFileData3(null);
      setImg3(null);
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    setFileData(null);
    setImg(null);
  };

  const deleteImage = (fileName) => {
    if (fileName == "video") {
      setFileVideo(null);
      setVideo(null);
      URL.revokeObjectURL(null);
    } else if (fileName == "video2") {
      setFileVideo2(null);
      setVideo2(null);
      URL.revokeObjectURL(null);
    } else if (fileName == "video3") {
      setFileVideo3(null);
      setVideo3(null);
      URL.revokeObjectURL(null);
    } else {
      setFileVideo4(null);
      setVideo4(null);
      URL.revokeObjectURL(null);
    }
  };

  const selectedCategoryOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedCategories(data);
    setEditSelectedCategories(selectedValue);
  };

  const getCategories = async () => {
    await GetPainPointCategories(keyword, page, perPage, 0, "", "", "")
      .then((result) => {
        if (result.status) {
          if (result.data) {
            setCategories(result.data);
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
    setImg(event.target.files[0]);
    setIsUpload(true);
  };

  const handleChangeImage2 = (event) => {
    setFileData2(URL.createObjectURL(event.target.files[0]));
    setImg2(event.target.files[0]);
    setIsUpload2(true);
  };

  const handleChangeImage3 = (event) => {
    setFileData3(URL.createObjectURL(event.target.files[0]));
    setImg3(event.target.files[0]);
    setIsUpload3(true);
  };

  const handleChangeVideo = (event) => {
    setFileVideo(URL.createObjectURL(event.target.files[0]));
    setVideo(event.target.files[0]);
    setIsUploadVideo(true);
  };

  const handleChangeVideo2 = (event) => {
    setFileVideo2(URL.createObjectURL(event.target.files[0]));
    setVideo2(event.target.files[0]);
    setIsUploadVideo2(true);
  };

  const handleChangeVideo3 = (event) => {
    setFileVideo3(URL.createObjectURL(event.target.files[0]));
    setVideo3(event.target.files[0]);
    setIsUploadVideo3(true);
  };
  const handleChangeVideo4 = (event) => {
    setFileVideo4(URL.createObjectURL(event.target.files[0]));
    setVideo4(event.target.files[0]);
    setIsUploadVideo4(true);
  };

  const handleVideoTitle = (event) => {
    setVideoTitle(event.target.value);
  };

  const handleVideoTitle2 = (event) => {
    setVideoTitle2(event.target.value);
  };
  const handleVideoTitle3 = (event) => {
    setVideoTitle3(event.target.value);
  };
  const handleVideoTitle4 = (event) => {
    setVideoTitle4(event.target.value);
  };

  useEffect(() => {
    setLoader(true);

    getCategories();

    GetPainPointsById(id)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            console.log("result.data===>", result.data);
            setRow(result.data);
            setFormData(result.data);
            setValue("title", result?.data?.title);
            setValue("title2", result?.data?.title2);
            setIsComplete(result?.data?.is_complete);
            setValue("internal_notes", result?.data?.internal_notes);
            let selectedExerciseTypes = [];
            if (result.data?.painPointCategories) {
              let arr = [];
              result.data?.painPointCategories.map((item) => {
                selectedExerciseTypes.push({
                  value: item.id,
                  label: item.name,
                });
                arr.push(item.id);
              });
              setSelectedCategories(arr);
              setEditSelectedCategories(selectedExerciseTypes);
            }

            setFileData(result?.data?.image);
            setFileData2(result?.data?.image2);
            setFileData3(result?.data?.image3);
            setImg(result?.data?.image);
            setImg2(result?.data?.image2);
            setImg3(result?.data?.image3);
            setFileVideo(result?.data?.video);
            setFileVideo2(result?.data?.video2);
            setFileVideo3(result?.data?.video3);
            setFileVideo4(result?.data?.video4);
            setVideo(result?.data?.video);
            setVideo2(result?.data?.video2);
            setVideo3(result?.data?.video3);
            setVideo4(result?.data?.video4);
            setValue("description", result?.data?.description);
            setValue("search_keyword", result?.data?.search_keyword);
            setVideoTitle(result?.data?.video_title);
            setVideoTitle2(result?.data?.video_title2);
            setVideoTitle3(result?.data?.video_title3);
            setVideoTitle4(result?.data?.video_title4);

            // setValue("video_title", result?.data?.video_title);
            // setValue("video_title2", result?.data?.video_title2);
            // setValue("video_title3", result?.data?.video_title3);
            // setValue("video_title4", result?.data?.video_title4);

            setDescription(result?.data?.description);
            // setTitle(result.data.title);
            // setVideo(result.data.video);
            // setFile(result.data.image)
            // setIsUpload(true);
            // setIsUploadVideo(true);
            setLoader(false);
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  }, []);
  const onSubmit = async (data) => {
    console.log("data======>", data, isUploadVideo);
    if (!img) {
      toast.error("Image 1 is required");
      return false;
    }
    data.video_title = videoTitle;
    data.video_title2 = videoTitle2;
    data.video_title3 = videoTitle3;
    data.video_title4 = videoTitle4;
    setLoader(true);
    if (isUpload) {
      data.image = img;
    }

    if (isUpload2) {
      data.image2 = img2;
    }

    if (isUpload3) {
      data.image3 = img3;
    }

    if (isUploadVideo) {
      data.video = video;
    }

    if (isUploadVideo2) {
      data.video2 = video2;
    }

    if (isUploadVideo3) {
      data.video3 = video3;
    }
    if (isUploadVideo4) {
      data.video4 = video4;
    }
    data.pain_point_categories = selectedCategories;
    data.description = description;
    await UpdatePainPoints(id, data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/pain-point-library");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  // const handleVideoChange = async (fileInput) => {
  //     console.log("click",fileInput.length);
  //     if(fileInput.length == 0){
  //         setVideo('');
  //         setFileVideo('')
  //         setIsUploadVideo(false)
  //     }

  // };

  // const validateDescription = () => {
  //     if (description === null || description.length === 0) {
  //         return 'Description is required';
  //     }
  //     return true; // Validation passed
  // };

  const BackTriggerr = () => {
    history.push("/pain-point-library");
  };

  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
    ],
  };
  return (
    <>
      <FullPageLoader loading={loader} />
      <div className="trigger-Point-index">
        <div className="page-title">
          <h1>Edit Trigger Point</h1>
          <Button onClick={BackTriggerr} className={"btn common-btn"}>
            Back
          </Button>
        </div>
        <div className="common-form">
          <Form
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md={3}>
                <Form.Group className="common-input" controlId="categories">
                  <Form.Label className="d-block">Category</Form.Label>
                  <Select
                    id="pain_point_category_id"
                    isMulti
                    value={editSelectedCategories}
                    options={
                      categories &&
                      categories.map((category) => ({
                        label: category.name,
                        value: category.id,
                      }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedCategoryOpt}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                {/* <>
                  <MyEditor
                    handleChange={(data) => {
                      try {
                        setTitle(data);
                      } catch (error) {
                        console.error("Error in CKEditor onChange:", error);
                      }
                    }}
                    data={title}
                    {...props}
                  />
                </> */}
                {/* <CKEditor
                                    id={"title"}
                                    {...register('title', {
                                    })}
                                    className={'custom-height'}
                                    editor={ClassicEditor}
                                    data={title}
                                    config={editorConfiguration}
                                    onChange={(event, editor) => {
                                        try {
                                            const data = editor.getData();
                                            setTitle(data);

                                        } catch (error) {
                                            console.error("Error in CKEditor onChange:", error);
                                        }
                                    }}
                                /> */}
                <Form.Group className="common-input" controlId="title">
                  <Form.Label>Title 1*</Form.Label>
                  <Form.Control
                    placeholder="Enter Title"
                    {...register("title", {
                      required: {
                        value: "required",
                        message: "Title is required",
                      },
                      // minLength: {
                      //   value: 3,
                      //   message: "Min length is 3",
                      // },
                      // maxLength: {
                      //   value: 80,
                      //   message: "Max length is 80",
                      // },
                    })}
                    type="text"
                  />
                  {errors.title && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.title.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="common-input" controlId="title">
                  <Form.Label>Title 2</Form.Label>

                  <Form.Control
                    placeholder="Enter Title"
                    {...register("title2", {})}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="common-input" controlId="title">
                  <Form.Label>Keywords</Form.Label>
                  <Form.Control
                    placeholder="Enter Keyword"
                    {...register("search_keyword", {
                      // required: {
                      //     value: "required",
                      //     message: "Keyword is required"
                      // }
                    })}
                    type="text"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <ImageUploading
                      className="mb-2"
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            type="button"
                            className="btn common-btn  mb-3"
                            onClick={() => {
                              setFileType("images");
                              setFileName("image");
                              setModalShow(true);
                            }}
                          >
                            Upload Image 1
                          </button>
                          &nbsp;
                          {file ? (
                            <>
                              <div className="image-item">
                                <img
                                  src={file ? file : defaultImage}
                                  className={"img-table img-thumbnail"}
                                  crossOrigin={
                                    cross_origin ? "anonymous" : null
                                  }
                                />
                                <Button
                                  type="button"
                                  className="btn common-btn"
                                  onClick={() => resetImage("image")}
                                >
                                  <TrashIcon />
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div className="third-img"></div>
                          )}
                        </div>
                      )}
                    </ImageUploading>
                  </Col>
                  <Col md={4}>
                    <ImageUploading
                      className="mb-2"
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            type="button"
                            className="btn common-btn  mb-3"
                            onClick={() => {
                              setFileType("images");
                              setFileName("image2");
                              setModalShow(true);
                            }}
                          >
                            Upload Image 2
                          </button>
                          &nbsp;
                          {file2 ? (
                            <>
                              <div className="image-item">
                                <img
                                  src={file2 ? file2 : defaultImage}
                                  className={"img-table img-thumbnail"}
                                  crossOrigin={
                                    cross_origin ? "anonymous" : null
                                  }
                                />
                                <Button
                                  type="button"
                                  className="btn common-btn"
                                  onClick={() => resetImage("image2")}
                                >
                                  <TrashIcon />
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div className="third-img"></div>
                          )}
                        </div>
                      )}
                    </ImageUploading>
                  </Col>
                  <Col md={4}>
                    <ImageUploading
                      className="mb-2"
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            type="button"
                            className="btn common-btn  mb-3"
                            onClick={() => {
                              setFileType("images");
                              setFileName("image3");
                              setModalShow(true);
                            }}
                          >
                            Upload Image 3
                          </button>
                          &nbsp;
                          {file3 ? (
                            <>
                              <div className="image-item">
                                <img
                                  src={file3 ? file3 : defaultImage}
                                  className={"img-table img-thumbnail"}
                                  crossOrigin={
                                    cross_origin ? "anonymous" : null
                                  }
                                />
                                <Button
                                  type="button"
                                  className="btn common-btn"
                                  onClick={() => resetImage("image3")}
                                >
                                  <TrashIcon />
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div className="third-img"></div>
                          )}
                        </div>
                      )}
                    </ImageUploading>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="common-input" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"description"}
                      // {...register("description", {
                      //   //validate: validateDescription // Use the custom validation function
                      // })}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onChange={(event, editor) => {
                        try {
                          const data = editor.getData();
                          setDescription(data);
                          // if (!data) {
                          //     setError('description', { message: 'Description is required' });
                          // } else if (data.length < 10) {
                          //     setError('description', { message: 'Min length is 3' });
                          // } else if (data.length > 1008) {
                          //     setError('description', { message: 'Max length is 1000' });
                          // } else {
                          //     clearErrors('description')
                          // }
                        } catch (error) {
                          console.error("Error in CKEditor onChange:", error);
                        }
                      }}
                      data={description}
                    />
                  </div>
                  {errors.description && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.description.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile"
                  //   onChange={(e) => handleChangeVideo(e)}
                  onClick={() => {
                    setFileType("videos");
                    setFileName("video");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 1
                  </Form.Label>
                </Form.Group>
                <div className="img-table img-thumbnail">
                  {fileVideo !== null && (
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo}
                        title="Video"
                      ></video>
                      <button
                        className={"btn common-btn"}
                        onClick={() => deleteImage("video")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </div>
                <Form.Group className="mt-3" controlId="video">
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle}
                    value={videoTitle}

                    //                   videoTitle
                    // videoTitle2
                    // videoTitle3
                    // videoTitle4
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile2"
                  //   onChange={(e) => handleChangeVideo2(e)}
                  onClick={() => {
                    setFileType("videos");
                    setFileName("video2");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 2
                  </Form.Label>
                  {/* <Form.Control
                    accept="video/*"
                    type="file"
                    {...register("video2")}
                  /> */}
                </Form.Group>
                <div className="img-table img-thumbnail">
                  {fileVideo2 !== null && (
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo2}
                        title="Video2"
                      ></video>
                      <button
                        className={"btn common-btn"}
                        onClick={() => deleteImage("video2")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </div>
                <Form.Group className="mt-3" controlId="title">
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle2}
                    value={videoTitle2}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile3"
                  //   onChange={(e) => handleChangeVideo3(e)}
                  onClick={() => {
                    setFileType("videos");
                    setFileName("video3");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 3
                  </Form.Label>
                  {/* <Form.Control
                    accept="video/*"
                    type="file"
                    {...register("video3")}
                  /> */}
                </Form.Group>
                <div className="img-table img-thumbnail">
                  {fileVideo3 !== null && (
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo3}
                        title="Video3"
                      ></video>

                      <button
                        className={"btn common-btn"}
                        onClick={() => deleteImage("video3")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </div>
                <Form.Group className="mt-3" controlId="title">
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle3}
                    value={videoTitle3}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile4"
                  //   onChange={(e) => handleChangeVideo4(e)}
                  onClick={() => {
                    setFileType("videos");
                    setFileName("video4");
                    setModalShow(true);
                  }}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 4
                  </Form.Label>
                  {/* <Form.Control
                    accept="video/*"
                    type="file"
                    {...register("video4")}
                  /> */}
                </Form.Group>
                <div className="img-table img-thumbnail">
                  {fileVideo4 !== null && (
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo4}
                        title="Video4"
                      ></video>

                      <button
                        className={"btn common-btn"}
                        onClick={() => deleteImage("video4")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </div>
                <Form.Group className="mt-3" controlId="title">
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle4}
                    value={videoTitle4}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={isComplete}
                    id={"isComplete"}
                    className="form-select exercise-dropdown"
                    aria-label="IsComplete"
                    {...register("is_complete", {
                      onChange: (e) => {
                        setIsComplete(e.target.value);
                      },
                    })}
                  >
                    <option value="0">Incomplete</option>
                    <option value="1">Complete</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={7}>
                <Form.Group>
                  <Form.Label>Internal Notes </Form.Label>
                  <Form.Control
                    as="textarea"
                    className="exercise-input"
                    placeholder="Internal Notes"
                    id={"internal_notes"}
                    {...register("internal_notes", {
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 500,
                        message: "Max length is 500",
                      },
                      //   onChange: (e) => props.changed(true)
                    })}
                    type="text"
                  />

                  {errors.internal_notes && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.internal_notes.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={2} className="mt-2">
                {loader ? (
                  <FullPageLoader loading={loader} />
                ) : (
                  <Button
                    type={"submit"}
                    className={"btn common-btn mt-4 w-100"}
                    value={"Create Muscles Anatomy"}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>

        <ThemeModal
          title={"S3 Browser"}
          content={
            <S3Browser
              fileName={fileName}
              selectedMedia={selectedMedia}
              fileType={fileType}
              folderName={folderName}
            />
          }
          size={"xl"}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      </div>
    </>
  );
}

export default EditPainPoint;
