import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import {
  AddIconOutline,
  SearchIcon,
  DeleteUpdated,
} from "../../components/SvgIcons";
import { toast } from "react-toastify";
import { genders } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  GetPrograms,
  DeleteProgram,
  DuplicateProgram,
  DeleteMultipleProgram,
} from "../../services/programs.service";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import { MdEdit, MdDelete, MdSummarize } from "react-icons/md";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import { MenuItem, Pagination, Select, Stack, Tooltip } from "@mui/material";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function ProgramLibrary(props) {
  let history = useHistory();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [bodyType, setBodyType] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [keyword, setKeyword] = useState("");
  const [orderByValue, setOrderByValue] = useState("asc");
  const [orderByColumn, setOrderByColumn] = useState("name");
  const [rowData, setRowData] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  //Multiple Ids Delete
  const apiRef = useGridApiRef();



  const CustomFooter = ({ totalRows }) => (
    <div style={{ padding: "10px", textAlign: "right" }}>
      Total Rows: {totalRows}
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      await getPrograms(true);
    };

    fetchData();
  }, [bodyType, subCategory, perPage.page]);

  useEffect(() => {
    const fetchData = async () => {
      if (keyword) {
        await getPrograms(false);
      } else {
        await getPrograms(true);
      }
    };

    fetchData();
  }, [keyword, bodyType, subCategory, perPage.page]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("programs", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("programs", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("programs", "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => (
                  <>
                    <div>
                      <Tooltip title="Edit" arrow>
                        <button
                          className={"btn btn-primary btn-sm"}
                          onClick={(event) => {
                            event.stopPropagation();
                            onEdit(params.id);
                          }}
                        >
                          <MdEdit />
                        </button>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <button
                          className={"btn btn-danger btn-sm mx-2"}
                          onClick={(event) => {
                            event.stopPropagation();
                            onDelete(params.id);
                          }}
                        >
                          <MdDelete />
                        </button>
                      </Tooltip>
                      <Tooltip title="Summarize" arrow>
                        <button
                          className={"btn btn-info  btn-sm"}
                          onClick={(event) => {
                            event.stopPropagation();
                            onReport(params.row);
                          }}
                        >
                          <MdSummarize />
                        </button>
                      </Tooltip>
                      <Tooltip title="Duplicate" arrow>
                        <button
                          className={"btn btn-warning  btn-sm mx-2"}
                          onClick={(event) => {
                            event.stopPropagation();
                            onDuplicate(params.id);
                          }}
                        >
                          <HiOutlineDocumentDuplicate />
                        </button>
                      </Tooltip>
                    </div>
                  </>
                ),
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "programs",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getPrograms = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }

    await GetPrograms(
      keyword,
      "",
      page,
      perPage,
      1,
      orderByValue,
      orderByColumn
    )
      .then((result) => {
        if (result?.status) {
          if (result?.data) {
            const rowData = [];
            setPage(result?.data?.meta?.current_page);
            setRowData(result?.data?.meta?.total);
            setPageCount(result?.data?.meta?.last_page);
            result?.data?.data?.map((dt) => {
              rowData.push({
                id: dt?.id,
                name: dt?.name,
                injury: dt?.injury ? dt?.injury.name : "-",
                bodyType: dt?.bodyType ? dt?.bodyType.name : "-",
                category: dt?.category ? dt?.category.name : "-",
                notes: dt?.programDays
                  ? dt?.programDays[0]?.internal_notes
                  : "-",
                isDraft: dt?.is_draft
                  ? dt?.is_draft == 1
                    ? "Complete"
                    : "Incomplete"
                  : "Incomplete",
                gender: dt?.gender
                  ? dt?.gender === genders.MALE
                    ? "Male"
                    : "Female"
                  : "",

                daysPerWeek: dt?.days_per_week ? dt?.days_per_week : "",
              });
            });
            setRows(rowData);
            setLoading(false);
          }
        }
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
      })
      .catch((error) => {
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
      });
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "programs",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("programs", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const onReport = (program) => {
    history.push({
      pathname: "/manage-program",
      state: {
        program: program,
        redirectToSummary: true,
      },
    });
  };

  const onEdit = (id) => {
    id
      ? history.push("/edit-program/" + id)
      : toast.error("Something wrong. Please refresh page.");
  };

  const onDuplicate = (program) => {
    console.log("program", program);
    swal({
      title: "Are you sure?",
      text: "You want to duplicate this program?",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter program name",
          className: "form-control",
          required: true,
        },
      },
      buttons: {
        close: {
          text: "Cancel",
          value: { button: "cancel" },
        },
        confirm: {
          text: "Confirm",
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(async (willShip) => {
      if (willShip?.button !== "cancel") {
        if (willShip) {
          const data = {
            name: willShip,
          };
          await DuplicateProgram(program, data)
            .then((result) => {
              if (result.status) {
                swal(result.message, {
                  icon: "success",
                });
                getPrograms();
              } else {
                toast.error(result.message);
              }
            })
            .catch((error) => {
              toast.error(error.response.data.message.replace(/_/g, " "));
            });
        } else {
          swal("Warning", "Program name is required!", "warning");
        }
      }
    });
  };

  const onDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Program?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      if (willShip) {
        await DeleteProgram(id)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getPrograms();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "#", width: 100 },
      { field: "name", headerName: "Program Name", width: 300 },
      { field: "gender", headerName: "Gender", width: 80 },
      { field: "bodyType", headerName: "Body Type", width: 150 },
      {
        field: "category",
        headerName: "Sub Category",
        width: 120,
      },
      {
        field: "notes",
        headerName: "Notes",
        width: 140,
        editable: true,
      },
      { field: "injury", headerName: "Injury Program", width: 300 },
      {
        field: "daysPerWeek",
        headerName: "Days",
        width: 80,
      },
      { field: "isDraft", headerName: "Draft", width: 130 },

      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="Edit" arrow>
                <button
                  className={"btn btn-primary btn-sm"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEdit(params.id);
                  }}
                >
                  <MdEdit />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  className={"btn btn-danger btn-sm mx-2"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(params.id);
                  }}
                >
                  <MdDelete />
                </button>
              </Tooltip>
              <Tooltip title="Summary" arrow>
                <button
                  className={"btn btn-info  btn-sm"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onReport(params.row);
                  }}
                >
                  <MdSummarize />
                </button>
              </Tooltip>
              <Tooltip title="Duplicate" arrow>
                <button
                  className={"btn btn-warning  btn-sm mx-2"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDuplicate(params?.row?.id);
                  }}
                >
                  <HiOutlineDocumentDuplicate />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const programManagementRedirect = (data) => {
    history.push("/program-trash-data/");
  };

  //Multiple Delete Ids

  const onMultipleDelete = async () => {
    const rows = await apiRef.current.getSelectedRows();

    const dataArray = [...rows.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removePrograms: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleProgram(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getPrograms();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "programs",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };

      const result = await ColumnHideShow(requestObj);

      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        setLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <div className={"orderPage program-index program-index-custom"}>
        <h1>Program Management Library</h1>
        <div className={"customOrderFilters"}>
          <Row>
            <Col md={1}></Col>
            <Col md={5} className="ms-auto">
              <div className="all-user">
                <div className="search-bar">
                  <div className="common-search-bar">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <SearchIcon />
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="Search"
                        onChange={(e) => {
                          e.target.value.length > 0
                            ? setKeyword(e.target.value.trim())
                            : setKeyword(null);
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <Link to={"/add-new-program"}>
                <Button className={"btn common-btn   w-100"}>
                  <AddIconOutline /> Add New
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
        {/*
      New MUI Table Hook
      */}
        <div className="common-table mt-2">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>
          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
                footer: () => <CustomFooter totalRows={rowData} />,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>
          <div className="user-pagination">
            <Row>
              <Col md={4}>
                {" "}
                <div className="theme-dataGrid">
                  <Button
                    className={"btn common-btn   w-35"}
                    onClick={() => programManagementRedirect()}
                  >
                    Trashed Exercises
                  </Button>
                </div>
              </Col>
              <Col md={4}>
                {" "}
                <Stack spacing={2}>
                  <Pagination
                    count={countPage}
                    page={page}
                    onChange={handlePaginationChange}
                    size="large"
                  />
                </Stack>
              </Col>
              <Col md={4}>
                {" "}
                <Stack direction="row" spacing={2} alignItems="center">
                  <label>Rows per page:</label>
                  <Select value={perPage} onChange={handlePageSizeChange}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </Stack>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgramLibrary;
