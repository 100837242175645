import React from "react";
import { FadeLoader } from "react-spinners";


const FullPageLoader = ({ loading }) => {
  return (
    <div className={`loader-container ${loading ? "visible" : ""}`}>
      <FadeLoader color={"#c00000"} loading={loading} />
    </div>
  );
};
    
export default FullPageLoader;
