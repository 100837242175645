import React, { useEffect, useState } from "react"
import { Col, Row, Form, Button } from "react-bootstrap";
import {constants, cross_origin, genders} from "../../utils/constants";
import { useHistory, useLocation } from "react-router";
import { GetProgramSummary, UpdateProgram } from "../../services/programs.service";
import obliques from "../../assets/images/obliques-m.PNG";
import russianTwist from "../../assets/images/russian-twist-1.jpg";
import { Interweave } from "interweave";
import { AiFillEye } from "react-icons/ai";
import ThemeModal from "../../components/ThemeModal";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { toast } from "react-toastify";

const SummaryBox = (props) => {
    const objData = props.data;
    const maxLength = 40;
    const [content, setContent] = useState(null);
    const [detailModalShow, setDetailModalShow] = useState(false);

    const CheckPosImage = (feelPos) => {
        if (feelPos === "TR") {
            return "top-right";
        }
        else if (feelPos === "TL") {
            return "top-left";
        }
        else if (feelPos === "BL") {
            return "bottom-left";
        }
        else if (feelPos === "BR") {
            return "bottom-right";
        }
        else {
            return "";
        }
    }

    const CheckPosText = (overlayPos) => {
        if (overlayPos === "TR") {
            return "top-right";
        }
        else if (overlayPos === "TL") {
            return "top-left";
        }
        else if (overlayPos === "BL") {
            return "bottom-left";
        }
        else if (overlayPos === "BR") {
            return "bottom-right";
        }
        else {
            return "";
        }
    }

    const checkOrientationText = (n) => {
        if (n == 1) {
            return "Left Side Only";
        }
        else if (n == 2) {
            return "Right Side Only";
        }
        // else if (n == 3) {
        //     return "Both Sides";
        // } 
        else {
            return "";
        }
    }

    const ReadMoreP = ({ text, maxLength, className }) => {
        const [expanded, setExpanded] = useState(false);
        const truncatedText = text?.slice(0, maxLength);
        return (
            <div>
                <div className={`titles-label ${className}`} style={{ wordWrap: 'break-word' }}>
                    <Interweave content={expanded ? text : truncatedText} />
                    {/*{expanded*/}
                    {/*    ? text*/}
                    {/*    : text?.length > maxLength*/}
                    {/*        ? `${text.slice(0, maxLength)}...`*/}
                    {/*        : text}*/}
                    {" "}
                    <button className={'btn-expand'} onClick={() => setExpanded(!expanded)}>
                        {expanded ? ' See less' : ' See more'}
                    </button>
                </div>

            </div>
        );
    }

    const detailModalShowFunc = (n, content) => {
        if (content.exercise.exerciseLevels && content.exercise.exerciseLevels.length > 0) {
            setContent(content.exercise.exerciseLevels)
        }
        setDetailModalShow(true)
    }

    const detailModalHideFunc = () => {
        setContent(null)
        setDetailModalShow(false)
    }

    const ExerciseDetail = ({ content }) => {
        const [data, setData] = useState([]);
        const [level1Data, setLevel1Data] = useState([]);
        const [level2Data, setLevel2Data] = useState([]);
        const [level3Data, setLevel3Data] = useState([]);

        useEffect(() => {
            if (content && content != null) {
                setData(content)
            } else {
                setData([])
            }
        }, [content])

        useEffect(() => {
            if (data && data != null && data.length > 0) {
                let level1 = []
                let level2 = []
                let level3 = []
                data.forEach((element) => {
                    if (element.level_id === 1) {
                        level1.push(element)
                    } else if (element.level_id === 2) {
                        level2.push(element)
                    } else if (element.level_id === 3) {
                        level3.push(element)
                    }
                })
                setLevel1Data(level1)
                setLevel2Data(level2)
                setLevel3Data(level3)
            } else {
                setLevel1Data([])
                setLevel2Data([])
                setLevel3Data([])
            }
        }, [data])
        return (
            <div className={"modalFeelItHere"}>
                <div className={"mt-3"}>
                    <Row>
                        {
                            level1Data &&
                            level1Data.map((exercise, index) => (
                                <Col xs={12} sm={12} md={6} lg={3} xl={3} key={index}>
                                    <div className="detail-card border-round">
                                        <div className="heading">
                                            {exercise.equipment_exercise_type == 0 ? 'Level ' + exercise.level_id : 'Level ' + exercise.level_id + ' Equipment ' + exercise.equipment_exercise_type} {exercise.gender == genders.MALE ? 'M' : 'F'}
                                        </div>
                                        <img className={"img-fluid detail-img"} crossOrigin={cross_origin ? "anonymous" : null} src={exercise.video_cover ? exercise.video_cover : russianTwist} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = constants.IMAGENOTFOUND;
                                        }} alt={"Exercise Cover"} title={"Exercise Cover Image"} />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row className={"mt-3"}>
                        {
                            level2Data &&
                            level2Data.map((exercise, index) => (
                                <Col xs={12} sm={12} md={6} lg={3} xl={3} key={index}>
                                    <div className="detail-card border-round">
                                        <div className="heading">
                                            {exercise.equipment_exercise_type == 0 ? 'Level ' + exercise.level_id : 'Level ' + exercise.level_id + ' Equipment ' + exercise.equipment_exercise_type} {exercise.gender == genders.MALE ? 'M' : 'F'}
                                        </div>
                                        <img className={"img-fluid detail-img"} crossOrigin={cross_origin ? "anonymous" : null} src={exercise.video_cover ? exercise.video_cover : russianTwist} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = constants.IMAGENOTFOUND;
                                        }} alt={"Exercise Cover"} title={"Exercise Cover Image"} />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row className={"mt-3"}>
                        {
                            level3Data &&
                            level3Data.map((exercise, index) => (
                                <Col xs={12} sm={12} md={6} lg={3} xl={3} key={index}>
                                    <div className="detail-card border-round">
                                        <div className="heading">
                                            {exercise.equipment_exercise_type == 0 ? 'Level ' + exercise.level_id : 'Level ' + exercise.level_id + ' Equipment ' + exercise.equipment_exercise_type} {exercise.gender == genders.MALE ? 'M' : 'F'}
                                        </div>
                                        <img className={"img-fluid detail-img"} crossOrigin={cross_origin ? "anonymous" : null} src={exercise.video_cover ? exercise.video_cover : russianTwist} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = constants.IMAGENOTFOUND;
                                        }} alt={"Exercise Cover"} title={"Exercise Cover Image"} />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='mt-5 summary-box'>
                <div className={"summary-box-head"}>
                    <h2>Day {objData.day}</h2>
                </div>
                <div className={"summary-box-body"}>
                    <Row className={"mb-2 g-1"}>
                        {
                            objData.programDayExercises.length > 0 ? objData.programDayExercises.map((item, key) => {
                                return (
                                    <Col xs={6} sm={6} md={4} lg={4} key={key}>
                                        <div className='titles-label mb-1'>Exercise {item.exercise_no}:</div>
                                        <div className={"modalFeelItHere"}>
                                            <div className={"image-view-container"} onClick={() => detailModalShowFunc(1, item)}> {/*  onClick={() => detailModalShowFunc(1, item)} */}
                                                <img crossOrigin={cross_origin ? "anonymous" : null} className={`${item && item.position == 2 ? 'img-fluid flip-video' : 'img-fluid'}`} src={item.exerciseLevel ? item.exerciseLevel.video_cover : ''} alt={'Exercise Cover'} onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = constants.IMAGENOTFOUNDREC;
                                                    currentTarget.classList.remove('flip-video');
                                                }} />
                                                {
                                                    item && item.feel_it_show && <img className={`img-fluid feel-it-here ${CheckPosImage(item && item.feel_it_position ? item.feel_it_position : '')}`} crossOrigin={cross_origin ? "anonymous" : null} src={item && item.exerciseLevel && item.exerciseLevel.body_part ? item.exerciseLevel.body_part.male_image : obliques} onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = constants.IMAGENOTFOUND;
                                                    }} alt={"Feel IT"} title={"Feel It Here Image"} />
                                                }
                                                {
                                                    item && item.text_show && item.text_position !== "BO" ? <p className={`text-placement ${CheckPosText(item.text_position)}`} style={{ fontSize: '9px' }}>{`${checkOrientationText(item && item.position ? item.position : '')}`}</p> : ""
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }) :
                                'No Summary Available'
                        }
                    </Row>
                </div>
                <div className='titles-box'>
                    <ReadMoreP text={objData?.internal_notes} maxLength={maxLength} className={'text-danger'} />
                </div>
            </div>
            <ThemeModal
                title={content && content.length > 0 && content[0].exercise ? content[0].exercise.name : ''}
                content={<ExerciseDetail content={content} />} size={"lg"}
                show={detailModalShow}
                onHide={() => {
                    detailModalHideFunc()
                }}
            />
        </>
    )
}

const ProgramSummary = (props) => {
    let history = useHistory();
    let location = useLocation();
    const [program, setProgram] = useState();
    const [programDays, setProgramDays] = useState([]);
    const [day, setDay] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    if (location.state !== undefined && program === undefined) {
        setProgram(location.state.program)
    }

    if (location.state === undefined && program === undefined) {
        history.push('/add-new-program')
    }

    useEffect(() => {
        const getProgramSummary = async (program_id, day) => {
            await GetProgramSummary(program_id, day).then((result) => {
                if (result.status && result.data) {
                    setProgramDays(result.data)
                    props.changed(false)
                }
            })
        }
        getProgramSummary(program.id, day)
        props.changed(false)
    }, [program, day])

    const updateCompleted = async () => {
        let dt = {
            name: program.name,
            is_draft: 1
        }
        await UpdateProgram(program.id, dt).then(async (data) => {
            if (data.status) {
                history.push({
                    pathname: '/program-library/',
                });
            } else {
                setDisableButton(false)
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                setDisableButton(false)
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    }

    return (
        <div className={"programSummary"}>
            <Button className='finish-btn' type="button" onClick={() => updateCompleted()} >
                Finish
            </Button>
            <Row>
                <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                    <Form.Label className="d-block mt-sm-2">Days</Form.Label>
                    <Form.Select
                        className="formselect program-dropdown"
                        aria-label="Weight"
                        onChange={(e) => setDay(e.target.value)}
                    >
                        <option value="" selected={true}>All Days</option>
                        <option value={1}>Day 1</option>
                        <option value={2}>Day 2</option>
                        <option value={3}>Day 3</option>
                        <option value={4}>Day 4</option>
                        <option value={5}>Day 5</option>
                        <option value={6}>Day 6</option>
                        <option value={7}>Day 7</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                {
                    programDays.length > 0 ? programDays.map((data, key) => {
                        return (
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <SummaryBox data={data} key={key} />
                            </Col>
                        )
                    }) :
                        'No Summary Available'
                }
            </Row>
        </div>
    )
}

export default ProgramSummary