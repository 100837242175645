import { apiUrl } from "../../utils/constants";
import { GET, PUT } from "../api.service.wrapper";

export const GetDashboard = async (params) => {
  const response = await GET(apiUrl.dashboard, params);
  return response;
};

export const GetDashboardSummary = async () => {
  const response = await GET(apiUrl.dashboard_summary);
  return response;
};

export const GetDashboardDetails = async (params) => {
  const response = await GET(apiUrl.dashboard_data);
  return response;
};

export const GetWeightSettings = async (id) => {
  const response = await GET(apiUrl.get_setting_details + "/" + id);
  return response;
};

export const UpdateWeightSettings = async (id, weight) => {
  const response = await PUT(apiUrl.update_weight_settings, id, {
    weight: weight,
  });
  return response;
};
