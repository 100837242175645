import AWS from "aws-sdk";
import {
  ChonkyActions,
  FileBrowser,
  FileList,
  FileNavbar,
  FileToolbar,
  setChonkyDefaults,
} from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { baseName, useStoryLinks, eitsDecryptString } from "../utils/helpers";
import { constants } from "../utils/constants";
import { FadeLoader } from "react-spinners";
import { Link } from "react-router-dom";

setChonkyDefaults({ iconComponent: ChonkyIconFA });
// The AWS credentials below only have read-only access to the Chonky demo bucket.
// You will need to create custom credentials for your bucket.
const BUCKET_NAME = constants.S3CREDENTIAL.bucketName;
const BUCKET_REGION = constants.S3CREDENTIAL.region;
const ACCESS_KEY_ID = await eitsDecryptString(
  constants.S3CREDENTIAL.accessKeyId,
  constants.S3CREDENTIAL.fallback,
  constants.S3CREDENTIAL.configKey,
  constants.S3CREDENTIAL.configIv
);
const SECRET_ACCESS_KEY = await eitsDecryptString(
  constants.S3CREDENTIAL.secretAccessKey,
  constants.S3CREDENTIAL.fallback,
  constants.S3CREDENTIAL.configKey,
  constants.S3CREDENTIAL.configIv
);

AWS.config.update({
  region: BUCKET_REGION,
  accessKeyId: ACCESS_KEY_ID,
  secretAccessKey: SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3();

const fetchS3BucketContents = async (
  bucket,
  delimiter,
  prefix,
  fileType = null,
  continuationToken = null,
  maxCalls = 3,
  currentCall = 0
) => {
  if (currentCall >= maxCalls) {
    return []; // Stop if the maximum number of calls is reached
  }

  const params = {
    Bucket: bucket,
    Delimiter: delimiter,
    Prefix: prefix !== "/" ? prefix : "",
  };

  if (continuationToken) {
    params.ContinuationToken = continuationToken;
  }

  const response = await s3.listObjectsV2(params).promise();
  const chonkyFiles = [];
  const s3Objects = response.Contents;
  const s3Prefixes = response.CommonPrefixes;

  if (s3Objects && fileType) {
    chonkyFiles.push(
      ...s3Objects
        .filter((object) => {
          if (fileType === "images") {
            return (
              object.Key.endsWith(".jpg") ||
              object.Key.endsWith(".png") ||
              object.Key.endsWith(".jpeg")
            );
          } else {
            return (
              object.Key.endsWith(".mp4") ||
              object.Key.endsWith(".webm") ||
              object.Key.endsWith(".mov") ||
              object.Key.endsWith(".avi") ||
              object.Key.endsWith(".mkv") ||
              object.Key.endsWith(".m4v") ||
              object.Key.endsWith(".flv")
            );
          }
        })
        .map((object) => ({
          id: object.Key,
          name: baseName(object.Key),
          modDate: object.LastModified,
          size: object.Size,
        }))
    );
  } else {
    chonkyFiles.push(
      ...s3Objects.map((object) => ({
        id: object.Key,
        name: baseName(object.Key),
        modDate: object.LastModified,
        size: object.Size,
      }))
    );
  }

  if (s3Prefixes) {
    chonkyFiles.push(
      ...s3Prefixes.map((prefix) => ({
        id: prefix.Prefix,
        name: baseName(prefix.Prefix),
        isDir: true,
      }))
    );
  }

  if (response.IsTruncated) {
    const nextFiles = await fetchS3BucketContents(
      bucket,
      delimiter,
      prefix,
      fileType,
      response.NextContinuationToken,
      maxCalls,
      currentCall + 1
    );
    chonkyFiles.push(...nextFiles);
  }

  return chonkyFiles;
};

const storyName = "AWS S3 Browser";
export const S3Browser = ({
  selectedMedia,
  fileName,
  fileType,
  folderName = "/",
}) => {
  console.log("folderName", folderName);
  const [error, setError] = useState(null);
  const [folderPrefix, setKeyPrefix] = useState(folderName);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true); // State for controlling the loader visibility

  const [searchTerm, setSearchTerm] = useState("");

  // Filter files based on search term
  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setLoading(true); // Show the loader when fetching S3 content starts

    fetchS3BucketContents(BUCKET_NAME, "/", folderPrefix, fileType)
      .then((data) => {
        setFiles(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [folderPrefix, fileType]);

  const searchData = (value) => {
    let delimiter = "";
    let folder = "";
    if (value == "" || value == null) {
      delimiter = "/";
      folder = folderPrefix;
    }
    setLoading(true);
    fetchS3BucketContents(BUCKET_NAME, delimiter, folder, fileType)
      .then((data) => {
        setFiles(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
    setSearchTerm(value);
  };

  const folderChain = React.useMemo(() => {
    let folderChain;
    if (folderPrefix === "/") {
      folderChain = [];
    } else {
      let currentPrefix = "";
      folderChain = folderPrefix
        ? folderPrefix
            .replace(/\/*$/, "")
            .split("/")
            .map((prefixPart) => {
              currentPrefix = currentPrefix
                ? currentPrefix + "/" + prefixPart
                : prefixPart;
              return {
                id: currentPrefix,
                name: prefixPart,
                isDir: true,
              };
            })
        : [];
    }
    folderChain.unshift({
      id: "/",
      name: BUCKET_NAME,
      isDir: true,
    });
    return folderChain;
  }, [folderPrefix]);

  const handleFileAction = useCallback(
    (data) => {
      if (data.id == "mouse_click_file" && !data.payload.file.isDir) {
        selectedMedia(
          fileName,
          constants.S3CREDENTIAL.s3EndPoint + data.payload.file.id
        );
      }
      if (data.id === ChonkyActions.OpenFiles.id) {
        if (data.payload.files && data.payload.files.length !== 1) return;
        if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;
        console.log("targetfile", data.payload.targetFile.id);
        const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, "")}/`;
        setKeyPrefix(newPrefix);
      }
    },
    [setKeyPrefix]
  );

  const clearSearch = (e) => {
    e.preventDefault();
    setSearchTerm("");
  };

  return (
    <div className="story-wrapper">
      <div className="story-description">
        {error && (
          <div className="story-error">
            An error has occurred while loading bucket: <strong>{error}</strong>
          </div>
        )}
      </div>

      <div style={{ height: "75vh" }}>
        <FileBrowser
          instanceId={storyName}
          // files={files}
          files={filteredFiles}
          folderChain={folderChain}
          onFileAction={handleFileAction}
          defaultFileViewActionId={ChonkyActions.EnableListView.id}
        >
          <FileNavbar />
          <FileToolbar />
          <div className={"mb-3"}>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              // onChange={e => setSearchTerm(e.target.value)}
              onChange={(e) => searchData(e.target.value)}
            />
            {/* <div className={'mt-3'}> */}
            <Link
              onClick={(e) => clearSearch(e)}
              className={"ms-2"}
              style={{ "text-decoration": "none" }}
            >
              Clear search
            </Link>
            {/* </div> */}
          </div>
          {loading ? ( // Show the loader when `loading` is true
            <div className="spin-loader mt-auto mb-auto">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <FileList />
          )}
        </FileBrowser>
      </div>
    </div>
  );
};
export default S3Browser;
