import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { STATUS } from "../../utils/constants";
import { MdEdit, MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {
  CreateInjury,
  DeleteInjury,
  GetInjuryById,
  GetInjuries,
  UpdateInjury,
  DeleteMultipleInjury,
} from "../../services/injurys.service";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { MenuItem, Stack, Tooltip } from "@mui/material";
import { Pagination } from "@material-ui/lab";
import { Select } from "@material-ui/core";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function InjuryProgram() {
  let history = useHistory();
  const [injuryId, setInjuryId] = useState(0);
  const [loading, setLoading] = useState(false);

  const [columns, setColumns] = useState([]);

  const [row, setRow] = useState();
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [rowData, setRowData] = useState([]);
  const [formData, setFormData] = useState({});

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const apiRef = useGridApiRef();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    const fetchInjuries = async () => {
      await getInjuriesFunction();
    };

    fetchInjuries();
  }, [, status, perPage, page]);

  useEffect(() => {
    if (keyword) {
      getInjuriesFunction(false);
    } else {
      getInjuriesFunction(true);
    }
  }, [keyword, page, perPage, status]);

  useEffect(() => {
    reset({
      name: "",
    });
  }, [showAddModal, showEditModal]);

  useEffect(async () => {
    Promise.all([await getColumns()])
      .then((res) => {
        console.log("res->", res);
        getColumnsWidth("injury_programs", res[0]);
      })
      .catch((error) => {});
  }, []);

  useEffect(async () => {
    await getColumnsHideShow("injury_programs", "column_hide_show");
    await getColumnsOrderChange();
  }, []);

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "#", width: 100 },
      { field: "name", headerName: "Injury", width: 300 },
      { field: "status", headerName: "Status", width: 150 },
      {
        field: "actions",
        headerName: "Actions",
        width: 50,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            // Logic to handle delete action
            const id = `${params.id}`;

            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              //Delete Product API
              if (willShip) {
                await DeleteInjury(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getInjuriesFunction();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          const onEdit = (data) => {
            const id = data;
            setShowEditModal(true);
            setInjuryId(id);
            GetInjuryById(id)
              .then((result) => {
                if (result.status) {
                  if (result.data) {
                    setRow(result.data);
                    setValue("name", result.data.name);
                    setValue("status", result.data.status);
                  }
                } else {
                  toast.error(result.message);
                }
              })
              .catch((error) => {
                toast.error(error.response.data.message.replace(/_/g, " "));
              });
          };

          return (
            <div>
              <Tooltip title="Edit" arrow>
                <button
                  className={"btn btn-primary btn-sm"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEdit(params.id);
                  }}
                >
                  <MdEdit />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  className={"btn btn-danger btn-sm mx-2"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(params.id);
                  }}
                >
                  <MdDelete />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    return obj;
    //setColumns(obj);
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "injury_programs",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "injury_programs",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("injury_programs", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("injury_programs", "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => {
                  const onDelete = () => {
                    const id = `${params.id}`;

                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to delete?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willShip) => {
                      if (willShip) {
                        await DeleteInjury(id)
                          .then((result) => {
                            if (result.status) {
                              swal(result.message, {
                                icon: "success",
                              });
                              getInjuriesFunction();
                            } else {
                              toast.error(result.message);
                            }
                          })
                          .catch((error) => {
                            toast.error(
                              error.response.data.message.replace(/_/g, " ")
                            );
                          });
                      }
                    });
                  };

                  const onEdit = (data) => {
                    const id = data;
                    setShowEditModal(true);
                    setInjuryId(id);
                    GetInjuryById(id)
                      .then((result) => {
                        if (result.status) {
                          if (result.data) {
                            setRow(result.data);
                            setFormData(result.data);
                            setValue("name", result.data.name);
                            setValue("status", result.data.status);
                          }
                        } else {
                          toast.error(result.message);
                        }
                      })
                      .catch((error) => {
                        toast.error(
                          error.response.data.message.replace(/_/g, " ")
                        );
                      });
                  };

                  return (
                    <div>
                      <Tooltip title="Edit" arrow>
                        <button
                          className={"btn btn-primary btn-sm"}
                          onClick={(event) => {
                            event.stopPropagation();
                            onEdit(params.id);
                          }}
                        >
                          <MdEdit />
                        </button>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <button
                          className={"btn btn-danger btn-sm mx-2"}
                          onClick={(event) => {
                            event.stopPropagation();
                            onDelete(params.id);
                          }}
                        >
                          <MdDelete />
                        </button>
                      </Tooltip>
                    </div>
                  );
                },
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const getInjuriesFunction = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }
    try {
      const result = await GetInjuries(keyword, page, perPage, 1, status);

      if (result.status) {
        const rowData = [];
        setPage(result?.data?.meta?.current_page);
        setPageCount(result?.data?.meta?.last_page);
        result?.data?.data?.map((dt) => {
          rowData.push({
            id: dt?.id,
            name: dt?.name,
            status: STATUS[dt?.status],
          });
        });

        setRowData(rowData);
      }

      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
    } catch (error) {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.replace(/_/g, " ")) ||
          "An error occurred"
      );
    }
  };

  const onMultipleDelete = async () => {
    const rows = await apiRef.current.getSelectedRows();

    const dataArray = [...rows.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeInjury: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleInjury(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getInjuriesFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const onEditSubmit = async (data) => {
    setLoader(true);
    if (injuryId != 0) {
      await UpdateInjury(injuryId, data)
        .then(async (data) => {
          setLoader(false);
          if (data.status) {
            toast.success(data.message);
            setShowEditModal(false);
            getInjuriesFunction();
          } else {
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    } else {
      await CreateInjury(data)
        .then(async (data) => {
          setLoader(false);
          if (data.status) {
            toast.success(data.message);

            setShowEditModal(false);
            getInjuriesFunction();
          } else {
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    }
  };

  const EditModal = (props) => {
    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onEditSubmit)}>
          <Form.Group className={"mb-3"} controlId="bodyType">
            <Form.Label>Injury Program Name*</Form.Label>
            <Form.Control
              {...register("name", {
                required: {
                  value: "required",
                  message: "Injury Program is required",
                },
              })}
              type={"text"}
              placeholder={"Injury Program Name"}
            />
          </Form.Group>
          <Form.Group className={"mb-3"} controlId="status">
            <Form.Label className="d-block">Select Status</Form.Label>
            <Form.Select
              className="formselect"
              aria-label="status"
              {...register("status", {
                required: {
                  value: "required",
                  message: "Status is required",
                },
              })}
            >
              <option value="10">Active</option>
              <option value="20">Inactive</option>
            </Form.Select>
            {errors.status && (
              <Form.Text className="text-muted validationText hasError">
                {errors.weight.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <Button
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"submit"}
            >
              Submit
            </Button>
          )}
        </Form>
      </div>
    );
  };

  const AddModal = () => {
    const {
      register,

      handleSubmit,

      formState: { errors },
    } = useForm({
      mode: "onBlur",
    });

    const onSubmit = async (data) => {
      setLoader(true);
      await CreateInjury(data)
        .then(async (data) => {
          setLoader(false);
          if (data.status) {
            toast.success(data.message);
            setShowAddModal(false);
            getInjuriesFunction();
          } else {
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    };
    return (
      <div className={"ViewModalContact"}>
        <Form
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group className={"mb-3"} controlId="bodyType">
            <Form.Label>Injury Program Name*</Form.Label>
            <Form.Control
              {...register("name", {
                required: {
                  value: "required",
                  message: "Injury Program is required",
                },
              })}
              type={"text"}
              placeholder={"Injury Program Name"}
            />
            {errors.name && (
              <Form.Text className=" validationText hasError">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className={"mb-3"} controlId="status">
            <Form.Label className="d-block">Select Status</Form.Label>
            <Form.Select
              className="formselect"
              aria-label="status"
              {...register("status", {
                required: {
                  value: "required",
                  message: "Status is required",
                },
              })}
            >
              <option value="10">Active</option>
              <option value="20">Inactive</option>
            </Form.Select>
            {errors.status && (
              <Form.Text className="text-muted validationText hasError">
                {errors.weight.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <Button
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Add New Injury Program"}
            >
              Add New Injury Program
            </Button>
          )}
        </Form>
      </div>
    );
  };

  //Pagination Section

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );

      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "injury_programs",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <div className="orderPage sports-index">
        <div className={"orderPage"}>
          <h1>Injury Program</h1>
          <div className={"customOrderFilters"}>
            <Row>
              <Col md={5}></Col>
              <Col md={5}>
                <div className="all-user">
                  <div className="search-bar">
                    <div className="common-search-bar">
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          <SearchIcon />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="Search"
                          onChange={(e) => {
                            e.target.value.length > 0
                              ? setKeyword(e.target.value.trim())
                              : setKeyword(null);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={2}>
                <Button
                  className={"btn common-btn   w-100"}
                  onClick={() => setShowAddModal(true)}
                >
                  <AddIconOutline /> Add New
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <section className="common-table mt-2">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>

          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rowData}
              columns={columns}
              onColumnWidthChange={handleColumnWidthChange}
              isColumnResizable
              onColumnVisibilityModelChange={toggleCustomFunc}
              columnVisibilityModel={columnVisibilityModel}
              checkboxSelection
              loading={searchLoading}
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              apiRef={apiRef}
              unstable_headerFilters
              density="compact"
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>

          <div className="user-pagination">
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <Stack spacing={2}>
                  <Pagination
                    count={countPage}
                    page={page}
                    onChange={handlePaginationChange}
                    size="large"
                  />
                </Stack>
              </Col>
              <Col md={4}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <label>Rows per page:</label>
                  <Select value={perPage} onChange={handlePageSizeChange}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </Stack>
              </Col>
            </Row>
          </div>

          <ThemeModal
            title={"Add New Injury Program"}
            show={showAddModal}
            onHide={() => setShowAddModal(false)}
            size={"md"}
            content={<AddModal />}
          />
          <ThemeModal
            title={"Edit Injury Program"}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            size={"md"}
            content={<EditModal />}
          />
        </section>
      </div>
    </>
  );
}

export default InjuryProgram;
