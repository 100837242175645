import React, { useState, useEffect, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ProfileIcon from "../../assets/images/profile-icon.png";
import { AiOutlineEdit } from "react-icons/ai";
import { toast } from "react-toastify";
import { GetProfile } from "../../services/users.service";
import { constants } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { useHistory } from "react-router-dom";

const initialData = {
  id: "",
  full_name: "User Name",
  email: "",
  phone: "",
  image: constants.USERIMAGENOTFOUND,
};

function ViewProfile() {
  const history = useHistory();
  const [data, setData] = useState(initialData);

  useEffect(() => {
    getProfile();
  }, []);

  useLayoutEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    await GetProfile()
      .then(async (result) => {
        if (result.status) {
          if (result.data?.profile) {
            let dt = result.data?.profile;
            if (dt) {
              await setData({
                id: dt.id,
                full_name: dt.full_name,
                email: dt.email,
                phone: dt.phone,
                image:
                  dt?.profile_picture?.mediumImage ||
                  constants.USERIMAGENOTFOUND,
              });
            }
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  return (
    <div className="profile-index">
      <h1>View Profile</h1>
      <section className="add-profile-section">
        <div className="profile-card">
          <Row>
            <Col md={9}>
              <div className="profile-details">
                <h4>{data?.full_name}User Name</h4>
                <p>Email:{data?.email}</p>
                <p>Phone:{data?.phone}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="profile-img">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = constants.USERIMAGENOTFOUND;
                  }}
                  src={data?.image}
                  alt={"Profile Image"}
                />
              </div>
            </Col>
          </Row>
        </div>
        <NavLink
          className="floating-link"
          to={{ pathname: "/edit-profile", state: data }}
        >
          Edit Profile
        </NavLink>
      </section>
    </div>
  );
}

export default ViewProfile;
