import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';


// export const GetSportsList = async () => {
//     let response = await GET(apiUrl.sports_list);
//     return response;
// }

export const UpdateSports = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.sports, id, data, config);
    return response;
}


export const GetSports = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null, orderByValue, orderByColumn) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['is_paginate'] = is_paginate;

    if (orderByValue) {
        params['order'] = orderByValue;
    }

    if (orderByColumn) {
        params['order-column'] = orderByColumn;
    }
    const response = await GET(apiUrl.sports, params);
    return response;
}

export const GetSportById = async (id) => {
    let response = '';
    response = await GET(apiUrl.sports + '/' + id);
    return response;
}

export const CreateSport = async (data) => {
    const response = await POST(apiUrl.sports, data);
    return response;
}

export const UpdateSport = async (id, data) => {
    const response = await PUT(apiUrl.sports, id, data);
    return response;
}

export const DeleteSport = async (id) => {
    const response = await DELETE(apiUrl.sports, id);
    return response;
}

export const DeleteMultipleSports = async (data) => {
    const response = await POST(apiUrl.deleteSports, data);
    return response;
}