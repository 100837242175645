import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {
  CreateBodyType,
  DeleteBodyType,
  GetBodyTypeById,
  GetBodyTypes,
  UpdateBodyType,
  DeleteMultipleBodyTypes,
} from "../../services/body_types.service";
import { MdEdit, MdDelete } from "react-icons/md";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function BodyTypes() {
  let history = useHistory();
  const apiRef = useGridApiRef();
  const [bodyTypeId, setBodyTypeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState();
  const [keyword, setKeyword] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const handleComPaginationChange = (event, value) => {
    setPage(value);
  };

  const handleComPageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getBodyTypes(true);
      setBodyTypeId(null);
      reset({
        name: "",
      });
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (keyword) {
      getBodyTypes(false);
    } else {
      getBodyTypes(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("body_types", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("body_types", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "ID", width: 310 },
      { field: "name", headerName: "Body Types", width: 480 },
      {
        field: "actions",
        headerName: "Actions",
        width: 180,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            const id = `${params.id}`;
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete this body type?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              if (willShip) {
                await DeleteBodyType(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getBodyTypes();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          const onEdit = (id) => {
            clearErrors("name");
            setShowEditModalFunc(true);
            setBodyTypeId(id);
            GetBodyTypeById(id)
              .then((result) => {
                if (result.status) {
                  if (result.data) {
                    setRow(result.data);
                    setValue("name", result.data.name);
                  }
                } else {
                  toast.error(result.message);
                }
              })
              .catch((error) => {
                toast.error(error.response.data.message.replace(/_/g, " "));
              });
          };

          return (
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params.id);
                }}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm mx-2"}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.id);
                }}
              >
                <MdDelete />
              </button>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const getBodyTypes = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }
    await GetBodyTypes(keyword, page, perPage, 1)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            const rowData = [];
            setPage(result?.data?.meta?.current_page);
            setPageCount(result?.data?.meta?.last_page);
            result?.data?.data?.map((dt) => {
              rowData.push({
                id: dt?.id,
                name: dt?.name,
              });
            });
            setRows(rowData);
            setLoading(false);
          }
          if (isInitialLoad) {
            setLoading(false);
          } else {
            setSearchLoading(false);
          }
        } else {
          setLoading(false);
          toast.error(result.message);
        }
      })
      .catch((error) => {
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "body_types",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("body_types", "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => {
                  const onDelete = () => {
                    const id = `${params.id}`;
                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to delete this body type?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willShip) => {
                      if (willShip) {
                        await DeleteBodyType(id)
                          .then((result) => {
                            if (result.status) {
                              swal(result.message, {
                                icon: "success",
                              });
                              getBodyTypes();
                            } else {
                              toast.error(result.message);
                            }
                          })
                          .catch((error) => {
                            toast.error(
                              error.response.data.message.replace(/_/g, " ")
                            );
                          });
                      }
                    });
                  };

                  const onEdit = (id) => {
                    clearErrors("name");
                    setShowEditModalFunc(true);
                    setBodyTypeId(id);
                    GetBodyTypeById(id)
                      .then((result) => {
                        if (result.status) {
                          if (result.data) {
                            setRow(result.data);
                            setValue("name", result.data.name);
                          }
                        } else {
                          toast.error(result.message);
                        }
                      })
                      .catch((error) => {
                        toast.error(
                          error.response.data.message.replace(/_/g, " ")
                        );
                      });
                  };

                  return (
                    <div>
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onEdit(params.id);
                        }}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className={"btn btn-danger btn-sm mx-2"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(params.id);
                        }}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  );
                },
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "body_types",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "body_types",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("body_types", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const onMultipleDelete = async () => {
    let rowsDeleted = await apiRef.current.getSelectedRows();

    const dataArray = [...rowsDeleted.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeBodyTypes: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleBodyTypes(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getBodyTypes();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const onSubmit = async (data) => {
    setLoader(true);
    await CreateBodyType(data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          setShowAddModalFunc(false);
          setShowEditModalFunc(false);
          reset({
            name: "",
          });
          getBodyTypes();
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const onEditSubmit = async (data) => {
    setLoader(true);
    if (bodyTypeId != 0 && bodyTypeId != null) {
      await UpdateBodyType(bodyTypeId, data)
        .then(async (data) => {
          setLoader(false);
          if (data.status) {
            toast.success(data.message);
            setShowAddModalFunc(false);
            setShowEditModalFunc(false);
            getBodyTypes();
            reset({
              name: "",
            });
          } else {
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    }
  };

  const EditModal = (props) => {
    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onEditSubmit)}>
          <Form.Group controlId="bodyType">
            <Form.Label>Body Type*</Form.Label>
            <Form.Control
              {...register("name", {
                required: {
                  value: "required",
                  message: "Body Type is required",
                },
                maxLength: {
                  value: 80,
                  message: "Max length is 80",
                },
                minLength: {
                  value: 2,
                  message: "Minimum length is 2",
                },
                pattern: {
                  value: /^[a-zA-Z0-9_ ]*$/,
                  message: "This field must contain only letters",
                },
              })}
              type={"text"}
              placeholder={"Body Type"}
            />
            {errors.name && (
              <Form.Text className=" validationText hasError">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <Button
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Edit Body Type"}
            >
              Edit Body Type
            </Button>
          )}
        </Form>
      </div>
    );
  };

  const AddModal = () => {
    setBodyTypeId(null);
    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="bodyType">
            <Form.Label>Body Type*</Form.Label>
            <Form.Control
              {...register("name", {
                required: {
                  value: "required",
                  message: "Body Type is required",
                },
                maxLength: {
                  value: 80,
                  message: "Max length is 80",
                },
                minLength: {
                  value: 2,
                  message: "Minimum length is 2",
                },
                pattern: {
                  value: /^[a-zA-Z0-9_ ]*$/,
                  message: "This field must contain only letters",
                },
              })}
              type={"text"}
              placeholder={"Body Type"}
            />
            {errors.name && (
              <Form.Text className=" validationText hasError">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <Button
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Add New Body Type"}
            >
              Add New Body Type
            </Button>
          )}
        </Form>
      </div>
    );
  };

  const setShowAddModalFunc = (data) => {
    reset({
      name: "",
    });
    setShowAddModal(data);
  };

  const setShowEditModalFunc = (data) => {
    reset({
      name: "",
    });
    setShowEditModal(data);
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}

      <div className={"orderPage body-types-index"}>
        <Row>
          <div className={"orderPage"}>
            <h1>Body Type</h1>
            <div className={"customOrderFilters"}>
              <Row>
                <Col md={1}></Col>
                <Col md={5} className="ms-auto">
                  <div className="all-user">
                    <div className="search-bar">
                      <div className="common-search-bar">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            <SearchIcon />
                          </InputGroup.Text>
                          <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="Search"
                            onChange={(e) => {
                              e.target.value.length > 0
                                ? setKeyword(e.target.value)
                                : setKeyword(null);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={2}>
                  <Button
                    variant="none"
                    className={"btn common-btn   w-100"}
                    onClick={() => {
                      setShowAddModalFunc(true);
                      clearErrors("name");
                      reset({
                        name: "",
                      });
                    }}
                  >
                    <AddIconOutline /> Add New
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
        <section className="common-table mt-2">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>
          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>
          <div className="user-pagination">
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                {" "}
                <Stack spacing={2}>
                  <Pagination
                    count={countPage}
                    page={page}
                    onChange={handleComPaginationChange}
                    size="large"
                  />
                </Stack>
              </Col>
              <Col md={3} className="ms-auto">
                {" "}
                <Stack direction="row" spacing={2} alignItems="center">
                  <label>Rows per page:</label>
                  <Select value={perPage} onChange={handleComPageSizeChange}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </Stack>
              </Col>
            </Row>
          </div>

          <div className="theme-dataGrid products-dataGrid">
            <ThemeModal
              title={"Add Body Type"}
              show={showAddModal}
              onHide={() => setShowAddModalFunc(false)}
              size={"md"}
              content={<AddModal />}
            />
            <ThemeModal
              title={"Edit Body Type"}
              show={showEditModal}
              onHide={() => setShowEditModalFunc(false)}
              size={"md"}
              content={<EditModal />}
            />
          </div>
        </section>
      </div>
    </>
  );
}

export default BodyTypes;
