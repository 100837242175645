import {apiUrl} from '../utils/constants';
import {DELETE, GET, POST, PUT} from './api.service.wrapper';

// export const GetCountriesList = async () => {
//     let response = await GET(apiUrl.countries_list);
//     return response;
// }

export const UpdateCountries = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.countries, id, data, config);
    return response;
}

export const GetCountries = async (keyword = '', role_id= '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(role_id){
        params['role_id'] = role_id;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.countries, params);
    return response;
}

export const GetCountryById = async (id) => {
    let response = '';
    response = await GET(apiUrl.countries + '/' + id);
    return response;
}

export const CreateCountry = async (data) => {
    const response = await POST(apiUrl.countries, data);
    return response;
}

export const UpdateCountry = async (id, data) => {
    const response = await PUT(apiUrl.countries, id, data);
    return response;
}

export const DeleteCountry = async (id) => {
    const response = await DELETE(apiUrl.countries, id);
    return response;
}
