import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function MyEditor({ handleChange, ...props }) {
  // CKEditor upload adapter for videos
  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }

    // Starts the upload process.
    upload() {
      return this.loader.file
        .then(file => {
          return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('video', file);

            fetch('/upload-video', {
              method: 'POST',
              body: data
            })
              .then(response => response.json())
              .then(result => {
                resolve({
                  default: result.url
                });
              })
              .catch(error => {
                reject(error);
              });
          });
        });
    }

    // Aborts the upload process.
    abort() {
      // Custom abort logic can be added here if necessary
    }
  }

  // CKEditor plugin to handle file uploads
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader);
    };
  }

  // CKEditor plugin to embed videos
  function videoEmbedPlugin(editor) {
    editor.ui.componentFactory.add('videoEmbed', locale => {
      const view = new editor.ui.button.ButtonView(locale);

      view.set({
        label: 'Insert Video',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.615 3.184A3.48 3.48 0 0 0 17.253 2H6.747A3.48 3.48 0 0 0 4.385 3.184 3.424 3.424 0 0 0 3.056 5.5v13c0 1.147.563 2.167 1.329 2.815A3.479 3.479 0 0 0 6.747 21h10.506a3.479 3.479 0 0 0 2.391-.685 3.424 3.424 0 0 0 1.329-2.815v-13c0-1.147-.563-2.167-1.329-2.815zM10 15.5v-7l6 3.5-6 3.5z"/></svg>',
        tooltip: true
      });

      view.on('execute', () => {
        const url = prompt('Enter video URL (YouTube, Vimeo, direct video link, or select a file to upload):');
        if (url) {
          if (isVideoUrl(url)) {
            embedVideoFromUrl(editor, url);
          } else {
            alert('Please provide a valid video URL.');
          }
        } else {
          // Handle local file upload
          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.accept = 'video/*';
          fileInput.onchange = async () => {
            const file = fileInput.files[0];
            if (file) {
              const uploadResult = await new MyUploadAdapter({ file }).upload();
              embedVideo(editor, uploadResult.default, file.type);
            }
          };
          fileInput.click();
        }
      });

      return view;
    });
  }

  // Function to check if the URL is a valid video URL
  function isVideoUrl(url) {
    const urlObj = new URL(url);
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return videoExtensions.some(ext => urlObj.pathname.endsWith(ext)) || urlObj.hostname.includes('youtube.com') || urlObj.hostname.includes('vimeo.com');
  }

  // Function to embed video from URL
  function embedVideoFromUrl(editor, url) {
    editor.model.change(writer => {
      let videoHtml = '';
      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        const videoId = getYouTubeVideoId(url);
        const iframeSrc = `https://www.youtube.com/embed/${videoId}`;
        videoHtml = `<iframe width="560" height="315" src="${iframeSrc}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      } else if (url.includes('vimeo.com')) {
        const videoId = getVimeoVideoId(url);
        const iframeSrc = `https://player.vimeo.com/video/${videoId}`;
        videoHtml = `<iframe width="560" height="315" src="${iframeSrc}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
      } else {
        videoHtml = `<video width="560" height="315" controls><source src="${url}" type="video/mp4">Your browser does not support the video tag.</video>`;
      }

      const videoEmbedElement = writer.createRawElement('div', {
        class: 'video-embed'
      }, domElement => {
        domElement.innerHTML = videoHtml;
      });
      editor.model.insertContent(videoEmbedElement, editor.model.document.selection);
    });
  }

  // Function to embed video
  function embedVideo(editor, src, type) {
    editor.model.change(writer => {
      const videoHtml = `<video width="560" height="315" controls><source src="${src}" type="${type}">Your browser does not support the video tag.</video>`;
      const videoEmbedElement = writer.createRawElement('div', {
        class: 'video-embed'
      }, domElement => {
        domElement.innerHTML = videoHtml;
      });
      editor.model.insertContent(videoEmbedElement, editor.model.document.selection);
    });
  }

  // Function to extract YouTube video ID from URL
  function getYouTubeVideoId(url) {
    const urlObj = new URL(url);
    if (urlObj.hostname === 'youtu.be') {
      return urlObj.pathname.slice(1);
    } else if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtube.com') {
      return urlObj.searchParams.get('v');
    }
    return null;
  }

  // Function to extract Vimeo video ID from URL
  function getVimeoVideoId(url) {
    const urlObj = new URL(url);
    if (
      urlObj.hostname === "vimeo.com" ||
      urlObj.hostname === "www.vimeo.com"
    ) {
      return urlObj.pathname.split("/")[1];
    }
    return null;
  }
  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
    ],
  };

  return (
    <div className="app new-file">
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin, videoEmbedPlugin],
          editorConfiguration,
        }}
        editor={ClassicEditor}
        onReady={(editor) => {}}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
        onChange={(event, editor) => {
          handleChange(editor.getData());
        }}
        {...props}
      />
    </div>
  );
}
