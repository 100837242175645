import React, { useEffect, useLayoutEffect, useState } from 'react'
import { PagesApi } from "../../services/auth/pages.service"
import { toast } from "react-toastify"
import parse from "html-react-parser";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { useHistory } from "react-router";

function PrivacyPolicy(props) {
    const history = useHistory();
    const [content, setContent] = useState()
    const [slug, setSlug] = useState()

    useEffect(async () => {
        let param = props.match.params.slug;
        await PagesApi(param).then((data) => {
            if (data.status) {
                setSlug(param)
                setContent(parse(data.data.content))
            }
            else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    }, [props])

    return (
        <div className={"policyPage PrivacyPolicy"}>
            <h3 className="page-heading">{slug}</h3>
            <hr />
            <div style={{ wordWrap: 'break-word' }}>{content || 'Loading...'}</div>
        </div>
    )
}

export default PrivacyPolicy
