import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import {
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    Row,
} from "react-bootstrap";
import {
    AddIconOutline,
    DeleteUpdated,
    SearchIcon,
} from "../../components/SvgIcons";
import { constants, cross_origin } from "../../utils/constants";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { MenuItem, Pagination, Stack } from "@mui/material";
import { Select } from "@material-ui/core";

import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { upload } from "../../utils/helpers";
import {
    CreateEquipmentCategory,
    DeleteEquipmentCategory,
    GetEquipmentCategories,
    GetEquipmentCategoryById,
    UpdateEquipmentCategory,
} from "../../services/equipment-categories.service";
import { TrashIcon } from "../../components/SvgIcons";
import { AiOutlineFolderView } from "react-icons/ai";
import { MdEdit, MdDelete } from "react-icons/md";
import S3Browser from "../../components/S3Browser";


import { styled } from "@mui/material/styles";
import {
    ColumnHideShow,
    GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    position: "relative",
    "& .MuiDataGrid-toolbarContainer": {
        position: "absolute",
        top: "-43px",
        marginLeft: "50px",
    },
    "& .MuiDataGrid-columnHeaders": {
        fontWeight: 600,
        fontSize: "1.05em",
        fontFamily: "Arial, sans-serif",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        fontSize: "1.05em",
    },
    "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
        visibility: "hidden !important",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "0.9em",
        fontFamily: "Arial, sans-serif",
    },
    "& .MuiDataGrid-row": {
        minHeight: "30px",
        maxHeight: "30px",
    },
}));

function EquipmentCategory() {
    let history = useHistory();
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(false);
    const [subCategoryId, setSubCategoryId] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);
    const [selectedImage, setCategoryImage] = useState(null);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [searchLoading, setSearchLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [countPage, setPageCount] = useState(0);



    const handlePaginationChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPerPage(event.target.value);
    };

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
    });

    useEffect(() => {
        const fetchData = async () => {
            await getCategoryFunction(true);
        };

        fetchData();
    }, [page, perPage]);

    useEffect(() => {
        if (keyword) {
            getCategoryFunction(false);
        } else {
            getCategoryFunction(true);
        }
    }, [keyword, page, perPage]);

    useEffect(() => {
        const fetchData = async () => {
            await getColumnsHideShow("equipment_category", "column_hide_show");
            await getColumnsOrderChange();
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [columns] = await Promise.all([getColumns()]);
                getColumnsWidth("equipment_category", columns);
            } catch (error) { }
        };

        fetchData();
    }, []);

    const getColumns = async () => {
        const obj = [
            { field: "id", headerName: "ID", width: 300 },
            { field: "name", headerName: "Category", width: 380 },
            {
                field: "image",
                headerName: "Image",
                width: 180,
                renderCell: (params) => (
                    <a href={params.value} target="_blank" rel="noopener noreferrer">
                        <img
                            src={params.value}
                            alt="image"
                            style={{ width: "auto", height: 50 }}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // Prevents looping
                                currentTarget.src = constants.IMAGENOTFOUND; // Fallback image URL
                            }}
                            className="img-table img-thumbnail"
                            crossOrigin={cross_origin ? "anonymous" : null} // Using a condition to apply the crossOrigin attribute
                        />
                    </a>
                ),
            },
            {
                field: "actions",
                headerName: "Actions",
                width: 150,
                sortable: false,
                filterable: false,
                disableClickEventBubbling: true,
                renderCell: (params) => {
                    const onDelete = () => {
                        // Logic to handle delete action
                        const id = `${params.id}`;

                        swal({
                            title: "Are you sure?",
                            text: "Are you sure you want to delete?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        }).then(async (willShip) => {
                            //Delete Product API
                            if (willShip) {
                                await DeleteEquipmentCategory(id)
                                    .then((result) => {
                                        if (result.status) {
                                            swal(result.message, {
                                                icon: "success",
                                            });
                                            getCategoryFunction();
                                        } else {
                                            toast.error(result.message);
                                        }
                                    })
                                    .catch((error) => {
                                        toast.error(error.response.data.message.replace(/_/g, " "));
                                    });
                            }
                        });
                    };

                    const onEdit = (id) => {
                        setSubCategoryId(id);
                        setShowEditModal(true);
                        GetEquipmentCategoryById(id)
                            .then((result) => {
                                if (result.status) {
                                    if (result.data) {
                                        setValue("name", result.data.name);
                                        setSelectedCategoryId(result.data?.parent_id);
                                        setSelectedCategoryName(result.data?.name);
                                        setCategoryImage(result?.data?.image);
                                    }
                                } else {
                                    toast.error(result.message);
                                }
                            })
                            .catch((error) => {
                                toast.error(error.message.replace(/_/g, " "));
                            });
                    };
                    const onView = (id) => {
                        history.push("/equipment-list/" + id);
                    };

                    return (
                        <div>
                            <button
                                className={"btn btn-primary btn-sm"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onEdit(params.id);
                                }}
                            >
                                <MdEdit />
                            </button>
                            <button
                                className={"btn btn-danger btn-sm mx-2"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDelete(params.id);
                                }}
                            >
                                <MdDelete />
                            </button>
                            <button
                                className={"btn btn-dark btn-sm"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onView(params.id);
                                }}
                            >
                                <AiOutlineFolderView />
                            </button>
                        </div>
                    );
                },
            },
        ];
        return obj;
    };

    const getColumnsWidth = async (type, columns) => {
        await GetColumnHideShow(type, "column_width_change").then((result) => {
            if (result?.status && result?.data) {
                const data = result?.data;
                const updatedObj = columns.map((item) => {
                    const foundItem = result?.data.find(
                        (data) => data.field_name === item.field
                    );
                    if (foundItem) {
                        return {
                            ...item,
                            width: foundItem.field_width,
                        };
                    }
                    return item;
                });
                setColumns(updatedObj);
            } else {
                setColumns(columns);
            }
        });
    };




    const handleColumnWidthChange = async (newColumns) => {
        setLoading(true);
        try {
            const requestObj = {
                module_name: "equipment_category",
                type: "column_width_change",
                field_name: newColumns?.colDef?.field,
                field_width: newColumns?.colDef?.width,
            };
            const result = await ColumnHideShow(requestObj);
            if (result.status && result.data) {
                if (columns.length > 0) {
                    const reorderedColumns = columns.map((column) => {
                        if (column.field == result?.data?.field_name) {
                            return { ...column, width: result?.data?.field_width };
                        }
                        return column;
                    });
                    setColumns(reorderedColumns);
                } else {
                    console.log("no columns are found.");
                }
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(error.response.data.message.replace(/_/g, " "));
        } finally {
            setLoading(false);
        }
    };

    const getColumnsHideShow = async (moduleName, type) => {
        await GetColumnHideShow(moduleName, type).then((result) => {
            if (result?.status && result?.data && result.data.table_data !== null) {
                const tableData = JSON.parse(result?.data?.table_data);
                setColumnVisibilityModel(tableData);
            }
        });
    };

    const getColumnsOrderChange = async () => {
        await GetColumnHideShow("equipment_category", "column_order_change")
            .then((result) => {
                if (result.status && result.data) {
                    const parsedArray = JSON.parse(result.data.table_data);
                    const dataArray = Object.values(parsedArray);
                    const completeColumns = dataArray.map((item) => {
                        if (item.field === "actions") {
                            return {
                                field: "actions",
                                headerName: "Actions",
                                sortable: false,
                                filterable: false,
                                width: item.width,
                                renderCell: (params) => {
                                    const onDelete = () => {
                                        const id = `${params.id}`;
                                        swal({
                                            title: "Are you sure?",
                                            text: "Are you sure you want to delete?",
                                            icon: "warning",
                                            buttons: true,
                                            dangerMode: true,
                                        }).then(async (willShip) => {
                                            if (willShip) {
                                                await DeleteEquipmentCategory(id)
                                                    .then((result) => {
                                                        if (result.status) {
                                                            swal(result.message, {
                                                                icon: "success",
                                                            });
                                                            getCategoryFunction();
                                                        } else {
                                                            toast.error(result.message);
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        toast.error(
                                                            error.response.data.message.replace(/_/g, " ")
                                                        );
                                                    });
                                            }
                                        });
                                    };

                                    const onEdit = (id) => {
                                        setSubCategoryId(id);
                                        setShowEditModal(true);
                                        GetEquipmentCategoryById(id)
                                            .then((result) => {
                                                if (result.status) {
                                                    if (result.data) {
                                                        setValue("name", result.data.name);
                                                        setSelectedCategoryId(result.data?.parent_id);
                                                        setSelectedCategoryName(result.data?.name);
                                                        setCategoryImage(result?.data?.image);
                                                    }
                                                } else {
                                                    toast.error(result.message);
                                                }
                                            })
                                            .catch((error) => {
                                                toast.error(error.message.replace(/_/g, " "));
                                            });
                                    };

                                    const onView = (id) => {
                                        history.push("/equipment-list/" + id);
                                    };

                                    return (
                                        <div>
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    onEdit(params.id);
                                                }}
                                            >
                                                <MdEdit />
                                            </button>
                                            <button
                                                className="btn btn-danger btn-sm mx-2"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    onDelete(params.id);
                                                }}
                                            >
                                                <MdDelete />
                                            </button>
                                            <button
                                                className="btn btn-dark btn-sm"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    onView(params.id);
                                                }}
                                            >
                                                <AiOutlineFolderView />
                                            </button>
                                        </div>
                                    );
                                },
                            };
                        } else if (item.field === "image") {
                            return {
                                field: "image",
                                headerName: "Images",
                                width: 280,
                                sortable: false,
                                renderCell: (params) => (
                                    <a
                                        href={params.value}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={params.value}
                                            alt="Product Image"
                                            style={{ width: "auto", height: 50 }}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = constants.IMAGENOTFOUND;
                                            }}
                                            className="img-table img-thumbnail"
                                            crossOrigin={cross_origin ? "anonymous" : null}
                                        />
                                    </a>
                                ),
                            };
                        } else {
                            return item;
                        }
                    });
                    setColumns(completeColumns);
                }
            })
            .catch((error) => {
                console.error("Error fetching column order change:", error);
            });
    };

    const saveColumnOrderToDatabase = async (newOrder) => {
        setLoading(true);
        try {
            const reorderedColumns = newOrder.map((fieldName) => {
                return columns.find((column) => column.field === fieldName);
            });

            const newArray = reorderedColumns.filter(
                (element) => element !== undefined
            );
            const requestObj = {
                table_data: JSON.stringify(newArray),
                module_name: "equipment_category",
                type: "column_order_change",
            };

            await ColumnHideShow(requestObj)
                .then((result) => {
                    if (result.status) {
                        if (result.data) {
                            getColumnsOrderChange();
                        }
                    } else {
                        setLoading(false);
                        toast.error(result.message);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error.response.data.message.replace(/_/g, " "));
                });
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleColumnOrderChange = () => {
        const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
        saveColumnOrderToDatabase(newOrder);
    };

    const toggleCustomFunc = async (newModel) => {
        setLoading(true);
        const requestObj = {
            table_data: JSON.stringify(newModel),
            module_name: "equipment_category",
            type: "column_hide_show",
        };

        try {
            setColumnVisibilityModel(newModel);
            const result = await ColumnHideShow(requestObj);

            if (result.status) {
                if (result.data) {
                    await getColumnsHideShow("equipment_category", "column_hide_show");
                }
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error(
                error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
            );
        } finally {
            setLoading(false);
        }
    };

    const getCategoryFunction = async (isInitialLoad = true) => {
        if (isInitialLoad) {
            setLoading(true);
        } else {
            setSearchLoading(true);
        }
        await GetEquipmentCategories(keyword, page, perPage, 1, "", "", "")
            .then((result) => {
                if (result.status) {
                    if (result.data) {
                        const rowData = [];
                        setPage(result?.data?.meta?.current_page);
                        setPageCount(result?.data?.meta?.last_page);
                        result?.data?.data.map((dt) => {
                            rowData.push({
                                id: dt?.id,
                                name: dt?.name,
                                image: dt?.image,
                            });
                        });
                        setRows(rowData);
                        setLoading(false);
                    }
                    if (isInitialLoad) {
                        setLoading(false);
                    } else {
                        setSearchLoading(false);
                    }
                } else {
                    setLoading(false);
                    toast.error(result.message);
                }
            })
            .catch((error) => {
                if (isInitialLoad) {
                    setLoading(false);
                } else {
                    setSearchLoading(false);
                }
            });
    };

    const EditModal = (props) => {
        const [loader, setLoader] = useState(false);
        const [selectCategory, setSelectCategory] = useState(null);
        const [img, setImg] = useState("");
        const [file, setFileData] = useState(null);
        const [isUpdate, setIsUpdate] = useState(false);

        const [defaultImage, setDefaultImage] = useState(false);
        const [fileName, setFileName] = useState(null);
        const [modalShow, setModalShow] = useState(false);

        // const resetImage = (file) => {
        //     // var s3 = AWS.S3(awsCredentials);
        //     // s3.deleteObject(
        //     //   {
        //     //     Bucket: MY_BUCKET,
        //     //     Key: "some/subfolders/nameofthefile1.extension",
        //     //   },
        //     //   function (err, data) {}
        //     // );
        //     // if (type == "image") {
        //     //   setFileData(null);
        //     //   setImg(null);
        //     // } else {
        //     // }
        // };

        const resetImage = (type) => {
            console.log("======>", type);
            if (type == "image") {
                setIsUpdate(true);
                setFileData(null);
                setImg(null);
            } else {
            }
        };

        const selectedMedia = (fileName, file) => {
            setImg(file);
            setFileData(file);
            setModalShow(false);
        };

        const selectedCategoryEdit = () => {
            let setSelectedCategory = [];
            setSelectedCategory.push({
                value: props.selectedCategoryId,
                label: props.selectedCategoryName,
            });
            setSelectCategory(setSelectedCategory);
            setFileData(props.selectedImage);
        };

        const handleChangeImage = async (event) => {
            setFileData(URL.createObjectURL(event.target.files[0]));
            setImg(event.target.files[0]);
        };

        useEffect(() => {
            selectedCategoryEdit();
        }, []);

        const onEditSubmit = async (data) => {
            setLoader(true);
            let imageUrl = null;
            if (img || isUpdate) {
                data.image = img;
            }
          
            await UpdateEquipmentCategory(subCategoryId, data)
                .then(async (data) => {
                    setLoader(false);
                    if (data.status) {
                        toast.success(data.message);
                        setShowEditModal(false);
                        getCategoryFunction();
                        reset({
                            name: "",
                            image: "",
                        });
                    } else {
                        toast.error(data.message.replace(/_/g, " "));
                    }
                })
                .catch((error) => {
                    setLoader(false);
                    if (error.response.status == 401) {
                        EmptyLocalStorage();
                        history.push("/");
                    } else {
                        return toast.error(error.response.data.message.replace(/_/g, " "));
                    }
                });
        };

        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onEditSubmit)}>
                    <Row className={"mt-3 mb-3"}>
                        <Col sm={6}>
                            <Form.Group
                                className={"mb-3 sub-sub-category"}
                                controlId="bodyType"
                            >
                                <Form.Label>Category*</Form.Label>
                                <Form.Control
                                    {...register("name", {
                                        required: {
                                            value: "required",
                                            message: "Sub Category is required",
                                        },
                                    })}
                                    type={"text"}
                                    placeholder={"Sub Category"}
                                />
                                {errors.name && (
                                    <Form.Text className=" validationText hasError">
                                        {errors.name.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mt-3 mb-3"}>
                        <Col sm={6}>
                            <Form.Group
                                controlId="formFile"
                                // onChange={(e) => handleChangeImage(e)}
                                onClick={() => {
                                    setFileName("image");
                                    setModalShow(true);
                                }}
                                className="custom-file-upload mb-0"
                            >
                                <Button className={"btn common-btn"}>Upload Image</Button>
                                {/* <Form.Control
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg, image/gif"
                                    {...register("image")}
                                /> */}
                                {errors.image && (
                                    <Form.Text className="text-muted validationText hasError">
                                        {errors.image.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            {file && (
                                <div className="image-item mb-3">
                                    <img
                                        crossOrigin={cross_origin ? "anonymous" : null}
                                        src={file}
                                        alt="Video Cover"
                                        className={"img-fluid"}
                                        width="50%"
                                        height="50%"
                                    />
                                    <div className="image-item__btn-wrapper">
                                        <button
                                            type="button"
                                            className="btn common-btn"
                                            onClick={() => resetImage("image")}
                                        >
                                            <TrashIcon />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    {loader ? (
                        <div className="spin-loader">
                            <FadeLoader color={"#EC1246"} height={10} />
                        </div>
                    ) : (
                        <Button
                            type={"submit"}
                            className={"btn common-btn w-100 mt-3"}
                            value={"save"}
                        >
                            save
                        </Button>
                    )}

                    <ThemeModal
                        title={"S3 Browser"}
                        content={
                            <S3Browser
                                fileName={fileName}
                                selectedMedia={selectedMedia}
                                fileType={"images"}
                            />
                        }
                        size={"xl"}
                        show={modalShow}
                        onHide={() => {
                            setModalShow(false);
                        }}
                    />
                </Form>
            </div>
        );
    };

    useEffect(() => {
        reset({
            name: "",
            parent_id: "",
        });
    }, [showAddModal, showEditModal]);

    const AddModal = () => {
        const [loader, setLoader] = useState(false);
        const {
            register,
            reset,
            handleSubmit,
            formState: { errors },
        } = useForm({
            mode: "onBlur",
        });

        const [img, setImg] = useState("");
        const [file, setFileData] = useState(null);
        const [defaultImage, setDefaultImage] = useState(false);
        const [fileName, setFileName] = useState(null);
        const [modalShow, setModalShow] = useState(false);

        const resetImage = (type) => {
            if (type == "image") {
                setFileData(null);
                setImg(null);
            } else {
            }
        };

        const selectedMedia = (fileName, file) => {
            setImg(file);
            setFileData(file);
            setModalShow(false);
        };


        const onSubmitCategory = async (data) => {
            setLoader(true);
            if (img) {
                data.image = img;
            }
            await CreateEquipmentCategory(data)
                .then(async (data) => {
                    setLoader(false);
                    if (data.status) {
                        toast.success(data.message);
                        setShowAddModal(false);
                        reset({
                            name: "",
                            image: "",
                        });
                        getCategoryFunction();
                    } else {
                        toast.error(data.message.replace(/_/g, " "));
                    }
                })
                .catch((error) => {
                    setLoader(false);
                    if (error.response.status == 401) {
                        EmptyLocalStorage();
                        history.push("/");
                    } else {
                        return toast.error(error.response.data.message.replace(/_/g, " "));
                    }
                });
        };

        const handleChangeImage = async (event) => {
            setFileData(URL.createObjectURL(event.target.files[0]));
            setImg(event.target.files[0]);
        };

        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onSubmitCategory)}>
                    <>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={12} xl={12}>
                                <Form.Group
                                    className={"mb-3 sub-category"}
                                    controlId="bodyType"
                                >
                                    <Form.Label>Category*</Form.Label>
                                    <Form.Control
                                        {...register("name", {
                                            required: {
                                                value: "required",
                                                message: "Category is required ",
                                            },
                                        })}
                                        type={"text"}
                                        placeholder={"Category"}
                                    />
                                    {errors.name && (
                                        <Form.Text className="mt-1  validationText hasError">
                                            Category is required
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={"mt-3 mb-3"}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group
                                    controlId="formFile"
                                    //   onChange={(e) => handleChangeImage(e)}
                                    onClick={() => {
                                        setFileName("image");
                                        setModalShow(true);
                                    }}
                                    className="custom-file-upload mb-0"
                                >
                                    <Form.Label className={"btn common-btn"}>
                                        Upload Image
                                    </Form.Label>
                                    {/* <Form.Control
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                    {...register("image")}
                  /> */}
                                    {errors.image && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.image.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                {file && (
                                    <div className="image-item mb-3">
                                        <img
                                            crossOrigin={cross_origin ? "anonymous" : null}
                                            src={file}
                                            alt="Video Cover"
                                            className={"img-fluid"}
                                            width="50%"
                                            height="50%"
                                        />
                                        <div className="image-item__btn-wrapper">
                                            <button
                                                type="button"
                                                className="btn common-btn"
                                                onClick={() => resetImage("image")}
                                            >
                                                <TrashIcon />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </>

                    {loader ? (
                        <FullPageLoader loading={loader} />
                    ) : (
                        <Button
                            type={"submit"}
                            className={"btn common-btn w-100 mt-3"}
                            value={"Add New Category"}
                        >
                            Add New Category
                        </Button>
                    )}

                    <ThemeModal
                        title={"S3 Browser"}
                        content={
                            <S3Browser
                                fileName={fileName}
                                selectedMedia={selectedMedia}
                                fileType={"images"}
                            />
                        }
                        size={"xl"}
                        show={modalShow}
                        onHide={() => {
                            setModalShow(false);
                        }}
                    />
                </Form>
            </div>
        );
    };

    return (
        <>
            {loading && <FullPageLoader loading={loading} />}
            <div className={"orderPage"}>
                <h1 className="page-heading">Categories</h1>
                <div className={"customOrderFilters"}>
                    <Row>
                        <Col md={5} className="ms-auto">
                            <div className="all-user">
                                <div className="search-bar">
                                    <div className="common-search-bar">
                                        <InputGroup className="mb-3 search-group">
                                            <InputGroup.Text id="basic-addon1">
                                                <SearchIcon />
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Search"
                                                aria-label="Search"
                                                aria-describedby="Search"
                                                maxLength={"255"}
                                                onChange={(e) => {
                                                    e.target.value.length > 0
                                                        ? setKeyword(e.target.value)
                                                        : setKeyword(null);
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={2}>
                            <Button
                                className={"btn common-btn   w-100"}
                                onClick={() => setShowAddModal(true)}
                            >
                                <AddIconOutline /> Add New
                            </Button>
                        </Col>
                    </Row>
                </div>

                <section className="common-table mt-2">
                    <div class="delete-icon">
                        <Button className={"common-btn"}>
                            <DeleteUpdated />
                        </Button>
                    </div>

                    <div style={{ height: "99vh", width: "100%" }}>
                        <StyledDataGrid
                            rows={rows}
                            loading={searchLoading}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={toggleCustomFunc}
                            isColumnResizable
                            onColumnWidthChange={handleColumnWidthChange}
                            columns={columns}
                            checkboxSelection
                            disableSelectionOnClick
                            unstable_headerFilters
                            apiRef={apiRef}
                            density="compact"
                            pageSize={perPage}
                            onPageChange={(newPage) => setPage(newPage)}
                            onPageSizeChange={(newPageSize) => {
                                setPerPage(newPageSize);
                                setPage(1);
                            }}
                            paginationMode="server"
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            initialState={{ pinnedColumns: { right: ["actions"] } }}
                            onColumnOrderChange={handleColumnOrderChange}
                        />

                    </div>
                    <div className="user-pagination">
                        <Row>
                            <Col md={4}></Col>
                            <Col md={4}>
                                <Stack spacing={2}>
                                    <Pagination
                                        count={countPage}
                                        page={page}
                                        onChange={handlePaginationChange}
                                        size="large"
                                    />
                                </Stack>
                            </Col>
                            <Col md={4}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <label>Rows per page:</label>
                                    <Select value={perPage} onChange={handlePageSizeChange}>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                    </Select>
                                </Stack>
                            </Col>
                        </Row>
                    </div>
                </section>
                <ThemeModal
                    title={"Add New Category"}
                    show={showAddModal}
                    onHide={() => setShowAddModal(false)}
                    size={"md"}
                    content={<AddModal />}
                    className={"sub-cat-modal"}
                />
                <ThemeModal
                    title={"Edit Category"}
                    show={showEditModal}
                    onHide={() => setShowEditModal(false)}
                    size={"md"}
                    content={
                        <EditModal
                            selectedCategoryId={selectedCategoryId}
                            selectedCategoryName={selectedCategoryName}
                            selectedImage={selectedImage}
                        />
                    }
                    className={"sub-cat-modal"}
                />

            </div>
        </>
    );
}

export default EquipmentCategory;
