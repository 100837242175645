import React from 'react'
import {Redirect, Route} from "react-router-dom";
import { GetToken } from '../services/auth/auth.service'

function AuthLayout({children}) {
    return (
        <div>
            <div className="auth_layout_wrapper">
                <div className="authentication">
                    {children}
                </div>
            </div>
        </div>
    )
}

const AuthLayoutRoute = ({isAuth, component: Component, ...rest}) => {
    isAuth = Boolean(GetToken());
    return(
        <>
            <Route
                {...rest}
                render={(matchProps) =>
                    isAuth ? (
                        <Redirect
                            to={{
                                pathname: "/dashboard",
                                state: { from: matchProps.location },
                            }}
                        />
                    ) : (
                        <AuthLayout>
                            <Component {...matchProps}/>
                        </AuthLayout>
                    )
                }
            />
        </>
    )
}

export default AuthLayoutRoute
