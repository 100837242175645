import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import { Container, Row, Col } from "react-bootstrap";
import {
  EmptyLocalStorage,
  GetAuthUser,
  GetToken,
} from "../services/auth/auth.service";
import UserContext from "../hooks/UserContext";
import { GetProfile } from "../services/users.service";
import { toast } from "react-toastify";
import { updateUser } from "../services/firebase.service";
import { useHistory } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import MuiXLicense from "./MuiXLicense";

function DashboardLayout({ children }) {
  const history = useHistory();
  const [user, setUser] = useState("Profile");
  const [changeUser, setChangeUser] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    //getProfile();
  }, []);

  const getProfile = async () => {
    await GetProfile()
      .then(async (result) => {
        if (result.status) {
          if (result.data?.profile) {
            let dt = result.data?.profile;
            if (dt) {
              await setUser(dt.full_name);
            }
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        return toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="page-index">
        {isOpen && <SideBar />}
        <main className= {`${isOpen ? ' main-open' : 'main-close'}`}>
          <UserContext.Provider value={user}>
            <Header
              isOpen={isOpen}
              setIsOpen={false}
              handleGetDataFromParent={toggleSidebar}
            />
          </UserContext.Provider>
          <div className={"main_content_wrapper"}>
            {children}
            <MuiXLicense />
          </div>
        </main>
      </div>
      {/* <div className="dashboard_layout_wrapper">
        <Container fluid className={"p-0"}>
          <Row>
            <div
              className={`toggle-btn ${isOpen ? "toggle-btn-open" : ""}`}
              onClick={toggleSidebar}
            >
              {isOpen ? <FaTimes /> : <FaBars />}
            </div>

            <aside
              className={` d-none d-lg-block sidebar-nav sidebar ${
                isOpen ? "open" : ""
              } sidebar-padding`}
            ></aside>
            {isOpen ? <div class="col-xxl-2"></div> : ""}
            <div className={` ${isOpen ? "col-xxl-10" : "col-xxl-12"} `}></div>
          </Row>
        </Container>
      </div> */}
    </>
  );
}

const DashboardLayoutRoute = ({
  isAuth,
  isOpen,
  component: Component,
  ...rest
}) => {
  isAuth = Boolean(GetToken());
  // isAuth = true;
  return (
    <>
      <Route
        {...rest}
        render={(matchProps) =>
          isAuth ? (
            <DashboardLayout>
              <Component {...matchProps} />
            </DashboardLayout>
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: matchProps.location },
              }}
            />
          )
        }
      />
    </>
  );
};

export default DashboardLayoutRoute;
