import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import ThemeModal from "../../components/ThemeModal";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { constants, cross_origin, STATUS } from "../../utils/constants";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {
  CreateSport,
  DeleteSport,
  GetSportById,
  GetSports,
  UpdateSport,
  DeleteMultipleSports,
} from "../../services/sports.service";
import Stack from "@mui/material/Stack";
import Pagination from "@material-ui/lab/Pagination";
import { MdEdit, MdDelete } from "react-icons/md";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { MenuItem, Select } from "@mui/material";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function Sports() {
  let history = useHistory();
  const apiRef = useGridApiRef();
  const [sportId, setSportId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getSportsFunction(true);
      setSportId(null);
    };

    fetchData();
  }, [status, page, perPage]);

  useEffect(() => {
    if (keyword) {
      getSportsFunction(false);
    } else {
      getSportsFunction(true);
    }
  }, [keyword, status, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("sports", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "ID", width: 150 },
      { field: "name", headerName: "Sport", width: 150 },
      { field: "status", headerName: "Status", width: 150 },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            const id = `${params.id}`;
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              if (willShip) {
                await DeleteSport(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getSportsFunction();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          const onEdit = (id) => {
            clearErrors("name");
            setShowEditModal(true);
            setSportId(id);
            GetSportById(id)
              .then((result) => {
                if (result.status) {
                  if (result.data) {
                    setValue("name", result.data.name);
                    setValue("status", result.data.status);
                  }
                } else {
                  toast.error(result.message);
                }
              })
              .catch((error) => {
                toast.error(error.response.data.message.replace(/_/g, " "));
              });
          };

          return (
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params.id);
                }}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm mx-2"}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.id);
                }}
              >
                <MdDelete />
              </button>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("sports", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };
  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "sports",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("sports", "column_order_change").then((result) => {
      if (result.status && result.data) {
        const parsedArray = JSON.parse(result.data.table_data);
        const dataArray = Object.values(parsedArray);
        const completeColumns = dataArray.map((item) => {
          if (item.field === "actions") {
            return {
              field: "actions",
              headerName: "Actions",
              sortable: false,
              filterable: false,
              width: item.width || 150,

              renderCell: (params) => {
                const onDelete = () => {
                  const id = `${params.id}`;
                  swal({
                    title: "Are you sure?",
                    text: "Are you sure you want to delete?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (willShip) => {
                    if (willShip) {
                      await DeleteSport(id)
                        .then((result) => {
                          if (result.status) {
                            swal(result.message, {
                              icon: "success",
                            });
                            getSportsFunction();
                          } else {
                            toast.error(result.message);
                          }
                        })
                        .catch((error) => {
                          toast.error(
                            error.response.data.message.replace(/_/g, " ")
                          );
                        });
                    }
                  });
                };

                const onEdit = (id) => {
                  clearErrors("name");
                  setShowEditModal(true);
                  setSportId(id);
                  GetSportById(id)
                    .then((result) => {
                      if (result.status) {
                        if (result.data) {
                          setValue("name", result.data.name);
                          setValue("status", result.data.status);
                        }
                      } else {
                        toast.error(result.message);
                      }
                    })
                    .catch((error) => {
                      toast.error(
                        error.response.data.message.replace(/_/g, " ")
                      );
                    });
                };

                return (
                  <div>
                    <button
                      className={"btn btn-primary btn-sm"}
                      onClick={(event) => {
                        event.stopPropagation();
                        onEdit(params.id);
                      }}
                    >
                      <MdEdit />
                    </button>
                    <button
                      className={"btn btn-danger btn-sm mx-2"}
                      onClick={(event) => {
                        event.stopPropagation();
                        onDelete(params.id);
                      }}
                    >
                      <MdDelete />
                    </button>
                  </div>
                );
              },
            };
          } else if (item.field === "image") {
            return {
              field: "image",
              headerName: "Image",
              width: 180,
              renderCell: (params) => (
                <a
                  href={params.value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={params.value}
                    alt="image"
                    style={{ width: "auto", height: 50 }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = constants.IMAGENOTFOUND;
                    }}
                    className={"img-table img-thumbnail"}
                    crossOrigin={cross_origin ? "anonymous" : null}
                  />
                </a>
              ),
            };
          } else {
            return item;
          }
        });
        setColumns(completeColumns);
      }
    });
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "sports",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "sports",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("sports", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const getSportsFunction = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }
    try {
      const result = await GetSports(keyword, page, perPage, 1, status);

      if (result.status) {
        if (result.data) {
          const rowData = [];
          setPage(result?.data?.meta?.current_page);
          setPageCount(result?.data?.meta?.last_page);
          result?.data?.data?.map((dt) => {
            rowData.push({
              id: dt?.id,
              name: dt?.name,
              status: STATUS[dt?.status],
            });
          });
          setRowData(rowData);
        }
      }
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
    } catch (error) {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.replace(/_/g, " ")) ||
          "An error occurred"
      );
    }
  };

  const onMultipleDelete = async () => {
    const rows = await apiRef.current.getSelectedRows();

    const dataArray = [...rows.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeSports: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleSports(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getSportsFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const onSubmit = async (data) => {
    setLoader(true);
    await CreateSport(data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          setShowAddModal(false);
          setShowEditModal(false);
          getSportsFunction();
          reset({
            name: "",
            status: "",
          });
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const onEditSubmit = async (data) => {
    setLoader(true);
    if (sportId != 0 && sportId != null) {
      await UpdateSport(sportId, data)
        .then(async (data) => {
          setLoader(false);
          if (data.status) {
            toast.success(data.message);
            setShowAddModal(false);
            setShowEditModal(false);
            getSportsFunction();
          } else {
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    }
  };

  const EditModal = (props) => {
    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onEditSubmit)}>
          <Form.Group className={"mb-3"} controlId="bodyType">
            <Form.Label>Sport Name*</Form.Label>
            <Form.Control
              {...register("name", {
                required: {
                  value: "required",
                  message: "Sport Name is required",
                },
                pattern: {
                  value: /^[a-zA-Z0-9_ ]*$/,
                  message: "This field must contain only letters",
                },
              })}
              type={"text"}
              placeholder={"Sport Name"}
            />
            {errors.name && (
              <Form.Text className=" validationText hasError">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className={"mb-3"} controlId="status">
            <Form.Label className="d-block">Select Status</Form.Label>
            <Form.Select
              className="formselect"
              aria-label="status"
              {...register("status", {
                required: {
                  value: "required",
                  message: "Status is required",
                },
              })}
            >
              <option value="10">Active</option>
              <option value="20">Inactive</option>
            </Form.Select>
            {errors.status && (
              <Form.Text className=" validationText hasError">
                {errors.status.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <FullPageLoader loading={loader} />
          ) : (
            <Button
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={""}
            >
              submit
            </Button>
          )}
        </Form>
      </div>
    );
  };

  const AddModal = () => {
    setSportId(null);
    return (
      <div className={"ViewModalContact"}>
        <Form
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group className={"mb-3"} controlId="bodyType">
            <Form.Label>Sport Name*</Form.Label>
            <Form.Control
              {...register("name", {
                required: {
                  value: "required",
                  message: "Sport Name is required",
                },
                pattern: {
                  value: /^[a-zA-Z0-9_ ]*$/,
                  message: "This field must contain only letters",
                },
              })}
              type={"text"}
              placeholder={"Sport Name"}
            />
            {errors.name && (
              <Form.Text className=" validationText hasError">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className={"mb-3"} controlId="status">
            <Form.Label className="d-block">Select Status</Form.Label>
            <Form.Select
              className="formselect"
              aria-label="status"
              {...register("status", {
                required: {
                  value: "required",
                  message: "Status is required",
                },
              })}
            >
              <option value="10">Active</option>
              <option value="20">Inactive</option>
            </Form.Select>
            {errors.status && (
              <Form.Text className=" validationText hasError">
                {errors.weight.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <FullPageLoader loading={loader} />
          ) : (
            <Button type={"submit"} className={"common-btn w-100"} value={""}>
              Add New Sport
            </Button>
          )}
        </Form>
      </div>
    );
  };

  const AddModalOpen = () => {
    setShowAddModal(true);
    reset({
      name: "",
      status: "10",
    });
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <div className="orderPage sports-index">
        <div className={"orderPage"}>
          <h1>Sports</h1>
          <div className={"customOrderFilters"}>
            <Row>
              <Col lg={1}></Col>
              <Col md={4}>
                <div className="select-section">
                  <Form.Group controlId="sport">
                    <Form.Select
                      className="formselect filter"
                      aria-label="status"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option key="blankChoice" hidden value>
                        Sports
                      </option>
                      <option value="">All</option>
                      <option value="10">Active</option>
                      <option value="20">InActive</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </Col>
              <Col md={5}>
                <div className="all-user">
                  <div className="search-bar">
                    <div className="common-search-bar">
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          <SearchIcon />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="Search"
                          onChange={(e) => {
                            e.target.value.length > 1
                              ? setKeyword(e.target.value)
                              : setKeyword(null);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={2}>
                <Button
                  className={"btn common-btn   w-100"}
                  onClick={() => AddModalOpen()}
                >
                  <AddIconOutline /> Add New
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <section className="common-table mt-2">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>

          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rowData}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>

          <div className="user-pagination">
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <Stack spacing={2}>
                  <Pagination
                    count={countPage}
                    page={page}
                    onChange={handlePaginationChange}
                    size="large"
                  />
                </Stack>
              </Col>
              <Col md={4}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <label>Rows per page:</label>
                  <Select value={perPage} onChange={handlePageSizeChange}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </Stack>
              </Col>
            </Row>
          </div>

          <ThemeModal
            title={"Add New Sport"}
            show={showAddModal}
            onHide={() => setShowAddModal(false)}
            size={"md"}
            content={<AddModal />}
          />
          <ThemeModal
            title={"Edit Sport"}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            size={"md"}
            content={<EditModal />}
          />
        </section>
      </div>
    </>
  );
}

export default Sports;
