import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import {
  DeletePage,
  GetPages,
  DeleteMultiplePages,
} from "../../services/pages.service";
import { toast } from "react-toastify";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { MdEdit, MdDelete } from "react-icons/md";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { AiOutlineFolderView } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { MenuItem, Pagination, Select, Stack } from "@mui/material";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function PageList() {
  let history = useHistory();
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [columns, setColumns] = useState([]);



  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getPages(true);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (keyword) {
      getPages(false);
    } else {
      getPages(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("content_management", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("content_management", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "#", width: 100 },
      { field: "title", headerName: "Title", width: 150 },
      { field: "slug", headerName: "Slug", width: 150 },
      { field: "created_at", headerName: "Created At", width: 150 },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            // Logic to handle delete action
            const id = `${params.id}`;

            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete this page?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              //Delete Product API
              if (willShip) {
                await DeletePage(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getPages();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    if (error.response.status == 401) {
                      EmptyLocalStorage();
                      history.push("/");
                    } else {
                      return toast.error(
                        error.response.data.message.replace(/_/g, " ")
                      );
                    }
                  });
              }
            });
          };

          const onEdit = (data) => {
            history.push("/page-edit/" + data, {
              data: data,
            });
          };

          const onViewPage = (data) => {
            history.push("/page/" + data.slug);
          };

          return (
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params.id);
                }}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm mx-2"}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.id);
                }}
              >
                <MdDelete />
              </button>
              <button
                className={"btn btn-dark btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onViewPage(params);
                }}
              >
                <AiOutlineFolderView />
              </button>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "content_management",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("content_management", "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => {
                  const onDelete = () => {
                    // Logic to handle delete action
                    const id = `${params.id}`;

                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to delete this page?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willShip) => {
                      //Delete Product API
                      if (willShip) {
                        await DeletePage(id)
                          .then((result) => {
                            if (result.status) {
                              swal(result.message, {
                                icon: "success",
                              });
                              getPages();
                            } else {
                              toast.error(result.message);
                            }
                          })
                          .catch((error) => {
                            if (error.response.status == 401) {
                              EmptyLocalStorage();
                              history.push("/");
                            } else {
                              return toast.error(
                                error.response.data.message.replace(/_/g, " ")
                              );
                            }
                          });
                      }
                    });
                  };

                  const onEdit = (data) => {
                    history.push("/page-edit/" + data, {
                      data: data,
                    });
                  };

                  const onViewPage = (data) => {
                    history.push("/page/" + data.slug);
                  };

                  return (
                    <div>
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onEdit(params.id);
                        }}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className={"btn btn-danger btn-sm mx-2"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(params.id);
                        }}
                      >
                        <MdDelete />
                      </button>
                      <button
                        className={"btn btn-dark btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onViewPage(params);
                        }}
                      >
                        <AiOutlineFolderView />
                      </button>
                    </div>
                  );
                },
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "content_management",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "content_management",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("content_management", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const getPages = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }
    await GetPages(keyword, page, perPage, 1)
      .then((result) => {
        if (result.status) {
          if (result.data.data) {
            const rowData = [];
            setPage(result?.data?.meta?.current_page);
            setPageCount(result?.data?.meta?.last_page);
            result?.data?.data?.map((dt, index) => {
              rowData.push({
                id: dt.id,
                index: ++index,
                slug: dt.slug,
                title: dt.name,
                created_at: moment(dt.created_at).fromNow(),
              });
            });
            setRows(rowData);
            setLoading(false);
          }
          if (isInitialLoad) {
            setLoading(false);
          } else {
            setSearchLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        if (isInitialLoad) {
          EmptyLocalStorage();
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const onMultipleDelete = async () => {
    let rowsDeleted = await apiRef.current.getSelectedRows();

    const dataArray = [...rowsDeleted.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removePages: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultiplePages(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getPages();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const AddPage = () => {
    history.push("/page-add");
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}

      <div className={"PageList"}>
        <h1>Pages</h1>
        <div className={"customOrderFilters"}>
          <Row>
            <Col md={1}> </Col>
            <Col md={5} className="ms-auto">
              <div className="all-user">
                <div className="search-bar">
                  <div className="common-search-bar">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <SearchIcon />
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="Search"
                        onChange={(e) => {
                          e.target.value.length > 0
                            ? setKeyword(e.target.value)
                            : setKeyword(null);
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <Button onClick={AddPage} className={"btn common-btn   w-100"}>
                <AddIconOutline /> Add New
              </Button>
            </Col>
          </Row>
        </div>

        <section className="common-table mt-2">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>

          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>
          <div className="user-pagination">
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <Stack spacing={2}>
                  <Pagination
                    count={countPage}
                    page={page}
                    onChange={handlePaginationChange}
                    size="large"
                  />
                </Stack>
              </Col>
              <Col md={4}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <label>Rows per page:</label>
                  <Select value={perPage} onChange={handlePageSizeChange}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                  </Select>
                </Stack>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
}

export default PageList;
