import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { constants, cross_origin } from "../../utils/constants";
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  GetExerciseById,
  GetExerciseSummary,
  UpdateExercise,
} from "../../services/exercises.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { Interweave } from "interweave";
import { TrashIcon } from "../../components/SvgIcons";
import { BiEdit } from "react-icons/bi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { VideoImg } from "../video/VideoImg";

const SummaryBox = (props) => {
  const maleData = props.maleData;
  const femaleData = props.femaleData;
  const maxLength = 40;

  const VideoMaleRef = useRef();
  const VideoFemaleRef = useRef();

  const ReadMoreP = ({ text, maxLength, className }) => {
    const [expanded, setExpanded] = useState(false);
    const truncatedText = text?.slice(0, maxLength);
    return (
      <div>
        <div
          className={`titles-label ${className}`}
          style={{ wordWrap: "break-word" }}
        >
          <Interweave content={expanded ? text : truncatedText} />
          <button
            className={"btn-expand"}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "See less" : "See more"}
          </button>
        </div>
      </div>
    );
  };

  const [videoFlipMale, setVideoFlipMale] = useState(false);
  const [videoFemalePlaying, setVideoFemalePlaying] = useState(false);
  const [videoMalePlaying, setVideoMalePlaying] = useState(false);
  const [replicateMale, setReplicateMale] = useState(false);
  const [videoUrlTempFemale, setVideoUrlTempFemale] = useState(null);
  const [videoUrlTemp2Male, setVideoUrlTemp2Male] = useState(null);
  const [videoUrlTemp2Female, setVideoUrlTemp2Female] = useState(null);
  const [videoFlipFemale, setVideoFlipFemale] = useState(false);
  const [maleVideoCover, setMaleVideoCover] = useState("");
  const [femaleVideoCover, setFemaleVideoCover] = useState("");
  const [resetCoverViewer, setResetCoverViewer] = useState(false);
  const history = useHistory();

  const setMaleVideoCoverFunc = (data) => {
    if (maleVideoCover !== data && data !== null && data !== "") {
      props.changed(true);
    }
    if (replicateMale === true) {
      setFemaleVideoCoverFunc(data);
    }
    setMaleVideoCover(data);
  };
  const setFemaleVideoCoverFunc = (data) => {
    if (femaleVideoCover !== data && data !== null && data !== "") {
      props.changed(true);
    }
    setFemaleVideoCover(data);
  };

  const spittedName = (url) => {
    const urlSegments = url.split("/");
    const videoNameWithExtension = urlSegments[urlSegments.length - 1];
    return videoNameWithExtension;
  };

  // const handleClick = (id) => {
  //   const url = `/edit-exercise-steps/${props.id}`;
  //   const state = { exercise: props.exercise, level: 1 };
  //   window.open(url, '_blank');
  //   history.push(url, state);
  // };

  return (
    <div className="mt-5 summary-box border-cls">
      <div className={"summary-box-head"}>
        <h2>
          {maleData.equipment_exercise_type === 0
            ? maleData.level_id == 4
              ? "Level 0"
              : "Level " + maleData.level_id
            : "Equipment " + maleData.equipment_exercise_type}
        </h2>
      </div>
      <div className={"summary-box-body"}>
        <Row className={"mb-2 g-1"}>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div className="titles-box titles-label">
              <Link
                target="_blank"
                to={`/edit-exercise-steps/${maleData?.exercise_id}`}
              >
                {maleData?.file_name}
              </Link>
            </div>
            <div className="SummaryCoverImg">
              <img
                className=""
                crossOrigin={cross_origin ? "anonymous" : null}
                src={maleData?.video_cover}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = constants.IMAGENOTFOUNDREC;
                }}
                alt="Exercise Level Cover"
              />
            </div>
            {maleData?.exerciseEducationVideo &&
            maleData?.exerciseEducationVideo[0] ? (
              <div className="titles-box titles-label pb-2 pt-2 text-center">
                <a
                  target="_blank"
                  href={
                    maleData?.exerciseEducationVideo[0]?.video
                      ? maleData?.exerciseEducationVideo[0]?.video
                      : ""
                  }
                >
                  {maleData?.exerciseEducationVideo[0]?.video
                    ? spittedName(maleData?.exerciseEducationVideo[0]?.video)
                    : ""}
                </a>
              </div>
            ) : (
              ""
            )}
            {maleData?.exerciseEducationVideo &&
            maleData?.exerciseEducationVideo[1] ? (
              <div className="titles-box titles-label pb-2 pt-2 text-center">
                <a
                  target="_blank"
                  href={
                    maleData?.exerciseEducationVideo[1]?.video
                      ? maleData?.exerciseEducationVideo[1]?.video
                      : ""
                  }
                >
                  {maleData?.exerciseEducationVideo[1]?.video
                    ? spittedName(maleData?.exerciseEducationVideo[1]?.video)
                    : ""}
                </a>
              </div>
            ) : (
              ""
            )}
            {maleData?.exerciseEducationVideo &&
            maleData?.exerciseEducationVideo[2] ? (
              <div className="titles-box titles-label pb-2 pt-2 text-center">
                <a
                  target="_blank"
                  href={
                    maleData?.exerciseEducationVideo[2]?.video
                      ? maleData?.exerciseEducationVideo[2]?.video
                      : ""
                  }
                >
                  {maleData?.exerciseEducationVideo[2]?.video
                    ? spittedName(maleData?.exerciseEducationVideo[2]?.video)
                    : ""}
                </a>
              </div>
            ) : (
              ""
            )}
            {maleData?.exerciseEducationVideo &&
            maleData?.exerciseEducationVideo[3] ? (
              <div className="titles-box titles-label pb-2 pt-2 text-center">
                <a
                  target="_blank"
                  href={
                    maleData?.exerciseEducationVideo[3]?.video
                      ? maleData?.exerciseEducationVideo[3]?.video
                      : ""
                  }
                >
                  {maleData?.exerciseEducationVideo[3]?.video
                    ? spittedName(maleData?.exerciseEducationVideo[3]?.video)
                    : ""}
                </a>
              </div>
            ) : (
              ""
            )}
            <div className="titles-box">
              <ReadMoreP
                text={maleData?.starting_position}
                maxLength={maxLength}
              />
            </div>
            <div className="titles-box">
              <ReadMoreP text={maleData?.movements} maxLength={maxLength} />
            </div>
            <div className="titles-box">
              <ReadMoreP
                text={maleData?.internal_notes}
                maxLength={maxLength}
                className={"text-danger"}
              />
            </div>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div className="titles-box titles-label">
              <Link
                target="_blank"
                to={`/edit-exercise-steps/${femaleData?.exercise_id}`}
              >
                {femaleData?.file_name}
              </Link>
            </div>
            <div className="SummaryCoverImg">
              <img
                className=""
                crossOrigin={cross_origin ? "anonymous" : null}
                src={femaleData?.video_cover}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = constants.IMAGENOTFOUNDREC;
                }}
                alt="Exercise Level Cover"
              />
            </div>
            {femaleData?.exerciseEducationVideo &&
            femaleData?.exerciseEducationVideo[0] ? (
              <div className="titles-box titles-label pb-2 pt-2 text-center">
                <a
                  target="_blank"
                  href={
                    femaleData?.exerciseEducationVideo[0]?.video
                      ? femaleData?.exerciseEducationVideo[0]?.video
                      : ""
                  }
                >
                  {femaleData?.exerciseEducationVideo[0]?.video
                    ? spittedName(femaleData?.exerciseEducationVideo[0]?.video)
                    : ""}
                </a>
              </div>
            ) : (
              ""
            )}
            {femaleData?.exerciseEducationVideo &&
            femaleData?.exerciseEducationVideo[1] ? (
              <div className="titles-box titles-label pb-2 pt-2 text-center">
                <a
                  target="_blank"
                  href={
                    femaleData?.exerciseEducationVideo[1]?.video
                      ? femaleData?.exerciseEducationVideo[1]?.video
                      : ""
                  }
                >
                  {femaleData?.exerciseEducationVideo[1]?.video
                    ? spittedName(femaleData?.exerciseEducationVideo[1]?.video)
                    : ""}
                </a>
              </div>
            ) : (
              ""
            )}
            {femaleData?.exerciseEducationVideo &&
            femaleData?.exerciseEducationVideo[2] ? (
              <div className="titles-box titles-label pb-2 pt-2 text-center">
                <a
                  target="_blank"
                  href={
                    femaleData?.exerciseEducationVideo[2]?.video
                      ? femaleData?.exerciseEducationVideo[2]?.video
                      : ""
                  }
                >
                  {femaleData?.exerciseEducationVideo[2]?.video
                    ? spittedName(femaleData?.exerciseEducationVideo[2]?.video)
                    : ""}
                </a>
              </div>
            ) : (
              ""
            )}
            {femaleData?.exerciseEducationVideo &&
            femaleData?.exerciseEducationVideo[3] ? (
              <div className="titles-box titles-label pb-2 pt-2 text-center">
                <a
                  target="_blank"
                  href={
                    femaleData?.exerciseEducationVideo[3]?.video
                      ? femaleData?.exerciseEducationVideo[3]?.video
                      : ""
                  }
                >
                  {femaleData?.exerciseEducationVideo[3]?.video
                    ? spittedName(femaleData?.exerciseEducationVideo[3]?.video)
                    : ""}
                </a>
              </div>
            ) : (
              ""
            )}
            <div className="titles-box">
              <ReadMoreP
                text={femaleData?.starting_position}
                maxLength={maxLength}
              />
            </div>
            <div className="titles-box">
              <ReadMoreP text={femaleData?.movements} maxLength={maxLength} />
            </div>
            <div className="titles-box ">
              <ReadMoreP
                text={femaleData?.internal_notes}
                maxLength={maxLength}
                className={"text-danger"}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
    // <div className="mt-5 summary-box">

    //   <div className={"summary-box-body"}>
    //     <Row className={"mb-2 g-1"}>
    //       <Col xs={6} sm={6} md={6} lg={6}>
    //         <Col xs={12} sm={12} md={12} lg={12} xl={12}>
    //           <Form.Group className="mb-3">
    //             <Form.Control
    //               className="p-2"
    //               placeholder="Paste or type text here"
    //               id={"male_file_name"}
    //               type="text"
    //               value={maleData?.file_name}
    //             />
    //           </Form.Group>
    //         </Col>
    //         <Col xs={12} sm={12} md={8} lg={6} xl={6}>
    //           <Form.Label></Form.Label>
    //           <div className="image-item mb-3">
    //             <img
    //               crossOrigin={cross_origin ? "anonymous" : null}
    //               src={maleData?.video_cover}
    //               alt="Video Cover"
    //               className={"img-fluid"}
    //             />
    //           </div>
    //         </Col>
    //         {maleData?.exerciseEducationVideo &&
    //         maleData?.exerciseEducationVideo[0] ? (
    //           <Col xs={12} sm={12} md={8} lg={12} xl={12}>
    //             <Form.Group controlId="email">
    //               <Form.Label className="d-block">
    //                 Educational Video # 1
    //               </Form.Label>

    //               <a
    //                 target="_blank"
    //                 href={
    //                   maleData?.exerciseEducationVideo[0]?.video
    //                     ? maleData?.exerciseEducationVideo[0]?.video
    //                     : ""
    //                 }
    //               >
    //                 {maleData?.exerciseEducationVideo[0]?.video
    //                   ? maleData?.exerciseEducationVideo[0]?.video
    //                   : ""}
    //               </a>
    //             </Form.Group>
    //           </Col>
    //         ) : (
    //           ""
    //         )}
    //         {maleData?.exerciseEducationVideo &&
    //         maleData?.exerciseEducationVideo[1] ? (
    //           <Col xs={12} sm={12} md={8} lg={12} xl={12} className="mt-3">
    //             <Form.Group controlId="email">
    //               <Form.Label className="d-block">
    //                 Educational Video # 2
    //               </Form.Label>

    //               <a
    //                 target="_blank"
    //                 href={
    //                   maleData?.exerciseEducationVideo[1]?.video
    //                     ? maleData?.exerciseEducationVideo[1]?.video
    //                     : ""
    //                 }
    //               >
    //                 {maleData?.exerciseEducationVideo[1]?.video
    //                   ? maleData?.exerciseEducationVideo[1]?.video
    //                   : ""}
    //               </a>
    //             </Form.Group>
    //           </Col>
    //         ) : (
    //           ""
    //         )}
    //         {maleData?.exerciseEducationVideo &&
    //         maleData?.exerciseEducationVideo[2] ? (
    //           <Col xs={12} sm={12} md={8} lg={12} xl={12} className="mt-3">
    //             <Form.Group controlId="email">
    //               <Form.Label className="d-block">
    //                 Educational Video # 3
    //               </Form.Label>

    //               <a
    //                 target="_blank"
    //                 href={
    //                   maleData?.exerciseEducationVideo[2]?.video
    //                     ? maleData?.exerciseEducationVideo[2]?.video
    //                     : ""
    //                 }
    //               >
    //                 {maleData?.exerciseEducationVideo[2]?.video
    //                   ? maleData?.exerciseEducationVideo[2]?.video
    //                   : ""}
    //               </a>
    //             </Form.Group>
    //           </Col>
    //         ) : (
    //           ""
    //         )}
    //         {maleData?.exerciseEducationVideo &&
    //         maleData?.exerciseEducationVideo[3] ? (
    //           <Col xs={12} sm={12} md={8} lg={12} xl={12} className="mt-3">
    //             <Form.Group controlId="email">
    //               <Form.Label className="d-block">
    //                 Educational Video # 4
    //               </Form.Label>

    //               <a
    //                 target="_blank"
    //                 href={
    //                   maleData?.exerciseEducationVideo[3]?.video
    //                     ? maleData?.exerciseEducationVideo[3]?.video
    //                     : ""
    //                 }
    //               >
    //                 {maleData?.exerciseEducationVideo[3]?.video
    //                   ? maleData?.exerciseEducationVideo[3]?.video
    //                   : ""}
    //               </a>
    //             </Form.Group>
    //           </Col>
    //         ) : (
    //           ""
    //         )}
    //         <div className="titles-box mt-3">
    //           <ReadMoreP
    //             text={maleData?.starting_position}
    //             maxLength={maxLength}
    //           />
    //         </div>
    //         <div className="titles-box">
    //           <ReadMoreP text={maleData?.movements} maxLength={maxLength} />
    //         </div>
    //         <div className="titles-box">
    //           <ReadMoreP
    //             text={maleData?.internal_notes}
    //             maxLength={maxLength}
    //             className={"text-danger"}
    //           />
    //         </div>
    //       </Col>
    //       <Col xs={6} sm={6} md={6} lg={6}>
    //         <Col xs={12} sm={12} md={12} lg={12} xl={12}>
    //           <Form.Group className="mb-3">
    //             <Form.Control
    //               className="p-2"
    //               placeholder="Paste or type text here"
    //               id={"male_file_name"}
    //               type="text"
    //               value={femaleData?.file_name}
    //             />
    //           </Form.Group>
    //         </Col>
    //         <Col xs={12} sm={12} md={8} lg={6} xl={6}>
    //           <Form.Label></Form.Label>
    //           <div className="image-item mb-3">
    //             <img
    //               crossOrigin={cross_origin ? "anonymous" : null}
    //               src={femaleData?.video_cover}
    //               alt="Video Cover"
    //               className={"img-fluid"}
    //             />
    //           </div>
    //         </Col>
    //         {femaleData?.exerciseEducationVideo &&
    //         maleData?.exerciseEducationVideo[0] ? (
    //           <Col xs={12} sm={12} md={8} lg={12} xl={12}>
    //             <Form.Group controlId="email">
    //               <Form.Label className="d-block">
    //                 Educational Video # 1
    //               </Form.Label>
    //               <a
    //                 target="_blank"
    //                 href={
    //                   femaleData?.exerciseEducationVideo[0]?.video
    //                     ? femaleData?.exerciseEducationVideo[0]?.video
    //                     : ""
    //                 }
    //               >
    //                 {maleData?.exerciseEducationVideo[0]?.video
    //                   ? femaleData?.exerciseEducationVideo[0]?.video
    //                   : ""}
    //               </a>
    //             </Form.Group>
    //           </Col>
    //         ) : (
    //           ""
    //         )}
    //         {femaleData?.exerciseEducationVideo &&
    //         maleData?.exerciseEducationVideo[1] ? (
    //           <Col xs={12} sm={12} md={8} lg={12} xl={12} className="mt-3">
    //             <Form.Group controlId="email">
    //               <Form.Label className="d-block">
    //                 Educational Video # 2
    //               </Form.Label>
    //               <a
    //                 target="_blank"
    //                 href={
    //                   femaleData?.exerciseEducationVideo[1]?.video
    //                     ? femaleData?.exerciseEducationVideo[1]?.video
    //                     : ""
    //                 }
    //               >
    //                 {maleData?.exerciseEducationVideo[1]?.video
    //                   ? femaleData?.exerciseEducationVideo[1]?.video
    //                   : ""}
    //               </a>
    //             </Form.Group>
    //           </Col>
    //         ) : (
    //           ""
    //         )}

    //         {femaleData?.exerciseEducationVideo &&
    //         maleData?.exerciseEducationVideo[2] ? (
    //           <Col xs={12} sm={12} md={8} lg={12} xl={12} className="mt-3">
    //             <Form.Group controlId="email">
    //               <Form.Label className="d-block">
    //                 Educational Video # 3
    //               </Form.Label>
    //               <a
    //                 target="_blank"
    //                 href={
    //                   femaleData?.exerciseEducationVideo[2]?.video
    //                     ? femaleData?.exerciseEducationVideo[2]?.video
    //                     : ""
    //                 }
    //               >
    //                 {maleData?.exerciseEducationVideo[2]?.video
    //                   ? femaleData?.exerciseEducationVideo[2]?.video
    //                   : ""}
    //               </a>
    //             </Form.Group>
    //           </Col>
    //         ) : (
    //           ""
    //         )}

    //         {femaleData?.exerciseEducationVideo &&
    //         maleData?.exerciseEducationVideo[3] ? (
    //           <Col xs={12} sm={12} md={8} lg={12} xl={12} className="mt-3">
    //             <Form.Group controlId="email">
    //               <Form.Label className="d-block">
    //                 Educational Video # 4
    //               </Form.Label>
    //               <a
    //                 target="_blank"
    //                 href={
    //                   femaleData?.exerciseEducationVideo[3]?.video
    //                     ? femaleData?.exerciseEducationVideo[3]?.video
    //                     : ""
    //                 }
    //               >
    //                 {maleData?.exerciseEducationVideo[3]?.video
    //                   ? femaleData?.exerciseEducationVideo[3]?.video
    //                   : ""}
    //               </a>
    //             </Form.Group>
    //           </Col>
    //         ) : (
    //           ""
    //         )}
    //         <div className="titles-box mt-3">
    //           <ReadMoreP
    //             text={femaleData?.starting_position}
    //             maxLength={maxLength}
    //           />
    //         </div>
    //         <div className="titles-box">
    //           <ReadMoreP text={femaleData?.movements} maxLength={maxLength} />
    //         </div>
    //         <div className="titles-box ">
    //           <ReadMoreP
    //             text={femaleData?.internal_notes}
    //             maxLength={maxLength}
    //             className={"text-danger"}
    //           />
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    // </div>
  );
};

function SummaryExcercise(props) {
  let history = useHistory();
  let location = useLocation();
  const [exercise, setExercise] = useState();
  const [maleData, setMaleData] = useState();
  const [femaleData, setFemaleData] = useState();
  const [disableButton, setDisableButton] = useState(false);
  let id = props.match.params.id;
  // const [exerciseId, setExerciseId] = useState(props.exerciseId);
  const [exerciseId, setExerciseId] = useState(id);
  const [level, setLevel] = useState("");

  const getExerciseById = async (id) => {
    await GetExerciseById(id).then((result) => {
      if (result.status) {
        if (result.data) {
          setExercise(result.data);
        } else {
          history.push("/add-exercise");
        }
      }
    });
  };

  if (location.state !== undefined && exercise === undefined) {
    setExercise(location.state.exercise);
  }

  if (location.state === undefined && exercise === undefined) {
    if (exerciseId !== undefined && exerciseId !== null) {
      getExerciseById(exerciseId);
    } else {
      history.push("/add-exercise");
    }
  }

  useEffect(() => {
    const getExerciseSummary = async (exercise_id) => {
      await GetExerciseSummary(exercise_id, level).then((result) => {
        if (result.status && result.data) {
          setMaleData(result.data["male"]);
          setFemaleData(result.data["female"]);
          //props.changed(false)
        }
      });
    };
    if (exercise !== null && exercise !== undefined) {
      exercise.id = exercise.id.replace(/^(C-|I-)/, "");
      getExerciseSummary(exercise.id);
    } else {
      getExerciseSummary(exerciseId);
    }
    // props.changed(false)
  }, [exercise, exerciseId, level]);

  const updateCompleted = async () => {
    let dt = {
      name: exercise.name,
      // is_complete: 1,
    };
    await UpdateExercise(exercise.id, dt)
      .then(async (data) => {
        setDisableButton(true);
        if (data.status) {
          toast.success(data.message);
          setDisableButton(false);
          history.push("/exercises");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const handleBackBtn = () => {
    history.push("/exercises");
  };

  return (
    <div className="Summary">
      <Button onClick={handleBackBtn} className={"common-btn mb-2"}>
        Back
      </Button>
      {exercise?.is_compilation == 1 ? (
        <Row>
          <Col xs={12} sm={12} md={4} lg={2} xl={2}>
            <Form.Label className="d-block mt-sm-2">Levels</Form.Label>
            <Form.Select
              className="formselect program-dropdown"
              aria-label="Weight"
              onChange={(e) => setLevel(e.target.value)}
            >
              <option value="" selected={true}>
                All Levels
              </option>
              <option value={1}>Level 1</option>
              <option value={2}>Level 2</option>
              <option value={3}>Level 3</option>
            </Form.Select>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Button
        className="finish-btn"
        onClick={updateCompleted}
        disable={disableButton}
      >
        Finish
      </Button>
      {maleData && Object.keys(maleData).length > 0 ? (
        <Row>
          {maleData.map((anObjectMapped, index) => {
            return (
              <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                <SummaryBox
                  maleData={anObjectMapped}
                  femaleData={femaleData ? femaleData[index] : ""}
                  key={index}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Row>
          <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
            No Summary Available
          </Col>
        </Row>
      )}
    </div>
  );
}

export default SummaryExcercise;
