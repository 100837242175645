import * as React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";



export default function PieChartWithCustomizedLabel({value1,value2}) {

  const data = [
    { value: value1, color: "#c00000" },
    { value: value2, color: "#121212" },
  ];
  
  const sizing = {
    margin: { right: 5 },
    width: 200,
    height: 200,
    legend: { hidden: false },
  };
  
  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);
  
  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <PieChart
        series={[
          {
            outerRadius: 100,
            data,
            arcLabel: getArcLabel,
          },
        ]}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "center" },
            padding: 22,
            labels: {
              usePointStyle: true,
              color: "#000",
            },
          },
        }}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontSize: 14,
          },
        }}
        {...sizing}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <div style={{ display: "flex", alignItems: "center", marginRight: 10 }}>
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: "#c00000",
              marginRight: 5,
            }}
          ></div>
          <span>Complete</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: "#121212",
              marginRight: 5,
            }}
          ></div>
          <span>Incomplete</span>
        </div>
      </div>
    </div>
  );
}
