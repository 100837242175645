import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { upload } from "../../utils/helpers";
import { Col, Form, Row, Button } from "react-bootstrap";
import { TrashIcon } from "../../components/SvgIcons";
import { FadeLoader } from "react-spinners";
import { CreateTests } from "../../services/tests.service";
import { genders } from "../../utils/constants";
import FullPageLoader from "../../components/FullPageLoader";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";

function AddTest() {
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [video, setVideo] = useState("");
  const [fileVideo, setFileVideo] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  /** Option function */
  const [optionValues, setOptionValues] = useState([{ option: "" }]);

  // const handleOptionChange = (index, e) => {
  //   const newOptionValues = [...optionValues];
  //   newOptionValues[index] = {
  //     ...newOptionValues[index],
  //     option: e.target.value,
  //   };
  //   setOptionValues(newOptionValues);
  // };
  let handleOptionChange = (i, e) => {
    let newOptionFormValues = [...optionValues];
    newOptionFormValues[i]["option"] = e.target.value;
    setOptionValues(newOptionFormValues);
    console.log("[e.target.value]", e.target.value);
  };
  let addOptionFormFields = () => {
    setOptionValues([...optionValues, { option: "" }]);
  };
  let removeOptionFormFields = (index) => {
    let rows = [...optionValues];
    rows.splice(index, 1);
    setTimeout(() => setOptionValues(rows), 1000);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [eduFormValues, setEduFormValues] = useState([]);

  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const videoRefs = useRef([]);

  const addEduFormFields = () => {
    setEduFormValues([
      ...eduFormValues,
      { eductionalNote: "", eductionalNewVideo: "", isShow: true },
    ]);
  };

  const removeEduFormFields = (indexToRemove) => {
    setEduFormValues(
      eduFormValues.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleChangeVideo = (e, index) => {
    const newEduFormValues = [...eduFormValues];
    newEduFormValues[index].eductionalNewVideo = URL.createObjectURL(
      e.target.files[0]
    );
    newEduFormValues[index].eductionalNewVideoFile = e.target.files[0];
    setEduFormValues(newEduFormValues);
  };

  const handleEduNoteChange = (index, e) => {
    const newEduFormValues = [...eduFormValues];
    newEduFormValues[index].eductionalNote = e.target.value;
    setEduFormValues(newEduFormValues);
  };

  const handleVideoClick = (index) => {
    setCurrentPlayingIndex(index);
    if (videoRefs.current[index]) {
      videoRefs.current[index].play();
    }
  };

  const handleChangeVideo1 = (event) => {
    setFileVideo(URL.createObjectURL(event.target.files[0]));
    setVideo(event.target.files[0]);
  };

  const selectedMedia = (fileName, file) => {
    console.log("fileName=====>", fileName);

    if (fileName.includes("videoFile")) {
      const index = parseInt(fileName.split(" ")[1], 10) - 1;
      const newEduFormValues = [...eduFormValues];
      newEduFormValues[index].eductionalNewVideo = file;
      newEduFormValues[index].eductionalNewVideoFile = file;
      setEduFormValues(newEduFormValues);
    } else {
      setFormData({ ...formData, ...{ [fileName]: file } });
      setFileVideo(file);
      setVideo(file);
    }

    setModalShow(false);
  };

  console.log("eduFormValues====>", eduFormValues);

  const onSubmit = async (data) => {
    setLoader(true);

    const uploadPromises = eduFormValues
      .filter((val) => val.isShow === true)
      .map(async (val) => {
        return {
          educational_video: val.eductionalNewVideo,
          note: val.eductionalNote,
        };
      });

    const eduValuesArrObj = await Promise.all(uploadPromises);

    let mainData = {
      feedback_options: optionValues,
      gender: data.gender != "" ? data.gender : 0,
      note: data.note,
      title: data.title,
      educational_videos: eduValuesArrObj,
    };

    if (video) {
      mainData.video = video;
    }

    await CreateTests(mainData)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/tests");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const deleteImage = (fileName) => {
    setFormData({ ...formData, ...{ [fileName]: "" } });
    setVideo("");
    setFileVideo(null);
  };
  const RedirectTest = () => {
    history.push("/tests");
  };

  return (
    <div className="program-index">
      <div className="page-title mb-3">
        <h1 className="page-heading">Add New Test</h1>
        <Button onClick={RedirectTest} className="btn common-btn">
          Back
        </Button>
      </div>
      <div className="test-index">
        <Form
          className="formAddUser"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row className="mb-4">
            <Col md={3}>
              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Title*</Form.Label>
                <Form.Control
                  placeholder="Enter Title"
                  {...register("title", {
                    required: {
                      value: "required",
                      message: "Title is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Min length is 3",
                    },
                    maxLength: {
                      value: 80,
                      message: "Max length is 80",
                    },
                  })}
                  type="text"
                />
                {errors.title && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.title.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group
                controlId="videoFile"
                // onChange={(e) => handleChangeVideo1(e)}
                onClick={() => {
                  setFileName("video");
                  setModalShow(true);
                }}
                className="custom-file-upload mb-0"
              >
                <Form.Label className={"btn common-btn"}>
                  Upload Explanation Video
                </Form.Label>
              </Form.Group>

              {fileVideo ? (
                <>
                  <div className="image-item ">
                    <video
                      controls
                      className={"img-table img-thumbnail"}
                      src={fileVideo}
                      title="Video"
                    ></video>
                    <Button
                      type="button"
                      className="btn btn-cross"
                      onClick={() => deleteImage("video")}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.8438 3.75H19.6875V2.8125C19.6875 1.2617 18.4258 0 16.875 0H13.125C11.5742 0 10.3125 1.2617 10.3125 2.8125V3.75H5.15625C3.86391 3.75 2.8125 4.80141 2.8125 6.09375V9.375C2.8125 9.89273 3.23227 10.3125 3.75 10.3125H4.26234L5.07229 27.3213C5.14383 28.8234 6.37781 30 7.88156 30H22.1184C23.6222 30 24.8562 28.8234 24.9277 27.3213L25.7377 10.3125H26.25C26.7677 10.3125 27.1875 9.89273 27.1875 9.375V6.09375C27.1875 4.80141 26.1361 3.75 24.8438 3.75ZM12.1875 2.8125C12.1875 2.29559 12.6081 1.875 13.125 1.875H16.875C17.3919 1.875 17.8125 2.29559 17.8125 2.8125V3.75H12.1875V2.8125ZM4.6875 6.09375C4.6875 5.83529 4.89779 5.625 5.15625 5.625H24.8438C25.1022 5.625 25.3125 5.83529 25.3125 6.09375V8.4375C25.0236 8.4375 5.88475 8.4375 4.6875 8.4375V6.09375ZM23.0548 27.2321C23.031 27.7328 22.6196 28.125 22.1184 28.125H7.88156C7.38029 28.125 6.96896 27.7328 6.94518 27.2321L6.13945 10.3125H23.8605L23.0548 27.2321Z"
                          fill="white"
                        ></path>
                        <path
                          d="M15 26.25C15.5177 26.25 15.9375 25.8302 15.9375 25.3125V13.125C15.9375 12.6073 15.5177 12.1875 15 12.1875C14.4823 12.1875 14.0625 12.6073 14.0625 13.125V25.3125C14.0625 25.8302 14.4822 26.25 15 26.25Z"
                          fill="white"
                        ></path>
                        <path
                          d="M19.6875 26.25C20.2052 26.25 20.625 25.8302 20.625 25.3125V13.125C20.625 12.6073 20.2052 12.1875 19.6875 12.1875C19.1698 12.1875 18.75 12.6073 18.75 13.125V25.3125C18.75 25.8302 19.1697 26.25 19.6875 26.25Z"
                          fill="white"
                        ></path>
                        <path
                          d="M10.3125 26.25C10.8302 26.25 11.25 25.8302 11.25 25.3125V13.125C11.25 12.6073 10.8302 12.1875 10.3125 12.1875C9.79477 12.1875 9.375 12.6073 9.375 13.125V25.3125C9.375 25.8302 9.79471 26.25 10.3125 26.25Z"
                          fill="white"
                        ></path>
                      </svg>
                    </Button>
                    {/* <div className="image-item__btn-wrapper"></div> */}
                  </div>
                </>
              ) : (
                <p>No video uploaded</p>
              )}
            </Col>
            <Col md={3}>
              <Form.Group controlId="gender">
                <Form.Label>Gender*</Form.Label>
                <Form.Select
                  className="w-50"
                  placeholder="Select Gender"
                  {...register("gender", {})}
                >
                  <option selected disabled value={""}>
                    Select Gender
                  </option>
                  <option value={genders.FEMALE}>Female</option>
                  <option value={genders.MALE}>Male</option>
                </Form.Select>
                {errors.gender && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.gender.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="note">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="write Note"
                  rows={4}
                  {...register("note", {})}
                />
                {errors.note && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.note.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={5} className="ms-auto">
              <h3>Feedback Questions</h3>
              <div className="form-group-add-text">
                {optionValues.map((_, index) => (
                  <div key={index}>
                    <Form.Group className="mb-4" controlId={`option-${index}`}>
                      <div className="wrapup">
                        {index > 0 ? (
                          <button
                            className={"btn-cross"}
                            onClick={() => removeOptionFormFields(index)}
                            type="button"
                          >
                            X
                          </button>
                        ) : null}
                        <Form.Control
                          value={optionValues[index].option}
                          placeholder={`Enter Option ${index + 1}`}
                          name={`option-${index}`}
                          type="text"
                          minLength={3}
                          // maxLength={80}
                          onChange={(e) => handleOptionChange(index, e)}
                        />
                        {errors.option && errors.option.type === "required" && (
                          <p>This field is required</p>
                        )}
                        {errors.option && (
                          <Form.Text className="text-muted validationText hasError">
                            {errors.option.message}
                          </Form.Text>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                ))}
              </div>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <button
                    onClick={() => addOptionFormFields()}
                    className={"btn common-btn me-2 mt-3"}
                    type="button"
                  >
                    Add More
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>

          <h6>Educational Videos</h6>
          <hr></hr>
          <div>
            <div className="video-horizantal-block-main">
              <div className="video-horizantal-block">
                {eduFormValues.map((eduElement, eduindex) => (
                  <div className="video-horizantal-block-inner" key={eduindex}>
                    <Form.Group
                      controlId={`videoFile-${eduindex}`}
                      className="custom-file-upload"
                      onClick={() => {
                        setFileName(`videoFile ${eduindex + 1}`);
                        setModalShow(true);
                      }}
                    >
                      <Form.Label className={"btn common-btn"}>
                        Upload ED Video {eduindex + 1}
                      </Form.Label>

                      {/* <Form.Control
                        accept="video/*"
                        type="file"
                        onChange={(e) => handleChangeVideo(e, eduindex)}
                      /> */}
                    </Form.Group>
                    <div className="image-item">
                      {eduElement.eductionalNewVideo ? (
                        <video
                          className={"img-table img-thumbnail"}
                          controls
                          title={`Video ${eduindex + 1}`}
                          autoPlay={currentPlayingIndex === eduindex}
                          src={eduElement.eductionalNewVideo}
                          ref={(el) => (videoRefs.current[eduindex] = el)}
                          onClick={() => handleVideoClick(eduindex)}
                        ></video>
                      ) : (
                        <p>No video uploaded</p>
                      )}
                    </div>
                    <Form.Group controlId={`note-${eduindex}`}>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        onChange={(e) => handleEduNoteChange(eduindex, e)}
                        rows={4}
                        name="eductionalNote"
                        value={eduElement.eductionalNote}
                      />
                    </Form.Group>

                    <button
                      className={"btn-cross"}
                      onClick={() => removeEduFormFields(eduindex)}
                      type="button"
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.8438 3.75H19.6875V2.8125C19.6875 1.2617 18.4258 0 16.875 0H13.125C11.5742 0 10.3125 1.2617 10.3125 2.8125V3.75H5.15625C3.86391 3.75 2.8125 4.80141 2.8125 6.09375V9.375C2.8125 9.89273 3.23227 10.3125 3.75 10.3125H4.26234L5.07229 27.3213C5.14383 28.8234 6.37781 30 7.88156 30H22.1184C23.6222 30 24.8562 28.8234 24.9277 27.3213L25.7377 10.3125H26.25C26.7677 10.3125 27.1875 9.89273 27.1875 9.375V6.09375C27.1875 4.80141 26.1361 3.75 24.8438 3.75ZM12.1875 2.8125C12.1875 2.29559 12.6081 1.875 13.125 1.875H16.875C17.3919 1.875 17.8125 2.29559 17.8125 2.8125V3.75H12.1875V2.8125ZM4.6875 6.09375C4.6875 5.83529 4.89779 5.625 5.15625 5.625H24.8438C25.1022 5.625 25.3125 5.83529 25.3125 6.09375V8.4375C25.0236 8.4375 5.88475 8.4375 4.6875 8.4375V6.09375ZM23.0548 27.2321C23.031 27.7328 22.6196 28.125 22.1184 28.125H7.88156C7.38029 28.125 6.96896 27.7328 6.94518 27.2321L6.13945 10.3125H23.8605L23.0548 27.2321Z"
                          fill="white"
                        ></path>
                        <path
                          d="M15 26.25C15.5177 26.25 15.9375 25.8302 15.9375 25.3125V13.125C15.9375 12.6073 15.5177 12.1875 15 12.1875C14.4823 12.1875 14.0625 12.6073 14.0625 13.125V25.3125C14.0625 25.8302 14.4822 26.25 15 26.25Z"
                          fill="white"
                        ></path>
                        <path
                          d="M19.6875 26.25C20.2052 26.25 20.625 25.8302 20.625 25.3125V13.125C20.625 12.6073 20.2052 12.1875 19.6875 12.1875C19.1698 12.1875 18.75 12.6073 18.75 13.125V25.3125C18.75 25.8302 19.1697 26.25 19.6875 26.25Z"
                          fill="white"
                        ></path>
                        <path
                          d="M10.3125 26.25C10.8302 26.25 11.25 25.8302 11.25 25.3125V13.125C11.25 12.6073 10.8302 12.1875 10.3125 12.1875C9.79477 12.1875 9.375 12.6073 9.375 13.125V25.3125C9.375 25.8302 9.79471 26.25 10.3125 26.25Z"
                          fill="white"
                        ></path>
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="save-next-add-more">
              <div className="save-next-add-more-inner">
                <Button
                  onClick={addEduFormFields}
                  className={"btn common-btn me-2 mt-3"}
                  type="button"
                >
                  Add ED Video
                </Button>
              </div>
              <div className="save-next-add-more-inner">
                <Row>
                  <Col className="mt-3" md={8}>
                    {loader ? (
                      <FullPageLoader loading={loader} />
                    ) : (
                      <input
                        type={"submit"}
                        className={"btn common-btn"}
                        value={"Save"}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <ThemeModal
            title={"S3 Browser"}
            content={
              <S3Browser
                fileName={fileName}
                selectedMedia={selectedMedia}
                fileType={"videos"}
              />
            }
            size={"xl"}
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          />
        </Form>
      </div>
    </div>
  );
}

export default AddTest;
