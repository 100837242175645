import { apiUrl } from "../utils/constants";
import { GET, POST, PUT, DELETE } from "./api.service.wrapper";

export const GetEquipments = async (
  keyword = "",
  page = "",
  perPage = "",
  is_paginate = 1,
  orderByValue,
  orderByColumn
) => {
  let params = {};

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  params["is_paginate"] = is_paginate;

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }
  const response = await GET(apiUrl.equipment_list, params);
  return response;
};

export const GetEquipmentByCategory = async (
  id,
  keyword = "",
  page = "",
  perPage = "",
  is_paginate = 1,
  orderByValue,
  orderByColumn
) => {
  let params = {};

  if (keyword) {
    params["keyword"] = keyword;
  }

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  params["is_paginate"] = is_paginate;

  params["is_paginate"] = is_paginate;

  if (orderByValue) {
    params["order"] = orderByValue;
  }

  if (orderByColumn) {
    params["order-column"] = orderByColumn;
  }
  const response = await GET(apiUrl.getEquipmentByCategory + "/" + id, params);
  return response;
};

export const CreateEquipment = async (data) => {
  const response = await POST(apiUrl.equipments, data);
  return response;
};

export const DeleteEquipment = async (id) => {
  const response = await DELETE(apiUrl.equipments, id);
  return response;
};

export const DeleteEquipmentOrder = async (id) => {
  const response = await DELETE(apiUrl.deleteEquipmentOrder, id);
  return response;
};

export const DeleteMultipleEquipment = async (data) => {
  const response = await POST(apiUrl.deleteEquipments, data);
  return response;
};

export const GetEquipmentById = async (id) => {
  const response = await GET(apiUrl.equipment_details + "/" + id);
  return response;
};

export const GetEquipmentOrder = async (id) => {
  const response = await GET(apiUrl.getEquipmentOrder + "/" + id);
  return response;
};

export const UpdateEquipment = async (id, data) => {
  const response = await PUT(apiUrl.equipments, id, data);
  return response;
};

export const UpdateOrderEquipments = async (data) => {
  const response = await POST(apiUrl.updateOrderEquipments, data);
  return response;
};
