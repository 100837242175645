import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';

export const UpdateExerciseTypes = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.exercise_type, id, data, config);
    return response;
}


export const GetExerciseTypes = async (keyword = '', page = '', perPage = '', is_paginate = 1, orderByValue, orderByColumn) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    params['is_paginate'] = is_paginate;

    if (orderByValue) {
        params['order'] = orderByValue;
    }

    if (orderByColumn) {
        params['order-column'] = orderByColumn;
    }
    const response = await GET(apiUrl.exercise_type, params);
    return response;
}

export const GetExerciseTypeById = async (id) => {
    let response = '';
    response = await GET(apiUrl.exercise_type + '/' + id);
    return response;
}

export const CreateExerciseType = async (data) => {
    const response = await POST(apiUrl.exercise_type, data);
    return response;
}

export const UpdateExerciseType = async (id, data) => {
    const response = await PUT(apiUrl.exercise_type, id, data);
    return response;
}

export const DeleteExerciseType = async (id) => {
    const response = await DELETE(apiUrl.exercise_type, id);
    return response;
}

export const DeleteMultipleExerciseType = async (data) => {
    const response = await POST(apiUrl.deleteExerciseType, data);
    return response;
}
