import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import {
  AddIconOutline,
  SearchIcon,
  MenuDots,
  DeleteUpdated,
} from "../../components/SvgIcons";
import { toast } from "react-toastify";
import { constants, cross_origin, STATUS } from "../../utils/constants";
import {
  GetEquipmentByCategory,
  DeleteMultipleEquipment,
  UpdateOrderEquipments,
  DeleteEquipment,
  DeleteEquipmentOrder,
} from "../../services/equipments.service";
import { useHistory, useParams } from "react-router-dom";
import FullPageLoader from "../../components/FullPageLoader";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  styled,
  TextField,
  Tooltip,
} from "@mui/material";
import { MdDelete, MdEdit } from "react-icons/md";
import debounce from "lodash/debounce";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function EquipmentByCategory() {
  let history = useHistory();
  const params = useParams();
  const apiRef = useGridApiRef();
  const category_id = params.id;
  const [loadingOrder, setOrderLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getEquipmentsFunction();
    };

    fetchData();
  }, [keyword]);

  const onEdit = (id) => {
    history.push("/edit-equipment/" + id);
  };

  const onDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      if (willShip) {
        await DeleteEquipmentOrder(id)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getEquipmentsFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const AddUser = () => {
    history.push(`/add-new-equipment/${category_id}`);
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      width: 60,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={selectedRows.includes(params.row.order_no)}
            onChange={() => handleCheckboxChange(params.row.order_no)}
          />
        );
      },
    },
    {
      field: "order_no",
      headerName: "Order Column",
      width: 200,
      // editable: true,
      renderCell: (params) => (
        <TextField
          type="text"
          value={
            inputValues[params.row.id] !== undefined
              ? inputValues[params.row.id]
              : params.row.order_no.toString() // Ensure it's a string for the input field
          }
          onChange={(e) =>
            handleInputChange(
              params.row.id,
              e.target.value,
              params.row.order_no
            )
          }
          InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
      ),
    },
    { field: "category", headerName: "CATEGORY", width: 200 },
    { field: "title", headerName: "TITLE", width: 150 },
    {
      field: "image",
      headerName: "IMAGE",
      width: 150,
      renderCell: (params) => {
        return (
          <img
            src={params.row.image ? params.row.image : constants.IMAGENOTFOUND}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = constants.IMAGENOTFOUND;
            }}
            className={"img-table img-thumbnail"}
            alt={"Image"}
            crossOrigin={cross_origin ? "anonymous" : null}
          />
        );
      },
    },
    { field: "weight_kg", headerName: "Weight KG", width: 150 },
    { field: "weight_lb", headerName: "Weight LB", width: 150 },
    {
      field: "referral_link",
      headerName: "REFERRAL LINK",
      width: 150,
    },
    { field: "note", headerName: "COMMENTS", width: 150 },
    { field: "status", headerName: "STATUS", width: 150 },
    {
      field: "actions",
      headerName: "ACTION",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div>
              <Tooltip title="Edit" arrow>
                <button
                  className={"btn btn-primary btn-sm"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEdit(params.id);
                  }}
                >
                  <MdEdit />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  className={"btn btn-danger btn-sm mx-2"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(params.id);
                  }}
                >
                  <MdDelete />
                </button>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  // const handleInputChange = async (rowId, value, oldIndex) => {
  //   const trimmedValue = value.trim();
  //   console.log("Trimmed Value:", trimmedValue);

  //   // Update input values state
  //   setInputValues((prev) => ({
  //     ...prev,
  //     [rowId]: trimmedValue,
  //   }));

  //   const newValue = trimmedValue === "" ? undefined : parseInt(trimmedValue, 10);
  //   console.log("New Value:", newValue, "Old Index:", oldIndex);

  //   // Only proceed if the new value is different from the old value
  //   if (newValue !== oldIndex && !isNaN(newValue)) {
  //     const categoryId = parseInt(category_id, 10);
  //     const data = {
  //       newValue,
  //       categoryId: categoryId,
  //       id: rowId,
  //     };

  //     try {
  //       setOrderLoading(true);
  //       const result = await UpdateOrderEquipments(data);
  //       console.log("Result Data:", result.data);

  //       if (result.data) {
  //         const updatedRows = result.data.map((dt) => ({
  //           id: dt.id,
  //           title: dt.title,
  //           image: dt.image,
  //           weight_kg: dt.weight_kg,
  //           weight_lb: dt.weight_lb,
  //           referral_link: dt.referral_link,
  //           description: dt.description,
  //           note: dt.note,
  //           status: STATUS[dt.status],
  //           order_no: dt.order_no,
  //           category: dt.equipmentCategory.name,
  //         }));

  //         // Sort updated rows by order_no
  //         updatedRows.sort((a, b) => a.order_no - b.order_no);
  //         console.log("Sorted Rows:", updatedRows);

  //         // Update state with new rows and reset pagination
  //         setRows(updatedRows);
  //         setTotalRows(updatedRows.length);
  //         setPage(1);

  //         // Clear input values to avoid stale data
  //         setInputValues({});
  //       } else {
  //         console.error("Update failed");
  //       }
  //     } catch (error) {
  //       console.error("Failed to update order number:", error);
  //       // Revert the input value if there's an error
  //       setInputValues((prev) => ({
  //         ...prev,
  //         [rowId]: oldIndex.toString(),
  //       }));
  //     } finally {
  //       setOrderLoading(false);
  //     }
  //   }
  // };

  // const UpdateOrder = async (data) => {
  //   setOrderLoading(true);
  //   await UpdateOrderEquipments(data)
  //     .then(async (result) => {
  //       if (result.status) {
  //         if (result.data) {
  //           await getEquipmentsFunction();
  //         }
  //       } else {
  //         // Handle the case where the update fails
  //         console.error("Update failed");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     })
  //     .finally(() => {
  //       setOrderLoading(false);
  //     });
  // };

  const handleInputChange = async (rowId, value, oldIndex) => {
    // Ensure value contains only numbers
    const trimmedValue = value.trim();
    const numericValue = trimmedValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    console.log("Trimmed Numeric Value:", numericValue);

    // Update input values state
    setInputValues((prev) => ({
      ...prev,
      [rowId]: numericValue,
    }));

    const newValue =
      numericValue === "" ? undefined : parseInt(numericValue, 10);

    // Only proceed if the new value is different from the old value
    if (newValue !== oldIndex && !isNaN(newValue)) {
      const categoryId = parseInt(category_id, 10);
      const data = {
        newValue,
        categoryId: categoryId,
        id: rowId,
      };

      try {
        setOrderLoading(true);
        const result = await UpdateOrderEquipments(data);

        if (result.data) {
          const updatedRows = result.data.map((dt) => ({
            id: dt.id,
            title: dt.title,
            image: dt.image,
            weight_kg: dt.weight_kg,
            weight_lb: dt.weight_lb,
            referral_link: dt.referral_link,
            description: dt.description,
            note: dt.note,
            status: STATUS[dt.status],
            order_no: dt.order_no,
            category: dt.equipmentCategory.name,
          }));

          // Sort updated rows by order_no
          updatedRows.sort((a, b) => a.order_no - b.order_no);

          // Update state with new rows and reset pagination
          setRows(updatedRows);
          setTotalRows(updatedRows.length);
          setPage(1);

          // Clear input values to avoid stale data
          setInputValues({});
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.error("Failed to update order number:", error);
        // Revert the input value if there's an error
        setInputValues((prev) => ({
          ...prev,
          [rowId]: oldIndex.toString(),
        }));
      } finally {
        setOrderLoading(false);
      }
    }
  };

  const getEquipmentsFunction = async () => {
    setOrderLoading(true);
    await GetEquipmentByCategory(category_id, keyword, page, perPage, 0)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            const rowData = [];
            setPage(1);
            setTotalRows(result.data.length);
            result.data.map((dt) => {
              rowData.push({
                id: dt?.id,
                title: dt?.title,
                image: dt?.image,
                weight_kg: dt?.weight_kg,
                weight_lb: dt?.weight_lb,
                referral_link: dt?.referral_link,
                description: dt?.description,
                note: dt?.note,
                status: STATUS[dt?.status],
                order_no: dt?.order_no,
                category: dt?.equipmentCategory?.name,
              });
            });
            setRows(rowData);
            setOrderLoading(false);
          }
        } else {
          setOrderLoading(false);
          toast.error(result.message);
        }
      })
      .catch((error) => {
        setOrderLoading(false);
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row);
    if (isSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow !== row)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    }
  };

  const onMultipleDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeEquipments: selectedRows,
      };
      if (willShip) {
        await DeleteMultipleEquipment(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getEquipmentsFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  // const handleRowOrderChange = async (params) => {
  //   setOrderLoading(true);
  //   let data = {
  //     oldIndex: params.oldIndex,
  //     newIndex: params.targetIndex,
  //   };
  //   await UpdateOrder(data);
  //   setOrderLoading(false);
  // };

  return (
    <>
      <div className="users-index">
        <div className="custom-order-filters">
          <div className="common-header-block">
            <section className="add-users-section">
              <Row className="align-items-center">
                <h1>Equipment</h1>
                <Row>
                  <Row>
                    <Col lg={1}></Col>
                    <Col md={5} className="ms-auto">
                      <div className="all-user">
                        <div className="search-bar">
                          <div className="common-search-bar">
                            <InputGroup>
                              <InputGroup.Text id="basic-addon1">
                                <SearchIcon />
                              </InputGroup.Text>
                              <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                onChange={(e) => {
                                  e.target.value.length > 0
                                    ? setKeyword(e.target.value.trim())
                                    : setKeyword(null);
                                }}
                              />
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={2} className="">
                      <Button
                        className={"btn common-btn   w-100"}
                        onClick={AddUser}
                      >
                        <AddIconOutline /> Add New
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Row>
            </section>
          </div>

          <section className="common-table mt-2">
            <div class="delete-icon">
              <Button className={"common-btn"} onClick={onMultipleDelete}>
                <DeleteUpdated />
              </Button>
            </div>
            <div style={{ height: "99vh", width: "100%" }}>
              <StyledDataGrid
                rows={rows}
                loadingOrder={loadingOrder}
                columns={columns}
                // onRowOrderChange={handleRowOrderChange}
                rowReordering
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                pagination
                slots={{ toolbar: GridToolbar }}
                unstable_headerFilters
              />
            </div>
            <div className="theme-dataGrid products-dataGrid">
              <div className="user-pagination">
                <Row>
                  <Col md={4}></Col>
                  <Col md={4}>
                    {" "}
                    <Stack spacing={2} alignItems="center">
                      <Pagination
                        count={""}
                        page={page}
                        onChange={""}
                        size="large"
                      />
                    </Stack>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Stack direction="row" spacing={2} alignItems="center">
                      <label>Rows per page:</label>
                      <Select value={perPage} onChange={""}>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                      </Select>
                    </Stack>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EquipmentByCategory;
