import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';


// export const GetOccupationList = async () => {
//     let response = await GET(apiUrl.sports_list);
//     return response;
// }

export const UpdateOccupations = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.occupations, id, data, config);
    return response;
}


export const GetOccupations = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.occupations, params);
    return response;
}

export const GetOccupationById = async (id) => {
    let response = '';
    response = await GET(apiUrl.occupations + '/' + id);
    return response;
}

export const CreateOccupation = async (data) => {
    const response = await POST(apiUrl.occupations, data);
    return response;
}

export const UpdateOccupation = async (id, data) => {
    const response = await PUT(apiUrl.occupations, id, data);
    return response;
}

export const DeleteOccupation = async (id) => {
    const response = await DELETE(apiUrl.occupations, id);
    return response;
}

export const DeleteMultipleOccupations = async (data) => {
    const response = await POST(apiUrl.deleteOccupations, data);
    return response;
}